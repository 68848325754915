import { useEffect, useState } from "react"
import { Col, Row } from "react-bootstrap"
import { useDispatch } from "react-redux"
import { createLog } from "../../../actions/logs"
import { api } from "../../../helpers/fetch"
import { AccordionToggle } from "../../AccordionToggle"
import { Button } from "../../Button"
import { CustomInput } from "../../CustomInput"
import { SubjectTopicAccBody } from "./SubjectTopicAccBody"


export const SubjectUnitAccBody = ({ key, setUnits, unit, units, role, bimester }) => {

    const dispatch = useDispatch();
    const [addTopic, setAddTopic] = useState(true)
    const [input, setInput] = useState("")
    const [topics, setTopics] = useState([])



    const handleAddUnit = () => { setAddTopic(false) }

    const handleInputUnitNameChange = ({ target }) => setInput(target.value)

    const handleConfirmNewUnit = async () => {
        const data = {
            title: input,
            unit: unit._id,
            activities: []
        }
        await api.post('subject-unit-topics', data).then(res => {
            if (res) {
                setTopics([
                    ...topics,
                    {
                        ...res.data,
                        activities: []
                    }
                ])
            }
            setInput("")
            setAddTopic(true)
        })
    }

    const handleCancelNewUnit = () => {
        setAddTopic(true)
    }

    const handleDelTopic = (e, topic, i) => {
        if (!topic.activities.length) {
            api.delete(`subject-unit-topics/${topic._id}`).then(res => {
                if (res) {
                    const newTopics = topics.filter((topic, index) => index !== i)
                    setTopics(newTopics)
                }
            })
        } else {
            dispatch(createLog({
                type: "error",
                title: "No se pudo borrar",
                text: `El tema que intenta borrar contiene actividades. Elimine estas e intentelo nuevamente`,
            }))
        }
    }


    useEffect(() => {
        setTopics(unit.topics)
    }, [unit])


    return (
        <>
            {topics?.map((topic, i) =>
                <Row className="mx-2" key={topic._id}>
                    <Col className="mb-2">
                        <AccordionToggle items={
                            [{
                                noBorder: true,
                                header: <>
                                    <div className="d-flex space-between w-100">
                                        <h5 className="m-0">{topic.title}</h5>
                                        {
                                            role !== 'students' &&
                                            <Button
                                                type="button"
                                                icon="deleteMUI"
                                                className="button__only-icon-withoutcolor button-s-sb mr-4 p-0 "
                                                iconRight={false}
                                                onClick={(e) => handleDelTopic(e, topic, i)} />
                                        }
                                    </div>
                                </>,
                                body: <SubjectTopicAccBody
                                    setUnits={setUnits}
                                    topic={topic}
                                    role={role}
                                    bimester={bimester}
                                />
                            }]
                        } />
                    </Col>
                </Row>
            )}
            {
                role !== 'students' &&
                (addTopic ?
                    <Button
                        className='mb-3 ml-4 mt-2 btn button-secondary button-m-sb'
                        content="Agregar tema"
                        onClick={handleAddUnit} />
                    :
                    <div className="d-flex mb-4 ml-3 mt-3">
                        <CustomInput
                            classNameInput="mx-3"
                            placeholder="Nombre de la unidad"
                            name="title"
                            value={input}
                            handleInputChange={handleInputUnitNameChange} />
                        <Button
                            icon="check"
                            className="circular-border-success mx-4"
                            onClick={handleConfirmNewUnit} />
                        <Button
                            icon="closeMUI"
                            className="circular-border-error"
                            onClick={handleCancelNewUnit} />
                    </div>)
            }
        </>
    )
}