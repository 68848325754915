import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import { BreadCrumbs } from '../../BreadCrumbs'
import { UploadUserForm } from './UploadUserForm'

export const UploadUser = () => {

    const { state } = useLocation();
    
    return (
        <main>
            <Container className='newuser__container mb-6'>
                <Row>
                    <Col xs={12}>
                        <BreadCrumbs lastSteps={[{ to: "/home", step: "Inicio" }, { to: "/controlsheets", step: "Panel de control" },]} title={`${state?.id ? "Editar" : "Crear"} usuario`}  />
                    </Col>
                </Row>
                {/* Se comenta para darle funcionalidad en el futuro */}
                {/* <Row>
                    <Col xs={12}>
                        <hr />
                    </Col>
                    <Col xs={12} >
                        <Button className="button-secondary button-m-sb d-flex ml-auto " content="Carga masiva" />
                    </Col>
                </Row> */}
                <div className='newuser__form mt-3'>
                    <UploadUserForm id={state ? state.id : undefined} />
                </div>
            </Container>
        </main>
    )
}
