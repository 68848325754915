import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { formatDateForInput } from "../../../helpers/format-date-for-input";
import { Button } from "../../Button";
import { CustomInput } from "../../CustomInput";
import { CustomSelect } from "../../CustomSelect";

export const UserFamilyInformation = ({ form, handleInputChange, disable }) => {
  const [familyMembers, setFamilyMembers] = useState(null);

  useEffect(() => {
    const familyMembersMap = (() => {
      const keys = Object.keys(form).filter((key) =>
        key.startsWith("familyMembers.")
      );
      const indexes = keys.map((key) =>
        parseInt(key.split(".")[1].split(".")[0])
      );
      return indexes.filter(
        (value, index, self) => self.indexOf(value) === index
      );
    })();
    !familyMembers &&
      familyMembersMap.length &&
      setFamilyMembers([...familyMembersMap]);
  }, [form]);

  const getKey = (index, key) => `familyMembers.${index}.${key}`;
  const addFamilyMember = () => {
    setFamilyMembers(
      (familyMembers && [...familyMembers, familyMembers.length]) || [0]
    );
  };

  // ------------------------------------------
  const bonds = [
    { label: "Esposo/a", value: "spouse" },
    { label: "Hijo/a", value: "child" },
    { label: "Padre/madre", value: "parent" },
    { label: "Concuvino", value: "concubin" },
    { label: "Otro", value: "other" },
  ];

  return (
    <>
      <Row className="userper mt-2">
        <Col xs={{ span: 8, offset: 1 }}>
          <div className="d-flex justify-content-between">
            <h4>Grupo familiar</h4>
          </div>
        </Col>
      </Row>
      {familyMembers?.map((member, i) => (
        <div key={i}>
          <Row className="d-flex align-items-center my-5">
            <Col
              xs={{ span: 2, offset: 1 }}
              className="userpersonaldata__form-input body-l"
            >
              <CustomInput
                disabled={!disable}
                placeholder="Ej: Alves, Roberto"
                labelContent="Apellido y nombre"
                name={getKey(i, "fullName")}
                value={form[getKey(i, "fullName")]}
                handleInputChange={handleInputChange}
                classNameInput="body-l w-100"
                classNameLabel="input-label"
                alwaysShow={true}
              />
            </Col>
            <Col xs={2} className="userpersonaldata__form-input body-l">
              <CustomSelect
                disabled={!disable}
                options={bonds}
                labelContent={
                  <span className="color-disabled input-label ">
                    Parentezco
                  </span>
                }
                placeholder="Ej: Esposa"
                name={getKey(i, "bond")}
                values={form[getKey(i, "bond")]}
                handleInputChange={handleInputChange}
                className="mr-3 body-l w-100"
                alwaysShow={true}
              />
            </Col>
            <Col xs={2} className="userpersonaldata__form-input body-l">
              <div className="d-flex position-relative">
                <label htmlFor={""} className="color-dissabled input-label ">
                  Fecha de nacimiento
                </label>
                <CustomInput
                  disabled={!disable}
                  required
                  type="date"
                  name={getKey(i, "birthDate")}
                  handleInputChange={handleInputChange}
                  value={formatDateForInput(form[getKey(i, "birthDate")])}
                  className="userpersonaldata__form-select body-l w-100"
                  placeholder="*Fecha"
                />
              </div>
            </Col>
            <Col xs={2}>
              <CustomInput
                disabled={!disable}
                placeholder="Ej: 20-36502586-6"
                labelContent="Cuil"
                name={getKey(i, "cuil")}
                value={formatDateForInput(form[getKey(i, "cuil")])}
                handleInputChange={handleInputChange}
                classNameInput="body-l w-100"
                classNameLabel="input-label"
                alwaysShow={true}
              />
            </Col>
          </Row>
          {i !== familyMembers.length - 1 && (
            <Row className="d-flex align-items-center ">
              <Col xs={{ span: 10, offset: 1 }}>
                <hr className="m-0" />
              </Col>
            </Row>
          )}
        </div>
      ))}
      <Row className="d-flex align-items-center mt-4 ">
        <Col xs={{ span: 10, offset: 1 }}>
          <Button
            disabled={!disable}
            onClick={addFamilyMember}
            icon="plusMUI"
            content={<span className="button-s-sb"> Agregar familiar</span>}
            className="button-terciary m-0 p-0"
          />
          <hr />
        </Col>
      </Row>
    </>
  );
};
