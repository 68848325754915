import { useEffect, useState } from "react"
import { Col, Container, Row } from "react-bootstrap"
import { Link, useParams } from "react-router-dom"
import { AccordionToggle } from "../../AccordionToggle"
import { Button } from "../../Button"
import { Loading } from "../../Loading"
import { SubjectModuleAccBody } from "./SubjectModuleAccBody"
import { api } from "../../../helpers/fetch"

export const SubjectModules = ({role}) => {

    const { id } = useParams();
    const [loading, setLoading] = useState(true)
    const [data, setData] = useState()

    const getUnits = async (subjectId) => {
        await api.get(`/subjects/${subjectId}/units`).then(res=>{
            setData(res.data);
        })
        setLoading(false)
    }

    useEffect(() => {
        getUnits(id)
    }, [id])
    
    return(
        <>
        {loading ? <Loading /> :
            <>
                <Container>
                    {
                        role !== 'students' &&
                        <Row className="mb-3 d-flex justify-content-end">
                            <Col xs="auto">
                                <Link to={`/subjects/detail/closingperiod/${id}`}>
                                    <Button
                                        className='btn button-primary button-m-sb'
                                        content="Cierre de períodos"
                                        />
                                </Link>
                            </Col>
                        </Row>

                    }
                    <Row>
                        <Col>
                            <h3>1er Cuatrimestre</h3>
                        </Col>
                    </Row>
                    <Row key='firstBimester'>
                        <Col className="mb-2">
                            <AccordionToggle items={
                                    [{
                                        header: <h4 className="m-0">1er Bimestre</h4>,
                                        body:   <SubjectModuleAccBody
                                                    role={role}
                                                    key="firstBimester"
                                                    data={data}
                                                    subjectId={id}
                                                    index={1} />
                                    }]
                                } />
                        </Col>
                    </Row>
                    <Row key="secondBimester">
                        <Col className="mb-2">
                            <AccordionToggle items={
                                    [{
                                        header: <h4 className="m-0">2do Bimestre</h4>,
                                        body:   <SubjectModuleAccBody
                                                    role={role}
                                                    key="secondBimester"
                                                    data={data}
                                                    subjectId={id}
                                                    index={2} />
                                    }]
                                } />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <h3>2do Cuatrimestre</h3>
                        </Col>
                    </Row>
                    <Row key="thirdBimester">
                        <Col className="mb-2">
                            <AccordionToggle items={
                                    [{
                                        header: <h4 className="m-0">3er Bimestre</h4>,
                                        body:   <SubjectModuleAccBody
                                                    role={role}
                                                    key="thirdBimester"
                                                    data={data}
                                                    subjectId={id}
                                                    index={3} />
                                    }]
                                } />
                        </Col>
                    </Row>
                    <Row key="fourthBimester">
                        <Col className="mb-2">
                            <AccordionToggle items={
                                    [{
                                        header: <h4 className="m-0">4to Bimestre</h4>,
                                        body:   <SubjectModuleAccBody
                                                    role={role}
                                                    key="fourthBimester"
                                                    data={data}
                                                    subjectId={id}
                                                    index={4} />
                                    }]
                                } />
                        </Col>
                    </Row>
                </Container>
            </>
        }
        </>
    )
}