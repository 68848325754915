import { api } from '.'

const token = localStorage.getItem('token')

const getHeaders = (token) => token && { headers: { "x-token": token } } || null

const driveApi = {
  async getFiles (jwt) {
    return (await api.get('drive/files', getHeaders(jwt)))?.data
  },
  async uploadFile (file, jwt) {
    return (await api.post('drive/files', file, getHeaders(jwt)))?.data
  },
  async updateFile (id, file, jwt) {
    return (await api.put(`drive/files/${id}`, file, getHeaders(jwt)))?.data
  },
  async deleteFile (file, jwt) {
    return (await api.delete(`drive/files/${file.id}`, getHeaders(jwt)))?.data
  },
}

export default driveApi