import React from 'react'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

export const PaginationControll = ({
    counter,
    increment,
    decrement,
    totalPages
}) => {
    return (
        <div className='d-flex align-items-center'>
            <div className={`circular-border-${counter > 1 ? 'primary' : 'disabled'} d-flex justify-content-center align-items-center  mr-5 p-2`} >
                <button 
                    className={`button__only-icon-withoutcolor icons__24 m-0 p-0 color-${counter > 1 ? 'primary' : 'disabled'}`}
                    onClick={decrement}
                    disabled={counter === 1}
                    
                >
                    <ArrowBackIosNewIcon className='' />
                </button>
            </div>
            <span className='button-l-sb color-text'>{counter}</span>
            <div className={`circular-border-${totalPages === counter ? 'disabled' : 'primary'} d-flex justify-content-center align-items-center ml-5 p-2`}>
                <button 
                    className={`button__only-icon-withoutcolor cons__24 m-0 p-0 color-${totalPages === counter ? 'disabled' : 'primary'}`}
                    onClick={increment}
                    disabled={totalPages === counter}
                >
                    <ArrowForwardIosIcon className='' />
                </button>
            </div>
        </div>
    )
}
