import { useEffect, useState } from "react"
import { Col, Container, Row } from "react-bootstrap"
import { useDispatch } from "react-redux"
import { useParams } from "react-router-dom"
import { useHistory } from "react-router-dom"

import { api } from "../../../helpers/fetch"
import { useForm } from "../../../hooks/useForm"
import { BreadCrumbs } from "../../BreadCrumbs"
import { CustomInput } from "../../CustomInput"
import { CustomSelect } from "../../CustomSelect"
import { Loading } from "../../Loading"
import { ScheduleDay } from "./ScheduleDay"
import { Button } from "../../Button"
import { createLog } from "../../../actions/logs"


export const NewSubject = () => {

    const [loading, setLoading] = useState(true)
    const dispatch = useDispatch();
    const { goBack } = useHistory();
    const { courseid } = useParams();
    const { subjectid } = useParams();

    //--------- Course Info --------------
    const [course, setCourse] = useState();

    //--------- Subject Info --------------
    const [values, handleInputChange, setValues] = useForm({
        name: "",
        schedule: [
            {
                day:"",
                fromHour: "",
                fromMin: "",
                toHour: "",
                toMin: ""
            }],
        teacher: ""
    })

    //--------- schedule Info --------------

    const defaultSheduleValues = [{day:"", fromHour: "", fromMin: "", toHour: "", toMin: ""}]

    const [scheduleDay, setScheduleDay] = useState(values.schedule)

    useEffect(() => {
        setValues({
            ...values,
            schedule: scheduleDay
        })
    }, [scheduleDay])
    
    const handleAddDay = () => {
        setScheduleDay([
            ...scheduleDay,
            defaultSheduleValues
        ])
    }

    const handleDelDay = () => {
        const copySchudle = values.schedule;
        copySchudle.pop()
        setValues({
            ...values,
            schedule: copySchudle
        })
    }

    //--------- Teacher --------------
    const [teacher, setTeacher] = useState();

    const teachersOptions = teacher?.map((teacher) => {
        return {label:`${teacher.name} ${teacher.lastName}` , value: teacher._id};
    });

    const getTeachers = () => {
        api.get("auth/getUsers?userRole=teachers&userRole=directorate").then(res => {
            const users = res.data.users.filter(aux => aux?.userAllRoles?.length > 1 || aux.userRole === 'teachers');
            setTeacher(users);
        })
    }

    const onSubmitEvent = () => {
        const data = Object.assign({}, course, values);
        api.post("subjects", data).then(res => {
            if(res.data.ok === true) {
                dispatch(createLog({
                    type: "success",
                    title: "¡Creación Exitosa!",
                    text: `La materia ${values.name} se creó con éxito.`,
                }))
                goBack();
            } else {
            dispatch(createLog({
                type: "error",
                title: "Hubo un problem",
                text: `La materia no pude ser creada, vuelve a intentarlo.`,
            }))
            }
        })
    }

    const onEditEvent = () => {
        api.put(`subjects/edit/${subjectid}`, values).then(res => {
            if(res.data.ok === true) {
                dispatch(createLog({
                    type: "success",
                    title: "Edición Exitosa!",
                    text: `La materia ${values.name} se editó con éxito.`,
                }))
                goBack();
            } else {
            dispatch(createLog({
                type: "error",
                title: "Hubo un problem",
                text: `La materia no pude ser editada, vuelve a intentarlo.`,
            }))
            }
        })
    }

    const onsubmitCancel = () => {
        goBack();
    }


    const getCourseDetail = async (id) => {
        await api.get(`courses/getById/${id}`).then(res => {
            setCourse(res.data.course);
            setLoading(false);
        })
    }

    const getSubjectDetail = async (id) => {
        await api.get(`subjects/getById/${id}`).then( async res => {
            await setScheduleDay(res.data.subject.schedule);
            await setValues(res.data.subject);
            await setValues({
                ...values,
                name: res.data.subject.name,
                teacher: res.data.subject.teacher._id,
                schedule: res.data.subject.schedule

            })

        })
    }

    useEffect(() => {
        getCourseDetail(courseid);
        getTeachers();
        if (subjectid) {
            getSubjectDetail(subjectid)
        }
    }, [courseid, subjectid])


    return(
        <>
            {loading ? <Loading /> :
                <Container>
                    <Row>
                        <Col xs={12}>
                            <BreadCrumbs lastSteps={[{ to: "/home", step: "Inicio" }, { to: "/Courses", step: "Cursos" }, { to: `/courses/detail/${courseid}`, step: `${course.age} ${course.division}` }]} title={subjectid ? values.name : 'Nueva Materia'} />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <hr className='w-100' />
                        </Col>
                    </Row>
                    <div className="coursenew_form px-5 py-3">
                        <Row>
                            <Col xs={3} className='my-3'>    
                                <p>{`Curso: ${course.age} ${course.division}`}</p>
                            </Col>
                            <Col xs={2} className='my-3'>    
                                <p>{`Turno: ${course.shift}`}</p>
                            </Col>
                            <Col xs={2} className='my-3'>    
                                <p>{`Nivel: ${course.level}`}</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={5} className='my-3'>    
                                <CustomInput classNameInput="w-100" name="name" handleInputChange={handleInputChange} value={values.name} placeholder='* Nombre de la materia' hidden={false} labelContent={<><span>*</span> Nombre de la materia</>} />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <hr className='w-100' />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                {scheduleDay.map(
                                    (schedule, i) => {
                                        return <ScheduleDay
                                            key={i}
                                            index={i}
                                            schedule={schedule}
                                            scheduleDay={scheduleDay}
                                            setScheduleDay={setScheduleDay} />} 
                                )}
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={2}>
                                <Button
                                    icon="plusMUI"
                                    content={<span className="ml-1 mt-1">Agregar día</span>}
                                    className=" button-terciary button-s-sb d-flex align-items-center m-0 p-0 "
                                    iconRight={false}
                                    type="button"
                                    onClick={handleAddDay}
                                />
                            </Col>
                            <Col xs={2}>
                                <Button
                                    icon="removeMUI"
                                    content={<span className="ml-1 mt-1">Quitar día</span>}
                                    className=" button-terciary button-s-sb d-flex align-items-center m-0 p-0 "
                                    iconRight={false}
                                    type="button"
                                    onClick={handleDelDay}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <hr className='w-100' />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={3} className='my-3'>    
                                <CustomSelect options={teachersOptions} name="teacher" values={values.teacher} handleInputChange={handleInputChange} placeholder='Docente a cargo' />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <hr className='w-100' />
                            </Col>
                        </Row>
                        <Row className="mb-5">
                            <Col xs={{ span: 10, offset: 1 }} className="d-flex">
                                <div className='ml-auto d-flex'>
                                    <Button
                                        type="reset"
                                        className='btn button__only-icon-withoutcolor color-primary  button-m-sb mr-5'
                                        content="Cancelar"
                                        onClick={onsubmitCancel} />
                                    <Button
                                        className='btn button-primary button-m-sb'
                                        content={subjectid ? 'Editar curso' : 'Crear curso'}
                                        onClick={subjectid ? onEditEvent : onSubmitEvent}
                                        disabled={(values.name && values.schedule && values.teacher) ? false : true} />
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>
            }
        </>
    )
}