import { Col, Row } from "react-bootstrap"
import classNames from "classnames";
import { CustomSelect } from "../../CustomSelect"

export const ScheduleDay = ({index, schedule, setScheduleDay, scheduleDay, disable= false}) => {


    const dayOptions = ["Lunes", "Martes", "Miércoles", "Jueves", "Viernes"];
    const hoursOptions = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23"];
    const minuteOptions = ["00", "05", "10", "15", "20", "25", "30", "35", "40", "45", "50", "55"];

    const handleItemChange = ({ target }) => {
        setScheduleDay(
            scheduleDay?.map((schedule, itemIndex) =>
                (itemIndex === index ? { ...schedule, [target.name]: target.value } : schedule)
            )
        );
    };

    return(
        <>
            <Row className={classNames({ "mb-1": index === 0, "my-3": index !== 0 })}>
                <Col xs={2} className='my-3'>    
                    <CustomSelect options={dayOptions} name="day" values={schedule.day} handleInputChange={handleItemChange} placeholder={'día'} disabled={disable} />
                </Col>
                <Col xs={1} className='my-3'>    
                    <CustomSelect className="time-selector" options={hoursOptions} name="fromHour" values={schedule.fromHour} handleInputChange={handleItemChange} placeholder={'--'} disabled={disable} />
                </Col>
                <Col xs={1} className='my-3'>    
                    <CustomSelect className="time-selector" options={minuteOptions} name="fromMin" values={schedule.fromMin} handleInputChange={handleItemChange} placeholder={'--'} disabled={disable} />
                </Col>
                <Col xs={1} className='my-3'>
                    <p className="text-align-center">-</p>
                </Col>
                <Col xs={1} className='my-3'>    
                    <CustomSelect className="time-selector" options={hoursOptions} name="toHour" values={schedule.toHour} handleInputChange={handleItemChange} placeholder={'--'} disabled={disable} />
                </Col>
                <Col xs={1} className='my-3'>    
                    <CustomSelect className="time-selector" options={minuteOptions} name="toMin" values={schedule.toMin} handleInputChange={handleItemChange} placeholder={'--'} disabled={disable} />
                </Col>
                
            </Row>
        </>
    )

}