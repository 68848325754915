export const SubjectInfoText = ({ name, values, className = '' }) => {
    return (
        <div className={"d-flex " + className}>
            <p className="body-l color-header-cartelera">{name}: </p>
            <div className="ml-1">
                {values?.map((value, i) =>
                    <p key={i} className="body-l-sb color-header-cartelera"> {value}</p>
                )}
            </div>
        </div>
    )
}
