import React from 'react'

export const EmptyEvent = () => {
    return (
        <div className='upcomingEventCard p-3'>
            <h4 className='mb-3'>Sin eventos próximos</h4>
            <p className='body-m'>
                En breve se agregarán nuevos eventos y actividades para compartir con toda la institución.
            </p>
        </div>
    )
}
