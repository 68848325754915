import { types } from "../types/types";

const initialState = {
    user: {
        uid: null
    },
}

export const authReducer = ( state = initialState, action) => {
    
    switch ( action.type ) {
        case types.authLogin:
            return{
                ...state,
                ...action.payload,
            }
        case types.authLogout:
            return{
                ...initialState,
            }
        default:
            return state;
    }

}
