import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { useParams } from "react-router-dom/cjs/react-router-dom.min"
import { createLog } from "../../../actions/logs"
import { api } from "../../../helpers/fetch"
import { AccordionToggle } from "../../AccordionToggle"
import { Line } from "../../Line"
import { Loading } from "../../Loading"
import { SubjectStudentConceptualReport } from "./SubjectStudentConceptualReport"
import { SubjectStudentEvaluativeReport } from "./SubjectStudentEvaluativeReport"

const periods = [
    { bimester: '1', name: '1er Bimestre' },
    { bimester: '2', name: '2do Bimestre' },
    { bimester: '3', name: '3er Bimestre' },
    { bimester: '4', name: '4to Bimestre' }
]

export const SubjectStudentReport = ({ type, title, course, id, role }) => {
    const { id: subjectId, uid: studentId } = useParams()
    const [criteria, setCriteria] = useState([])
    const [standard, setStandard] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [edit, setEdit] = useState(false)
    const dispatch = useDispatch();

    const handleInputChangeObject = (bimester, key, value) => {
        const filteredCriteria = criteria.filter(criterion => criterion?.bimester !== bimester)
        const currentCriterion = criteria.find(criterion => criterion?.bimester === bimester)

        setCriteria([...filteredCriteria, {
            ...currentCriterion,
            bimester,
            [key]: value
        }])
    }

    const getAssessments = async () => {
        try {
            const res = await api.get(`assessments/getAssessmentsByStudentByYear/${id ? id : studentId}/${(new Date()).getFullYear()}`)
            const subjectStudentAssesment = res.data.assessments?.find(assessment => assessment?.subject?._id === subjectId)
            setCriteria(subjectStudentAssesment?.assessmentCriteria)

            const standardArray = subjectStudentAssesment?.assessmentStandard
            const standardFormat = []

            for (const std of standardArray) {
                await api.get(`subject-units/${std?.topic?.unit}`).then(res =>
                    standardFormat.push({
                        ...std,
                        topic: {
                            ...std.topic,
                            unit: res.data
                        }
                    })
                )
            }

            setStandard(standardFormat)
            setIsLoading(false)

        } catch (err) {
        }
    }

    const [infoActivity, setInfoActivity] = useState([])
    const [infoStudent, setInfoStudent] = useState([])
    const getActivity = async () => {
        try {
            const studentData = await api.get(`activity-deliveriesbystudent/${id ? id : studentId}`)
            
            const filterStudentData = studentData.data.filter(act => act.subject.toString() === subjectId.toString())
            setInfoStudent(filterStudentData)
    
            const activityarr = []
            for (const std of filterStudentData) {
                await api.get(`activities/${std.activity}`).then(res => {
                    activityarr.push(res.data)
                })
            }
            setInfoActivity(activityarr)
            
        } catch (error) {
            console.log(error)
        }
    }

    const handleSaveClick = async () => {
        try {
            api.post(`assessments/${studentId}/${subjectId}`, { assessmentCriteria: criteria })
            dispatch(
                createLog({
                    type: "success",
                    title: "Informe valorativo guardado correctamente."
                })
            )
        } catch (error) {
            dispatch(
                createLog({
                    type: "error",
                    title: "No se pudo guardar correctamente."
                })
            )

        }
    }

    const handleCancelClick = () => {
        setEdit(false)
    }
    const handleEditClick = () => {
        setEdit(true)
    }

    useEffect(() => {
        getAssessments()
        getActivity()
    }, [studentId, subjectId])


    return (
        <>{isLoading ? <Loading /> :
            <AccordionToggle
                classNameAccordion='mt-3'
                items={[
                    {
                        header: <h4 className="m-0">{title}</h4>,
                        body:
                            <div className="mx-3">
                                <Line />
                                <AccordionToggle
                                    alwaysOpen={false}
                                    classNameAccordion='mx-2'
                                    separator={<Line />}
                                    items={
                                        periods.map((period, i) => {
                                            const standardsByBimester = standard.filter(std => std?.topic?.unit?.bimester === i)
                                            return {
                                                classNameHeader: 'px-2',
                                                classNameCard: 'border-0',
                                                header: <h5 className="m-0">{period.name}</h5>,
                                                body: <div key={period.name}>
                                                    {type === 'conceptual' && <SubjectStudentConceptualReport {...{ period, criteria, handleInputChangeObject, handleSaveClick, handleCancelClick, handleEditClick, role, edit }} />}
                                                    {type === 'evaluative' && <SubjectStudentEvaluativeReport course={course} standard={standardsByBimester} infoActivity={infoActivity} period={period.bimester} infoStudent={infoStudent} role={role} />}
                                                </div>
                                            }
                                        })
                                    }
                                />
                            </div>
                    }
                ]}
            />
        }</>
    )
}
