import React from 'react'
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import InsertInvitationOutlinedIcon from '@mui/icons-material/InsertInvitationOutlined';
import { Link } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/es';

export const ReminderEventCard = ({ reminders = [] }) => {


    return (
        <div className='remindereventcard__container p-3'>
            <h5>Recordatorios</h5>
            <hr className='remindereventcard__hr w-100 my-2' />
            {reminders.map((e, i) =>
                <div className='d-flex flex-direction-column mb-2  ' key={e + i}>
                    <p className='body-s-b'>{e.title}</p>
                    <div className='mt-2 d-flex align-items-center icons__18 '>
                        <AccessTimeOutlinedIcon className='color-labels' />
                        <span className='body-s text-nowrap ml-2'>
                        {moment(e.fromHour).format("dddd hh:mm A ")}
                        </span>
                    </div>
                    <hr className='remindereventcard__hr w-100 my-2' />
                </div>
            )}
            <div className='w-100 d-flex justify-content-center mt-auto'>
                <Link to="/calendar" className='remindereventcard__link'>
                    <InsertInvitationOutlinedIcon /><span className='button-s-sb'>Ver todos los eventos</span>
                </Link>
            </div>
        </div>
    )
}
