import React, { useEffect, useState } from 'react'
import { Container, Col, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { BreadCrumbs } from '../../BreadCrumbs'
import { Button } from '../../Button'
import { CustomFabicon } from '../../CustomFabicon'
import { Board } from '../../Board'
import { CustomOptions } from '../../CustomOptions'
import { Loading } from '../../Loading'
import { EmptyStates } from '../../EmptyStates'
import emptyMessages from "../../../Imgs/EmptyStates/Messages 01.png"
import classNames from 'classnames'
import { Fab } from '@mui/material'
import { getInbox, getSent } from '../../../api/messages'

export const Messages = () => {
  const appendMessages = (oldMessages, newMessages) => {
    updateMessages([
      ...(oldMessages || []),
      ...(newMessages || [])
    ])
  }

  const updateMessages = (list) => {
    setViewMessages(list)
    messageList = list
  }

  const wrapLoader = (fn) => (...args) => {
    setLoading(true)
    fn(...args).finally(() => setLoading(false))
  }

  const fetcher = (fetchFn) => wrapLoader(async (params = {}) => {
    const { clear } = params
    const { messages, nextPageToken, resultSizeEstimate } = await fetchFn(!clear && _nextPageToken)
    _nextPageToken = nextPageToken
    setPagination({
      nextPageToken,
      resultSizeEstimate
    })
    appendMessages(!clear && messageList, messages)
  })

  const loadInbox = fetcher(getInbox)
  const loadSent = fetcher(getSent)

  const resetInbox = () => {
    updateMessages([])
    setPagination({
      nextPageToken: '',
      resultSizeEstimate: 0
    })
  }
  // ------------------------------------------
  const [viewMessageList, setViewMessages] = useState([]);
  let messageList = viewMessageList
  const [loading, setLoading] = useState(true);
  const [pagination, setPagination] = useState({
    nextPageToken: '',
    resultSizeEstimate: 0
  })
  let _nextPageToken = pagination.nextPageToken

  const options = [{
    label: "Recibidos",
    type: "Recibidos",
    optionFunc: () => {
      resetInbox()
      loadInbox({ clear: true })
    },
    loadMoreFn: loadInbox
  }, {
    label: "Enviados",
    type: "Enviados",
    optionFunc: () => {
      resetInbox()
      loadSent({ clear: true })
    },
    loadMoreFn: loadSent
  }]

  const [value, setValue] = useState(options[0]);


  useEffect(() => {
    loadInbox({ clear: true });
  }, [])

  const LoadMoreBtn = () => <Fab
    size="large" variant="extended" aria-label="add" style={{
      backgroundColor: "#0056A8",
    }}
    onClick={() => value?.loadMoreFn()}
  >
    Cargar más
  </Fab>

  return (
    <main className='mb-6'>
      <Container className="position-relative mb-6 ">


        <div className='messages__container '>
          <Row>
            <Col xs={12}>
              <BreadCrumbs lastSteps={[{ to: "/home", step: "Inicio" }]} title="Mensajes" onClick={loadInbox} />
            </Col>
          </Row>
          <Row className="mt-5">
            <Col xs={12}>
              <CustomOptions
                options={options} value={value} setValue={setValue} />
            </Col>
          </Row>
        </div>
        {(() => {
          if (loading) return <Loading />

          if (viewMessageList.length) return <>
            <Board firstRow={
              <Col xs={12} className="d-flex justify-content-between">
                <div className='my-2'>
                  <Button icon="refreshMUI" className="button__only-icon-withoutcolor" />
                </div>
                <div className='d-flex flex-nowrap align-items-center color-labels'>
                  <p className='body-s-regular messages-amount mr-3 mt-1'>
                    1-{viewMessageList.length} de {pagination.resultSizeEstimate}
                  </p>
                  <div className='d-flex mb-2  '>
                    <Button icon="arrowLeftMUI" className="button__only-icon-withoutcolor color-background icons__16 p-0 m-0" disabled />
                    <Button icon="arrowRightMUI" className="button__only-icon-withoutcolor color-background icons__16  p-0 ml-3" disabled={!pagination.nextPageToken} />
                  </div>
                </div>
              </Col>}
              rows={
                viewMessageList?.map((row, i) =>
                  <Link to={`/messages/messagedetail/${row.threadId}`} key={row.id} >
                    <Row className={classNames('py-1', { "board__row ": i !== viewMessageList?.length, "board__last-row ": i === viewMessageList?.length - 1 })}>
                      <Col xs={3}>
                        <p className='body-m py-3'>{row.payload.headers.find(head => head.name === "From").value}</p>
                      </Col>
                      <Col xs={6}>
                        <p className='body-m py-3'>{row.snippet}</p>
                      </Col>
                      <Col xs={3} className="ml-auto">
                        <p className='body-m py-3'>{row.payload.headers.find(head => head.name === "Date").value}</p>
                      </Col>
                    </Row>
                  </Link>
                )
              }
            />
            {
              pagination.nextPageToken && <div className="text-center mt-3">
                <LoadMoreBtn />
              </div>

            }
          </>

          return <EmptyStates src={emptyMessages} title="Aún no recibiste mensajes" text="Cuando recibas mensajes se visualizarán aquí, mientras puedes envíar un mensaje o chequear tus materias." /> //ojo que el mensaje y el title varia según el usuario
        })()}
        <CustomFabicon text="Nuevo mensaje" to="/messages/new" />

      </Container >
    </main>
  )
}
