import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'

import { Base64 } from 'js-base64';
import { forwardMessage, getMessageAttachment, replyToMessage } from '../../../../api/messages';
import { Button } from '../../../Button';
import { DropDown } from '../../../DropDown';
import { File } from '../../../File';
const VIEW_TYPES = {
  VIEW: 'VIEW',
  REPLY: 'REPLY',
  FORWARD: 'FORWARD'
}

function download (blob, filename) {
  const a = document.createElement('a')
  a.href = URL.createObjectURL(blob)
  a.setAttribute('download', filename)
  a.click()
  a.remove()
}

function openFile (blob) {
  const url = URL.createObjectURL(blob)
  window.open(url, '_blank')
}

const getMessageHeader = (message, header) => message?.payload?.headers?.find(head => head.name?.toLowerCase() === header?.toLowerCase())
const getFrom = (message) => getMessageHeader(message, 'from')
const getTo = (message) => getMessageHeader(message, 'to')

const getMessageBody = (msg) => {
  const partTypes = [
    "text/html",
    "text/plain",
  ]
  const alternateParts = [
    'multipart/related',
    'multipart/alternative',
  ]
  const parts = (() => {
    const parts = msg?.payload?.parts
    const findPart = (_parts) => {
      if (_parts.some(part => partTypes.includes(part.mimeType)))
        return _parts

      const _parts2 = _parts.find(part => alternateParts.includes(part.mimeType))
      if (_parts2) return findPart(_parts2.parts)
      return null
    }
    return parts && findPart(parts)
  })()
  const part = parts && partTypes.reduce((acc, type) => acc || (parts).find(part => part.mimeType === type), null)

  const data = part?.body?.data || msg.payload.body?.data || ''

  return Base64.decode(data)
}



export const ThreadMessage = ({ msg, onUpdateThread, isLast }) => {
  const [currentViewType, setCurrentViewType] = useState(VIEW_TYPES.VIEW);
  const canReply = isLast

  // ------------------------------------------
  const MessageReplyEditor = () => {
    const editingMessage = JSON.parse(JSON.stringify(msg || ''))

    if (!editingMessage) return null

    const from = getFrom(editingMessage)
    const fromEmail = (() => {
      if (from?.value?.includes('<')) {
        return from?.value?.split('<')[1]
      }
      return from?.value
    })()?.replace('>', '')?.trim()
    from.value = `Responder a <${fromEmail}>`
    let messageText = ''
    const submit = async () => {
      if (!messageText) return // TODO show error
      await replyToMessage(editingMessage.threadId, fromEmail, messageText)
      onUpdateThread()
    }

    return <>
      <Message msg={editingMessage} isReply={true}>
        <Row>
          <Col xs={{ span: 10, offset: 1 }} >
            <label htmlFor="reciever" className='w-100 ' >
              Mensaje
            </label>
            <textarea placeholder="Escribe tu respuesta" name="message" onChange={(e) => messageText = e.target.value} className="newmessages__textarea mt-2 body-l w-100 p-3" rows="10" />
          </Col>
          <Col xs={{ span: 10, offset: 1 }} className="d-flex justify-content-end">
            <Button onClick={() => setCurrentViewType(VIEW_TYPES.VIEW)} content={<span>Cancelar</span>} className="button-terciary button-m-sb mr-2" />
            <Button onClick={submit} content={<span>Enviar Mensaje</span>} className="button-primary mr-4 button-m-sb" />
          </Col>
        </Row>
      </Message>
    </>
  }
  // ------------------------------------------
  const MessageForwardSelector = () => {
    let email = ''
    const submit = async (e) => {
      e.preventDefault()
      if (!email) return // TODO show error
      await forwardMessage(msg?.id, [email])
      setCurrentViewType(VIEW_TYPES.VIEW)
    }

    return <>
      <form onSubmit={submit}>
        <Row>
          <Col xs={{ span: 10, offset: 1 }} className="d-flex justify-content-end custom-input mb-3">
            <input required type="email" placeholder="Reenviar a" name="reciever" className="body-l w-100" onChange={(e) => email = e.target.value} />
          </Col>
          <Col xs={{ span: 10, offset: 1 }} className="d-flex justify-content-end">
            <Button type="button" onClick={() => setCurrentViewType(VIEW_TYPES.VIEW)} content={<span>Cancelar</span>} className="button-terciary button-m-sb mr-2" />
            <Button type="submit" content={<span>Reenviar Mensaje</span>} className="button-primary mr-4 button-m-sb" />
          </Col>
        </Row>
      </form>
    </>
  }
  // ------------------------------------------

  return <>
    <Message msg={msg} isReply={false} isForward={false} canReply={canReply} setCurrentViewType={setCurrentViewType} />
    <div className='mb-4'>
      {
        (() => {
          switch (currentViewType) {
            case VIEW_TYPES.VIEW:
              return isLast && (
                <Row className='pb-2'>
                  <Col xs={{ span: 10, offset: 1 }} >
                    {
                      canReply && <Button onClick={() => setCurrentViewType(VIEW_TYPES.REPLY)} content={
                        <span className="ml-3">
                          Responder
                        </span>}
                        icon="replyMUI" className="button-primary mr-4  button-m-sb" />
                    }
                    <Button onClick={() => setCurrentViewType(VIEW_TYPES.FORWARD)} content={
                      <span className="ml-3 pt-2">
                        Reenviar
                      </span>} icon="redoMUI" className="button-secondary button-m-sb ml-2" />
                  </Col>
                </Row>
              )
            case VIEW_TYPES.REPLY:
              return canReply && (
                <MessageReplyEditor />
              )
            case VIEW_TYPES.FORWARD:
              return (
                <MessageForwardSelector />
              )
          }
        })()
      }
    </div>
  </>
}

const Message = ({ msg, isReply, isForward, canReply, setCurrentViewType, children }) => {
  const messageDropdownOptions = [
    {
      name: 'Responder',
      icon: 'replyMUI',
      action: () => {
        setCurrentViewType(VIEW_TYPES.REPLY)
      },
      enabled: canReply,
    },
    {
      name: 'Reenviar',
      icon: 'forwardMUI',
      action: () => {
        setCurrentViewType(VIEW_TYPES.FORWARD)
      }
    },
  ]
    .filter(option => !('enabled' in option) || option.enabled)


  const IFrameContent = ({ srcDoc }) => {
    const iframeContentRef = React.createRef()

    useEffect(() => {
      const iframe = iframeContentRef.current
      if (iframe) {
        iframe.addEventListener('load', function () {
          const iframeBody = this.contentWindow.document.body
          const margin = 16
          const height = (Math.max(iframeBody.scrollHeight, iframeBody.offsetHeight) || 0) + margin
          this.style.height = `${height}px`
        })
      }
    }, [iframeContentRef])

    return (
      <iframe
        ref={iframeContentRef}
        srcDoc={srcDoc}
        className="messagesdetail__html"
        style={{ border: 'none' }}
      />
    )
  }



  const [showingOptions, setShowingOptions] = useState(false)
  const hideOptions = () => {
    setShowingOptions(false)
  }
  const trigger = (option) => {
    option.action()
    hideOptions()
  }
  const toggleOptions = (e) => {
    e.preventDefault()
    e.stopPropagation()
    setShowingOptions(!showingOptions)
  }

  const attachments = (msg.payload?.parts?.filter(part => part.body?.attachmentId) || [])
    .map(part => {
      return {
        ...part,
        name: part.filename,
      }
    })

  const getAttachmentBlob = async (file) => getMessageAttachment(msg.id, file.body.attachmentId, file.mimeType, file.filename)

  const openAttachment = async (file) => {
    const blob = await getAttachmentBlob(file)
    openFile(blob)
  }

  const fileOptions = [
    {
      name: 'Descargar',
      icon: 'downloadMUI',
      action: async (file) => {
        const blob = await getAttachmentBlob(file)
        download(blob, file.filename)
      }
    }
  ]

  const showMessageContent = isReply || isForward
  return <>
    <Row>
      <Col xs={{ span: 10, offset: 1 }} className="messagedetail__mail-header">
        <div className='position-relative'>
          <div className='d-flex flex-direction-column'>
            <div className='d-flex flex-direction-row'>
              <span className='body-m-sb'>{getFrom(msg)?.value}</span>
            </div>
            {!isReply && <p className=' body-s color-disabled mt-2'>{getTo(msg)?.value} </p>} {/* TODO show 'para mi' with matching user email*/}
          </div>
          <img src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png" alt="imagen de perfil del usuario" className='messagedetail__profile-img' />
        </div>
        {
          !isReply &&
          <>
            <div className='body-s color-labels'>
              {msg?.payload?.headers.find(head => head.name === "Date").value}
            </div>
            <DropDown options={messageDropdownOptions}>
            </DropDown>
          </>
        }

      </Col>
    </Row>
    {
      (() => {
        if (!showMessageContent) return (
          <Row className='mt-4 mb-5 pb-2'>
            <Col xs={{ span: 10, offset: 1 }} className="messagedetail__mail-body">
              <IFrameContent
                title={msg.snippet}
                srcDoc={getMessageBody(msg) || ''}
                className="messagesdetail__html"
              />
            </Col>
            <Col xs={{ span: 10, offset: 1 }} className="d-flex">
              {
                attachments.map(attachment => <File className='mr-2' file={attachment} options={fileOptions} noUrl={true} onClick={() => openAttachment(attachment)} />)
              }
            </Col>
          </Row>
        )
        return children
      })()

    }

  </>
}
