import React, { useState } from 'react'
import { useForm } from "../../../hooks/useForm"
import { Col, Row } from 'react-bootstrap'
import { Button } from '../../Button';
import { api } from '../../../helpers/fetch';
import { useDispatch } from 'react-redux';
import { createLog } from '../../../actions/logs';
import { FilePicker } from 'react-file-picker'
import { useHistory } from 'react-router-dom';

export const MessageForm = () => {
  const [values, handleInputChange, setFormValue] = useForm({
    reciever: [],
    cc: [],
    cco: [],
    subject: "",
    message: "",
    attachments: [] /* {
      name: '',
      mimeType: '',
      content: '',
    } */
  });
  const dispatch = useDispatch()
  const { goBack } = useHistory();

  const onSubmitEvent = async () => {
    const formData = new FormData()
    formData.append('to', values.reciever)
    formData.append('cc', ccFor || [])
    formData.append('bcc', ccoFor || [])
    formData.append('subject', values.subject)
    formData.append('message', values.message)
    values.attachments.forEach((attachment, i) => {
      formData.append('files', attachment)
    })

    const resp = await api.post('/messages', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })

    if (resp.status === 200) {
      dispatch(createLog({
        type: "success",
        title: "Enviado!",
        text: "El mensaje ha sido enviado correctamente...",
      }))
      goBack();
    } else {
      dispatch(createLog({
        type: "error",
        title: "La entrega no se envió",
        text: "Por favor, intenta nuevamente.",
      }))
    }
  }
  const [options, setOptions] = useState({ cc: false, cco: false });
  // TODO PSLA change profile code 
  // const handleFileChange = (e) => {   
  //     const file = e.target.files[0];
  //     if (file) {
  //       dispatch(changeImage(file));
  //     }
  //   };
  const handleAttach = () => {
    // console.log("a")
    // document.querySelector("#fileSelector").click();
  };

  const [mailFor, setMailFor] = useState([]);
  const [ccFor, setCcFor] = useState([]);
  const [ccoFor, setCcoFor] = useState([]);

  const deleteMail = (e, type) => {

    if (type === "mailFor")
      setMailFor(mailFor.filter(mail =>
        mail !== e
      ))
    if (type === "ccFor")
      setCcFor(ccFor.filter(mail =>
        mail !== e
      ))
    if (type === "ccoFor")
      setCcoFor(ccoFor.filter(mail =>
        mail !== e
      ))
  }

  const handleKeyPress = (e, type) => {
    if ((e.key === "Enter" || e.key === " ") && e.target.value.length > 5) {
      switch (type) {
        case "mailFor":
          setMailFor([...mailFor, e.target.value])
          e.target.value = ""
          break;

        case "ccFor":
          setCcFor([...ccFor, e.target.value])
          e.target.value = ""
          break;

        case "ccoFor":
          setCcoFor([...ccoFor, e.target.value])
          e.target.value = ""
          break;

        default:
          break;
      }
    }
  }

  const onFilePickerChange = async (file) => {
    addAttachment(file)
  }
  const addAttachment = (file) => {
    setFormValue({
      ...values,
      attachments: [
        ...(values.attachments || []),
        file
      ]
    })
  }
  const removeAttachment = (file) => {
    setFormValue({
      ...values,
      attachments: values.attachments.filter(attachment => attachment !== file)
    })
  }

  return (
    <form onSubmit={e => e.preventDefault()}>
      <Row className='mt-5  align-items-center'>
        <Col xs={{ span: 8, offset: 1 }}>
          <label htmlFor="reciever" hidden >Dirección de correo Electrónico</label>
          <div className='d-flex flex-direction-column'>
            <div className='d-flex flex-direction-row messageform__mails-container flex-wrap'>
              {mailFor?.map((e, i) => {
                return <div className='messageform__mails-format m-1' key={e + i}>
                  <span className='body-s-b px-2 '>{e}</span>
                  <Button type="button" icon="cross" className="button__only-icon-withoutcolor" onClick={() => deleteMail(e, "mailFor")} />
                </div>
              })
              }
            </div>
          </div>
        </Col>
        <Col xs={{ span: 4, offset: 1 }}>
          <input placeholder="Para" name="reciever" onChange={handleInputChange} className="newmessages__input body-l w-100 mt-3" onKeyPress={(e) => handleKeyPress(e, "mailFor")} />
        </Col>
        <Col xs={{ span: 2 }}>
          {!options.cc && <button type="button" className='body-l-sb button__only-icon-withoutcolor color-mail-cco   mt-3' onClick={() => setOptions({ ...options, cc: true })}>
            CC
          </button>}
          {!options.cco && !options.cc &&

            <button type="button" className='body-l-sb button__only-icon-withoutcolor color-mail-cco ml-4  mt-3' onClick={() => setOptions({ ...options, cco: true })}>
              CCO
            </button>
          }
        </Col>
      </Row>
      {
        options.cc &&
        <Row className='mt-5  align-items-center'>
          <Col xs={{ span: 8, offset: 1 }}>
            <div className='d-flex flex-direction-column'>
              <div className='d-flex flex-direction-row flex-wrap messageform__mails-container'>
                {ccFor?.map((e, i) => {
                  return <div className='messageform__mails-format' key={e + i}>
                    <span className='body-s-b px-2 '>{e}</span>
                    <Button type="button" icon="cross" className="button__only-icon-withoutcolor" onClick={() => deleteMail(e, "ccFor")} />
                  </div>
                })
                }
              </div>
            </div>
          </Col>
          <Col xs={{ span: 4, offset: 1 }}>
            <input placeholder="CC" name="cc" onChange={handleInputChange} className="newmessages__input body-l w-100 mt-3" onKeyPress={(e) => handleKeyPress(e, "ccFor")} />
          </Col>
          {options.cc && !options.cco && <Col xs={{ span: 2 }}>
            <button type="button" className='body-l-sb button__only-icon-withoutcolor color-mail-cco mt-3' onClick={() => setOptions({ ...options, cco: true })}>
              CCO
            </button>
          </Col>}
        </Row>
      }
      {
        options.cco &&
        <Row className='mt-5  align-items-center'>
          <Col xs={{ span: 8, offset: 1 }}>
            <div className='d-flex flex-direction-column'>
              <div className='d-flex flex-direction-row messageform__mails-container'>
                {ccoFor?.map((e, i) => {
                  return <div className='messageform__mails-format my-1' key={e + i}>
                    <span className='body-s-b px-2 '>{e}</span>
                    <Button type="button" icon="cross" className="button__only-icon-withoutcolor" onClick={() => deleteMail(e, "ccoFor")} />
                  </div>
                })
                }
              </div>
            </div>
          </Col>
          <Col xs={{ span: 4, offset: 1 }}>
            <input placeholder="CCO" name="cco" onChange={handleInputChange} className="newmessages__input body-l w-100 mt-3" onKeyPress={(e) => handleKeyPress(e, "ccoFor")} />
          </Col>
        </Row>
      }
      <Row className='mt-5  align-items-center'>
        <Col xs={{ span: 4, offset: 1 }}>
          <label htmlFor="reciever" hidden >Dirección de correo Electrónico</label>
          <input placeholder="Asunto" name="subject" value={values.subject} onChange={handleInputChange} className="newmessages__input body-l w-100 mt-4" />

        </Col>
      </Row>
      <Row className='mt-5  align-items-center'>
        <Col xs={{ span: 10, offset: 1 }} className="position-relative ">
          <label htmlFor="reciever" className='w-100 ' >Mensaje
            <textarea placeholder="Mensaje" name="message" value={values.message} onChange={handleInputChange} className="newmessages__textarea mt-2 body-l w-100 p-3" rows="10" />
          </label>
          <div className='newmessages__textarea-attach d-flex align-items-center'>
            <FilePicker onChange={onFilePickerChange}>
              <Button icon="attachMUI" type="file"
                content="Adjuntar" className="newmessages__textarea-attach-button button__only-icon-withoutcolor color-primary button-s-sb mx-3 my-2" />
            </FilePicker>
            {
              (values?.attachments?.length || null) && values.attachments.map((attachment, i) => <div key={i} className='newmessages__textarea-attach-file'>
                <span className='body-s-b'>{attachment.name}</span>
                <Button type="button" icon="cross" className="button__only-icon-withoutcolor" onClick={() => removeAttachment(attachment)} />
              </div>)
            }

          </div>
        </Col>
      </Row>
      <Row className='my-5  align-items-center'>
        <Col xs={{ span: 10, offset: 1 }}>
          <div className=' d-flex justify-content-end w-100 '>
            <button type="button" className='button__only-icon-withoutcolor color-primary  button-m-sb mr-5'>
              Cancelar
            </button>
            <button type="button" onClick={onSubmitEvent} className='button-primary button-m-sb' >
              Enviar mensaje
            </button>
          </div>
        </Col>
      </Row>

    </form>
  )
}
