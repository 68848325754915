import React from 'react'
import classNames from 'classnames'
import { Button } from './Button'

export const CustomOptions = ({ options = [], value, setValue, resetCounter }) => {

    return (
        <>
            <div className='d-flex flex-direction-row my-0'>
                {options.map((option) =>
                    <div className={classNames({ 'customoptions__active-buttons': value.type === option.type })} key={option.label + '1'}>
                        <Button onClick={() => {
                            if (value.type === option.type) return
                            setValue(option)
                            resetCounter && resetCounter()
                            option.optionFunc && option.optionFunc()
                        }}
                            className="button-m-sb button__only-icon-withoutcolor px-3 py-2 mx-3  " content={option.label} />
                    </div>
                )}
            </div>
            <hr className='w-100 customoptions__hr' />
        </>
    )
}
