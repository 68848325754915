import { useEffect, useState } from "react"
import { Col, Container, Row } from "react-bootstrap"
import { useDispatch } from "react-redux"
import { useHistory } from "react-router-dom"

import { createLog } from "../../../actions/logs"
import { api } from "../../../helpers/fetch"
import { useForm } from "../../../hooks/useForm"
import { BreadCrumbs } from "../../BreadCrumbs"
import { Button } from "../../Button"
import { CustomInput } from "../../CustomInput"
import { CustomSelect } from "../../CustomSelect"
import { Loading } from "../../Loading"



export const CourseForm = () => {

    const { goBack } = useHistory();
    const dispatch = useDispatch();

    const [values, handleInputChange, setValues] = useForm({
        year: 0,
        level: "",
        age: "",
        division: "",
        shift: "",
        tutorship: ""
    })
    const [loading, setLoading] = useState(true);

    const [schoolYears, setSchoolYears] = useState([]);

    const getSchoolYears = () => {
        api.get("/years/").then(res => {
            setSchoolYears(
                res.data.years.sort( (a,b) => b.year - a.year ).map( y => ({ label: y.year, value: y.year }))
            );
            setLoading(false);
        })
    };

    //--------- Levels --------------
    const levelOptions = [
        { label: 'Jardín', value: 'Elementary' },
        { label: 'Primaria', value: 'Primary' },
        { label: 'Secundaria', value: 'High' },
    ];

    //--------- Division --------------
    const divisionOptions = [
        { label: 'A', value: 'A' },
        { label: 'B', value: 'B' },
        { label: 'C', value: 'C' },
    ];

    //--------- Shift --------------
    const shiftOptions = [
        { label: 'Mañana', value: 'Mañana' },
        { label: 'Tarde', value: 'Tarde' },
    ];

    //--------- Tutorships --------------
    const [tutors, setTutors] = useState();

    const tutorsOptions = tutors?.map((tutor) => {
        return { label: `${tutor.name} ${tutor.lastName}`, value: tutor._id };
    });

    const getTutors = () => {
        api.get("auth/getUsers?userRole=teachers&userRole=tutorship&userRole=directorate").then(res => {
            const users = res.data.users.filter(aux => aux?.userAllRoles?.length > 1 || aux.userRole === 'teachers' || aux.userRole === 'tutorship')
            setTutors(users);
        })
    }

    const onSubmitEvent = async () => {
        api.post("courses", values).then(res => {
            if(res.data.ok === true) {
                dispatch(createLog({
                    type: "success",
                    title: "¡Creación Exitosa!",
                    text: `El curso ${values.age} - ${values.division} se creó con éxito.`,
                }))
                goBack();
            } else {
            dispatch(createLog({
                type: "error",
                title: "Hubo un problem",
                text: `El curso ${values.age} - ${values.division} no se pudo crear, vuelve a intentarlo.`,
            }))
            }
        })
    }

    const onsubmitCancel = () => {
        goBack();
    }

    useEffect(() => {
        getSchoolYears();
        getTutors();
    }, [])

    return (
        <>
            {loading ? <Loading /> :
                <Container>
                    <Row>
                        <Col xs={12}>
                            <BreadCrumbs lastSteps={[{ to: "/home", step: "Inicio" }, { to: "/Courses", step: "Cursos" }]} title="Nuevo Curso" />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <hr className='w-100' />
                        </Col>
                    </Row>
                    <div className="coursenew_form px-5 py-3">
                        <Row>
                            <Col xs={2} className='my-3'>
                                <p>Ciclo Lectivo</p>
                                <CustomSelect options={schoolYears} name="year" values={values.year} handleInputChange={handleInputChange} />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <hr className='w-100' />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={2} className='my-3'>
                                <CustomSelect options={levelOptions} name="level" values={values.level} handleInputChange={handleInputChange} placeholder={'nivel'} />
                            </Col>
                            <Col xs={2} className='my-3'>
                                <CustomInput classNameInput="w-100" placeholder='Año' name="age" handleInputChange={handleInputChange} value={values.age} />
                            </Col>
                            <Col xs={2} className='my-3'>
                                <CustomSelect options={divisionOptions} name="division" values={values.division} handleInputChange={handleInputChange} placeholder={'división'} />
                            </Col>
                            <Col xs={2} className='my-3'>
                                <CustomSelect options={shiftOptions} name="shift" values={values.shift} handleInputChange={handleInputChange} placeholder={'turno'} />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <hr className='w-100' />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={3} className='my-3'>
                                <CustomSelect options={tutorsOptions} name="tutorship" values={values.tutorship} handleInputChange={handleInputChange} placeholder={values.level === 'High' ? 'Preceptor' : 'Docente'} />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <hr className='w-100' />
                            </Col>
                        </Row>
                        <Row className="mb-5">
                            <Col xs={{ span: 10, offset: 1 }} className="d-flex">
                                <div className='ml-auto d-flex'>
                                    <Button
                                        type="reset"
                                        className='btn button__only-icon-withoutcolor color-primary  button-m-sb mr-5'
                                        content="Cancelar"
                                        onClick={onsubmitCancel} />
                                    <Button
                                        className='btn button-primary button-m-sb'
                                        content="Crear curso"
                                        onClick={onSubmitEvent}
                                        disabled={!(values.year && values.level && values.age && values.division && values.shift && values.tutorship)} />
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>
            }
        </>
    )
}