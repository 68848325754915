import React from 'react';
import { Provider } from 'react-redux';
import { AppRouter } from './router/AppRouter';
import 'bootstrap/dist/css/bootstrap.min.css';
import "./scss/styles.scss";
import { store } from './store/store';
import { GoogleOAuthProvider } from '@react-oauth/google';

export const IlpApp = () => {

  return (
    <Provider store={store}>
      <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
        <AppRouter />
      </GoogleOAuthProvider>
    </Provider>
  )
};


