import { useState } from "react"
import { Col, Container, Row } from "react-bootstrap"
import { useDispatch } from "react-redux"
import { useHistory } from "react-router-dom"

import { createLog } from "../../../actions/logs"
import { api } from "../../../helpers/fetch"
import { useForm } from "../../../hooks/useForm"
import { BreadCrumbs } from "../../BreadCrumbs"
import { Button } from "../../Button"
import { CustomInput } from "../../CustomInput"
import { Loading } from "../../Loading"

export const YearsForm = () => {

    const { goBack } = useHistory();
    const dispatch = useDispatch();

    const [values, handleInputChange] = useForm({
        year: undefined,
    })
    const [loading, setLoading] = useState(false);

    const onSubmitEvent = async () => {
        setLoading(true)
        api.post("years", { year: parseInt(values.year)}).then(res => {
            setLoading(false)
            if(res.data.ok === true) {
                dispatch(createLog({
                    type: "success",
                    title: "¡Creación Exitosa!",
                    text: `El ciclo lectivo ${values.year} se creó con éxito.`,
                }))
                goBack();
            } else {
            dispatch(createLog({
                type: "error",
                title: "Hubo un problem",
                text: `El ciclo lectivo ${values.year}} no se pudo crear, vuelve a intentarlo.`,
            }))
            }
        })
    }

    const onsubmitCancel = () => {
        goBack();
    }
    console.log(values)
    return (
        <>
            {loading ? <Loading /> :
                <Container>
                    <Row>
                        <Col xs={12}>
                            <BreadCrumbs lastSteps={[{ to: "/home", step: "Inicio" }, { to: "/Courses", step: "Cursos" }, { to: "/createyear", step: "Nuevo ciclo lectivo" }]} title="Nuevo ciclo lectivo" />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <hr className='w-100' />
                        </Col>
                    </Row>
                    <div className="coursenew_form px-5 py-3">
                        <Row>
                            <Col xs={2} className='my-3'>
                                <p>Ciclo Lectivo</p>
                                <CustomInput classNameInput="w-100" placeholder='Año' name="year" type="number" handleInputChange={handleInputChange} value={values.year} />
                            </Col>
                        </Row>
                        <Row className="mb-5">
                            <Col xs={{ span: 10, offset: 1 }} className="d-flex">
                                <div className='ml-auto d-flex'>
                                    <Button
                                        type="reset"
                                        className='btn button__only-icon-withoutcolor color-primary  button-m-sb mr-5'
                                        content="Cancelar"
                                        onClick={onsubmitCancel} />
                                    <Button
                                        className='btn button-primary button-m-sb'
                                        content="Crear ciclo lectivo"
                                        onClick={onSubmitEvent}
                                        disabled={!values.year && typeof values.year === "number" } />
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>
            }
        </>
    )
}