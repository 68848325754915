import React, { useEffect, useState } from 'react'
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import InsertChartOutlinedIcon from '@mui/icons-material/InsertChartOutlined';
import BookOutlinedIcon from '@mui/icons-material/BookOutlined';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBook, faBookOpenReader, faCalendar, faEnvelope, faHome, faPersonChalkboard, faUserGraduate, faBookOpen } from '@fortawesome/free-solid-svg-icons'

//secretary
import DnsOutlinedIcon from '@mui/icons-material/DnsOutlined';
import FaceOutlinedIcon from '@mui/icons-material/FaceOutlined';

import classNames from "classnames";
import { Link, useLocation } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';

export const Aside = ({ show = true, navHeight }) => {

    const [activeLink, setActiveLink] = useState("");
    const { user } = useAuth();
    const { userRole } = user;
    const { pathname } = useLocation();
    useEffect(() => {
        setActiveLink(`${pathname.substring(1)}`)
    }, [pathname]);

    return (
        <aside className={classNames('aside__width ', {
            "sidebar-width-open": show === true,
            "sidebar-width-close": show === false,
        })} style={{ "top": navHeight }}
        >
            <div className='sidebar__container'>
                <div className="sidebar">
                    <div className='d-flex flex-direction-column ' >
                        <Link to="/home" className={classNames('px-4 navbar__link-div d-flex flex-nowrap   align-items-center text-nowrap pb-3 mt-4  pt-3 mb-2', { "link-active": activeLink === "home" })}>
                            <div className=' d-flex flex-nowrap text-nowrap align-items-center ' >
                                <FontAwesomeIcon icon={faHome} className="icons__18 color-text" />{show && <p className={`mb-0 pl-2 mr-auto ${activeLink === "home" ? 'body-s-sb' : 'body-s'}`}>Inicio</p>}
                            </div>
                        </Link>
                        {userRole === "administration" &&
                            <>
                                <Link to="/controlsheets" className={classNames('navbar__link-div  px-4 py-3 my-2 ', { "link-active": activeLink === "controlsheets" })}>
                                    <div className=' d-flex flex-nowrap text-nowrap align-items-center ' >
                                        <AssignmentOutlinedIcon className="icons__24  color-text " />{show && <p className={`mb-0 pl-2 mr-auto ${activeLink === "controlsheets" ? 'body-s-sb' : 'body-s'}`}>Planilla de control</p>}
                                    </div>
                                </Link>
                                <Link to="/budgets" className={classNames('navbar__link-div  px-4 py-3 my-2 ', { "link-active": activeLink === "budgets" })}>
                                    <div className=' d-flex flex-nowrap text-nowrap align-items-center ' >
                                        <InsertChartOutlinedIcon className="icons__24  color-text " />{show && <p className={`mb-0 pl-2 mr-auto ${activeLink === "budgets" ? 'body-s-sb' : 'body-s'}`}>Presupuestos</p>}
                                    </div>
                                </Link>
                                <Link to="/regulations" className={classNames('navbar__link-div  px-4 py-3 my-2 ', { "link-active": activeLink === "regulations" })}>
                                    <div className=' d-flex flex-nowrap text-nowrap align-items-center ' >
                                        <BookOutlinedIcon className="icons__24  color-text " />{show && <p className={`mb-0 pl-2 mr-auto ${activeLink === "regulations" ? 'body-s-sb' : 'body-s'}`}>Reglamentaciones</p>}
                                    </div>
                                </Link>
                            </>}
                        {(userRole === "secretariat" || userRole === 'directorate') &&
                            <>
                                <Link to="/courses" className={classNames('navbar__link-div  px-4 py-3 my-2 ', { "link-active": activeLink === "courses" })}>
                                    <div className=' d-flex flex-nowrap text-nowrap align-items-center ' >
                                        <FontAwesomeIcon icon={faBook} />
                                        {show && <p className={`mb-0 pl-2 mr-auto ${activeLink === "courses" ? 'body-s-sb' : 'body-s'}`}>Cursos</p>}
                                    </div>
                                </Link>
                                <Link to="/teacherlist" className={classNames('navbar__link-div  px-4 py-3 my-2 ', { "link-active": activeLink === "teacherlist" })}>
                                    <div className=' d-flex flex-nowrap text-nowrap align-items-center ' >
                                        <FontAwesomeIcon icon={faPersonChalkboard} />
                                        {show && <p className={`mb-0 pl-2 mr-auto ${activeLink === "teacherlist" ? 'body-s-sb' : 'body-s'}`}>Listado docente</p>}
                                    </div>
                                </Link>
                                <Link to="/studentlist" className={classNames('navbar__link-div  px-4 py-3 my-2 ', { "link-active": activeLink === "studentlist" })}>
                                    <div className=' d-flex flex-nowrap text-nowrap align-items-center ' >
                                        <FontAwesomeIcon icon={faUserGraduate} />
                                        {show && <p className={`mb-0 pl-2 mr-auto ${activeLink === "studentlist" ? 'body-s-sb' : 'body-s'}`}>Listado de alumnos</p>}
                                    </div>
                                </Link>
                            </>}
                        {userRole === "teachers" &&
                            <>
                                <Link to="/courses" className={classNames('navbar__link-div  px-4 py-3 my-2 ', { "link-active": activeLink === "courses" })}>
                                    <div className='d-flex flex-nowrap text-nowrap align-items-center' >
                                        <FontAwesomeIcon icon={faBook} className="icons__18 color-text" />
                                        {show && <p className={`mb-0 pl-2 mr-auto ${activeLink === "courses" ? 'body-s-sb' : 'body-s'}`}>Cursos</p>}
                                    </div>
                                </Link>
                                <Link to="/subjects" className={classNames('navbar__link-div  px-4 py-3 my-2 ', { "link-active": activeLink === "subjects" })}>
                                    <div className='d-flex flex-nowrap text-nowrap align-items-center' >
                                        <FontAwesomeIcon icon={faBookOpenReader} className="icons__18 color-text" />
                                        {show && <p className={`mb-0 pl-2 mr-auto ${activeLink === "subjects" ? 'body-s-sb' : 'body-s'}`}>Materias</p>}
                                    </div>
                                </Link>
                            </>}
                        {userRole === 'students' &&
                            <>
                                <Link to="/studentsubjects" className={classNames('navbar__link-div  px-4 py-3 my-2 ', { "link-active": activeLink === "materias" })}>
                                    <div className='d-flex flex-nowrap text-nowrap align-items-center' >
                                        <FontAwesomeIcon icon={faBookOpenReader} className="icons__18 color-text" />
                                        {show && <p className={`mb-0 pl-2 mr-auto ${activeLink === "materias" ? 'body-s-sb' : 'body-s'}`}>Materias</p>}
                                    </div>
                                </Link>
                                <Link to={`/reportcard/${user._id}`} className={classNames('navbar__link-div  px-4 py-3 my-2 ', { "link-active": activeLink === "reportcard" })}>
                                    <div className='d-flex flex-nowrap text-nowrap align-items-center' >
                                        <FontAwesomeIcon icon={faBookOpen} className="icons__18 color-text" />
                                        {show && <p className={`mb-0 pl-2 mr-auto ${activeLink === "reportcard" ? 'body-s-sb' : 'body-s'}`}>Boletines</p>}
                                    </div>
                                </Link>
                            </>}
                        {userRole === 'tutorship' &&
                            <>
                                <Link to="/courses" className={classNames('navbar__link-div  px-4 py-3 my-2 ', { "link-active": activeLink === "courses" })}>
                                    <div className=' d-flex flex-nowrap text-nowrap align-items-center ' >
                                        <FontAwesomeIcon icon={faBook} />
                                        {show && <p className={`mb-0 pl-2 mr-auto ${activeLink === "courses" ? 'body-s-sb' : 'body-s'}`}>Cursos</p>}
                                    </div>
                                </Link>
                            </>}
                        <hr className={classNames('ml-3 pr-1 ', { "hr-open": show === true, "hr-closed": show === false })} />
                        <Link to="/calendar" className={classNames('navbar__link-div  px-4 py-3 my-2 ', { "link-active": activeLink === "calendar" })}>
                            <div className=' d-flex flex-nowrap text-nowrap align-items-center ' >
                                <FontAwesomeIcon icon={faCalendar} className="icons__18 color-text" />
                                {show && <p className={`mb-0 pl-2 mr-auto ${activeLink === "calendar" ? 'body-s-sb' : 'body-s'}`}>Calendario</p>}
                            </div>
                        </Link>
                        <Link to="/messages" className={classNames('navbar__link-div  px-4 py-3 my-2 ', { "link-active": activeLink === "messages" })}>
                            <div className=' d-flex flex-nowrap text-nowrap align-items-center ' >
                                <FontAwesomeIcon icon={faEnvelope} className="icons__18 color-text" />
                                {show && <p className={`mb-0 pl-2 mr-auto ${activeLink === "messages" ? 'body-s-sb' : 'body-s'}`}>Mensajes</p>}
                            </div>
                        </Link>
                        <hr className={classNames('ml-3 pr-1 ', { "hr-open": show === true, "hr-closed": show === false })} />
                    </div>
                </div>
            </div >
        </aside >
    )
}
