import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { formatDateForInput } from "../../../helpers/format-date-for-input";
import { Button } from "../../Button";
import { CustomInput } from "../../CustomInput";
import { CustomSelect } from "../../CustomSelect";

export const UserExternalJobs = ({ form, handleInputChange, disable }) => {
  const [externalJobs, setExternalJobs] = useState(null);

  useEffect(() => {
    const externalJobsMap = (() => {
      const keys = Object.keys(form).filter((key) =>
        key.startsWith("externalJobs.")
      );
      const indexes = keys.map((key) =>
        parseInt(key.split(".")[1].split(".")[0])
      );
      return indexes.filter(
        (value, index, self) => self.indexOf(value) === index
      );
    })();
    !externalJobs &&
      externalJobsMap.length &&
      setExternalJobs([...externalJobsMap]);
  }, [form]);

  const getKey = (index, key) => `externalJobs.${index}.${key}`;
  const addExternalJob = () => {
    setExternalJobs(
      (externalJobs && [...externalJobs, externalJobs.length]) || [0]
    );
  };

  // ------------------------------------------
  const hours = new Array(8).fill(8).map((_, i) => {
    i++;
    return { label: `${i} hora${(i !== 1 && "s") || ""}`, value: i };
  });

  return (
    <>
      <Row className="userper my-2">
        <Col xs={{ span: 8, offset: 1 }}>
          <div className="d-flex justify-content-between">
            <h4>Otro/s empleo/s</h4>
          </div>
        </Col>
      </Row>
      {externalJobs?.map((job, i) => (
        <div key={i}>
          <Row className="d-flex align-items-center my-5">
            <Col
              xs={{ span: 3, offset: 1 }}
              className="userpersonaldata__form-input body-l"
            >
              <CustomInput
                labelContent="Cargo"
                name={getKey(i, "name")}
                value={form[getKey(i, 'name')]}
                placeholder="Ej: Docente Nivel Inicial"
                classNameInput="w-100"
                classNameLabel="input-label"
                alwaysShow={true}
                disabled={!disable}
              />
            </Col>
            <Col xs={2} className="userpersonaldata__form-input body-l">
              <div className="d-flex position-relative">
                <label htmlFor={""} className="color-dissabled input-label ">
                  Fecha de ingreso
                </label>
                <CustomInput
                  type="date"
                  name={getKey(i, "date")}
                  value={formatDateForInput(form[getKey(i, "date")])}
                  handleInputChange={handleInputChange}
                  placeholder="*Fecha"
                  className="userpersonaldata__form-select body-l w-100"
                  disabled={!disable}
                  required
                />
              </div>
            </Col>
            <Col xs={2} className="userpersonaldata__form-input body-l">
              <CustomSelect
                options={hours}
                labelContent={
                    <span className="color-disabled input-label ">
                    Cantidad de horas
                  </span>
                }
                name={getKey(i, "hoursAmount")}
                values={form[getKey(i, 'name')]}
                handleInputChange={handleInputChange}
                className="mr-3 body-l w-100"
                placeholder="Ej: 5"
                disabled={!disable}
                alwaysShow
              />
            </Col>
          </Row>
          <Row className="d-flex align-items-center ">
            <Col xs={{ span: 3, offset: 1 }}>
              <CustomInput
                disabled={!disable}
                placeholder="Escuela Belgrano N° 65"
                labelContent="Empresa/ institución"
                name={getKey(i, "institution")}
                value={form[getKey(i, 'institution')]}
                handleInputChange={handleInputChange}
                classNameInput="body-l w-100"
                classNameLabel="input-label"
                alwaysShow={true}
              />
            </Col>
            <Col xs={3}>
              <CustomInput
                disabled={!disable}
                placeholder="Avellaneda"
                labelContent="Localidad"
                name={getKey(i, "location")}
                value={form[getKey(i, 'location')]}
                handleInputChange={handleInputChange}
                classNameInput="body-l w-100"
                classNameLabel="input-label"
                alwaysShow={true}
              />
            </Col>
          </Row>
          <Row className="d-flex align-items-center ">
            <Col xs={{ span: 10, offset: 1 }}>
              <hr />
            </Col>
          </Row>
        </div>
      ))}
      <Row className="d-flex align-items-center mt-4 mb-5">
        <Col xs={{ span: 10, offset: 1 }}>
          <Button
            disabled={!disable}
            onClick={addExternalJob}
            icon="plusMUI"
            content={<span className="button-s-sb"> Agregar empleo</span>}
            className="button-terciary"
          />
        </Col>
      </Row>
    </>
  );
};
