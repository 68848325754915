import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { BreadCrumbs } from '../../BreadCrumbs'
import { Button } from '../../Button'
import { useForm } from '../../../hooks/useForm'
import { CustomInput } from '../../CustomInput'
import { CustomSelect } from '../../CustomSelect'
import { ReceiptInfo } from './ReceiptInfo'
import ReceiptOutlinedIcon from '@mui/icons-material/ReceiptOutlined';
import classNames from 'classnames'
import { useParams } from 'react-router-dom'
import { useHistory } from 'react-router-dom'
import { api } from '../../../helpers/fetch'
import { useDispatch } from 'react-redux'
import { createLog } from '../../../actions/logs'
import { Loading } from "../../Loading"
import moment from 'moment';

export const BudgetDetail = () => {
    const defaultReceiptsValues = [{
        date: "",
        provider: "",
        items: [
            {
                description: "",
                quantity: 0,
                unitaryPrice: 0,
            }]
    }]

    const [values, handleInputChange, setValues] = useForm({
        title: "",
        date: "",
        forecast: 0,
        educationStage: "*Nivel",
        area: "*Área",
        course: "*Curso",
        receipts: [{
            date: "",
            provider: "",
            items: [
                {
                    description: "",
                    quantity: 0,
                    unitaryPrice: 0,
                }],
        }]
    })
    
    const educationStageOptions = [
        { label: "Inicial", value: "initial" },
        { label: "Primario", value: "primary" },
        { label: "Secundario", value: "secondary" }]
    const courseOptions = [
        { label: "1-A", value: "1-A" },
        { label: "1-B", value: "1-B" },
        { label: "1-C", value: "1-C" }]
    const areaOptions = [
        { label: "Alumnos", value: "students" },
        { label: "Docentes", value: "teachers" },
        { label: "No docentes", value: "notteachers" },
        { label: "Directivos", value: "directors" }]

    const { id } = useParams();
    const { goBack } = useHistory();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const [receipts, setReceipts] = useState(defaultReceiptsValues);


    const total =
        receipts?.reduce((total, value) => {
            return total + value.items?.reduce((total, value) => {
                return total + value.quantity * value.unitaryPrice ? total + value.quantity * value.unitaryPrice : 0
            }, 0
            )
        }, 0);
    const difference = values.forecast - total



    useEffect(() => {
        api.get(`budget/${id}`)
            .then( resp => {
                setValues(resp.data.budget);
                setReceipts(resp.data.budget.receipts);
            })
            .catch(() => {
                goBack();
                dispatch(
                    createLog({
                        type: "info",
                        title: "Lo sentimos",
                        text: "Algo fue mal...",
                    })
                )
            })
            .finally(() => {
                setLoading(false);
            })

    }, [dispatch, goBack, id, setValues]);

    const onSubmitEvent = (e) => {
        e.preventDefault();
        goBack();
    };

    return (
        <main>
            {loading ? <Loading /> :
                <Container className="position-relative budgetnew__container" >
                    <div>
                        <Row>
                            <Col xs={12}>
                                <BreadCrumbs lastSteps={[{ to: "/home", step: "Inicio" }, { to: "/budgets", step: "Presupuestos" }]} title="Presupuesto" />
                            </Col>
                        </Row>
                    </div>
                    <form className='budgetnew__form'>
                        <div>
                            <Row className='my-6'>
                                <Col xs={{ offset: 1, span: 4 }}>
                                    <CustomInput labelContent={<span className='input-label  d-flex flex-nowrap'> <p className='color-error mr-1'>*</p> Título</span>} handleInputChange={handleInputChange} placeholder="*Título" name="title" value={values.title} classNameInput="w-100 body-l" disabled={true} />
                                </Col>
                                <Col xs={2} className="mx-2">
                                    <input required type="date" name="date" className='newbudget-form-select body-l' value={moment(values.date).format("YYYY-MM-DD")} placeholder="*Fecha" disabled={true} />
                                </Col>
                                <Col xs={2}>
                                    <CustomInput labelContent='Monto estimado' handleInputChange={handleInputChange} name="forecast" value={values.forecast} classNameInput="body-l" classNameLabel='input-label' disabled={true} />
                                </Col>
                            </Row>
                            <Row className='my-4'>
                                <Col xs={{ offset: 1, span: 2 }}>
                                    <CustomSelect options={educationStageOptions} placeholder="Nivel" name="educationStage" values={values.educationStage} disabled={true} />
                                </Col>
                                <Col xs={2}>
                                    <CustomSelect options={areaOptions} placeholder="Área" name="area" values={values.area} disabled={true} />
                                </Col>
                                <Col xs={2}>
                                    <CustomSelect options={courseOptions} placeholder="Curso" name="course" values={values.course} disabled={true} />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={{ offset: 1, span: 10 }}>
                                    <hr className='w-100' />
                                </Col>
                            </Row>
                            <Row className='mb-3'>
                                <Col xs={{ offset: 1, span: 10 }} className="d-flex justify-content-between flex-nowrap mt-4">
                                    <h4 className='m-0 d-flex justify-content-center align-items-center'><ReceiptOutlinedIcon className='mb-1 mr-2' />   Comprobantes</h4>
                                </Col>
                            </Row>

                                {receipts.map((receipt, i) => {
                                    return <ReceiptInfo
                                        key={i}
                                        index={i}
                                        receipt={receipt}
                                        setReceipts={setReceipts}
                                        receiptsList={receipts}
                                        disableImputs={true} />
                                })}
                            <Row className='  mt-4'>
                                <Col xs={{ offset: 8, span: 3 }}>
                                    <div className='d-flex justify-content-between body-l-sb newbudget__total px-3 py-2 '>
                                        <span>Total</span>
                                        <span>$ {total}</span>

                                    </div>
                                </Col>
                            </Row>
                            <Row className='mb-3  '>
                                <Col xs={{ offset: 8, span: 3 }}>
                                    <div className='d-flex justify-content-between align-items-center body-m px-3 py-2 '>
                                        <span className='mt-1'> Diferencia</span>
                                        <span className={classNames('body-l-sb m-0', {
                                            "color-success": difference > 0,
                                            "color-error": difference < 0,
                                            "color-text": difference === 0
                                        })}>$ {difference}</span>
                                    </div>
                                </Col>
                            </Row>
                        <Row>
                            <Col xs={{ offset: 1, span: 10 }}>
                                <hr className='w-100' />
                            </Col>
                        </Row>
                        <Row className="mb-5">
                            <Col xs={{ span: 10, offset: 1 }} className="d-flex">
                                <div className='ml-auto d-flex'>
                                    <Button
                                        className='btn button-primary button-m-sb'
                                        content='Volver'
                                        onClick={onSubmitEvent} />
                                </div>
                            </Col>
                        </Row>
                    </div>

                </form>
            </Container >
            }
        </main>
    )
}
