import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { BreadCrumbs } from '../../BreadCrumbs'
import EventOutlinedIcon from '@mui/icons-material/EventOutlined';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { api } from '../../../helpers/fetch';
import { useHistory, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { createLog } from '../../../actions/logs';
import moment from 'moment';
import { Button } from '../../Button';
import { useAuth } from '../../../hooks/useAuth';

export const EventDetail = () => {

    const [eventActive, setEventActive] = useState({});
    const { adminUser } = useSelector(state => state.auth)
    const { goBack } = useHistory();
    const { id } = useParams();
    const dispatch = useDispatch();
    const { push } = useHistory();
    const { user } = useAuth();
    const { uid } = user;
    const [guest, setGuest] = useState([])

    const handleDelete = () => {
        dispatch(
            createLog({
                type: "warning",
                title: "¿Estas seguro que quieres borrar este evento?",
                callback: onDeleteEventConfirm,
            }

            )
        )
    };

    const onDeleteEventConfirm = async () => {
        api.delete(`events/${id}`)
            .then(resp => {
                if (resp.status === 200) {
                    goBack()
                    dispatch(
                        createLog({
                            type: "info",
                            title: "El evento ha sido eliminado",
                        }
                        )
                    )
                } else {
                    alert("Lo sentimos, el evento no pudo ser borrado");
                }
            })
    };

    const onEditEvent = () => {
        push(`/calendar/editEvent/${id}`);
    }

    const getData = (id) => {
        api.get(`events/${id}`)
            .then(resp => {
                setEventActive(resp.data)
                api.get("auth/getAllUsers").then(res => {
                    const userArray = []
                    resp.data.guestList.forEach(us => {
                        const data = res.data.users.find(aux => aux.id === us)
                        userArray.push(data)
                    })
                    setGuest(userArray)
                })
            })
            .catch(() => {
                goBack();
                dispatch(
                    createLog({
                        type: "info",
                        title: "Lo sentimos",
                        text: "Algo fue mal...",
                    })
                )
            })
    }

    useEffect(() => {
        getData(id)
    }, [id])

    return (
        <main>
            <Container>
                <Row className='mb-4 pb-2'>
                    <Col xs={12}>
                        <BreadCrumbs lastSteps={[{ to: "/home", step: "Inicio" }, { to: "/calendar", step: "Calendario" }]} title="Evento" />
                    </Col>
                </Row>
                <div className='eventdetail__container'>
                    <Row className='mb-3  mt-4 pt-2'>
                        <Col className='d-flex space-between'
                            xs={{ offset: 1, span: 10 }}>
                            <h3>{eventActive.title}</h3>
                            <div className='d-flex'>
                                <Button
                                    className='btn button__only-icon-withoutcolor color-primary button-s-sb'
                                    icon="pencilMUI"
                                    content="Editar"
                                    iconRight={false}
                                    onClick={onEditEvent}
                                    disabled={eventActive.owner !== uid && eventActive.owner !== adminUser?.uid} />
                                <Button
                                    className='btn button__only-icon-withoutcolor color-primary button-s-sb'
                                    icon="deleteMUI"
                                    content="Borrar"
                                    iconRight={false}
                                    onClick={handleDelete}
                                    disabled={eventActive.owner !== uid && eventActive.owner !== adminUser?.uid} />
                            </div>
                        </Col>
                    </Row>
                    <Row className='mb-4 '>
                        <Col xs={{ offset: 1, span: 10 }} className="d-flex flex-direction-row flex-nowrap align-items-center">
                            <div className='d-flex'>
                                <EventOutlinedIcon className="icons__24 mr-2" />
                                <p className='body-l'>{moment(eventActive.fromHour).format("LLLL")}</p>
                            </div>
                            <div className='d-flex mx-3'>
                            </div>
                            <div className='d-flex '>
                                <AccessTimeIcon className="icons__24 mr-2" />
                                <p className='body-l'>{moment(eventActive.toHour).format("LLLL")}</p>
                            </div>
                        </Col>
                    </Row>
                    <Row className='mb-3 '>
                        <Col xs={{ offset: 1, span: 10 }} className="d-flex flex-direction-row flex-nowrap align-items-center">
                            <h4>Invitados</h4>
                        </Col>
                    </Row>
                    <Row className='mb-4 pb-2'>
                        <Col xs={{ offset: 1, span: 10 }} className="d-flex flex-direction-row flex-nowrap align-items-center">
                            {guest?.map((e) =>
                                <div className='messageform__mails-format mr-4' key={e._id}>
                                    <span className='body-s-b'>{`${e?.lastName}, ${e?.name} (${e?.email})`}</span>
                                </div>
                            )}
                            {
                                eventActive.isGlobalEvent &&
                                <p className='body-m'>Toda la institución</p>
                            }
                            {eventActive.invitedAreas?.map((area, i) =>
                                <p className='body-m mr-3' key={i}>
                                    {area === 'directorate' && 'Directores'}
                                    {area === 'secretariat' && 'Secretarios'}
                                    {area === 'students' && 'Alumnos'}
                                    {area === 'teachers' && 'Docentes'}
                                    {area === 'administration' && 'Administradores'}
                                    {area === 'tutorship' && 'Tutores'}
                                </p>
                            )}
                        </Col>
                    </Row>
                    <Row className=''>
                        <Col xs={{ offset: 1, span: 10 }} className="d-flex flex-direction-row flex-nowrap align-items-center">
                            <hr className='w-100' />
                        </Col>
                    </Row>
                    <Row className='mb-2'>
                        <Col xs={{ offset: 1, span: 10 }} className="d-flex flex-direction-row flex-nowrap align-items-center">
                            <h4 className='p-0 m-0'>Descripción</h4>
                        </Col>
                    </Row>

                    <Row className='mb-4 mt-3 py-0 '>
                        <Col xs={{ offset: 1, span: 10 }} className="d-flex flex-direction-row flex-nowrap align-content-start">
                            <p className='body-m color-labels'>{eventActive.description}</p>
                        </Col>
                    </Row>
                </div>
            </Container>
        </main>
    )
}
