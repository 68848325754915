import { useSelector } from 'react-redux'
import { getUnflattenedObject } from '../../../helpers/flatten'
import { reduceString } from '../../../helpers/reduceString'
import { UserAlternariveContactInformation } from '../users/UserAlternariveContactInformation'
import { UserBrothersInformation } from '../users/UserBrothersInformation'
import { UserParentInformation } from '../users/UserParentInformation'
import { UserContactInformation } from './UserContactInformation'
import { UserExternalJobs } from './UserExternalJobs'
import { UserFamilyInformation } from './UserFamilyInformation'
import { UserPersonalAttachments } from './UserPersonalAttachments'
import { UserPersonalData } from './UserPersonalData'
import { UserPresentation } from './UserPresentation'

export const UserInformation = ({ form, onInputChange, setForm, disable, handleDisable }) => {
  const { userRole, uid } = useSelector(state => state.auth.user)
  const userFlatten = getUnflattenedObject(form)

  return (
    <>
      <UserPresentation
        registerDate={userFlatten?.incorporationDate}
        id={userFlatten._id}
        name={userFlatten.name || " "}
        lastName={userFlatten.lastName || " "}
        profileImgUrl={userFlatten.urlImgProfile}
        role={userFlatten.userRole}
        edit={userRole === "administration" || userFlatten._id === uid}
        shifts={reduceString(userFlatten?.courses, "shift", "/")}
        level={reduceString(userFlatten?.courses, "level", "/")}
        course={userFlatten?.courses && userFlatten?.courses.find(crs => crs.year === (new Date()).getFullYear())?.age}
        disable={disable}
        handleDisable={handleDisable}
      />
      <UserPersonalData documentId={userFlatten.documentId} documentType={userFlatten.documentType} role={userFlatten.userRole} form={form} handleInputChange={onInputChange} disable={disable}/>
      <UserPersonalAttachments form={form} setForm={setForm} disable={disable}/>
      {
        form.userRole === 'students'
          ? <>
            <UserBrothersInformation form={form} handleInputChange={onInputChange} disable={disable}/>
            <UserParentInformation form={form} handleInputChange={onInputChange} parentToModify={'father'} title="Datos del padre" disable={disable}/>
            <UserParentInformation form={form} handleInputChange={onInputChange} parentToModify={'mother'} title="Datos de la madre" disable={disable}/>
            <UserAlternariveContactInformation form={form} handleInputChange={onInputChange} disable={disable}/>
          </>
          : <>
            <UserContactInformation form={form} handleInputChange={onInputChange} disable={disable}/>
            <UserFamilyInformation form={form} handleInputChange={onInputChange} disable={disable}/>
            <UserExternalJobs form={form} handleInputChange={onInputChange} disable={disable}/>
          </>
      }
    </>
  )
}
