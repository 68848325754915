import React from 'react'
import { Link } from 'react-router-dom'
import InsertInvitationOutlinedIcon from '@mui/icons-material/InsertInvitationOutlined';
export const EmptyReminder = () => {
    return (
        <div className='remindereventcard__container p-3'>
            <h5>¡Genial! No tienes ningún recordatorio para las próximas 24 hs.</h5>
            <p className='body-m'>Igualmente puedes ver los demás recordatorios. </p>
            <div className='w-100 d-flex justify-content-center mt-auto'>
                <Link to="/calendar" className='remindereventcard__link'>
                    <InsertInvitationOutlinedIcon /> <span className='button-s-sb'>Ver todos los recordatorios</span>
                </Link>
            </div>
        </div>
    )
}
