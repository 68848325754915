export const provinces = () => [
  { label: 'Buenos Aires', value: 'buenos-aires' },
  { label: 'Ciudad Autónoma de Buenos Aires', value: 'ciudad-autónoma-de-buenos-aires' },
  { label: 'Catamarca', value: 'catamarca' },
  { label: 'Chaco', value: 'chaco' },
  { label: 'Chubut', value: 'chubut' },
  { label: 'Córdoba', value: 'córdoba' },
  { label: 'Corrientes', value: 'corrientes' },
  { label: 'Entre Ríos', value: 'entre-ríos' },
  { label: 'Formosa', value: 'formosa' },
  { label: 'Jujuy', value: 'jujuy' },
  { label: 'La Pampa', value: 'la-pampa' },
  { label: 'La Rioja', value: 'la-rioja' },
  { label: 'Mendoza', value: 'mendoza' },
  { label: 'Misiones', value: 'misiones' },
  { label: 'Neuquén', value: 'neuquén' },
  { label: 'Río Negro', value: 'río-negro' },
  { label: 'Salta', value: 'salta' },
  { label: 'San Juan', value: 'san-juan' },
  { label: 'San Luis', value: 'san-luis' },
  { label: 'Santa Cruz', value: 'santa-cruz' },
  { label: 'Santa Fe', value: 'santa-fe' },
  { label: 'Santiago del Estero', value: 'santiago-del-estero' },
  { label: 'Tierra del Fuego', value: 'tierra-del-fuego' },
  { label: 'Tucumán', value: 'tucumán' },
]
