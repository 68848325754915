import { combineReducers } from "redux";
import { authReducer } from "./authReducer";
import { logsReducer } from "./logsReducer";
import { usersReducer } from "./usersReducer";
import { scopesReducer } from "./scopesReducer";

export const rootReducer = combineReducers({
    auth: authReducer,
    logs: logsReducer,
    scope: scopesReducer,
    users: usersReducer
})