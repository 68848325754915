import React, { useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import userImg from "../../Imgs/AvatarsProfilePic.png"
import { Link } from "react-router-dom";
import { SettingsDropdown } from './SettingsDropdown'
import { useAuth } from '../../hooks/useAuth';
import { Loading } from '../Loading';

export const Header = () => {

    const [show, setShow] = useState(false);
    const { user } = useAuth();
    const {name, lastName, urlImgProfile, userRole} = user;
    
    return (
        <Container>
            <Row>
                <Col xs={12} className="d-flex flex-direction-row w-100 m-0 p-0 h-100">
                    <div className='header__container w-100'>
                        <Link to="/home">
                            <h5 className=' mb-0 ml-3 pl-1'>Instituto Luján Porteño</h5>
                        </Link>
                        <div className='navbar_profile m-0 button__only-icon-withoutcolor'  >
                            <button onClick={() => setShow(!show)} className='m-0 button__only-icon-withoutcolor' >
                                <span className='px-3 color-white'>{name} {lastName}</span>
                                <img
                                    src={
                                        urlImgProfile || userImg
                                    }
                                    onError={(e) => {
                                        e.currentTarget.src = userImg

                                    }}
                                    alt="imagen de perfil del usuario" />
                            </button>
                            {userRole === undefined ? <Loading /> : 
                            <SettingsDropdown role={userRole} show={show} setShow={setShow} />
                            }
                        </div>
                    </div>
                </Col>
            </Row>
        </Container >

    )
}
