import { useEffect, useState } from "react"
import { Col, Row } from "react-bootstrap"
import { Board } from '../../Board'
import { Button } from "../../Button"
import classNames from "classnames"
import { Link } from "react-router-dom"
import { CustomFabicon } from "../../CustomFabicon"
import { Loading } from "../../Loading"
import { useHistory } from "react-router-dom"
import { EmptyStates } from "../../EmptyStates"
import emptySubject from '../../../Imgs/EmptyStates/Social 7 cursos.png'

export const StudentSubjectList = ({ id, courseInfo, role, totalAttendances, getStudentAttendancesById }) => {

    const { push } = useHistory();
    const [loading, setLoading] = useState(true);
    const [studentsList, setStudentsList] = useState([]);
    const [course, setCourse] = useState();

    const handleDelete = () => {
        push(`/courses/assign/${id}`);
    };

    useEffect(() => {
        setStudentsList(courseInfo.students)
        setCourse(courseInfo)
        setLoading(false);
    }, [courseInfo])


    return (
        <>
            {
                (role === 'teachers' || role === 'tutorship') &&
                <Row className="justify-content-end">
                    <Col className="col-auto my-2">
                        <Link to={`/attendance/${id}`}>
                            <Button className='button-primary button-m-sb' content='Asistencia' />
                        </Link>
                    </Col>
                </Row>
            }

            {loading ? <Loading /> :
                <>
                    {
                        studentsList.length === 0 ?
                            <EmptyStates src={emptySubject} title='Asignaciones en proceso' text='Actualmente la asignación de alumnos está en proceso. Para más información puedes comunicarte con secretaría.' />
                            :
                            <Board
                                className="board-row-color-3 pt-3"
                                firstRow={
                                    <>
                                        <Col xs={3}>
                                            <div className='my-2'>
                                                <span className='body-m-b'>Apellido y Nombre</span>
                                            </div>
                                        </Col>
                                        <Col xs={2} className=" mx-1">
                                            <div className='my-2'>
                                                <span className='body-m-b'>Curso</span>
                                            </div>
                                        </Col>
                                        <Col xs={2} className=" mx-2">
                                            <div className='my-2'>
                                                <span className='body-m-b'>Turno</span>
                                            </div>
                                        </Col>
                                        <Col xs={2} className=" mx-2">
                                            <div className='my-2'>
                                                <span className='body-m-b'>Asistencia</span>
                                            </div>
                                        </Col>
                                    </>
                                }
                                rows={
                                    studentsList.map(
                                        (student, i) =>
                                            <div className='position-relative' key={student._id}>
                                                <Link to={`/reportcard/${student._id}`} className="w-100">
                                                    <Row className={classNames('py-1', { "board__row ": i !== student.length, "board__last-row ": i === student.length - 1 })}>
                                                        <Col xs={3} className="">
                                                            <div className='my-2'>
                                                                <span className='body-m'>{`${student.lastName}, ${student.name}`}</span>
                                                            </div>
                                                        </Col>
                                                        <Col xs={2} className=" mx-2">
                                                            <div className='my-2'>
                                                                <span className='body-m'>{`${course.age} - ${course.division}`}</span>
                                                            </div>
                                                        </Col>
                                                        <Col xs={2} className=" mx-2">
                                                            <div className='my-2'>
                                                                <span className='body-m'>{course.shift}</span>
                                                            </div>
                                                        </Col>
                                                        <Col xs={2} className=" mx-2">
                                                            <div className='my-2'>
                                                                <span className='body-m'>{getStudentAttendancesById(student._id)} de {totalAttendances}</span>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Link>
                                                {
                                                    (role !== 'teachers' && role !== 'directorate' && role !== 'tutorship') &&
                                                    <Row className='position-absolute-top position-absolute-right'>
                                                        <Col xs={{ span: 1 }} className="mr-2 ml-auto">
                                                            <div className='my-2 d-flex justify-content-end'>
                                                                <Button icon="bookOpenFA" className="button__only-icon-withoutcolor button_z-indez_top" />
                                                                <Button icon="deleteMUI" className="button__only-icon-withoutcolor button_z-indez_top" onClick={handleDelete} />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                }
                                            </div>
                                    )
                                }
                            />
                    }
                </>
            }
            {
                (role !== 'teachers' && role !== 'directorate' && role !== 'tutorship') &&
                <CustomFabicon text="Nueva asignación" to={`/courses/assign/${id}`} />
            }
        </>
    )
}