import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { startLoginWithGoogle, startLogout, revalidateSession } from '../../actions/auth';
import { useGoogleLogin } from '@react-oauth/google';

import { DropdownButton } from './DropdownButton';
import { createLog } from '../../actions/logs';
import { useHistory } from 'react-router';
import { Button } from '../Button';
import { api } from '../../helpers/fetch';
import { useState } from 'react';
import classNames from 'classnames'
import { useAuth } from '../../hooks/useAuth'

const options = [
    { label: "Directivo", type: "directorate" },
    { label: "Docente", type: "teachers" }
]

export const SettingsDropdown = ({ role, show, setShow }) => {

    const dispatch = useDispatch();
    const { scope, auth } = useSelector(state => state)
    const { user } = useAuth()
    const { push } = useHistory();
    const [change, setChange] = useState(role === 'directorate' ? true : false)

    const logout = () => dispatch(startLogout());

    const changeRol = async (value) => {
        await api.put(`auth/changeRole/${user.uid}`, { "userRole": value })
        dispatch(revalidateSession())
        setChange(!change)
    }

    const login= useGoogleLogin({
        onSuccess: async tokenResponse   => {
            dispatch(startLoginWithGoogle(tokenResponse));
            setShow(false);
        },
        onError: error => {
            dispatch(
                createLog({
                    type: "error",
                    title: "Lo sentimos!",
                    text: "No logramos obtener el token necesario",
                })
            );
            setShow(false);
        }
    });

    return (
        show &&
        <div className='d-flex flex-direction-column show-box pb-3' >
            {
                role === "administration" &&
                <DropdownButton
                    onClick={login}
                    icon="google"
                    label="Ingresa con otra cuenta"
                />
            }
            {
                (user.userAllRoles?.length > 1) &&
                <>
                    <Button
                        content={<span className='m-auto'> Rol {options.find(op => op.type === user.userAllRoles[0]).label} </span>}
                        className={classNames(
                            'd-flex button__only-icon-withoutcolor py-2 px-4 mt-3',
                            {
                                'showicon': !change,
                                'body-m-b': change,
                                'color-primary': change
                            }
                        )}
                        icon={change ? "checkFA" : "rotateFA"}
                        iconRight={true}
                        onClick={() => changeRol(user.userAllRoles[0])}
                    />
                    <Button
                        content={<span className='m-auto'>Rol {options.find(op => op.type === user.userAllRoles[1]).label}</span>}
                        icon={change ? "rotateFA" : "checkFA"}
                        className={classNames(
                            'd-flex bg-hover-4 button__only-icon-withoutcolor body-m py-2 px-4 my-2',
                            {
                                'showicon': change,
                                'body-m-b': !change,
                                'color-primary': !change
                            }
                        )}
                        iconRight={true}
                        onClick={() => changeRol(user.userAllRoles[1])}
                    />
                </>
            }
            <hr className='m-0' />
            <DropdownButton
                label="Ficha personal"
                icon="userFA"
                onClick={() => push({
                    pathname: `/userdetail/${user.uid}`,
                    state: { type: `${user.userRole}` },
                })}
            />
            <DropdownButton
                label="Cerrar sesión"
                icon="logoutFA"
                onClick={logout}
            />
        </div>
    )
}

SettingsDropdown.propTypes = {
    role: PropTypes.string.isRequired,
}