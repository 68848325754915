import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { BreadCrumbs } from '../../BreadCrumbs'
import { MessageForm } from './MessageForm'

export const NewMessage = () => {
    return (
        <main>
            <Container className='newmessages__container'>
                <Row>
                    <Col xs={12}>
                        <BreadCrumbs lastSteps={[{ to: "/home", step: "Inicio" }, { to: "/messages", step: "Mensajes" }]} title="Nuevo mensaje" />
                    </Col>
                </Row>
                <div className='newmessages__mail'>
                    <MessageForm />
                </div>
            </Container>
        </main>
    )
}
