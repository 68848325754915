import { Col, Row } from "react-bootstrap"
import { AccordionToggle } from "../../AccordionToggle"
import { faFolder, faRectangleList } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { File } from "../../File"
import { SubjectInfoText } from "./SubjectInfoText"
import { CourseEvaluativeCriteria } from "./SubjectEvaluativeCriteria"
import { useEffect, useRef } from "react"
import { useState } from "react"
import { CustomInput } from "../../CustomInput"
import { Button } from "../../Button"
import { api } from "../../../helpers/fetch"
import { FilePicker } from 'react-file-picker'
import { getUploadableFile } from '../../../helpers/get-uploadable-file'
import driveApi from '../../../api/drive'
import { getFileUrl } from '../../../helpers/get-file-url'
import { Toast } from 'primereact/toast'
import { translate } from "../../../helpers/reduceString"


export const SubjectInfo = ({ subject, id, role }) => {

  const [subjectData, setSubjectData] = useState()
  const [schedule, setSchedule] = useState([])

  //--------- Program---------

  const [program, setProgram] = useState()
  const [programInput, setProgramInput] = useState({ program: "" })
  const [addProgram, setAddProgram] = useState(false)
  const [editProgram, setEditProgram] = useState(false)

  const toast = useRef(null)

  const programFileOptions = [{
    name: 'Editar',
    icon: 'pi pi-pencil',
    action: () => {
      setEditProgram(true)
    }
  }]

  const handleProgramChange = ({ target }) => {
    setProgramInput({
      ...programInput,
      [target.name]: target.value
    })
  }

  const handleAddProgramDocument = () => { setAddProgram(true) }
  const handleSubmitProgram = () => {
    api.put(`subjects/edit/${id}`, programInput).then(res => {
      if (res) {
        setProgram(programInput.program)
        setEditProgram(false)
      }
    })
  }
  const getProgram = async () => {
    const resp = await api.get(`/subjects/getById/${subject._id}`)
    setProgram(resp.data.subject?.program)
  }

  const handleCancelSubmitProgramt = () => {
    if (addProgram === true) {
      setAddProgram(false)
    } else {
      setEditProgram(false)
    }
  }

  const showSuccess = (summary = 'Actualizado', detail) => {
    toast.current.show({ severity: 'success', summary, detail, life: 3000 })
  }

  const showError = (summary, detail) => {
    toast.current.show({ severity: 'error', summary, detail, life: 3000 })
  }

  const onFilePickerChange = async (file) => {
    try {
      const driveFile = await driveApi.uploadFile(await getUploadableFile(file))
      addResource({
        resourseName: driveFile.name,
        resourseUrl: getFileUrl(driveFile)
      })
      showSuccess('Archivo subido', 'El archivo se ha subido correctamente')
    } catch (error) {
      showError('Error al subir el archivo', error.message)
      throw error
    }
  }

  //--------- Resources---------

  const [resources, setResources] = useState([])

  const resourcesFileOptions = [{
    name: 'Borrar',
    icon: 'pi pi-trash',
    action: (file, fileId) => {
      const resoursesFiltered = resources.filter((resource, index) => fileId !== index)
      const data = { resources: resoursesFiltered }
      api.put(`subjects/edit/${id}`, data).then(res => {
        if (res) {
          setResources(resoursesFiltered)
        }
      })

    }
  }]


  const addResource = async (resource) => {
    const data = { resources: resources.concat([resource]) }
    api.put(`subjects/edit/${id}`, data).then(res => {
      if (res) {
        setResources(data.resources)
      }
    })
  }

  useEffect(() => {
    setSchedule(subject?.schedule?.map(item => { return (`${item.day} de ${item.fromHour}:${item.fromMin} a ${item.toHour}:${item.toMin}`) }))
  }, [subjectData])


  useEffect(() => {
    setSubjectData(subject)
    setResources(subject.resources)
    setProgram(subject.program)
    if (subject._id !== undefined) getProgram()
  }, [subject])


  return (
    <>
      <Toast ref={toast} />
      <div className="subject__info">
        <Row className="my-2">
          <Col>
            <h3 className="m-0">Información de la materia</h3>
          </Col>
        </Row>
        <Row className="mb-4">
          <Col xs={3}>
            <SubjectInfoText name="Curso" values={[`${subjectData?.course?.age} - ${subjectData?.course?.shift}`]} />
          </Col>
          <Col xs={3}>
            <SubjectInfoText name="Materia" values={[subjectData?.name]} />
          </Col>
          <Col xs={3}>
            <SubjectInfoText name="Nivel" values={[translate(subjectData?.course?.level)]} />
          </Col>
        </Row>
        <Row className="mb-4">
          <Col xs={6}>
            <SubjectInfoText name="Días y horarios" values={schedule} />
          </Col>
          <Col xs={6}>
            <SubjectInfoText name="Docente a cargo" values={[`${subjectData?.teacher?.lastName}, ${subjectData?.teacher?.name}`]} />
          </Col>
        </Row>
      </div>
      <Row>
        <Col className='mb-3'>
          <hr className='customoptions__hr w-100 m-0 mb-2' />
        </Col>
      </Row>
      <Row>
        <Col className="mb-2">
          <AccordionToggle items={
            [{
              header: <h4 className="m-0">Criterios valorativos</h4>,
              body: <CourseEvaluativeCriteria
                role={role}
                subject={subject}
                courseCriteria={subjectData?.subjectAssessmentCriteria} />
            },]
          } />
        </Col>
      </Row>
      <Row>
        <Col className="my-3">
          <div className="d-flex align-items-center">
            <FontAwesomeIcon icon={faRectangleList} />
            <h4 className="m-0 ml-2">Programa</h4>
          </div>
        </Col>
      </Row>
      <Row>
        {program &&
          <>
            {
              !editProgram ?
                <Col xs={4}>
                  <File
                    file={{ name: "Programa" }}
                    url={program}
                    options={role !== 'students' && programFileOptions} 
                    dropDown={role === 'students' ? false : true}/>
                </Col>
                :
                <>
                  <Col xs={5}>
                    <CustomInput
                      classNameInput={'w-100'}
                      classNameLabel={''}
                      handleInputChange={handleProgramChange}
                      name="program"
                      value={programInput.program}
                      placeholder="Url del programa"
                    />
                  </Col>
                  <Col xs={7} className='ml-auto d-flex'>
                    <Button
                      type="button"
                      className=" button-terciary button-s-sb d-flex align-items-center ml-2 mr-5 p-0 "
                      content={<span className="ml-1 mt-1">Cancelar</span>}
                      iconRight={false}
                      onClick={handleCancelSubmitProgramt} />
                    <Button
                      type="button"
                      className='btn button-primary color-primary button-m-sb mr-5'
                      content={<span className="ml-1 mt-1">Guardar</span>}
                      onClick={handleSubmitProgram}
                      disabled={programInput.program ? false : true} />
                  </Col>
                </>
            }
          </>
        }
        {(!program && role !== 'students') &&
          (addProgram ?
            <>
              <Col xs={5} >
                <CustomInput
                  classNameInput={'w-100'}
                  classNameLabel={''}
                  handleInputChange={handleProgramChange}
                  name="program"
                  value={programInput.program}
                  placeholder="Url del programa"
                />
              </Col>
              <Col xs={7} className='ml-auto d-flex'>
                <Button
                  type="button"
                  className=" button-terciary button-s-sb d-flex align-items-center ml-2 mr-5 p-0 "
                  content={<span className="ml-1 mt-1">Cancelar</span>}
                  iconRight={false}
                  onClick={handleCancelSubmitProgramt} />
                <Button
                  type="button"
                  className='btn button-primary color-primary button-m-sb mr-5'
                  content={<span className="ml-1 mt-1">Guardar</span>}
                  onClick={handleSubmitProgram}
                  disabled={programInput.program ? false : true} />
              </Col>
            </>
            :
            <Col>
              <Button
                icon="plusMUI"
                content={<span className="ml-1 mt-1">Agregar programa</span>}
                className=" button-terciary button-s-sb d-flex align-items-center m-0 p-0 "
                iconRight={false}
                type="button"
                onClick={handleAddProgramDocument} />
            </Col>
          )
        }
      </Row>
      <Row>
        <Col className="my-3">
          <div className="d-flex align-items-center">
            <FontAwesomeIcon icon={faFolder} />
            <h4 className="m-0 ml-2">Recursos</h4>
          </div>
        </Col>
      </Row>
      <Row>
        {resources?.map((resource, i) =>
          <Col xs={4} key={resource._id} >
            <div className="my-0 mb-3">
              <File
                fileId={i}
                file={{ name: resource.resourseName }}
                url={resource.resourseUrl}
                options={role !== 'students' && resourcesFileOptions} 
                dropDown={role === 'students' ? false : true}/>
            </div>
          </Col>
        )}
      </Row>
      {
        role !== 'students' &&
        <FilePicker onChange={onFilePickerChange}>
          <Button icon="attachMUI" type="file"
            content="Adjuntar" className="newmessages__textarea-attach-button button__only-icon-withoutcolor color-primary button-s-sb my-2" />
        </FilePicker>
      }
    </>
  )
}
