import { types } from "../types/types";

const initialState = null;

export const logsReducer = ( state = initialState, action) => {
    
    switch ( action.type ) {
        case types.setLog:
            return action.payload
        case types.clearLog:
            return initialState
        default:
            return state;
    }

};
