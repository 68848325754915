
export const translate = (value) => {
    switch (value) {
        case "Elementary":
            return "Inicial"
    
        case "Primary":
            return "Primaria"
    
        case "High":
            return "Secundaria"
    
        default:
            return value;
    }
}

export const reduceString = (arr, param, char) => {
    if ( !arr || !param || !char) return "";
    return arr.reduce(
        (previousValue, currentValue) => 
            !previousValue.split(char).includes(translate(currentValue[param])) ?
                `${previousValue}${char}${translate(currentValue[param])}`
            :
                previousValue,
        ""
    ).slice(1)
}