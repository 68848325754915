import React, { useEffect } from 'react'
import { useSelector } from 'react-redux';
import { useAuth } from '../hooks/useAuth';
import { useForm } from '../hooks/useForm'

export const SelectAccount = ({ setToken }) => {
    const { user, adminUser } = useSelector( state => state.auth );
    const { user: defaultUser } = useAuth();
    const [ values, handleInputChange ] = useForm({ email: defaultUser?.email })
    
    useEffect(() => {
        if (values.email === process.env.REACT_APP_ADMIN_EMAIL) {
            setToken(adminUser.jwt)
        } else {
            setToken(localStorage.getItem('token'))
        }
    }, [values])
    

    return (
        user?.uid && adminUser?.uid ?
            <select 
                name="email"
                value={values.email}
                onChange={handleInputChange}
                className="select"
            >
                <option value={user?.email}>{user?.email}</option>
                <option value={adminUser?.email}>{adminUser?.email}</option>
            </select>
        : <></>
    )
}
