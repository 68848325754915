import React, { useState, useEffect, useRef } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { FilePicker } from "react-file-picker";
import { BreadCrumbs } from "../../BreadCrumbs";
import { CustomFabicon } from "../../CustomFabicon";
import driveApi from "../../../api/drive";
import { Toast } from "primereact/toast";
import { getFileUrl } from "../../../helpers/get-file-url";
import { File } from "../../File";
import { useSelector } from "react-redux";
import { getUploadableFile } from "../../../helpers/get-uploadable-file";

export const Regulations = () => {
  const [regulations, setRegulations] = useState([]);
  const [folders, setFolders] = useState([]);
  const { adminUser } = useSelector((state) => state.auth);
  const toast = useRef(null);

  const showSuccess = (summary = "Actualizado", detail) => {
    toast.current.show({ severity: "success", summary, detail, life: 3000 });
  };

  const showError = (summary, detail) => {
    toast.current.show({ severity: "error", summary, detail, life: 3000 });
  };

  const updateRegulations = async () => {
    try {
      const files = await driveApi.getFiles(adminUser?.jwt);
      setRegulations(
        files
          .filter( file => !file.mimeType.includes('folder'))
          .sort((a, b) => {
            if(a.name < b.name) { return -1; }
            if(a.name > b.name) { return 1; }
            return 0;
          })
      );
      setFolders(
        files
          .filter( file => file.mimeType.includes('folder'))
          .sort((a, b) => {
            if(a.name < b.name) { return -1; }
            if(a.name > b.name) { return 1; }
            return 0;
          })
      );
    } catch (error) {
      showError("Error al actualizar las reglamentaciones");
      throw error;
    }
  };
  useEffect(() => {
    updateRegulations();
  }, []);

  const onFilePickerChange = async (file) => {
    try {
      await driveApi.uploadFile(await getUploadableFile(file), adminUser?.jwt);
      await updateRegulations();
      showSuccess("Archivo subido", "El archivo se ha subido correctamente");
    } catch (error) {
      showError("Error al subir el archivo", error.message);
      throw error;
    }
  };

  const options = [
    {
      name: "Copiar link",
      icon: "pi pi-link",
      action: (file) => {
        navigator.clipboard.writeText(getFileUrl(file));
        showSuccess("Link copiado", "El link se ha copiado al portapapeles");
      },
    },
    {
      name: "Cambiar nombre",
      icon: "pi pi-pencil",
      action: async (file) => {
        const [oldName, extension] = file.name.split(".");
        const newName = prompt("Nuevo nombre:", oldName);

        if (newName && newName !== oldName) {
          await driveApi.updateFile(
            file.id,
            { name: extension ? `${newName}.${extension}` : newName },
            adminUser?.jwt
          );
          updateRegulations();
          showSuccess(
            "Nombre cambiado",
            "El nombre del archivo se ha cambiado correctamente"
          );
        }
      },
    },
    {
      name: "Borrar",
      icon: "pi pi-trash",
      action: async (file) => {
        await driveApi.deleteFile(file, adminUser?.jwt);
        updateRegulations();
        showSuccess(
          "Archivo borrado",
          "El archivo se ha borrado correctamente"
        );
      },
    },
  ];

  return (
    <Container>
      <Toast ref={toast} />
      <div className="regulations__container">
        <BreadCrumbs
          lastSteps={[{ to: "/", step: "Inicio" }]}
          title="Reglamentaciones"
        />
        <hr />
        <div>
          <b>Archivos</b>
        </div>
        <Row>
          {[...folders, ...regulations].map((regulation, index) => (
            <Col xl={3} lg={4} md={6} xs={12} key={regulation.id}>
              <File
                key={index}
                file={regulation}
                options={options}
                onError={showError}
              />
            </Col>
          ))}
        </Row>
      </div>
      <FilePicker onChange={onFilePickerChange}>
        <CustomFabicon text="Nueva reglamentación" />
      </FilePicker>
    </Container>
  );
};
