import { useCallback, useEffect, useState } from "react"
import { Col, Container, Row } from "react-bootstrap"
import { useParams } from "react-router-dom/cjs/react-router-dom.min"
import { BreadCrumbs } from "../../BreadCrumbs"
import { CustomOptions } from "../../CustomOptions"
import { SubjectInfo } from "./SubjectInfo"
import { api } from "../../../helpers/fetch"

import { SubjectModules } from "./SubjectModules"
import { SubjectStudents } from "./SubjectStudents"
import { Loading } from "../../Loading"
import { useSelector } from "react-redux"
import SubjectStudentCalification from "./SubjectStudentCalification"


const options = [
    { label: "Información", type: "information", },
    { label: "Módulos", type: "modules", },
    { label: "Alumnos", type: "students", },
]
const option = [
    { label: "Información", type: "information", },
    { label: "Módulos", type: "modules", },
    { label: "Calificaciones", type: "report", }
]

export const SubjectDetail = () => {
    const auth = useSelector(state => state.auth)
    const course = auth.user.userRole === 'students' ? '/studentsubjects' : '/subjects'
    const { id: subjectId } = useParams()
    const [value, setValue] = useState(options[0]);
    const [subject, setSubject] = useState({})
    const [assessments, setAssessments] = useState({})
    const [isLoading, setIsLoading] = useState(true)


    const getSubject = async () => {
        const resp = await api.get(`/subjects/getById/${subjectId}`)
        setSubject(resp.data.subject)
    }
    
    const getAssessments = async () => {
        const res = await api.get(`assessments/getAssessmentsBySubject/${subjectId}`)
        setAssessments(res.data.assessments)
        setIsLoading(false)
    }

    const getSubjectDetail = useCallback(
        async (id) => {
            await api.get(`subjects/getById/${id}`)
                .then(res => {
                    const studentsArr = res.data.subject.course.students.map(student => student._id)
                    studentsArr.forEach(async (student) => {
                        await api.post(`assessments/${student}/${subjectId}`)
                    });
                })
        }, [subjectId]
    )

    useEffect(() => {
        if (auth.user.userRole !== 'students') {
            getSubjectDetail(subjectId)
        }
        getSubject()
        getAssessments()
    }, [subjectId, getSubjectDetail])

    return (
        <Container className="subjectDetail__container">
            {isLoading ? <Loading /> :
                <>
                    <Row className='mb-2 pb-2'>
                        <Col xs={12}>
                            <BreadCrumbs
                                lastSteps={[
                                    { to: "/home", step: "Inicio" },
                                    { to: `${course}`, step: "Materias" }
                                ]}
                                title={subject.name}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <CustomOptions options={auth.user.userRole === 'students' ? option : options} value={value} setValue={setValue} />
                        </Col>
                    </Row>
                    {
                        auth.user.userRole === 'students' ?
                            <>
                                {value.type === "information" && <SubjectInfo subject={subject} course={course} id={subjectId} role={auth.user.userRole} />}
                                {value.type === "modules" && <SubjectModules role={auth.user.userRole} />}
                                {value.type === "report" && <SubjectStudentCalification assessments={assessments} />}
                            </>
                            :
                            <>
                                {value.type === "information" && <SubjectInfo subject={subject} course={course} id={subjectId} role={auth.user.userRole}/>}
                                {value.type === "modules" && <SubjectModules role={auth.user.userRole} />}
                                {value.type === "students" && <SubjectStudents assessments={assessments} course={course} subject={subject} />}
                            </>
                    }

                </>
            }
        </Container>
    )
}
