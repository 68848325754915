import { Col, Container, Row } from "react-bootstrap"
import FiberManualRecordOutlinedIcon from '@mui/icons-material/FiberManualRecordOutlined';
import { CustomSelect } from "../../CustomSelect";
import { CustomInput } from "../../CustomInput";
import { useForm } from "../../../hooks/useForm";
import { useEffect, useState, useRef } from "react";
import moment from "moment";
import { UrlDocument } from "../../UrlDocument";
import { Button } from "../../Button";
import { api } from "../../../helpers/fetch";
import { useDispatch } from "react-redux";
import { createLog } from "../../../actions/logs";

import { Toast } from 'primereact/toast'
import { FilePicker } from 'react-file-picker'
import { getUploadableFile } from '../../../helpers/get-uploadable-file'
import driveApi from '../../../api/drive'
import { getFileUrl } from '../../../helpers/get-file-url'
import { File } from "../../File"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFolder } from "@fortawesome/free-solid-svg-icons"

export const ActivityForm = ({ topic, activities, setActivities, setAddActivity, isActivityToEdit = false, setIsEditable, allActivities }) => {

    const dispatch = useDispatch();
    const typeOptions = [
        { label: "Actividad", value: "activity" },
        { label: "Evaluación", value: "test" }
    ]

    const [dateTime, setDateTime] = useState({ date: "", time: "" })

    const [values, handleInputChange, setValues] = useForm({
        topic: topic?._id,
        title: "",
        type: "",
        expDateTime: "",
        detail: "",
        urlDoc: [{
            url: "",
            docName: ""
        }],
        resources: [{
            resourseName: '',
            resourseUrl: ''
        }]
    })

    const handleDateTimeInputChange = ({ target }) => {
        setDateTime({
            ...dateTime,
            [target.name]: target.value
        })
        let dateTimeConcat = dateTime.date + "T" + dateTime.time
        setValues({
            ...values,
            expDateTime: moment(dateTimeConcat).format('yyyy-MM-DD hh:mm')
        })
    }

    const defaultUrlDoc = { url: "", docName: "" }

    const [urls, setUrls] = useState(values.urlDoc)

    const handleAddUrlDocument = () => {
        setUrls([
            ...urls,
            defaultUrlDoc
        ])
    }

    const onSubmitEvent = async () => {
        if (isActivityToEdit) {
            await api.put(`activities/${values._id}`, values).then(res => {
                if (res) {
                    const act = allActivities.filter(aux=> aux._id !== res.data._id)
                    setActivities([
                        ...act,
                        res.data
                    ])
                    dispatch(createLog({
                        type: "success",
                        title: "Edición exitosa",
                        text: `La actividad/evaluación se ha editado con exito.`,
                    }))
                    setIsEditable(false)
                }
            })
        } else {
            await api.post('activities', values).then(res => {
                if (res) {
                    setActivities([
                        ...activities,
                        res.data
                    ])
                    setAddActivity(true)
                }
            })
        }

    }

    const onSubmitCancel = () => {
        if (isActivityToEdit === false) {
            setAddActivity(true)
        } else {
            setIsEditable(false)
        }
    }

    const toast = useRef(null)

    const showSuccess = (summary = 'Actualizado', detail) => {
        toast.current.show({ severity: 'success', summary, detail, life: 3000 })
    }

    const showError = (summary, detail) => {
        toast.current.show({ severity: 'error', summary, detail, life: 3000 })
    }

    const onFilePickerChange = async (file) => {
        try {
            const driveFile = await driveApi.uploadFile(await getUploadableFile(file))
            addResource({
                resourseName: driveFile.name,
                resourseUrl: getFileUrl(driveFile)
            })
            showSuccess('Archivo subido', 'El archivo se ha subido correctamente')
        } catch (error) {
            showError('Error al subir el archivo', error.message)
            throw error
        }
    }

    //--------- Resources---------

    const [resources, setResources] = useState([])

    const resourcesFileOptions = [{
        name: 'Borrar',
        icon: 'pi pi-trash',
        action: (file, fileId) => {
            const resoursesFiltered = resources.filter((resource, index) => fileId !== index)
            const data = { resources: resoursesFiltered }
            setResources(data.resources)
        }
    }]

    const addResource = async (resource) => {
        const data = { resources: resources.concat([resource]) }
        setResources(data.resources)
    }

    useEffect(() => {
        setValues({
            ...values,
            resources: resources
        })
    }, [resources])
    
    useEffect(() => {
        setValues({
            ...values,
            urlDoc: urls,
        })
    }, [urls])
    
    useEffect(() => {
        if (isActivityToEdit === true) {
            setValues(activities)
            setResources(activities.resources)
        }
    }, [isActivityToEdit, activities])
    
    return (
        <>
            <Toast ref={toast} />
            <Container>
                <Row>
                    <Col>
                        <div className="d-flex m-4">
                            <FiberManualRecordOutlinedIcon className="icons__8 color-primary pt-1 mr-1" />
                            <p className="ml-3 body-l-sb">Nuevo contenido</p>
                        </div>
                    </Col>
                </Row>
                <Row className="mb-4 mt-3 ml-4">
                    <Col xs={3}>
                        <CustomSelect
                            className="w-100 my-4"
                            options={typeOptions}
                            handleInputChange={handleInputChange}
                            name="type"
                            labelContent={<><span style={{ color: "#ff0000" }}>*</span> Tipo de contenido</>}
                            values={values.type}
                            placeholder="Tipor de contenido"
                            alwaysShow={true} />
                    </Col>
                    <Col xs={4}>
                        <CustomInput
                            classNameInput="w-100 my-4"
                            classNameLabel="w-100 mb-4 pb-2"
                            handleInputChange={handleInputChange}
                            name="title"
                            labelContent={<><span style={{ color: "#ff0000" }}>*</span> Título de la Actividad</>}
                            value={values.title}
                            placeholder="Título de la Actividad"
                            alwaysShow={true} />
                    </Col>
                </Row>
                <Row className="mb-4 ml-4">
                    <Col xs={3}>
                        <CustomInput
                            type="date"
                            classNameInput="w-100 my-4"
                            classNameLabel="w-100 mb-4 pb-2"
                            handleInputChange={handleDateTimeInputChange}
                            name="date"
                            labelContent={<><span style={{ color: "#ff0000" }}>*</span> Fecha de entrega</>}
                            value={moment(dateTime.date).format("yyyy-MM-DD")}
                            placeholder="Fecha de entrega"
                            alwaysShow={true} />
                    </Col>
                    <Col xs={3}>
                        <CustomInput
                            type="time"
                            classNameInput="w-100 my-4"
                            classNameLabel="w-100 mb-4 pb-2"
                            handleInputChange={handleDateTimeInputChange}
                            name="time"
                            labelContent={<><span style={{ color: "#ff0000" }}>*</span> Hora de entrega</>}
                            value={dateTime.time}
                            placeholder="Hora de entrega"
                            alwaysShow={true} />
                    </Col>
                </Row>
                <Row>
                    <Col xs={7} className="position-relative ml-4">
                        <label htmlFor="Detalle de la actividad" className='mt-5 ml-3 position-absolute'>Detalle de la actividad</label>
                        <textarea
                            name="detail"
                            onChange={handleInputChange}
                            rows={5}
                            cols={65}
                            className='my-4 py-5 px-3'
                            value={values.detail} />
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <hr className='w-100' />
                    </Col>
                </Row>
                <Row className="ml-4">
                    <Col>
                        {values.urlDoc.map((doc, i) =>
                            <UrlDocument
                                index={i}
                                doc={doc}
                                urls={urls}
                                setUrls={setUrls}
                                classNameInput="w-100 my-4"
                                classNameLabel="w-100 mb-4 pb-2"
                            />

                        )}
                    </Col>
                </Row>
                <Row>
                    <Col xs={2} className='ml-4'>
                        <Button
                            icon="plusMUI"
                            content={<span className="ml-1 mt-1">Agregar documento</span>}
                            className=" button-terciary button-s-sb d-flex align-items-center m-0 p-0 "
                            iconRight={false}
                            type="button"
                            onClick={handleAddUrlDocument} />
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <hr className='w-100' />
                    </Col>
                </Row>
                <Row>
                    <Col xs={2} className='ml-4 mt-3'>
                        <div className="d-flex align-items-center">
                            <FontAwesomeIcon icon={faFolder} />
                            <h5 className="m-0 ml-2">Archivos</h5>
                        </div>
                    </Col>
                </Row>
                <Row className='ml-1 mr-1 mt-3'>
                    {
                        resources?.map(resource =>
                            <Col xs={4} key={resource._id}>
                                <div className="my-0 mb-3">
                                    <File
                                        fileId={resource._id}
                                        file={{ name: resource.resourseName }}
                                        url={resource.resourseUrl}
                                        options={resourcesFileOptions} />
                                </div>
                            </Col>
                        )}
                </Row>
                <Row xs={2} className='ml-4 mt-3'>
                    <FilePicker onChange={onFilePickerChange} >
                        <Button icon="attachMUI" type="file"
                            content="Adjuntar" className="newmessages__textarea-attach-button button__only-icon-withoutcolor color-primary button-s-sb my-2" />
                    </FilePicker>
                </Row>
                <Row>
                    <Col xs={12}>
                        <hr className='w-100' />
                    </Col>
                </Row>
                <Row className="mb-4">
                    <Col xs={{ span: 10, offset: 1 }} className="d-flex">
                        <div className='ml-auto d-flex'>
                            <Button
                                type="reset"
                                className='btn button__only-icon-withoutcolor color-primary  button-m-sb mr-5'
                                content="Cancelar"
                                onClick={onSubmitCancel} />
                            <Button
                                className='btn button-primary button-m-sb'
                                content="Guardar"
                                onClick={onSubmitEvent}
                                disabled={(values.title && values.type && dateTime.date && dateTime.time && values.detail) ? false : true} />
                        </div>
                    </Col>
                </Row>
            </Container>
        </>


    )
}