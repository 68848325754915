import React from "react";
import { Link } from "react-router-dom";

export const BreadCrumbs = ({
    lastSteps,
    title,
    lastLink = undefined,
}) => {
    return (
        <>
            <div
                className="d-flex flex-direction-row mt-3 pt-2
                color-primary"
            >
                {lastSteps?.map((link) => (
                    <Link to={link.to} key={link.to?.pathname ?? link.to}>
                        <p className=" ml-1  color-primary">{link.step} /</p>
                    </Link>
                ))}
                <strong className=" ml-1 color-text"> {lastLink || title}</strong>
            </div>
            {title && <h2 className=" mt-3 color-text">{title}</h2>}
        </>
    );
};
