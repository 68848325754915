import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { api } from "../../../api"
import { BreadCrumbs } from "../../BreadCrumbs"
import { CardCartelera } from "../../CardCartelera"
import { Loading } from "../../Loading";
import { Col, Container, Row } from "react-bootstrap"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarDay } from '@fortawesome/free-solid-svg-icons';
import { EmptyStates } from "../../EmptyStates"
import emptySubject from '../../../Imgs/EmptyStates/Social 7 cursos.png'

const SubjectStudent = () => {
    const auth = useSelector(state => state.auth)
    const [subjects, setSubjects] = useState([])
    const [breadCrumbs, setBreadCrumbs] = useState(true)
    const [loading, setLoading] = useState(true)
    const breadCrumbsVisible = true
    const getCourse = async () => {
        try {
            const { data } = await api.get(`/courses/${(new Date()).getFullYear()}`)
            const id = data.courses.filter(course => course.students.includes(auth.user._id)).map(course => course._id).flat()

            const materias = await api.get(`subjects/${id}`)
            setSubjects(materias.data.subjects)
        } catch (error) {
            console.log(error)
        }
        setLoading(false)
    }

    useEffect(() => {
        setBreadCrumbs(breadCrumbsVisible);
        getCourse()
    }, [])

    return (
        <main>
            {loading ? <Loading /> :
                <Container>
                    <div className={breadCrumbs ? '' : 'hidden'}>
                        <Row className='mb-2 pb-2'>
                            <Col xs={12}>
                                <BreadCrumbs
                                    lastSteps={[
                                        { to: "/home", step: "Inicio" }
                                    ]}
                                    title="Materias" />
                            </Col>
                        </Row>
                        <Row>
                            <Col className='mb-3'>
                                <hr className='customoptions__hr w-100 m-0 mb-3' />
                            </Col>
                        </Row>
                    </div>
                    {
                        subjects.length === 0 ?
                            <EmptyStates src={emptySubject} title='Asignaciones en proceso' text='Actualmente la asignación de materias está en proceso. Para más información puedes comunicarte con secretaría.' />
                            :
                            <Row className='my-2 py-2'>
                                {subjects?.map((subject, i) =>
                                    <Col sm={12} md={6} lg={5} xl={3} key={i} className="mb-3">
                                        <CardCartelera
                                            title={subject.name}
                                            to={
                                                {
                                                    pathname: `/subjects/detail/${subject._id}`,
                                                    state: { course: '/studentsubjects' }
                                                }
                                            }
                                            cardBody={
                                                <>
                                                    <p className="body-m-sb mb-2">
                                                        {
                                                            !subject.course
                                                                ?
                                                                `Docente: ${subject.teacher.lastName}, ${subject.teacher.name}`
                                                                :
                                                                'Sin asignar'
                                                        }
                                                    </p>
                                                    <p className="body-m-sb"><FontAwesomeIcon icon={faCalendarDay} className="mr-2" />
                                                        {`${subject.schedule[0].day}
                                                ${subject.schedule[1] ? `- ${subject.schedule[1].day}` : ''}
                                                ${subject.schedule[2] ? `- ${subject.schedule[2].day}` : ''}`}
                                                    </p>
                                                </>
                                            }
                                            arrow={false}
                                        />
                                    </Col>
                                )}
                            </Row>
                    }
                </Container>
            }
        </main>
    )
}
export default SubjectStudent