import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { Button } from '../../Button'
import AvatarsProfilePic from "../../../Imgs/AvatarsProfilePic.png"
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import moment from 'moment'
import { useSelector } from 'react-redux'
import {translate} from "../../../helpers/reduceString"

export const UserPresentation = ({ id, name = "", profileImgUrl = "", lastName = "", registerDate , level = "", shifts = "", course = "", edit = true, role, disable = Boolean, handleDisable}) => {
    const auth = useSelector((state) => state.auth);
    const history = useHistory()
    const goToUserNew = (e) => {
        e.preventDefault()
        history.push({
            pathname: `/uploadUser`,
            state: { id: id }
        })
    }
    return (
        <Row className='userpresentation__container mt-4 pt-2'>
            <Col xs={{ span: 2, offset: 1 }}>
                <img src={profileImgUrl}
                    onError={(e) => {
                        e.currentTarget.src = AvatarsProfilePic
                    }}
                    alt={"Foto del usuario" + name} />
            </Col>
            <Col xs={8}>
                <div className='d-flex flex-direction-column'>
                    <div className='d-flex justify-content-between flex-direction column'>
                        <h3 className='m-0'>{name + " " + lastName}</h3>

                        {
                            (edit && role === 'administration') ? 
                            <Button 
                                onClick={(e) => goToUserNew(e)} 
                                content={<span className='ml-3'>Editar</span>} 
                                icon="penFA" 
                                className="button-terciary" 
                            />
                            :
                            (
                                disable === false &&
                                <Button 
                                    content={<span className='ml-3'>Editar</span>} 
                                    icon="penFA" 
                                    className="button-secondary"
                                    onClick={handleDisable}
                                />
                            )
                        }
                    </div>
                    {
                        role === 'students'
                            ?
                            <Row className="mt-4 pt-4">
                                <Col xs={3}>
                                    <span className='color-disabled input-label'>Nivel</span>
                                    <p className='color-disabled'>{translate(level)}</p>
                                </Col>
                                <Col xs={3}>
                                    <span className='color-disabled input-label'>Turno</span>
                                    <p className='color-disabled'>{shifts}</p>
                                </Col>
                                <Col xs={3}>
                                    <span className='color-disabled input-label'>Curso</span>
                                    <p className='color-disabled'>{course}</p>
                                </Col>
                            </Row>
                            :
                            <>
                                <Row>
                                    <Col className='d-flex'>
                                        <span className='color-disabled input-label mr-2'>Fecha de ingreso</span>
                                        <p className='color-disabled input-label'>{moment(registerDate).format('DD/MM/yyyy')}</p>
                                    </Col>
                                </Row>
                                <Row className='mt-4 pt-3'>
                                    <Col xs={3}>
                                        <span className='color-disabled input-label'>Nivel</span>
                                        <p className='color-disabled'>{translate(auth.user.level)}</p>
                                    </Col>
                                    <Col xs={3}>
                                        <span className='color-disabled input-label'>Turno</span>
                                        <p className='color-disabled'>{shifts}</p>
                                    </Col>
                                </Row>
                            </>
                    }
                </div>
            </Col>
            <Col xs={{ span: 10, offset: 1 }}>
                <hr />
            </Col>

        </Row >
    )
}
