import React from "react";
import { Col, Row } from "react-bootstrap";
import { CustomInput } from "../../CustomInput";

export const UserContactInformation = ({
  form,
  handleInputChange,
  disable,
}) => {
  return (
    <>
      <Row className="userper my-2">
        <Col xs={{ span: 8, offset: 1 }}>
          <div className="d-flex justify-content-between">
            <h4 className="m-0">Datos de contacto</h4>
          </div>
        </Col>
      </Row>
      <Row className="d-flex align-items-center my-4">
        <Col
          xs={{ span: 2, offset: 1 }}
          className="userpersonaldata__form-input body-l"
        >
          <CustomInput
            disabled={!disable}
            name="celPhone"
            value={form.celPhone}
            placeholder="11-2222-3333"
            labelContent="Telefono celular"
            handleInputChange={handleInputChange}
            classNameInput="body-l w-100 pt-4"
            classNameLabel="input-label"
            alwaysShow={true}
          />
        </Col>
        <Col xs={3}>
          <CustomInput
            disabled={!disable}
            placeholder="Ej: hola@gmail.com"
            labelContent="Correo electrónico"
            handleInputChange={handleInputChange}
            name="email"
            classNameInput="body-l w-100 pt-4"
            classNameLabel="input-label"
            alwaysShow={true}
          />
        </Col>
      </Row>
      <Row className="d-flex align-items-center mt-4 pt-2">
        <Col xs={{ span: 10, offset: 1 }}>
          <hr />
        </Col>
      </Row>
    </>
  );
};
