const setInPath = (obj, path, value) => {
  const [key, ...rest] = path
  const isArray = parseInt(rest[0]).toString() !== NaN.toString()

  if (typeof obj[key] !== 'object') {
    if (isArray)
      obj[key] = []
    else
      obj[key] = {}
  }

  if (path.length === 1) {
    if (isArray)
      obj[key].push(value)
    else
      obj[key] = value
  }
  else
    setInPath(obj[key], rest, value)
}

export const getUnflattenedObject = (obj) => {
  const nested = {}
  Object.keys(obj)
    .filter((key, i, keys) => {
      const hasNestedKeys = keys.some(k => k.startsWith(`${key}.`))
      return !hasNestedKeys
    })
    .forEach(key => setInPath(nested, key.split('.'), obj[key]))
  return nested
}

export const getFlattenedObject = (obj, baseKey = '', target = {}) => {
  Object.entries(obj).forEach(([key, value]) => {
    const internalKey = baseKey && `${baseKey}.${key}` || key
    if (value && typeof value === 'object') {
      getFlattenedObject(value, internalKey, target)
    } else {
      target[internalKey] = value
    }
  })
  return target
}
