import React from 'react'
import { Col, Row } from 'react-bootstrap'

export const EmptyStates = ({ src = "", title, text }) => {
    return (<div className="emptystate__container">
        <Row className='justify-content-center'>
            <Col xs={5} className=' d-flex justify-content-center' >
                <img src={src || ""} alt={title + text} />
            </Col >
        </Row >
        <Row className='justify-content-center'>
            <Col xs={5} >
                <h3 className='color-primary'>{title}</h3>
            </Col>
        </Row>
        <Row className='justify-content-center'>
            <Col xs={4} className=" px-0 mx-0">
                <p>{text}</p>
            </Col>
        </Row>
    </div >
    )
}
