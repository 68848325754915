import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Link, useHistory } from 'react-router-dom'
import { Board } from '../../Board'
import { BreadCrumbs } from '../../BreadCrumbs'
import { Button } from '../../Button'
import { CustomFabicon } from '../../CustomFabicon'
import { CustomOptions } from "../../CustomOptions"
import { PaginationControll } from '../../PaginationControll'
import { CustomSelect } from '../../CustomSelect'
import { useForm } from '../../../hooks/useForm'
import { api } from '../../../helpers/fetch'
import { createLog } from '../../../actions/logs'
import { useDispatch } from 'react-redux'
import { useCounter } from '../../../hooks/useCounter'
import { Loading } from '../../Loading'
import { CustomInput } from '../../CustomInput'
import { Form } from '../../Form'

const options = [{
    label: "Alumnos",
    type: "students",
}, {
    label: "Docentes",
    type: "teachers",
}, {
    label: "No docentes",
    type: "not-teacher",
}, {
    label: "Directivos",
    type: "directorate",
}];
const levelOptions = [
    { label: "Inicial", value: "initial" },
    { label: "Primario", value: "primary" },
    { label: "Secundario", value: "secondary" }
];
const curseOptions = [
    { label: "Inicial", value: "initial" },
    { label: "Primario", value: "primary" },
    { label: "Secundario", value: "secondary" }
];

const role = {
    teachers: 'Docente',
    tutorship: 'Tutor',
    secretariat: 'Secretario',
    administration: 'Administrativo'
}

const level = {
    Elementary: 'Inicial',
    Primary: 'Primaria',
    High: 'Secundaria'
}

const directorRole = {
    GeneralDirector: 'Director general',
    Director: 'Director',
    LegalRepresentative: 'Representante legal',
    Vicedirector: 'Vicedirector'
}

export const Sheet = () => {
    const dispatch = useDispatch();
    const { push } = useHistory();
    const [values, handleInputChange, setValues] = useForm({
        area: options[0],
        lastName: "",
    });
    const [loading, setLoading] = useState(false);
    const [users, setUsers] = useState([]);
    const [maxToCount, setMaxToCount] = useState(1)
    const [courses, setCourses] = useState([])
    const {
        counter,
        increment,
        decrement,
        reset
    } = useCounter();

    const getUsers = async () => {
        setLoading(true);
        api.get(`auth/getUsers?userRole=${values.area.type}&lastName=${values.lastName}&page=${counter}`)
            .then(
                resp => {
                    if (resp.data.ok) {
                        setUsers(resp.data.users)
                        setMaxToCount(resp.data.totalPages)
                    } else {
                        dispatch(
                            createLog({
                                type: "error",
                                title: "Planillas de Control",
                                text: resp.data.msg
                            })
                        )
                    }
                }
            )
            .finally(() => setLoading(false))
    }

    const getCourses = async () => {
        api.get(`courses/`).then(res => {
            setCourses(res.data.courses)
        })
    }

    useEffect(() => {
        getCourses()
    }, [])

    useEffect(() => {
        getUsers();
    }, [values.area, dispatch, counter])

    return (
        <main>
            <Container className="position-relative">
                <div className='sheet__container '>
                    <Row>
                        <Col xs={12}>
                            <BreadCrumbs lastSteps={[{ to: "/home", step: "Inicio" }]} title="Planilla de control" />
                        </Col>
                    </Row>

                    <Row className="mt-5 ">
                        <Col xs={12}>
                            <CustomOptions
                                options={options}
                                value={values.area}
                                setValue={option => setValues({
                                    area: option,
                                    lastName: ""
                                })}
                                resetCounter={reset}
                            />
                        </Col>
                    </Row>
                    {
                        (values.area.label === "Alumnos" || values.area.label === "Docentes") &&
                        <Form
                            className='w-100'
                            onSubmit={getUsers}
                        >
                            <Row className="mt-2 mb-4 d-flex align-items-end">
                                <Col xs={2}>
                                    <CustomInput
                                        labelContent="Apellido"
                                        placeholder="Apellido"
                                        name="lastName"
                                        handleInputChange={handleInputChange}
                                        value={values.lastName}
                                        classNameInput="pb-1"
                                    />
                                </Col>
                                <Col xs={2}>
                                    <CustomSelect options={levelOptions} placeholder="Nivel" name="level" handleInputChange={handleInputChange} values={values.level} disabled />
                                </Col>
                                {values.area.label === "Alumnos" &&
                                    <Col xs={2}>
                                        <CustomSelect options={curseOptions} placeholder="Curso" name="curse" handleInputChange={handleInputChange} values={values.curse} disabled />
                                    </Col>
                                }
                                <Col xs={2}>
                                    <div>
                                        <Button
                                            content="Aplicar"
                                            className="button-secondary button-m-sb"
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </Form>
                    }
                </div>
                {loading ? <Loading /> : <>
                    <Board firstRow={
                        <>
                            <Col xs={3} className="">
                                <div className='my-2'>
                                    <span className='body-m-b'> Apellido, Nombre</span>
                                </div>
                            </Col>
                            {
                                (values.area.type === 'students') &&
                                <>
                                    <Col xs={1} className=" mx-2">
                                        <div className='my-2'>
                                            <span className='body-m-b'> Nivel</span>
                                        </div>
                                    </Col>
                                    <Col xs={1} className=" mx-2">
                                        <div className='my-2'>
                                            <span className='body-m-b'> Curso</span>
                                        </div>
                                    </Col>
                                    <Col xs={3} className="mx-2 text-align-center">
                                        <div className='my-2'>
                                            <span className='body-m-b'> E-mail</span>
                                        </div>
                                    </Col>
                                </>

                            }
                            {
                                (values.area.type === 'teachers') &&
                                <>
                                    <Col xs={1} className=" mx-2">
                                        <div className='my-2'>
                                            <span className='body-m-b'> Nivel</span>
                                        </div>
                                    </Col>
                                    <Col xs={2} className=" mx-2">
                                        <div className='my-2'>
                                            <span className='body-m-b'> Turno</span>
                                        </div>
                                    </Col>
                                    <Col xs={3} className="mx-2">
                                        <div className='my-2'>
                                            <span className='body-m-b'> E-mail</span>
                                        </div>
                                    </Col>
                                </>
                            }
                            {
                                (values.area.type === 'not-teacher') &&
                                <>
                                    <Col xs={2} className=" mx-2">
                                        <div className='my-2'>
                                            <span className='body-m-b'> Rol</span>
                                        </div>
                                    </Col>
                                    <Col xs={2} className=" mx-2">
                                        <div className='my-2'>
                                            <span className='body-m-b'> Nivel</span>
                                        </div>
                                    </Col>
                                    <Col xs={3} className="mx-2">
                                        <div className='my-2'>
                                            <span className='body-m-b'> E-mail</span>
                                        </div>
                                    </Col>
                                </>
                            }
                            {
                                (values.area.type === 'directorate') &&
                                <>
                                    <Col xs={2} className=" mx-2">
                                        <div className='my-2'>
                                            <span className='body-m-b'> Rol</span>
                                        </div>
                                    </Col>
                                    <Col xs={1} className=" mx-2">
                                        <div className='my-2'>
                                            <span className='body-m-b'> Nivel</span>
                                        </div>
                                    </Col>
                                    <Col xs={1} className=" mx-2">
                                        <div className='my-2'>
                                            <span className='body-m-b'> Turno</span>
                                        </div>
                                    </Col>
                                    <Col xs={3} className="mx-2">
                                        <div className='my-2'>
                                            <span className='body-m-b'> E-mail</span>
                                        </div>
                                    </Col>
                                </>
                            }

                        </>
                    }
                        rows={
                            users.map(
                                usr =>
                                    <Link key={usr._id} to={{
                                        pathname: `/userdetail/${usr._id}`,
                                        state: { type: `${usr.userRole}` },
                                    }} >
                                        <Row className='board__row py-1'>
                                            <Col xs={3} className="">
                                                <div className='my-2'>
                                                    <span className='body-m'> {`${usr.lastName}, ${usr.name}`}</span>
                                                </div>
                                            </Col>
                                            {
                                                (values.area.type === 'students') &&
                                                <>
                                                    <Col xs={1} className=" mx-2">
                                                        <div className='my-2'>
                                                            <span className='body-m'> {level[courses?.find(aux => aux?.students?.some(s => s === usr?._id))?.level] || "N/A"}</span>
                                                        </div>
                                                    </Col>
                                                    <Col xs={2} className=" mx-2">
                                                        <div className='my-2'>
                                                            <span className='body-m'> {courses?.find(aux => aux?.students?.some(s => s === usr?._id))?.age || "N/A"}</span>
                                                        </div>
                                                    </Col>
                                                </>
                                            }
                                            {
                                                (values.area.type === 'teachers') &&
                                                <>
                                                    <Col xs={1} className=" mx-2">
                                                        <div className='my-2'>
                                                            <span className='body-m'> {level[courses?.find(aux => aux?.tutorship === usr?._id)?.level ] || "N/A"}</span>
                                                        </div>
                                                    </Col>
                                                    <Col xs={2} className=" mx-2">
                                                        <div className='my-2'>
                                                            <span className='body-m'> {courses?.find(aux => aux?.tutorship === usr?._id)?.shift || "N/A"}</span>
                                                        </div>
                                                    </Col>
                                                </>
                                            }
                                            {
                                                (values.area.type === 'not-teacher') &&
                                                <>
                                                    <Col xs={2} className=" mx-2">
                                                        <div className='my-2'>
                                                            <span className='body-m'> {role[usr.userRole] || "N/A"}</span>
                                                        </div>
                                                    </Col>
                                                    <Col xs={1} className=" mx-2">
                                                        <div className='my-2'>
                                                            <span className='body-m'> {level[usr?.level] || "-"}</span>
                                                        </div>
                                                    </Col>
                                                </>
                                            }
                                            {
                                                (values.area.type === 'directorate') &&
                                                <>
                                                    <Col xs={2} className=" mx-2">
                                                        <div className='my-2'>
                                                            <span className='body-m'> {directorRole[usr?.directorType] || "-"}</span>
                                                        </div>
                                                    </Col>
                                                    <Col xs={1} className=" mx-2">
                                                        <div className='my-2'>
                                                            <span className='body-m'> {level[usr?.level] || "-"}</span>
                                                        </div>
                                                    </Col>
                                                    <Col xs={1} className=" mx-2">
                                                        <div className='my-2'>
                                                            <span className='body-m'> {usr?.shift || "-"}</span>
                                                        </div>
                                                    </Col>
                                                </>
                                            }



                                            <Col xs={3} className="mx-2">
                                                <div className='my-2'>
                                                    <span className='body-m'>{usr.email}</span>
                                                </div>
                                            </Col>
                                            <Col xs={{ span: 1 }} className="mr-2 ml-auto">
                                                <div className='my-2 d-flex justify-content-end'>
                                                    <Button
                                                        onClick={e => {
                                                            e.preventDefault();
                                                            push({
                                                                pathname: `/uploadUser`,
                                                                state: { id: usr._id }
                                                            });
                                                            e.stopPropagation();
                                                        }}
                                                        icon="pencilMUI"
                                                        className="button__only-icon-withoutcolor"
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                    </Link>
                            )
                        }
                        className="board-row-color-3"
                    />

                    {/* <CustomFabicon text={`Nuevo ${value.toLowerCase().slice(0, -1)}`} to={`/newUser/${options.find(({ option }) =>
                        option === value
                    ).to}`} /> */}
                </>
                }
                <Row className='my-5 pt-2'>
                    <Col xs={11} className="d-flex justify-content-center">
                        <PaginationControll
                            counter={counter}
                            increment={counter < maxToCount ? increment : () => null}
                            decrement={counter > 1 ? decrement : () => null}
                            totalPages={maxToCount}
                        />
                    </Col>
                </Row>
                <CustomFabicon text={`Nuevo usuario`} to={`/uploadUser`} />
            </Container >
        </main >
    )
}
