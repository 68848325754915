import { useEffect, useState } from "react"
import { createLog } from '../../../actions/logs'
import { useDispatch } from 'react-redux'

import { Button } from "../../Button"
import { Line } from "../../Line"
import { SubjectEvaluativeCriteriaItem } from "./SubjectEvaluativeCriteriaItem"
import { useParams } from "react-router-dom/cjs/react-router-dom.min"
import { api } from "../../../helpers/fetch"

const criteriaEntries = [
    {name: "firstCriteria", title: 'Participación en clases y/o reuniones virtuales.'},
    {name: "secondCriteria", title: 'Entrega de trabajos solicitados.'},
    {name: "thirdCriteria", title: 'Interacción con los compañeros y docentes en linea.'},
    {name: "fourthCriteria", title: 'Comprensión de los contenidos específicos de la asignatura.'}
]

export const CourseEvaluativeCriteria = ({subject, courseCriteria, role}) => {
    
    const {id} = useParams()
    const [isEditCriteria, setIsEditCriteria] = useState(false)
    
    const [criteria, setCriteria] = useState([])

    const dispatch = useDispatch();

    const editModeHandle = () => {
        setIsEditCriteria(true)
    }

    const cancelEditModeHandle = () => {
        setIsEditCriteria(false)
    }

    const onChangeHandle = (e, property) => {
        const changedCriteria = { ...criteria }
        changedCriteria[property] = e.target.value
        setCriteria(changedCriteria)
    }

    const submitHandle = (e) => {
        e.preventDefault()
        const data = {subjectAssessmentCriteria: criteria}
        api.put(`subjects/edit/${id}`, data).then(res=> {
            if(res.data.ok === true) {
                setIsEditCriteria(false)
                dispatch(createLog({
                    type: "success",
                    title: "¡Guardado exitoso!",
                    text: "Los cambios en el criterio se guadaron correctamente."
                }))
            }
        })
    }

    useEffect(() => {
        if(courseCriteria) {
            setCriteria(courseCriteria)
        }
    }, [courseCriteria])
    
    

    return (
        <form onSubmit={submitHandle} className="mx-7 mt-1 position-relative subjectCriteria__container">
            {(!isEditCriteria && role !== 'students') &&
                <Button
                    icon="penFA"
                    className="button-quaternary position-absolute p-0"
                    content={<span className="ml-2 pl-1 body-s-sb">Editar criterios</span>}
                    onClick={editModeHandle}
                />
            }

            {criteriaEntries?.map((entrie, i) =>
                <div key={entrie.title}>
                    <SubjectEvaluativeCriteriaItem
                        entrie={entrie.title}
                        editable={isEditCriteria}

                        value={Object?.values(criteria)[i]}
                        property={Object?.keys(criteria)[i]}

                        handleInputChange={e => onChangeHandle(e, entrie.name)}
                        role={role}
                    />
                    {i + 1 !== criteriaEntries.length &&
                        <Line mb={4} />
                    }
                </div>
            )}

            {isEditCriteria &&
                <div>
                    <Line mb={4} />
                    <div className="d-flex justify-content-end mb-3">
                        <Button
                            className="button-quaternary body-m-sb"
                            content="Cancelar"
                            onClick={cancelEditModeHandle}
                        />
                        <Button
                            className="button-primary body-m-sb ml-4"
                            content="Guardar"
                            type="submit"
                        />
                    </div>
                </div>
            }
        </form>
    )
}
