import { Col, Row } from "react-bootstrap"

export const Line = ({mb =  0 , mt = 0}) => {
    return (
        <Row>
            <Col className={`mb-${mb} mt-${mt}`}>
                <hr className='customoptions__hr w-100 m-0' />
            </Col>
        </Row>
    )
}

