import classNames from 'classnames'
import React, { useEffect, useRef } from 'react'
import { Button } from '../Button'
import { Header } from './Header'
export const Navbar = ({ show, setShow, setNavRef }) => {

    const navbar = useRef()
    useEffect(() => {
        setNavRef(navbar)
    }, [setNavRef]);
    return (
        <nav className="navbar__container" ref={navbar}>
            <div className={classNames('navbar__burger-button py-4 px-4', {
                "sidebar-width-open": show === true,
                "sidebar-width-close": show === false,
            })}  >
                <Button icon={show ? "closeMUI" : "menuMUI"} className="icons__24 button__only-icon-withoutcolor color-white-svg p-0 m-0 " onClick={() => setShow(!show)} />
            </div>
            <Header />
        </nav>
    )
}
