import { useEffect, useState } from "react"
import { Col, Row } from "react-bootstrap"
import { FaCheck } from "react-icons/fa"
import { useDispatch } from "react-redux"
import { createLog } from "../../../actions/logs"
import { api } from "../../../helpers/fetch"
import { useForm } from "../../../hooks/useForm"
import { Button } from "../../Button"
import { CustomSelect } from "../../CustomSelect"
import CustomText from "../../CustomText"
import { File } from "../../File"
import { Line } from "../../Line"
import { SubjectEvaluativeCriteriaItem } from "./SubjectEvaluativeCriteriaItem"
import { SubjectInfoText } from "./SubjectInfoText"

const option = [
    { label: '1 (Uno)', value: 1 },
    { label: '2 (Dos)', value: 2 },
    { label: '3 (Tres)', value: 3 },
    { label: '4 (Cuatro)', value: 4 },
    { label: '5 (Cinco)', value: 5 },
    { label: '6 (Seis)', value: 6 },
    { label: '7 (Siete)', value: 7 },
    { label: '8 (Ocho)', value: 8 },
    { label: '9 (Nueve)', value: 9 },
    { label: '10 (Diez)', value: 10 },
]

export const EvaluativeReportBody = ({ actStudent, infoActStudent, userRole }) => {

    const [values, handleInputChange, setValues] = useForm({
        ...actStudent,
        isQualify: true,
        calification: 0,
        observation: "",
        dateCalification: new Date().toLocaleDateString()
    })
    const [topic, setTopic] = useState([])
    const [unit, setUnit] = useState([])
    const [isEdit, setIsEdit] = useState(!actStudent.isQualify)
    const dispatch = useDispatch();

    const getTopic = async () => {
        const topicAct = await api.get(`subject-unit-topics/${infoActStudent.topic}`)
        const unitAct = await api.get(`subject-units/${topicAct.data.unit}`)
        setTopic(topicAct.data)
        setUnit(unitAct.data)
    }

    const onSubmitEvent = async () => {
        try {
            const res = await api.put(`activity-deliveries/${actStudent._id}`, values)
            if (res.status === 200) {
                dispatch(createLog({
                    type: "success",
                    title: "Envio exitoso",
                    text: `Los datos se enviaron correctamente`
                }))
            }

        } catch (error) {
            dispatch(createLog({
                type: "error",
                title: "No se pudo enviar",
                text: `Intente mas tarde`
            }))
        }
    }

    const handleEditEventTrue = () => {
        setIsEdit(true)
    }
    const handleEditEventFalse = () => {
        setIsEdit(false)
    }

    useEffect(() => {
        getTopic()
    }, [])

    const meses = new Array("Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre");
    const diasSemana = new Array("Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado")
    const time = new Date(actStudent.date)

    return (
        <div className="coursenew_form px-7 py-3">
            <h3 className="m-0">Detalle de evaluación</h3>
            <SubjectInfoText className='mt-3' name='Pertenece' values={[
                `${actStudent.bimester}${actStudent.bimester !== (1 || 3) ? (actStudent.bimester === 2 ? 'do' : 'to') : 'er'}  Bimestre / 
                    ${unit.title} / 
                    Tema: ${topic.title}`
            ]} />
            <SubjectInfoText className='mt-3' name='Entregado a tiempo' values={[`${diasSemana[time.getDay()]} ${time.getDate()} de ${meses[time.getMonth()]} a las ${time.getHours()}:${time.getMinutes()} hs`]} />
            {
                actStudent.isQualify ?
                    <div className="d-flex align-items-center">
                        <p className="circular-border-qualify mr-2"><FaCheck className="icons__12" /></p>
                        <SubjectInfoText className='mt-3' name='Estado' values={['Calificado']} />
                    </div>
                    :
                    <SubjectInfoText className='mt-3' name='Estado' values={['Sin calificar']} />
            }

            <Line mt={4} mb={4} />

            <h4 className="m-0">Entrega</h4>
            <Row>
                <Col xs={4} >
                    {actStudent?.urlDoc?.map((file, i) =>

                        <div className="my-4" key={file._id}>
                            <File
                                fileId={i}
                                file={{ name: file.url }}
                                url={file.docName}
                            />
                        </div>

                    )}
                </Col>
            </Row>

            <h5 className="m-0">Comentarios</h5>
            <Row>
                <Col xs={6} >
                    <p>{actStudent.comment}</p>
                </Col>
            </Row>
            {
                (actStudent.isQualify && userRole === 'students') &&
                <>
                    <Line mt={4} mb={4} />
                    <h4 className="m-0">Calificación</h4>
                    <Row className="mt-3">
                        <Col xs={3}>
                            <CustomText value={actStudent?.calification} />
                        </Col>
                    </Row>

                    <div className="subjectCriteria__container mt-3">
                        <SubjectEvaluativeCriteriaItem
                            role={userRole}
                            value={actStudent?.observation}
                        />
                    </div>
                </>
            }
            {
                userRole === 'teachers' &&
                <>
                    <Line mt={4} mb={4} />
                    <h4 className="m-0">Calificación</h4>
                    <Row className="mt-3">
                        <Col xs={3}>
                            <CustomSelect
                                className="my-4"
                                options={option}
                                name="calification"
                                values={values?.calification}
                                handleInputChange={handleInputChange}
                                placeholder={actStudent?.isQualify ? (option.find(aux => aux?.value === actStudent?.calification).label) : 'Nota numérica'}
                                disabled={!isEdit}
                                alwaysShow
                            />
                        </Col>
                    </Row>

                    <div className="subjectCriteria__container mt-3">
                        <SubjectEvaluativeCriteriaItem
                            name='observation'
                            role={userRole}
                            handleInputChange={handleInputChange}
                            editable={isEdit}
                            value={actStudent?.observation}
                            label='Observaciones de la evaluación'
                        />
                    </div>
                </>

            }
            {
                userRole !== 'students' &&
                <>
                    {
                        isEdit === true ?
                            <Row className="mt-2 mb-3 justify-content-end">
                                <Col className="col-auto">
                                    <Button
                                        className="button-quaternary body-m-sb"
                                        content="Cancelar"
                                        onClick={handleEditEventFalse}
                                    />
                                </Col>
                                <Col className="col-auto">
                                    <Button
                                        className="button-primary body-m-sb ml-4"
                                        content="Guardar"
                                        onClick={() => { handleEditEventFalse(); onSubmitEvent() }}
                                    />
                                </Col>
                            </Row>
                            :
                            <Row className="mt-2 mb-3 justify-content-end">
                                <Button
                                    className='btn button-primary body-m-sb ml-4'
                                    content="Editar notas"
                                    iconRight={false}
                                    onClick={handleEditEventTrue}
                                />
                            </Row>
                    }
                </>
            }
        </div>
    )
}
