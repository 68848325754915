import { Col, Row } from "react-bootstrap"
import { useHistory, useParams } from "react-router-dom"
import { Board } from "../../Board"
import { EmptyStates } from "../../EmptyStates"
import emptySubject from '../../../Imgs/EmptyStates/Social 7 cursos.png'

const firstRow = ['Apellido y nombre', '1er Cuatrimestre', '2do Cuatrimestre', 'Otras instancias']
const numWord = ['Cero', 'Uno', 'Dos', 'Tres', 'Cuatro', 'Cinco', 'Seis', 'Siete', 'Ocho', 'Nueve', 'Diez']

export const SubjectStudents = ({ assessments, course, subject }) => {
    const { id: subjectId } = useParams()
    const { push } = useHistory()
    const { students } = subject.course

    const handleStudentClick = (studentId) => {
        push({
            pathname: `/subjects/student/detail/${subjectId}/${studentId}`,
            state: course
        })
    }

    return (
        <div className="subject__students mt-2">
            {
                students.length === 0 ?
                    <EmptyStates src={emptySubject} title='Asignaciones en proceso' text='Actualmente la asignación de alumnos está en proceso. Para más información puedes comunicarte con secretaría.' />
                    :
                    <Board
                        className="board-row-color-3"
                        firstRow={firstRow.map((col, i) =>
                            <Col xs={3} key={i}>
                                <div className='my-2'>
                                    <span className='body-m-b'>{col}</span>
                                </div>
                            </Col>
                        )}
                        rows={
                            students?.map((student) =>
                                <Row key={student._id} className='board__row py-1 cursor-pointer' onClick={() => handleStudentClick(student._id)}>
                                    <Col xs={3}>
                                        <div className='my-2'>
                                            <span className='body-m-regular'>{`${student.lastName}, ${student.name}`}</span>
                                        </div>
                                    </Col>
                                    <Col xs={3}>
                                        <div className='my-2'>
                                            <span className='body-m-regular'>
                                                {
                                                    assessments?.find(aux => aux.student._id === student._id)?.finalReviews?.fisrtTerm
                                                        ? `${assessments?.find(aux => aux.student._id === student._id)?.finalReviews?.fisrtTerm} (${numWord[assessments?.find(aux => aux.student._id === student._id)?.finalReviews?.fisrtTerm]})`
                                                        : 'Periodo próximo'
                                                }
                                            </span>
                                        </div>
                                    </Col>
                                    <Col xs={3}>
                                        <div className='my-2'>
                                            <span className='body-m-regular'>
                                                {
                                                    assessments?.find(aux => aux.student._id === student._id)?.finalReviews?.secondTerm
                                                        ? `${assessments?.find(aux => aux.student._id === student._id)?.finalReviews?.secondTerm} (${numWord[assessments?.find(aux => aux.student._id === student._id)?.finalReviews?.secondTerm]})`
                                                        : 'Periodo próximo'
                                                }
                                            </span>
                                        </div>
                                    </Col>
                                    <Col xs={3}>
                                        <div className='my-2'>
                                            {
                                                assessments?.find(aux => aux.student._id === student._id)?.finalReviews?.otherReviews?.length
                                                    ? assessments?.find(aux => aux.student._id === student._id)?.finalReviews?.otherReviews.map(rev =>
                                                        `${rev.instanceReview} (${numWord[rev.instanceReview]})`
                                                    ).join(' / ')
                                                    : 'Periodo próximo'
                                            }
                                        </div>
                                    </Col>
                                </Row>
                            )
                        }
                    />
            }
        </div>
    )
}
