import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearLog } from "../actions/logs";
import { Button } from "./Button";
import { BsExclamationCircleFill, BsInfoCircleFill, BsXCircleFill, BsCheckCircleFill  } from "react-icons/bs"

export const Alert = () => {
    const { logs } = useSelector((state) => state);

    const dispatch = useDispatch();
    let selectedIcon, classSelected;

    switch (logs?.type) {
        case "success":
            selectedIcon = <BsCheckCircleFill className="color-success " />;
            classSelected = `alert-color-success`;
            break;
        case "error":
            selectedIcon = <BsXCircleFill className="color-error" />;
            classSelected = `alert-color-error`;
            break;
        case "info":
            selectedIcon = <BsInfoCircleFill className="color-primary" />;
            classSelected = `alert-color-info`;
            break;
        case "warning":
            selectedIcon = <BsExclamationCircleFill className="color-warning" />;
            classSelected = `alert-color-warning`;
            break;
        default:
            selectedIcon = <></>;
            classSelected = "";
            break;
    }
    return (
        logs && (
            <div className={`d-flex alert p-3 ${classSelected}`}>
                <div className="icons__18"> {selectedIcon}</div>
                <div className="mx-3 w-100">
                    <h5 className="body-l-sb">{logs?.title}</h5>
                    <p className="body-l">{logs?.text}</p>
                    {
                        !!logs?.callback && 
                        <div className="d-flex justify-content-end">
                            <Button
                                className='py-3 mr-3 button__only-icon-withoutcolor color-primary button-s-sb'
                                content="Cancelar"
                                onClick={() => dispatch(clearLog())} />
                            <Button
                                className='py-3 button__only-icon-withoutcolor color-primary button-s-sb'
                                content="Aceptar"
                                onClick={() =>{
                                        logs?.callback()
                                        dispatch(clearLog())
                                    }} />
                        </div>
                    }
                </div>
                <Button
                    onClick={() => dispatch(clearLog())}
                    className="button__only-icon-withoutcolor icons__36"
                    icon="cross"
                />

            </div>
        )
    );
};

