import React, { useEffect, useRef, useState } from 'react'
import { Container, Row, Col, Button } from 'react-bootstrap'
import { useLocation, useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { BreadCrumbs } from '../../BreadCrumbs'
import { CustomOptions } from '../../CustomOptions'
import { Loading } from '../../Loading'
import { UserAssignments } from './UserAssignments'
import { UserInformation } from './UserInformation'
import moment from 'moment'
import { api } from '../../../helpers/fetch'
import { useDispatch } from 'react-redux'
import { createLog } from '../../../actions/logs'
import { UserMedicalHistory } from '../users/UserMedicalHistory'
import { getFlattenedObject, getUnflattenedObject } from '../../../helpers/flatten'
import { useForm } from '../../../hooks/useForm'
import { Toast } from 'primereact/toast'
import { Line } from '../../Line'

const options = [{
  label: "Datos personales",
  type: "personalData",
}, {
  label: "Asignaciones",
  type: "assignments"
}]

const studentOptions = [{
  label: "Datos personales",
  type: "personalData",
}, {
  label: "Ficha médica",
  type: "medicalHistory"
}]

export const UserDetail = () => {
  const { state } = useLocation()
  const { id } = useParams()
  const toast = useRef(null)
  const [disable, setDisable] = useState(false)

  const [loading, setLoading] = useState(true)
  const [option, setOption] = useState(state.type === 'students' ? studentOptions[0] : options[0])

  const [lastUpdate, setLastUpdate] = useState(new Date())
  const [user, onInputChange, setForm] = useForm({
    dateBirth:"",
    documentType:"",
    documentId:"",
    nacionality:"",
    province:"",
    locality:"",
    zipCode:"",
    address:{
      street: "",
      floor: "",
      apartment: "",
    },
  })
  const dispatch = useDispatch()
  const getUser = async (uid) => {
    api.get(`auth/getUser/${uid}`)
      .then(
        resp => {
          resp.data.ok ?
            setForm(getFlattenedObject({
                ...resp.data.user, 
                physicalAbilityCertifications: resp.data.user.physicalAbilityCertifications.filter(Boolean) 
              }))
            :
            dispatch(
              createLog({
                type: "error",
                title: "Planillas de Control",
                text: resp.data.msg
              })
            )
        }
      ).catch(err => console.log(err))
      .finally(() => setLoading(false))
  }

  const showSuccess = (summary = 'Actualizado', detail) => {
    toast.current.show({ severity: 'success', summary, detail, life: 3000 })
  }

  const showError = (summary, detail) => {
    toast.current.show({ severity: 'error', summary, detail, life: 3000 })
  }

  const handleDisableTrue = () => { setDisable(true) }
  const handleDisableFalse = () => { setDisable(false) }

  const getSubmittableForm = () => {
    const _form = getUnflattenedObject(user)
    if (!_form.physicalAbilityCertifications) {
      _form.physicalAbilityCertifications = []
    }
    return _form
  }

  const saveForm = async () => {
    try {
      await api.put(`/auth/modify-profile/${user._id}`, getSubmittableForm())
      showSuccess('Actualizado', 'El usuario se ha actualizado correctamente')
    } catch (error) {
      showError('Error al actualizar el usuario', error.message)
      throw error
    }
  }

  useEffect(() => {
    getUser(id)
  }, [id])

  let title
  switch (state.type) {
    case "teachers":
      title = "Información del docente"
      break
    case "tutorship":
      title = "Información del preceptor"
      break
    case "directorate":
      title = "Información del director"
      break
    case "students":
      title = "Información del Alumno"
      break
    default:
      title = "Ficha personal"
      break
  }

  const content = (() => {
    let body = null

    switch (option.type) {
      case "assignments":
        body = <UserAssignments user={user} setLastUpdate={setLastUpdate} />
        break

      case "medicalHistory":
        body = <UserMedicalHistory form={user} onInputChange={onInputChange} setForm={setForm} setLastUpdate={setLastUpdate} disable={disable} handleDisable={handleDisableTrue}/>
        break

      default: // "personalData"
        body = <UserInformation form={user} onInputChange={onInputChange} setForm={setForm}
          setLastUpdate={setLastUpdate} disable={disable} handleDisable={handleDisableTrue} />
        break
    }

    return <>
      {body}
      {
        (user.role !== "administration" && disable === true) &&
        <>
          <Row className='mt-3  align-items-center'>
            <Col xs={{ span: 10, offset: 1 }}>
              <Line mb={5} />
              <div className=' d-flex justify-content-end w-100 mb-5'>
                <Button
                  type="button"
                  className='align-items-center color-primary button-m-sb mr-3 button-secondary'
                  onClick={handleDisableFalse}
                >
                  Cancelar
                </Button>
                <Button
                  onClick={() => { saveForm(); handleDisableFalse() }}
                  type="button"
                  className='align-items-center button-primary button-m-sb'
                >
                  Guardar
                </Button>
              </div>
            </Col>
          </Row>
        </>
      }
    </>
  })()

  return (
    <main>
      <Toast ref={toast} />
      <div className='userdetail__container mb-6'>
        <Container className="">
          <Row>
            <Col xs={12}>
              <BreadCrumbs lastSteps={[{ to: "/home", step: "Inicio" }, { to: "/controlsheets", step: "Planillas de control" }]} title={title} />
            </Col>
          </Row>
          {
            (state.type === "teachers" || state.type === "tutorship" || state.type === 'directorate') ?
              <Row className="mt-3 ">
                <Col xs={12}>
                  <CustomOptions options={options} value={option} setValue={setOption} />
                </Col>
              </Row> :
              state.type === "students" ?
                <Row className="mt-3 ">
                  <Col xs={12}>
                    <CustomOptions options={studentOptions} value={option} setValue={setOption} />
                  </Col>
                </Row> :
                <hr />
          }
          <Row className="my-3 ">
            <Col xs={12} className="d-flex justify-content-end ">
              <p className='color-disabled body-s'>Última actualización:{moment(lastUpdate).format(" DD[/]MM[/]YYYY")}</p>
            </Col>
          </Row>
        </Container >
        <Container className="userdetail__box">
          {
            loading
              ? <Loading />
              : content
          }
        </Container >
      </div>
    </main >
  )
}
