import React, { useState } from 'react'
import { Dropdown } from "primereact/dropdown";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { useEffect } from 'react';

const reviewsOption = [
    { label: "1 (uno)", value: 1 },
    { label: "2 (dos)", value: 2 },
    { label: "3 (tres)", value: 3 },
    { label: "4 (cuatro)", value: 4 },
    { label: "5 (cinco)", value: 5 },
    { label: "6 (seis)", value: 6 },
    { label: "7 (siete)", value: 7 },
    { label: "8 (ocho)", value: 8 },
    { label: "9 (nueve)", value: 9 },
    { label: "10 (diez)", value: 10 },
]
export const CustomSelect = ({ options = [""], name = "", values = "", handleInputChange = {}, placeholder = "", required = false, disabled = false, labelContent = "", className = "", alwaysShow = false, role }) => {
    const [showLabel, setShowLabel] = useState(false);
    const [number, setNumber] = useState('-')

    const numberInfo = () => {
        reviewsOption.map(option => {
            if (values === option.value)
                setNumber(option.label)
        })
    }
    useEffect(() => {
        numberInfo()
    })

    return (

        role === 'secretariat' ?
            <span className={className === '' ? "body-m" : className}> {number} </span>
            :
            <div className={'position-relative ' + className}>
                {((labelContent !== "" && showLabel) || alwaysShow) && <label className='position-absolute customselect-label color-label'>{labelContent}</label>}
                <Dropdown
                    disabled={disabled}
                    name={name}
                    required={required}
                    value={values}
                    options={options}
                    onChange={handleInputChange}
                    placeholder={placeholder}
                    onFocus={(e) => {
                        setShowLabel(true)
                    }}
                    onBlur={(e) => {
                        setShowLabel(false)
                    }}
                />
            </div>


    )
}
