import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { CustomInput } from "../../CustomInput"
import { ReceiptItemGroup } from './ReceiptItemGroup'
import moment from 'moment';



export const ReceiptInfo = ({ index, receipt, setReceipts, receiptsList, disableImputs }) => {


    const handleChangeReceipts = ({ target }) => {
        setReceipts(
            receiptsList.map(
                (c, receiptIndex) =>
                    receiptIndex === index ?
                        {
                            ...c,
                            [target.name]: target.value
                        }
                        : c
            )
        )
    }

    return (
    <>
        <Row>
            <Col xs={{ offset: 1, span: 10 }}>
                <h5> Comprobante {index + 1}</h5>
            </Col>
        </Row>
        <Row className='my-3'>
            <Col xs={{ offset: 1, span: 2 }}>
                <input type="date" name="date" onChange={handleChangeReceipts} value={ moment(receipt.date).format("YYYY-MM-DD") } className="newbudget-form-select" disabled={disableImputs} />
            </Col>
            <Col xs={2}>
                <CustomInput labelContent="Proveedor" name="provider" handleInputChange={handleChangeReceipts} value={receipt.provider} classNameInput="body-l" classNameLabel='input-label' disabled={disableImputs} />
            </Col>
        </Row>
        <ReceiptItemGroup
            index={index}
            items={receipt.items}
            receiptsList={receiptsList}
            setReceipts={setReceipts}
            disableImputs={disableImputs} />

    </>
    )
}
