import React from "react";
import {
  BsEye,
  BsPlus,
  BsChevronRight,
  BsChevronLeft,
  BsSearch,
  BsChevronDown,
  BsFillCircleFill,
  BsHeart,
  BsX,
  BsPencil,
  BsHouse,
  BsGrid,
  BsCalendar,
  BsPlayFill,
  BsStar,
  BsStarFill,
  BsChevronUp,
  BsAward,
  BsHeartFill,
  BsToggleOff,
  BsToggleOn,
  BsChevronDoubleRight,
  BsChevronDoubleLeft,
} from "react-icons/bs";
import { IoCameraOutline } from "react-icons/io5";
import {
  FaFacebook,
  FaWhatsapp,
  FaTwitter,
  FaInstagram,
  FaCheck,
} from "react-icons/fa";
import MenuIcon from '@mui/icons-material/Menu';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import InsertChartOutlinedIcon from '@mui/icons-material/InsertChartOutlined';
import BookOutlinedIcon from '@mui/icons-material/BookOutlined';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import { GiHamburgerMenu } from "react-icons/gi";
import CloseIcon from '@mui/icons-material/Close';
import RefreshIcon from '@mui/icons-material/Refresh';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import ReplyOutlinedIcon from '@mui/icons-material/ReplyOutlined';
import RedoOutlinedIcon from '@mui/icons-material/RedoOutlined';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import DescriptionIcon from '@mui/icons-material/Description';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { MoreVert } from '@mui/icons-material';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBookOpen, faPen } from "@fortawesome/free-solid-svg-icons";

export const Button = ({
  className,
  content,
  icon = "noIcon",
  onClick,
  iconRight = false,
  id = "",
  onMouseEnter,
  onMouseLeave,
  type,
  disabled = false,
  wrap = false,
}) => {
  let iconContent;
  switch (icon) {
    case "penFA":
      iconContent = <FontAwesomeIcon icon={faPen} />
      break;
    case "bookOpenFA":
      iconContent = <FontAwesomeIcon icon={faBookOpen} />
      break;
    case "userFA":
      iconContent = <i className="fa-solid fa-circle-user"></i>
      break;
    case "logoutFA":
      iconContent = <i className="fa-solid fa-arrow-right-from-bracket"></i>
      break;
    case "checkFA":
      iconContent = <i className="fa-solid fa-check"></i>
      break;
    case "rotateFA":
      iconContent = <i className="fa-solid fa-rotate"></i>
      break;
    case "penFA":
      iconContent = <i className="fa-solid fa-pen" ></i >;
      break;
    case "plusMUI":
      iconContent = <AddIcon className="icons__24" />;
      break;
    case "removeMUI":
      iconContent = <RemoveIcon className="icons__24" />;
      break;
    case "driveMUI":
      iconContent = <DescriptionIcon style={{ "color": "#149456" }} className="icons__24" />;
      break;
    case "pencilMUI":
      iconContent = <CreateOutlinedIcon className="icons__24" />;
      break;
    case "deleteMUI":
      iconContent = < DeleteOutlineOutlinedIcon className="icons__24" />;
      break;
    case "houseMUI":
      iconContent = <HomeOutlinedIcon className="icons__24" />;
      break;
    case "moreVert":
      iconContent = <MoreVert className="icons__24" />;
      break;
    case "arrowUpMUI":
      iconContent = <KeyboardArrowUpIcon className="icons__24 icon-selected" />;
      break;
    case "redoMUI":
      iconContent = <RedoOutlinedIcon className="icons__24 mb-1" />;
      break;
    case "replyMUI":
      iconContent = <ReplyOutlinedIcon className="icons__24 mb-1" />;
      break;
    case "refreshMUI":
      iconContent = <RefreshIcon className="icons__24" />;
      break;
    case "closeMUI":
      iconContent = <CloseIcon className="icons__24 ml-auto" />;
      break;
    case "menuMUI":
      iconContent = <MenuIcon className="icons__24 ml-auto" />;
      break;
    case "attachMUI":
      iconContent = <AttachFileIcon className="icons__24 ml-auto" />;
      break;
    case "assigmentMUI":
      iconContent = <AssignmentOutlinedIcon className="icons__24" />;
      break;
    case "chartMUI":
      iconContent = <InsertChartOutlinedIcon className="icons__24" />;
      break;
    case "bookMUI":
      iconContent = <BookOutlinedIcon className="icons__24" />;
      break;
    case "calendarMUI":
      iconContent = <CalendarTodayOutlinedIcon className="icons__24" />;
      break;
    case "emailMUI":
      iconContent = <EmailOutlinedIcon className="icons__24" />;
      break;
    case "house":
      iconContent = <BsHouse className="icons__24" />;
      break;
    case "check":
      iconContent = <FaCheck className="icons__12 mx-1 mr-2 " />;
      break;
    case "award":
      iconContent = <BsAward className="icons__24" />;
      break;
    case "starFill":
      iconContent = <BsStarFill className="icons__24" />;
      break;
    case "star":
      iconContent = <BsStar className="icons__24" />;
      break;
    case "grid":
      iconContent = <BsGrid className="icons__24" />;
      break;
    case "calendar":
      iconContent = <BsCalendar className="icons__24" />;
      break;
    case "playFill":
      iconContent = <BsPlayFill className="icons__24" />;
      break;
    case "cross":
      iconContent = <BsX className="icons__24" />;
      break;
    case "camera":
      iconContent = <IoCameraOutline className="icons__24" />;
      break;
    case "search":
      iconContent = <BsSearch className="icons__24" />;
      break;
    case "plus":
      iconContent = <BsPlus className="icons__24" />;
      break;
    case "arrowRight":
      iconContent = <BsChevronRight className="icons__24" />;
      break;
    case "arrowRightMUI":
      iconContent = < ArrowForwardIosIcon className="icons__24" />;
      break;
    case "DoubleArrowRight":
      iconContent = <BsChevronDoubleRight className="icons__24" />;
      break;
    case "DoubleArrowLeft":
      iconContent = <BsChevronDoubleLeft className="icons__24" />;
      break;
    case "arrowLeft":
      iconContent = <BsChevronLeft className="icons__24" />;
      break;
    case "arrowLeftMUI":
      iconContent = <ArrowBackIosNewIcon className="icons__24" />;
      break;
    case "eye":
      iconContent = <BsEye className="icons__24" />;
      break;
    case "hamburger":
      iconContent = <GiHamburgerMenu className="icons__24" />;
      break;
    case "arrowDown":
      iconContent = <BsChevronDown className=" icon-selected  icons__24" />;
      break;
    case "arrowUp":
      iconContent = <BsChevronUp className="icons__24" />;
      break;
    case "heart":
      iconContent = <BsHeart className="icons__24" />;
      break;
    case "heartFill":
      iconContent = <BsHeartFill className="icons__24" />;
      break;
    case "circle":
      iconContent = <BsFillCircleFill className="icons__24" />;
      break;
    case "toggleOn":
      iconContent = <BsToggleOn className="icons__24" />;
      break;
    case "toggleOff":
      iconContent = <BsToggleOff className="icons__24" />;
      break;
    case "pencil":
      iconContent = <BsPencil className="icons__24" />;
      break;
    case "filter":
      iconContent = (
        <div className="icons__24">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-sliders"
            viewBox="0 0 16 16"
          >
            <path
              fillRule="evenodd"
              d="M11.5 2a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM9.05 3a2.5 2.5 0 0 1 4.9 0H16v1h-2.05a2.5 2.5 0 0 1-4.9 0H0V3h9.05zM4.5 7a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM2.05 8a2.5 2.5 0 0 1 4.9 0H16v1H6.95a2.5 2.5 0 0 1-4.9 0H0V8h2.05zm9.45 4a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zm-2.45 1a2.5 2.5 0 0 1 4.9 0H16v1h-2.05a2.5 2.5 0 0 1-4.9 0H0v-1h9.05z"
            />
          </svg>
        </div>
      );
      break;
    case "facebook":
      iconContent = <FaFacebook className="icons__24" />;
      break;
    case "instagram":
      iconContent = <FaInstagram className="icons__24" />;
      break;
    case "whatsapp":
      iconContent = <FaWhatsapp className="icons__24" />;
      break;
    case "twitter":
      iconContent = <FaTwitter className="icons__24" />;
      break;
    case "google":
      iconContent = <i className="fa-brands fa-google"/>;
      break;
    default:
      iconContent = " ";
      break;
  }

  return (
    <button
      className={`${className || 'button-primary'} ${!wrap ? "text-nowrap" : ""}  center-box`}
      onClick={onClick}
      id={id}
      onMouseOver={onMouseEnter}
      onMouseLeave={onMouseLeave}
      type={type}
      disabled={disabled}
    >
      {iconRight ? (
        <>
          {content}
          {iconContent}
        </>
      ) : (
        <>
          {iconContent}
          {content}
        </>
      )}
    </button>
  );
};
