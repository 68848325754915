import { api } from "../helpers/fetch";
import { types } from "../types/types";
import { createLog } from "./logs";

export const getUsers = () => {
    return async (dispatch) => {
      const resp = await api.get("auth/getAllUsers");
      const body = resp.data;
      
      if (resp.status === 200 ) {
        dispatch(setUsers(body.users));
      } else {
        dispatch(
          createLog({
            type: "error",
            title: "Lo sentimos!",
            text: body.msg
          })
        )
      }
    };
};

const setUsers = (item) => ({
    type: types.getUsers,
    payload: item,
});