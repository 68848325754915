import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { createLog } from '../actions/logs'
import { api } from '../api'

export const usePutAndGetData = (subjectid, courseid, customOption) => {

    const [loading, setLoading] = useState(true)
    const [infoState, setInfoState] = useState({
        course: {},
        subject: {},
        assessments: [],
        otherInstance: []
    })
    const [editedAssessments, setEditedAssessments] = useState(infoState.assessments)
    const [selectedInstance, setSelectedInstance] = useState({})
    const dispatch = useDispatch();

    const getInfo = async (curso, materia) => {
        const courses = api.get(`courses/getById/${curso}`)
        const subjects = api.get(`subjects/getById/${materia}`)
        const assessments = api.get(`assessments/getAssessmentsBySubject/${materia}`)
        const otherInstances = api.get(`otherInstance/getInstanceFromSubject/${materia}`)
        const res = await Promise.all([courses, subjects, assessments, otherInstances])

        setInfoState({
            course: res[0].data.course,
            subject: res[1].data.subject,
            assessments: res[2].data.assessments,
            otherInstance: res[3].data.allInstance
        })
        setLoading(false)
    }

    const handleInputChange = (pos, value) => {
        const newAssessment = editedAssessments[pos]
        setEditedAssessments([
            ...editedAssessments.slice(0, pos),
            {
                ...newAssessment,
                finalReviews: {
                    ...newAssessment.finalReviews,
                    ...((customOption.type === "firstTerm") ? { isAprovedfirst: value } : { isAprovedsecond: value })
                }
            },
            ...editedAssessments.slice(pos + 1)
        ])
    }

    const handleAproveAll = () => {
        const editedAssessmentsArray = []
        editedAssessments.forEach((ass) => {
            editedAssessmentsArray.push({
                ...ass,
                finalReviews: {
                    ...ass.finalReviews,
                    ...((customOption.type === "firstTerm") ? { isAprovedfirst: true } : { isAprovedsecond: true })
                }
            })

        })
        setEditedAssessments(editedAssessmentsArray)
    }

    const onSubmitEvent = () => {
        try {
            setLoading(true)
            editedAssessments.forEach(async (assessment) => {
                await api.post(`assessments/${assessment?.student?._id}/${assessment?.subject?._id}`, assessment)
            });
            setLoading(false)
            dispatch(
                createLog({
                    type: 'success',
                    title: '¡Cierre de periodo exitoso!',
                    text: 'El cuatrimestre se cerró con éxito. '
                })
            )
        } catch (error) {
            dispatch(
                createLog({
                    type: "error",
                    title: "Error",
                    text: "Algo fue mal"
                })
            )
        }
    }

    const otherInstanceOption = infoState.otherInstance.map((instance, i) => {
        return { label: instance.name, value: instance._id, index: i }
    })

    const handleSelectedInstanceChange = ({target}) => {
        setSelectedInstance(target.value);
    }

    useEffect(() => {
        getInfo(subjectid, courseid)
    }, [subjectid, courseid])

    useEffect(() => {
        setEditedAssessments(infoState.assessments)
    }, [infoState.assessments])


    return {
        ...infoState,
        setInfoState,
        setLoading,
        loading,
        handleInputChange,
        handleAproveAll,
        onSubmitEvent,
        editedAssessments,
        selectedInstance,
        handleSelectedInstanceChange,
        otherInstanceOption,
    }
}