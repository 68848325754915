import React from 'react'
import { CustomInput } from '../../CustomInput';
import { Col, Row } from 'react-bootstrap'
import classNames from 'classnames';


export const ReceiptItem = ({ index, item, setItemsList, itemsList, disableImputs }) => {

    const handleItemChange = ({ target }) => {
        setItemsList(
            itemsList.map((item, itemIndex) =>
                (itemIndex === index ? { ...item, [target.name]: target.value } : item)
            )
        );
    };


    return (
        <Row className={classNames({ "mt-6 mb-5": index === 0, "my-5": index !== 0 })}>
            <Col xs={{ offset: 3, span: 5 }}>
                <CustomInput classNameInput="w-100 body-l" classNameLabel='input-label' value={item.description} handleInputChange={handleItemChange} name="description" labelContent=' Descripción' disabled={disableImputs} />
            </Col>
            <Col xs={1}>
                <CustomInput classNameInput="w-100 body-l" classNameLabel='input-label' placeholder='Cant.' value={item.quantity} handleInputChange={handleItemChange} name="quantity" labelContent="Cantidad" disabled={disableImputs} />
            </Col>
            <Col xs={2}>
                <CustomInput classNameInput="w-100 body-l" classNameLabel='input-label' placeholder=' Monto unitario' value={item.unitaryPrice} handleInputChange={handleItemChange} name="unitaryPrice" labelContent="Monto unitario" disabled={disableImputs} />
            </Col>
        </Row>
    );
};
