import { api } from '.'
const REQUEST_LIMIT = 50

const makeUrl = (url, pageToken) => {
  const query = new URLSearchParams()
  query.append('limit', REQUEST_LIMIT)
  if (pageToken) {
    query.append('pageToken', pageToken)
  }
  return `${url}?${query.toString()}`
}

export const getInbox = async (pageToken) => {
  const response = await api.get(makeUrl('/messages/inbox', pageToken))
  return response.data
}
export const getSent = async (pageToken) => {
  const response = await api.get(makeUrl('/messages/sent', pageToken))
  return response.data
}
export const getMessage = async (id) => {
  const response = await api.get(`/messages/${id}`)
  return response.data
}
export const getMessageAttachment = async (id, attachmentId, mimeType, fileName) => {
  const response = await api.get(`/messages/${id}/attachments/${attachmentId}`, {
    responseType: 'blob',
    params: { mimeType, fileName }
  })
  return response.data
}
export const getThread = async (id) => {
  const response = await api.get(`/messages/threads/${id}`)
  return response.data
}

export const sendMessage = async (message = { to: '', cc: [], bcc: [], subject: '', message: '', threadId: '' }) => {
  const response = await api.post('/messages', message)
  return response.data
}

export const forwardMessage = async (messageId, to = [], message = '') => {
  const response = await api.post(`/messages/${messageId}/forward`, { to, message })
  return response.data
}

export const forwardThread = async (threadId, to = [], message = '') => {
  const response = await api.post(`/messages/threads/${threadId}/forward`, { to, message })
  return response.data
}

export const replyToMessage = async (threadId, to, message) => {
  return sendMessage({
    to,
    cc: [],
    bcc: [],
    subject: '',
    message,
    threadId
  })
}