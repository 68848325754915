import { useState } from "react"
import { Col, Row } from "react-bootstrap"
import { Button } from "../../Button"
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import moment from "moment";
import { File } from "../../File";
import { ActivityForm } from "./ActivityForm";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { api } from "../../../helpers/fetch";
import { useDispatch, useSelector } from "react-redux";
import { createLog } from "../../../actions/logs";
import SubjectStudentAside from "./SubjectStudentAside";

export const SubjectActivityAccBody = ({ activity, activities, setActivities, role, bimester }) => {

    const { id } = useParams();
    const dispatch = useDispatch();
    const [isEditable, setIsEditable] = useState(false)
    const auth = useSelector(state => state.auth)

    const handleEditEvent = () => {
        setIsEditable(true)
    }

    const handleDelEvent = () => {
        api.delete(`activities/${activity._id}/${id}`).then(res => {
            if (res.status === 200) {
                const newActivitiesArr = activities.filter(act => act._id !== activity._id)
                setActivities(newActivitiesArr)
            }
        })
            .catch(err => {
                dispatch(createLog({
                    type: "error",
                    title: "No se pudo borrar",
                    text: `La evaluación ya contienen notas y no pueden ser borradas`
                }))
            })
    }

    return (
        <>
            {!isEditable ?
                <>
                    <Row className="ml-5 mt-3">
                        <Col xs={4}>
                            <p className="ml-3 body-l-sb">Detalle de la actividad</p>
                        </Col>
                        <Col xs={8}>
                            <div className='ml-auto d-flex justify-content-end'>
                                {
                                    role !== 'students' ?
                                        <>
                                            <Button
                                                type="button"
                                                icon="pencilMUI"
                                                className='btn button-secondary color-primary button-m-sb mr-4'
                                                content={<span className="ml-1 mt-1">Editar</span>}
                                                onClick={handleEditEvent} />
                                            <Button
                                                type="button"
                                                icon="deleteMUI"
                                                className="button-terciary button-s-sb d-flex align-items-center m-0 p-0 mr-5"
                                                content={<span className="ml-1 mt-1">Borrar</span>}
                                                iconRight={false}
                                                onClick={handleDelEvent} />
                                        </>
                                        :
                                        <>
                                            <SubjectStudentAside auth={auth} activity={activity} bimester={bimester} id={id} />
                                        </>
                                }
                            </div>
                        </Col>
                    </Row>
                    <Row className="ml-5 mt-1">
                        <Col xs={7} className="d-flex my-2">
                            <CalendarTodayOutlinedIcon className="icons__8 color-primary pt-1 ml-3 mr-2" />
                            <p className="body-m">Fecha de entrega: </p>
                            <p className="body-m-sb ml-2">{moment(activity.expDateTime).format("DD/MM/yyyy")}</p>
                        </Col>
                    </Row>
                    <Row className="ml-5 mt-1">
                        <Col xs={7} className="d-flex my-2">
                            <AccessTimeIcon className="icons__8 color-primary pt-1 ml-3 mr-2" />
                            <p className="body-m">Hora de entrega: </p>
                            <p className="body-m-sb ml-2">{moment(activity.expDateTime).format("HH:mm")}</p>
                        </Col>
                    </Row>
                    <Row className="ml-5 mt-3">
                        <Col xs={7} className="d-flex my-2">
                            <p className="body-m ml-3">{activity.detail}</p>
                        </Col>
                    </Row>
                    <Row className="ml-5 mt-3">
                        <Col xs={4}>
                            <p className="ml-3 body-l-sb">Archivos</p>
                        </Col>
                    </Row>
                    <Row className="ml-3 mr-3 my-3">
                        {activity?.urlDoc?.map(url =>
                            <Col xs={4} key={url._id}>
                                <File
                                    file={{ name: url.docName }}
                                    url={url.url}
                                    dropDown={false} />
                            </Col>
                        )}
                    </Row>
                    <Row className="ml-3 mr-3 my-3">
                        {activity?.resources?.map(resource =>
                            <Col xs={4} key={resource._id} >
                                <File
                                    fileId={resource._id}
                                    file={{ name: resource.resourseName }}
                                    url={resource.resourseUrl}
                                    dropDown={false} />

                            </Col>
                        )}
                    </Row>
                </>
                :
                <ActivityForm
                    activities={activity}
                    allActivities={activities}
                    isActivityToEdit={true}
                    setIsEditable={setIsEditable}
                    setActivities={setActivities}
                />
            }
        </>
    )
}