import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Board } from '../../Board'
import { BreadCrumbs } from '../../BreadCrumbs'
import { Button } from '../../Button'
import { CustomFabicon } from '../../CustomFabicon'
import { PaginationControll } from '../../PaginationControll'
import { Link } from "react-router-dom"
import classNames from 'classnames'
import { Loading } from '../../Loading'
import { EmptyStates } from '../../EmptyStates'
import emptyBudget from "../../../Imgs/EmptyStates/Wallet 04 presupuesto.jpg"
import { api } from '../../../helpers/fetch'
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { createLog } from '../../../actions/logs'
import { useHistory } from 'react-router-dom'
import { useCounter } from '../../../hooks/useCounter'
import sheetsApi from '../../../api/sheets'

export const Budgets = () => {

  const [loading, setLoading] = useState(true);
  const [budgets, setBudgets] = useState([])
  const { counter, increment, decrement } = useCounter(1)
  const [maxToCount, setMaxToCount] = useState(1)


  const dispatch = useDispatch();
  const { push } = useHistory();

  useEffect(() => {
    getBudgets();
  }, [counter])


  const getBudgets = async () => {
    const page = counter;
    setLoading(true);
    api.post('allbudgets', { page })
      .then(res => {
        setLoading(false);
        setMaxToCount(res.data.totalPages);
        return setBudgets(res.data.budgets);
      })
  }

  const budgetAmount = (budget) => {
    const receiptsMap = budget.receipts
      .map(receipts => receipts.items
        .map(items => items.quantity * items.unitaryPrice));
    const budgetsSubTotals = receiptsMap.reduce((values, subTotal) => {
      return values.concat(subTotal)
    }, [0]);

    const budgetTotal = budgetsSubTotals.reduce((total, values) => {
      return total + values;
    }, 0)

    return budgetTotal;
  }

  const handleDelete = (id) => {
    dispatch(
      createLog({
        type: "warning",
        title: "¿Estas seguro que quieres borrar este evento?",
        callback: e => onDeleteEventConfirm(id)
      }
      )
    )
  };

  const onDeleteEventConfirm = (id) => {
    const inactiveBudget = { active: false }
    api.put(`budget/${id}`, inactiveBudget)
      .then(resp => {
        if (resp.status === 200) {
          getBudgets();
          dispatch(
            createLog({
              type: "info",
              title: "El presupuesto ha sido eliminado",
            }
            )
          )
        } else {
          dispatch(
            createLog({
              type: "info",
              title: "Lo sentimos, el presupuesto no pudo ser borrado",
            }
            )
          )
        }
      })
  };

  const handleEdit = (id) => {
    push(`/budgets/edit/${id}`);
  }

  const log = ({ type, title, text }) => {
    dispatch(
      createLog({
        type,
        title,
        text,
      })
    )
  }

  const exportSheet = async (exporter) => {
    const sheet = await exporter()
    const url = sheet.createdSheet?.spreadsheetUrl
    url && window.open(url, '_blank')
    return sheet.ok
  }

  const exportSingleSheet = async (id) => {
    try {
      log({
        type: "info",
        title: "Exportando presupuesto",
      })
      const expSheet = await exportSheet(() => sheetsApi.exportBudgetToSheet(id))
      if (!expSheet) {
        throw new Error()
      }
    } catch (error) {
      log({
        type: "error",
        title: "Error al exportar el presupuesto",
        text: "Por favor, intente nuevamente"
      })
    }
  }

  const exportAllSheets = async () => {
    try {
      log({
        type: "info",
        title: "Exportando todos los presupuestos",
      })
      const expSAllSheet = await exportSheet(() => sheetsApi.exportAllBudgetsToSheet())
      if (!expSAllSheet) {
        throw new Error()
      }
    } catch (error) {
      log({
        type: "error",
        title: "Error al exportar los presupuestos",
        text: "Por favor, intente nuevamente"
      })
    }
  }

  return (
    <main>
      <Container className="position-relative budget__container">
        <div className='budget__container '>
          <Row>
            <Col xs={12}>
              <BreadCrumbs lastSteps={[{ to: "/home", step: "Inicio" }]} title="Presupuestos" />
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <hr className='w-100' />
            </Col>
          </Row>
          <Row className='mb-3'>
            <Col xs={12} >
              <div>
                <Button 
                  onClick={exportAllSheets}
                  content={<span className='ml-2'>Exportar presupuestos</span>}
                  icon="driveMUI"
                  className="button-secondary button-m-sb ml-auto d-flex"
                  disabled={!budgets.length} />
              </div>
            </Col>
          </Row>
        </div>
        {loading ? <Loading /> :
          <>
            {
              budgets.length ?
                <Board
                  firstRow={
                    <>
                      <Col xs={3}>
                        <div className='my-2'>
                          <span className='body-m-b'> Presupuesto</span>
                        </div>
                      </Col>
                      <Col xs={1} className=" mx-1">
                        <div className='my-2'>
                          <span className='body-m-b'> Fecha</span>
                        </div>
                      </Col>
                      <Col xs={2} className=" mx-2">
                        <div className='my-2'>
                          <span className='body-m-b'> Monto estimado</span>
                        </div>
                      </Col>
                      <Col xs={2} className=" mx-2">
                        <div className='my-2'>
                          <span className='body-m-b'> Monto real</span>
                        </div>
                      </Col>
                      <Col xs={2} className="mx-2">
                        <div className='my-2'>
                          <span className='body-m-b'> Diferencia</span>
                        </div>
                      </Col>
                    </>
                  }
                  rows={budgets.map((budget, i) =>
                    <div className='position-relative' key={budget._id}>
                      <Link to={`/budgets/detail/${budget._id}`} className="w-100">
                        <Row className={classNames('py-1', { "board__row ": i !== budget.length, "board__last-row ": i === budget.length - 1 })}>
                          <Col xs={3} className="">
                            <div className='my-2'>
                              <span className='body-m'>{budget.title}</span>
                            </div>
                          </Col>
                          <Col xs={1} className=" mx-2">
                            <div className='my-2'>
                              <span className='body-m'>{moment(budget.date).format("DD/MM/YY")}</span>
                            </div>
                          </Col>
                          <Col xs={2} className=" mx-2">
                            <div className='my-2'>
                              <span className='body-m'> $ {budget.forecast} </span>
                            </div>
                          </Col>
                          <Col xs={2} className=" mx-2">
                            <div className='my-2'>
                              <span className='body-m'> $ {budgetAmount(budget)}</span>
                            </div>
                          </Col>
                          <Col xs={2} className="mx-2">
                            <div className='my-2'>
                              <span className={classNames('body-m', { "success": (budget.forecast - budgetAmount(budget)) > 0, "error": (budget.forecast - budgetAmount(budget)) < 0 })}> $ {budget.forecast - budgetAmount(budget)} </span>
                            </div>
                          </Col>
                        </Row>
                      </Link>
                      <Row className='position-absolute-top position-absolute-right'>
                        <Col xs={{ span: 1 }} className="mr-2 ml-auto">
                          <div className='my-2 d-flex justify-content-end'>
                            <Button icon="pencilMUI" className="button__only-icon-withoutcolor" onClick={e => handleEdit(budget._id)} />
                            <Button icon="driveMUI" className="button__only-icon-withoutcolor" onClick={e => exportSingleSheet(budget._id)} />
                            <Button icon="deleteMUI" className="button__only-icon-withoutcolor button_z-indez_top" onClick={e => handleDelete(budget._id)} />
                          </div>
                        </Col>
                      </Row>
                    </div>


                  )
                  }
                  className="board-row-color-3" />
                :
                <EmptyStates src={emptyBudget} title="No hay presupuestos aún" text="Inicia la carga de tu primer presupuesto, cuando lo hagas se visualizará aquí." />
            }
            <CustomFabicon text="Nuevo presupuesto" to="/budgets/new" />
            <Row className='my-5 pt-2'>
              <Col xs={11} className="d-flex justify-content-center">
                <PaginationControll
                  counter={counter}
                  increment={counter < maxToCount ? increment : () => null}
                  decrement={counter > 1 ? decrement : () => null}
                  totalPages={maxToCount} />
              </Col>
            </Row>
          </>
        }
      </Container >
    </main>
  )
}
