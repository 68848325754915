import React, { useState } from 'react'
import PdfExtensionIcon from '../Imgs/icons/pdf-extension.svg'
import DocExtensionIcon from '../Imgs/icons/docs-extension.svg'
import SlideExtensionIcon from '../Imgs/icons/slides-extension.svg'
import FolderIcon from '../Imgs/icons/folder.svg'
import SheetExtensionIcon from '../Imgs/icons/sheets-extension.svg'
import GenericExtensionIcon from '../Imgs/icons/generic-extension.svg'
import { getFileUrl } from '../helpers/get-file-url'
import { DropDown } from './DropDown'

export const File = ({ file, options, onError, onClick, noUrl = false, url, target, dropDown = true, fileId }) => {

  const [isClicking, setIsClicking] = useState(false)

  const trigger = async (option) => {
    try {
      await option.action(file, fileId)
    } catch (error) {
      (onError || console.error)('Error al realizar la acción', error.message)
    }
  }

  const onDropdownClick = (e) => {
    e.preventDefault()
    e.stopPropagation()
  }

  const fileIcon = (file) => {
    if (file.name.includes('.pdf')) {
      return <img src={PdfExtensionIcon} alt="pdf" />
    } else if (file.name.includes('.docx')) {
      return <img src={DocExtensionIcon} alt="pdf" />
    } else if (file.name.includes('.pptx')) {
      return <img src={SlideExtensionIcon} alt="pdf" />
    } else if (file.name.includes('.xlsx') || file.mimeType.includes('.spreadsheet')) {
      return <img src={SheetExtensionIcon} alt="pdf" />
    } else if (file.mimeType.includes('folder')) {
      return <img src={FolderIcon} alt="folder" />
    } else {
      return <img src={GenericExtensionIcon} alt="archivo" />
    }
  }

  const onMouseDown = (e) => {
    const isBodyClick = !e.target.closest('.dropdown-container')
    isBodyClick && setIsClicking(true)
  }
  const clearIsClicking = () => {
    setIsClicking(false)
  }

  const _url = onClick || noUrl ? undefined : url || getFileUrl(file)
  const _onClick = onClick || null

  return (
    <a className={`regulation-container drive-file d-flex ${isClicking ? 'active' : ''}`} href={_url} target={target || '_blank'} rel="noreferrer" onMouseDown={onMouseDown} onMouseUp={clearIsClicking} onMouseLeave={clearIsClicking}>
      <div className='d-flex' onClick={_onClick}>
        <div className='icon-container mr-2'>
          {fileIcon(file)}
        </div>
        <div className='regulation-name body-m-b'>
          {file.name}
        </div>
      </div>
      {dropDown &&
        <div className='dropdown-container position-relative z-4'>
          <DropDown options={options} onClick={onDropdownClick} onChange={trigger} />
        </div>
      }
    </a>
  )
}
