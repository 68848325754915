export const nationalities = () => [
  { label: 'Argentina', value: 'argentina' },
  { label: 'Uruguay', value: 'uruguay' },
  { label: 'Brasil', value: 'brasil' },
  { label: 'Paraguay', value: 'paraguay' },
  { label: 'Bolivia', value: 'bolivia' },
  { label: 'Chile', value: 'chile' },
  { label: 'Peru', value: 'peru' },
  { label: 'Ecuador', value: 'ecuador' },
  { label: 'Colombia', value: 'colombia' },
  { label: 'Venezuela', value: 'venezuela' },
  { label: 'Otro', value: 'other' },
]
