import React, { useEffect, useState } from 'react'
import { Container, Row, Col } from "react-bootstrap"
import { UpcomingEventCard } from './UpcomingEventCard'
import { ReminderEventCard } from './ReminderEventCard'
import { CardCartelera } from '../../CardCartelera'
import { api } from '../../../helpers/fetch'
import { sortByDate } from '../../../helpers/sortByDate'
import moment from 'moment'
import 'moment/locale/es'
import { EmptyReminder } from './EmptyReminder'
import { EmptyEvent } from './EmptyEvent'
import { useDispatch } from 'react-redux'
import { getUsers } from '../../../actions/users'
import { useAuth } from '../../../hooks/useAuth'

import img from '../../../Imgs/home.jpg'

moment.locale('es');

export const Home = () => {
    const { user } = useAuth();
    const { userRole } = user;
    const [cardOptions, setCardOptions] = useState([]);
    const [itinerary, setItinerary] = useState([]);
    const dispatch = useDispatch();

    const reminderList = itinerary.reminder;
    const reminderLength = reminderList?.length;

    const getEvents = async () => {
        api.get("events")
            .then(
                resp => {
                    setItinerary({ event: sortByDate(resp.data, "Ascendente").find(e => e.type === "EVENT" && moment(e.date).diff(new Date(), 'hours') > 0) || "", reminder: sortByDate(resp.data, "Ascendente").filter(e => e.type === "REMINDER" && moment(e.fromHour).diff(new Date(), 'days') === 0) || "" })
                }
            )
    }

    const switchFunction = (role) => {
        switch (role) {
            case "administration":
                setCardOptions([
                    { name: "Planilla de control", to: "/controlsheets" },
                    { name: "Presupuestos", to: "/budgets" },
                    { name: "Reglamentaciones", to: "/regulations" },
                    { name: "Calendario", to: "/calendar" },
                    { name: "Mensajes", to: "/messages" }
                ])
                break;
            case "secretariat":
                setCardOptions([
                    { name: "Cursos", to: "/courses" },
                    { name: "Listado docente", to: "/teacherlist" },
                    { name: "Listado de alumnos", to: "/studentlist" },
                    { name: "Calendario", to: "/calendar" },
                    { name: "Mensajes", to: "/messages" }
                ])
                break;
            case 'teachers':
                setCardOptions([
                    { name: "Cursos", to: "/courses" },
                    { name: "Materias", to: "/subjects" },
                    { name: "Calendario", to: "/calendar" },
                    { name: "Mensajes", to: "/messages" }
                ])
                break;
            case 'directorate':
                setCardOptions([
                    { name: "Cursos", to: "/courses" },
                    { name: "Listado docente", to: "/teacherlist" },
                    { name: "Listado de alumnos", to: "/studentlist" },
                    { name: "Calendario", to: "/calendar" },
                    { name: "Mensajes", to: "/messages" }
                ])
                break;
            case 'students':
                setCardOptions([
                    { name: "Materias", to: "/studentsubjects" },
                    { name: "Boletines", to: `/reportcard/${user._id}` },
                    { name: "Calendario", to: "/calendar" },
                    { name: "Mensajes", to: "/messages" }
                ])
                break;
            case 'tutorship': 
                setCardOptions([
                    { name: "Cursos", to: "/courses" },
                    { name: "Calendario", to: "/calendar" },
                    { name: "Mensajes", to: "/messages" }
                ])
                break;
            default:
                setCardOptions([
                    { name: "Calendario", to: "/calendar" },
                    { name: "Mensajes", to: "/messages" }
                ])
                break;
        }
    }

    useEffect(() => {
        dispatch(getUsers());
        getEvents(); 
        switchFunction(userRole)     
    }, [userRole, dispatch]);

    return (
        <main >
            <Container className='home__container'>
                <Row >
                    <Col sm={12} md={10} lg={8} xl={9} className="mt-5">
                        <div className='w-100 home__img'>
                            <img src={img} alt="ILP instituto imagen ilustrativa" className='w-100' />
                        </div>
                    </Col>
                    <Col sm={12} md={4} lg={4} xl={3} className="mt-5">
                        {itinerary.event ? <UpcomingEventCard evt={itinerary.event} /> : <EmptyEvent />}
                    </Col>
                </Row>

                <Row className='my-3'>
                    <Col xs={9}>
                        <Row>
                            {cardOptions.map((e, i) =>
                                <Col sm={12} md={6} lg={5} xl={4} key={e + i} className="mb-3">
                                    <CardCartelera title={e.name} to={e.to} />
                                </Col>
                            )}
                        </Row>
                    </Col>
                    <Col sm={12} md={4} lg={4} xl={3}>
                        {reminderLength === 0 ? <EmptyReminder /> : <ReminderEventCard reminders={itinerary.reminder} />}
                    </Col>
                </Row>
            </Container >
        </main >
    )
}
