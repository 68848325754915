import classNames from "classnames"
import { useCallback, useEffect, useState } from "react"
import { Col, Container, Row } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { createLog } from "../../../actions/logs"
import { api } from "../../../api"
import { useForm } from "../../../hooks/useForm"
import { Board } from "../../Board"
import { BreadCrumbs } from "../../BreadCrumbs"
import { Button } from "../../Button"
import { CustomInput } from "../../CustomInput"
import { CustomOptions } from "../../CustomOptions"
import { CustomSelect } from "../../CustomSelect"
import CustomText from "../../CustomText"
import { Loading } from "../../Loading"


const options = [
    { label: "1er Cuatrimestre", type: "firstTerm" },
    { label: "2do Cuatrimestre", type: "secondTerm" },
    { label: "Otras instancias", type: "otherInstances" }
]

const reviewsOption = [
    { label: "1 (uno)", value: 1 },
    { label: "2 (dos)", value: 2 },
    { label: "3 (tres)", value: 3 },
    { label: "4 (cuatro)", value: 4 },
    { label: "5 (cinco)", value: 5 },
    { label: "6 (seis)", value: 6 },
    { label: "7 (siete)", value: 7 },
    { label: "8 (ocho)", value: 8 },
    { label: "9 (nueve)", value: 9 },
    { label: "10 (diez)", value: 10 },
]

export const CourseSubjectClosingPeriod = () => {

    const { courseid, subjectid } = useParams()
    const [assessments, setAssessments] = useState([])
    const [course, setCourse] = useState({})
    const [subject, setSubject] = useState({})
    const [loading, setLoading] = useState(true)
    const dispatch = useDispatch();
    const { user } = useSelector(state => state.auth)

    const [disable, setDisable] = useState(true)
    const [customOption, setcustomOption] = useState(options[0])

    const [values, handleInputChange, setValues] = useForm({ name: "" })

    const [otherInstance, setOtherInstance] = useState([]);

    const otherInstanceOption = otherInstance.map((instance, i) => {
        return { label: instance.name, value: instance._id, index: i }
    })

    const handleAddOtherInstance = async () => {
        await api.post(`otherInstance/${subject._id}`, values)
            .then(res => {
                if (res.data.ok === true) {
                    dispatch(createLog({
                        type: "success",
                        title: "¡Creación exitosa!",
                        text: `Se ha creado correctamente la nueva instancia.`,
                    }))
                } else {
                    dispatch(createLog({
                        type: "error",
                        title: "¡Hubo un error!",
                        text: `No se ha podido crear correctamente la nueva instancia, vuelva a intentarlo.`,
                    }))
                }
            })
        getOtherInstance(subjectid);
    }

    const [selectedInstance, setSelectedInstance] = useState({})

    const handleSelectedInstanceChange = ({ target }) => {
        setSelectedInstance(target.value);
    }

    const getOtherInstance = async (id) => {
        await api.get(`otherInstance/getInstanceFromSubject/${id}`)
            .then(res => {
                setOtherInstance(res.data.allInstance)
            })
    }

    const getCourseDetail = async (id) => {
        await api.get(`courses/getById/${id}`)
            .then(res => {
                setCourse(res.data.course);
            })
    }

    const getSubjectDetail = useCallback(
        async (id) => {
            await api.get(`subjects/getById/${id}`)
                .then(res => {
                    const studentsArr = res.data.subject.course.students.map(student => student._id)
                    setSubject(res.data.subject);
                    studentsArr.forEach(async (student) => {
                        await api.post(`assessments/${student}/${subjectid}`)
                    });
                    setLoading(false);
                })
        }, [subjectid]
    )

    const getAssessments = async (id) => {
        await api.get(`assessments/getAssessmentsBySubject/${id}`).then(res => {
            setAssessments(res.data.assessments);
        })
    }

    const handleAssessmentChange = (e, i) => {
        setAssessments(
            assessments.map((ass, assIndex) =>
            (assIndex === i ? {
                ...ass,
                finalReviews: {
                    ...ass.finalReviews,
                    [e.target.name]: e.value
                }
            }
                :
                ass)

            )
        )
    }

    const handleOtherReviewsChange = (e, i) => {
        setAssessments(
            assessments.map((ass, assIndex) =>
            (assIndex === i ? {
                ...ass,
                finalReviews: {
                    ...ass.finalReviews,
                    otherReviews: [
                        ...ass.finalReviews.otherReviews.filter(inst => inst.otherInstance !== selectedInstance),
                        {
                            otherInstance: selectedInstance,
                            [e.target.name]: e.value
                        }
                    ]
                }
            }
                :
                ass)

            )
        )
    }

    const onsubmitCancel = () => {
        setDisable(true)
    }

    const onSubmitEvent = async () => {
        setLoading(true);
        const countIterations = []
        assessments.forEach(async (assessment, index, arr) => {
            await api.post(`assessments/${assessment.student._id}/${assessment.subject._id}`, assessment)
            countIterations.push(index);
            if (countIterations.length === arr.length) {
                getAssessments(subjectid);
            }
        });
        dispatch(createLog({
            type: "success",
            title: "¡Guardado exitoso!",
            text: `Las notas se guardaron correctamente.`,
        }))
        setDisable(true);
        setLoading(false);
    }

    const onEditEvent = () => {
        setDisable(false)
    }

    useEffect(() => {
        getSubjectDetail(subjectid)
        if (courseid) { getCourseDetail(courseid) }
        getAssessments(subjectid)
        getOtherInstance(subjectid)
    }, [subjectid, courseid, getSubjectDetail])

    return (
        <main>
            {loading ? <Loading /> :
                <Container>
                    <Row className='mb-2 pb-2'>
                        <Col xs={12}>
                            {user.userRole === "secretariat" ?
                                <BreadCrumbs
                                    lastSteps={[
                                        { to: "/home", step: "Inicio" },
                                        { to: "/courses", step: "Cursos" },
                                        { to: `/courses/detail/${course._id}`, step: `${course.age ?? ''} ${course.division ?? ''} - ${course.shift ?? ''}` },
                                        { to: `/courses/detail/subjects/${course._id}/${subject._id}`, step: `${subject.name ?? ''}` },
                                    ]}
                                    title="Cierre de periodos" />
                                :
                                <BreadCrumbs
                                    lastSteps={[
                                        { to: "/home", step: "Inicio" },
                                        { to: "/subjects", step: "Materias" },
                                        { to: `/subjects/detail/${subject._id}`, step: `${subject.name}` },
                                    ]}
                                    title="Cierre de periodos" />

                            }
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={12}>
                            <CustomOptions
                                options={options}
                                value={customOption}
                                setValue={setcustomOption}
                            />
                        </Col>
                    </Row>
                    <Row className="mb-4 d-flex align-items-end">
                        {customOption.type === "otherInstances" &&
                            <>
                                <Col xs={3} className="mt-3">
                                    <CustomSelect
                                        options={otherInstanceOption}
                                        placeholder={otherInstance.length ? "Elija una instancia" : "No hay instancias creadas"}
                                        labelContent={<span className='color-disabled input-label'>Otras instancias</span>}
                                        alwaysShow
                                        handleInputChange={handleSelectedInstanceChange}
                                        values={selectedInstance}
                                        name="otherInstance" />
                                </Col>
                                {user.userRole === "teachers" &&
                                    <>
                                        <Col xs={2}>
                                            <CustomInput
                                                classNameInput="text-align-center"
                                                name="name"
                                                handleInputChange={handleInputChange}
                                                value={values.name}
                                                placeholder={'Nueva instancia'} />

                                        </Col>
                                        <Col xs={2}>
                                            <Button
                                                icon="plusMUI"
                                                content={<span className="ml-1 mt-1">Agregar instancia</span>}
                                                className=" button-terciary button-s-sb d-flex align-items-center m-0 p-0 "
                                                iconRight={false}
                                                type="button"
                                                onClick={handleAddOtherInstance}
                                            />
                                        </Col>
                                    </>
                                }
                            </>
                        }
                        {(user.userRole === "teachers" && disable) &&
                            <>
                                <Col xs={customOption.type !== "otherInstances" ? 12 : 5} className="d-flex">
                                    <div className='ml-auto d-flex'>
                                        <Button
                                            className='btn button-primary button-m-sb'
                                            content="Editar notas"
                                            onClick={onEditEvent} />
                                    </div>
                                </Col>
                            </>
                        }
                    </Row>
                    <Board
                        className="board-row-color-3 pt-3"
                        firstRow={
                            <>
                                <Col xs={3}>
                                    <div className='my-2'>
                                        <span className='body-m-b'>Apellido y nombre</span>
                                    </div>
                                </Col>
                                {customOption.type !== "otherInstances" ?
                                    <>
                                        {
                                            customOption.type === "firstTerm" &&
                                            <>
                                                <Col xs={2}>
                                                    <div className='my-2'>
                                                        <span className='body-m-b'>1er Bimestre</span>
                                                    </div>
                                                </Col>
                                                <Col xs={2}>
                                                    <div className='my-2'>
                                                        <span className='body-m-b'>2do Bimestre</span>
                                                    </div>
                                                </Col>
                                            </>
                                        }
                                        {
                                            customOption.type === "secondTerm" &&
                                            <>
                                                <Col xs={2}>
                                                    <div className='my-2'>
                                                        <span className='body-m-b'>3er Bimestre</span>
                                                    </div>
                                                </Col>
                                                <Col xs={2}>
                                                    <div className='my-2'>
                                                        <span className='body-m-b'>4to Bimestre</span>
                                                    </div>
                                                </Col>
                                            </>

                                        }
                                        <Col xs={2}>
                                            <div className='my-2'>
                                                <span className='body-m-b'>Nota conceptual</span>
                                            </div>
                                        </Col>
                                        <Col xs={3}>
                                            <div className='my-2'>
                                                <span className='body-m-b'>Nota del cuatrimestre</span>
                                            </div>
                                        </Col>
                                    </>
                                    :
                                    <>
                                        <Col xs={2}>
                                            <div className='my-2'>
                                                <span className='body-m-b'>1er Cuatrimestre</span>
                                            </div>
                                        </Col>
                                        <Col xs={2}>
                                            <div className='my-2'>
                                                <span className='body-m-b'>2do Cuatrimestre</span>
                                            </div>
                                        </Col>
                                        <Col xs={2}>
                                            <div className='my-2'>
                                                <span className='body-m-b'>Otras instancias</span>
                                            </div>
                                        </Col>
                                    </>
                                }
                            </>
                        }
                        rows={assessments.map((assessment, i) =>
                            <Row className={classNames('py-1', { "board__row ": i !== assessment.length, "board__last-row ": i === assessment.length - 1 })} key={assessment._id} >
                                <Col xs={3}>
                                    <div className='my-2'>
                                        <span className='body-m'>{`${assessment.student.lastName}, ${assessment.student.name}`}</span>
                                    </div>
                                </Col>
                                {customOption.type === "firstTerm" &&
                                    <>
                                        <Col xs={2} className="d-flex align-items-center">
                                            <CustomSelect
                                                role={user.userRole}
                                                className="w-70"
                                                options={reviewsOption}
                                                name="firstBimester"
                                                values={assessment.finalReviews.firstBimester}
                                                handleInputChange={(e) => handleAssessmentChange(e, i)}
                                                placeholder={'Nota'}
                                                disabled={disable} />
                                        </Col>
                                        <Col xs={2} className="d-flex align-items-center">
                                            <CustomSelect
                                                role={user.userRole}
                                                className="w-70"
                                                options={reviewsOption}
                                                name="secondBimester"
                                                values={assessment.finalReviews.secondBimester}
                                                handleInputChange={(e) => handleAssessmentChange(e, i)}
                                                placeholder={'Nota'}
                                                disabled={disable} />
                                        </Col>
                                        <Col xs={2} className="d-flex align-items-center">
                                            <CustomText value={Math.trunc((assessment.finalReviews?.firstBimester + assessment.finalReviews?.secondBimester) / 2)} />
                                        </Col>
                                        <Col xs={3} className="d-flex align-items-center">
                                            <CustomSelect
                                                role={user.userRole}
                                                className="w-70"
                                                options={reviewsOption}
                                                name="fisrtTerm"
                                                values={assessment.finalReviews.fisrtTerm}
                                                handleInputChange={(e) => handleAssessmentChange(e, i)}
                                                placeholder={'Nota'}
                                                disabled={disable} />
                                        </Col>
                                    </>
                                }
                                {customOption.type === "secondTerm" &&
                                    <>
                                        <Col xs={2} className="d-flex align-items-center">
                                            <CustomSelect
                                                role={user.userRole}
                                                className="w-70"
                                                options={reviewsOption}
                                                name="thirthBimester"
                                                values={assessment.finalReviews.thirthBimester}
                                                handleInputChange={(e) => handleAssessmentChange(e, i)}
                                                placeholder={'Nota'}
                                                disabled={disable} />
                                        </Col>
                                        <Col xs={2} className="d-flex align-items-center">
                                            <CustomSelect
                                                role={user.userRole}
                                                className="w-70"
                                                options={reviewsOption}
                                                name="fourthBimester"
                                                values={assessment.finalReviews.fourthBimester}
                                                handleInputChange={(e) => handleAssessmentChange(e, i)}
                                                placeholder={'Nota'}
                                                disabled={disable} />
                                        </Col>
                                        <Col xs={2} className="d-flex align-items-center">
                                            <CustomText value={Math.trunc((assessment.finalReviews?.thirthBimester + assessment?.finalReviews?.fourthBimester) / 2)} />
                                        </Col>
                                        <Col xs={3} className="d-flex align-items-center">
                                            <CustomSelect
                                                role={user.userRole}
                                                className="w-70"
                                                options={reviewsOption}
                                                name="secondTerm"
                                                values={assessment.finalReviews.secondTerm}
                                                handleInputChange={(e) => handleAssessmentChange(e, i)}
                                                placeholder={'Nota'}
                                                disabled={disable} />
                                        </Col>
                                    </>
                                }
                                {customOption.type === "otherInstances" &&
                                    <>
                                        <Col xs={2} className="d-flex align-items-center">
                                            <CustomText value={assessment.finalReviews?.fisrtTerm} />
                                        </Col>
                                        <Col xs={2} className="d-flex align-items-center">
                                            <CustomText value={assessment.finalReviews?.secondTerm} />
                                        </Col>
                                        <Col xs={2} className="d-flex align-items-center">
                                            {
                                                user.userRole !== 'secretariat' ?
                                                    <CustomSelect
                                                        role={user.userRole}
                                                        className="w-70"
                                                        options={reviewsOption}
                                                        name="instanceReview"
                                                        values={assessment.finalReviews.otherReviews.find(or => or.otherInstance === selectedInstance)?.instanceReview}
                                                        handleInputChange={(e) => handleOtherReviewsChange(e, i)}
                                                        placeholder={'Nota'}
                                                        disabled={disable} />
                                                    :
                                                    <p>
                                                        {
                                                            assessment?.finalReviews?.otherReviews?.some(or => or?.otherInstance === selectedInstance) ?
                                                                `${otherInstance?.find(aux => aux?._id === selectedInstance)?.name} - ${assessment?.finalReviews?.otherReviews?.find(or => or?.otherInstance === selectedInstance)?.instanceReview}`
                                                                :
                                                                `Nota`
                                                        }
                                                    </p>
                                            }
                                        </Col>
                                    </>
                                }
                            </Row>
                        )
                        }
                    />
                    {disable === false &&
                        <Row className="my-5">
                            <Col xs={{ span: 10, offset: 1 }} className="d-flex">
                                <div className='ml-auto d-flex'>
                                    <Button
                                        type="reset"
                                        className='btn button__only-icon-withoutcolor color-primary  button-m-sb mr-5'
                                        content="Cancelar"
                                        onClick={onsubmitCancel} />
                                    <Button
                                        className='btn button-primary button-m-sb'
                                        content="Guardar notas"
                                        onClick={onSubmitEvent} />
                                </div>
                            </Col>
                        </Row>
                    }

                </Container>
            }
        </main>
    )
}
