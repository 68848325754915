import { faChevronDown } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import classNames from "classnames"
import { useContext } from "react"
import { Accordion, AccordionContext, Card, useAccordionButton } from "react-bootstrap"

function ToggleHeader({ children, eventKey }) {
    const decoratedOnClick = useAccordionButton(eventKey)
    const { activeEventKey } = useContext(AccordionContext)
    
    return (
        <div className="d-flex justify-content-between align-items-center">
            {children}
            <button
                className={classNames('accordion__button', { 'accordion__button--open': [activeEventKey].flat().some(key => key === eventKey) })}
                onClick={decoratedOnClick}
                >
                <FontAwesomeIcon icon={faChevronDown} />
            </button>
        </div>
    )
}

export const AccordionToggle = ({ items, classNameAccordion, separator, alwaysOpen = true }) => {
    return (
        <Accordion className={"accordion " + classNameAccordion} defaultActiveKey="0" flush alwaysOpen={alwaysOpen} >
            {items?.map((item, i) =>
                <div key={i}>
                    <Card className={'accordion__card ' + item?.classNameCard ?? ''}>
                        <Card.Header className={"accordion__header " + item.classNameHeader ?? ''}>
                            <ToggleHeader eventKey={i}>{item.header}</ToggleHeader>
                        </Card.Header>
                        <Accordion.Collapse eventKey={i}>
                            <Card.Body className='accordion__body'>{item.body}</Card.Body>
                        </Accordion.Collapse>
                    </Card>
                    {separator && i < items?.length - 1 && separator}
                </div>
            )}
        </Accordion>
    )
}
