import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Button } from "../../Button";

import { ReceiptItem } from "./ReceiptItem";

export const ReceiptItemGroup = ({ index, receiptsList, setReceipts, items, disableImputs }) => {

    const defaultvalues = [{ description: "", quantity: "", unitaryPrice: "" }]
    const [itemsList, setItemsList] = useState(items);
    const [subTotal, setSubTotal] = useState(0);


    const handleAddItem = () => {
        setItemsList([
            ...itemsList,
            defaultvalues
        ]);
    };

    
    useEffect(() => {
        
        setReceipts(
            receiptsList.map(
                (receipt, receiptIndex) =>
                    receiptIndex === index ?
                        {
                            ...receipt,
                            items: itemsList
                        }
                        : receipt
            )
        )
       
        setSubTotal(itemsList.reduce((total, value) => {
            return total + value.quantity * value.unitaryPrice ? total + value.quantity * value.unitaryPrice : 0
        }, 0
        ));
    }, [itemsList, index, setReceipts])

    


    return (
        <>
            {
                itemsList.map(
                    (item, i) => {
                        return <ReceiptItem
                            key={i}
                            index={i}
                            item={item}
                            itemsList={itemsList}
                            setItemsList={setItemsList}ç
                            disableImputs={disableImputs}
                        />
                    }
                )
            }
            <Row className="my-3">
                <Col xs={{ offset: 3, span: 5 }}>
                    {disableImputs ? <div></div> :
                        <Button
                            icon="plusMUI"
                            content={<span className="ml-1 mt-1">Agregar item</span>}
                            className=" button-terciary button-s-sb d-flex align-items-center m-0 p-0 "
                            iconRight={false}
                            type="button"
                            onClick={handleAddItem}
                        />
                    }
                </Col>
                <Col xs={1}>
                    <label name="subtotal" className="body-m">Subtotal</label>
                </Col>
                <Col xs={2} className="d-flex justify-content-end">
                    <span className="ml-auto body-m-sb">$ {subTotal}</span>
                </Col>
                <Col xs={{ offset: 3, span: 8 }}>
                    <hr className="w-100 mt-2" />
                </Col>
            </Row>
        </>
    );
};
