import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter as Router, Switch } from "react-router-dom";

import { PrivateRoutes } from './PrivateRoutes';
import { PublicRoutes } from './PublicRoutes';
import { Messages } from '../components/pages/Messages/Messages';
import { Navbar } from '../components/Navbar/Navbar';
import { Aside } from '../components/Navbar/Aside';
import { Home } from '../components/pages/home/Home';
import { NewMessage } from '../components/pages/Messages/NewMessage';
import { MessageDetail } from '../components/pages/Messages/MessageDetail';
import { EventForm } from '../components/pages/Calendar/EventForm';
import { Calendar } from '../components/pages/Calendar/Calendar';
import { EventDetail } from '../components/pages/Calendar/EventDetail';
import { Sheet } from '../components/pages/ControlSheets/Sheet';
import { Budgets } from '../components/pages/budgets/Budgets';
import { BudgetForm } from '../components/pages/budgets/BudgetForm';
import { Regulations } from '../components/pages/Regulations/Regulations';
import { UploadUser } from '../components/pages/ControlSheets/UploadUser';
import { Alert } from '../components/Alert';
import { UserDetail } from '../components/pages/ControlSheets/UserDetail';
import { revalidateSession } from '../actions/auth';
import { useAuth } from '../hooks/useAuth';
import { LoginScreen } from '../components/pages/login/LoginScreen';
import { Login } from '../components/pages/login/Login';
import { setScope } from '../actions/scopes';
import { api } from '../helpers/fetch';
import { Loading } from '../components/Loading';
import { BudgetDetail } from '../components/pages/budgets/BudgetDetail';
import { SubjectDetail } from '../components/pages/subjects/SubjectDetail';
import { Subjects } from '../components/pages/subjects/Subjects'
import { Courses } from '../components/pages/Courses/Courses';
import { CourseForm } from '../components/pages/Courses/CourseForm';
import { CourseDetail } from '../components/pages/Courses/CourseDetail';
import { NewSubject } from '../components/pages/Courses/NewSubject';
import { CourseSubjectDetail } from '../components/pages/Courses/CourseSubjectDetail';
import { List } from '../components/pages/lists/List';
import { ReportCard } from '../components/pages/reportCard/ReportCard';
import { CourseSubjectClosingPeriod } from '../components/pages/Courses/CourseSubjectClosingPeriod';
import { NewStudentAssign } from '../components/pages/Courses/NewStudentAssign';
import { Attendance } from '../components/pages/attendance/Attendance';
import { SubjectStudentDetail } from '../components/pages/subjects/SubjectStudentDetail';
import { EvaluativeReport } from '../components/pages/subjects/EvaluativeReport';
import CourseDirectiveClosingPeriod from '../components/pages/Courses/CourseDirectiveClosingPeriod';
import SubjectStudent from '../components/pages/subjects/SubjectStudent';
import { YearsForm } from '../components/pages/Courses/YearsForm';

export const AppRouter = () => {

    const [loading, setLoading] = useState(true);
    const [show, setShow] = useState(true);
    const [navRef, setNavRef] = useState();
    const { adminUser } = useSelector(state => state.auth);
    const { user } = useAuth();
    const dispatch = useDispatch();
    const token = localStorage.getItem("token") || "";

    const isAuthenticated = !!user?.uid;
    useEffect(() => {
        if (token) {
            dispatch(revalidateSession());
        }
        api.get('/auth/scopes')
            .then(res => dispatch(setScope(res.data.join(' '))))
            .catch(() => console.log("Error de scopes!"))
            .finally(() => setLoading(false))
    }, [dispatch, token])

    if (loading) return <Loading />
    return (
        <Router>
            {isAuthenticated && <Navbar show={show} setShow={setShow} setNavRef={setNavRef} />}
            <Alert />
            <div className="d-flex">
                {isAuthenticated && <Aside show={show} navHeight={navRef?.current?.offsetHeight || 72} />}
                <Switch>
                    <PublicRoutes
                        exact
                        path="/"
                        component={LoginScreen}
                        isAuthenticated={isAuthenticated}
                    />
                    <PrivateRoutes
                        exact
                        path="/home"
                        component={Home}
                        isAuthenticated={isAuthenticated}
                    />
                    <PrivateRoutes
                        exact
                        path="/controlsheets"
                        component={Sheet}
                        isAuthenticated={isAuthenticated && user.userRole === "administration"}
                    />
                    <PrivateRoutes
                        exact
                        path="/uploadUser"
                        component={UploadUser}
                        isAuthenticated={isAuthenticated && user.userRole === "administration"}
                    />
                    <PrivateRoutes
                        exact
                        path="/userdetail/:id"
                        component={UserDetail}
                        isAuthenticated={isAuthenticated}
                    />
                    <PrivateRoutes
                        exact
                        path="/reportcard/:id"
                        component={ReportCard}
                        isAuthenticated={isAuthenticated}
                    />
                    <PrivateRoutes
                        exact
                        path="/budgets"
                        component={Budgets}
                        isAuthenticated={isAuthenticated && user.userRole === "administration"}
                    />
                    <PrivateRoutes
                        exact
                        path="/budgets/new"
                        component={BudgetForm}
                        isAuthenticated={isAuthenticated && user.userRole === "administration"}
                    />
                    <PrivateRoutes
                        exact
                        path="/budgets/detail/:id"
                        component={BudgetDetail}
                        isAuthenticated={isAuthenticated && user.userRole === "administration"}
                    />
                    <PrivateRoutes
                        exact
                        path="/budgets/edit/:id"
                        component={BudgetForm}
                        isAuthenticated={isAuthenticated && user.userRole === "administration"}
                    />
                    <PrivateRoutes
                        exact
                        path="/regulations"
                        component={!adminUser?.uid ? Login : Regulations}
                        isAuthenticated={isAuthenticated && user.userRole === "administration"}
                    />
                    <PrivateRoutes
                        exact
                        path="/courses"
                        component={Courses}
                        isAuthenticated={isAuthenticated && ['teachers', 'secretariat', 'directorate', 'tutorship'].includes(user.userRole)}
                    />
                    <PrivateRoutes
                        exact
                        path="/subjects"
                        component={Subjects}
                        isAuthenticated={isAuthenticated && ['teachers'].includes(user.userRole)}
                    />
                    <PrivateRoutes
                        exact
                        path="/studentsubjects"
                        component={SubjectStudent}
                        isAuthenticated={isAuthenticated && ['students'].includes(user.userRole)}
                    />
                    <PrivateRoutes
                        exact
                        path="/attendance/:id"
                        component={Attendance}
                        isAuthenticated={isAuthenticated && ['teachers', 'tutorship'].includes(user.userRole)}
                    />
                    <PrivateRoutes
                        exact
                        path="/subjects/detail/closingperiod/:subjectid"
                        component={CourseSubjectClosingPeriod}
                        isAuthenticated={isAuthenticated && user.userRole === "teachers"}
                    />
                    <PrivateRoutes
                        exact
                        path="/subjects/detail/:id"
                        component={SubjectDetail}
                        isAuthenticated={isAuthenticated && ['teachers', 'secretariat', 'students'].includes(user.userRole)}
                    />
                    <PrivateRoutes
                        exact
                        path="/subjects/student/detail/:id/:uid"
                        component={SubjectStudentDetail}
                        isAuthenticated={isAuthenticated && ['teachers'].includes(user.userRole)}
                    />
                    <PrivateRoutes
                        exact
                        path="/subjects/detail/evaluative-report/:id/:uid/:activityDeliveryId"
                        component={EvaluativeReport}
                        isAuthenticated={isAuthenticated && ['teachers', 'students'].includes(user.userRole)}
                    />
                    <PrivateRoutes
                        exact
                        path="/courses/new"
                        component={CourseForm}
                        isAuthenticated={isAuthenticated && user.userRole === "secretariat"}
                    />
                    <PrivateRoutes
                        exact
                        path="/courses/createyear"
                        component={YearsForm}
                        isAuthenticated={isAuthenticated && user.userRole === "secretariat"}
                    />
                    <PrivateRoutes
                        exact
                        path="/courses/detail/:id"
                        component={CourseDetail}
                        isAuthenticated={isAuthenticated && ['teachers', 'secretariat', 'directorate', 'tutorship'].includes(user.userRole)}
                    />
                    <PrivateRoutes
                        exact
                        path="/courses/detail/subjects/:courseid/:subjectid"
                        component={CourseSubjectDetail}
                        isAuthenticated={isAuthenticated && ['secretariat', 'directorate'].includes(user.userRole)}
                        />
                    <PrivateRoutes
                        exact
                        path="/courses/detail/subjects/closingperiod/:courseid/:subjectid"
                        component={CourseSubjectClosingPeriod}
                        isAuthenticated={isAuthenticated && ['secretariat', 'directorate'].includes(user.userRole)}
                    />
                    <PrivateRoutes 
                        exact
                        path="/courses/directive/closingperiod/:courseid/:subjectid"
                        component={CourseDirectiveClosingPeriod}
                        isAuthenticated={isAuthenticated && user.userRole === "directorate"}
                    />
                    <PrivateRoutes
                        exact
                        path="/courses/newsubject/:courseid/:subjectid?"
                        component={NewSubject}
                        isAuthenticated={isAuthenticated && user.userRole === "secretariat"}
                    />
                    <PrivateRoutes
                        exact
                        path="/teacherlist"
                        component={List}
                        isAuthenticated={isAuthenticated && ['secretariat', 'directorate'].includes(user.userRole)}
                        />
                    <PrivateRoutes
                        exact
                        path="/studentlist"
                        component={List}
                        isAuthenticated={isAuthenticated && ['secretariat', 'directorate'].includes(user.userRole)}
                    />
                    <PrivateRoutes
                        exact
                        path="/courses/assign/:courseid"
                        component={NewStudentAssign}
                        isAuthenticated={isAuthenticated && user.userRole === "secretariat"}
                    />
                    <PrivateRoutes
                        exact
                        path="/calendar"
                        component={Calendar}
                        isAuthenticated={isAuthenticated}
                    />
                    <PrivateRoutes
                        exact
                        path="/calendar/eventdetail/:id"
                        component={EventDetail}
                        isAuthenticated={isAuthenticated}
                    />
                    <PrivateRoutes
                        exact
                        path="/calendar/editevent/:id"
                        component={EventForm}
                        isAuthenticated={!!token}
                    />
                    <PrivateRoutes
                        exact
                        path="/calendar/new"
                        component={EventForm}
                        isAuthenticated={isAuthenticated}
                    />
                    <PrivateRoutes
                        exact
                        path="/messages"
                        component={Messages}
                        isAuthenticated={isAuthenticated}
                    />
                    <PrivateRoutes
                        exact
                        path="/messages/new"
                        component={NewMessage}
                        isAuthenticated={isAuthenticated}
                    />
                    <PrivateRoutes
                        exact
                        path="/messages/messagedetail/:id"
                        component={MessageDetail}
                        isAuthenticated={isAuthenticated}
                    />
                </Switch>
            </div>
        </Router>
    );
}