import React from "react";
import { Container, Row, Col } from "react-bootstrap"
import login from "../../../Imgs/ilpImgLogin.png"
import { Login } from "./Login";

export const LoginScreen = () => {
    return (
      <Container fluid className="login-container ">
          <Row>
              <Col xs={6} className="login-img-container p-0 ">
                  <img src={login} alt="Imagen ilustrativa del login ocupando la mitad de la pantalla" />
              </Col>
              <Login />
          </Row>
      </Container>
  )
}
