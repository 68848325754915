import { useDispatch, useSelector } from "react-redux";
import { Col } from 'react-bootstrap';
import { Button } from "../../Button";
import { useGoogleLogin  } from '@react-oauth/google';

import logo from "../../../Imgs/Logo.svg"
import { createLog } from "../../../actions/logs";
import { startLoginWithGoogle } from '../../../actions/auth';
import { useAuth } from '../../../hooks/useAuth';
import classNames from 'classnames';

export const Login = () => {

    const { user } = useAuth();
    const dispatch = useDispatch();
    const {scope} = useSelector((state) => state)

    const login= useGoogleLogin({
        onSuccess: async tokenResponse   => {
            dispatch(startLoginWithGoogle(tokenResponse));
        },
        scope: scope,
        onError: error => {
            dispatch(
                createLog({
                    type: "error",
                    title: "Lo sentimos!",
                    text: "No logramos obtener el token necesario",
                })
            );
        }
    });

    return (
        <Col xs={{ offset: 1, span: 3 }} className={classNames("d-flex", { "align-items-center": !user.uid }, { "mt-6": user.uid }, { "mb-6": !user.uid })}>
            <div className="d-flex flex-direction-column ">
                {
                    !user.uid &&
                    <div className="d-flex align-items-center ">
                        <img src={logo} alt="Logo del instituo Luján Porteño" />
                        <div className="mt-2 pl-3">
                            <h5 className="my-1">Instituto</h5>
                            <h4 className="my-1 color-hover-1">Luján Porteño</h4>
                        </div>
                    </div>
                }
                <div className="d-flex flex-direction-column ">
                    <h2 className="color-text mt-6 pt-3">Inicia sesión</h2>
                    {
                        user.uid &&
                        <p className="button-m-sb ">
                            Para acceder a esta sección debes iniciar sesión con la siguiente cuenta<br /><span className="color-primary">{process.env.REACT_APP_ADMIN_EMAIL}</span>
                        </p>
                    }
                    <div className="my-4 py-2">
                        <Button
                            onClick={login}
                            className="button-primary"
                            content={
                                <span className="ml-3">
                                    Ingresa con Google
                                </span>}
                            icon="google"
                        />
                    </div>
                    {
                        !user.uid &&
                        <p className="button-m-sb ">
                            Si no conoces tu cuenta escribinos <br /> a <span className="color-primary">administracion@ilp.edu.ar</span>
                        </p>
                    }
                </div>
            </div>
        </Col>
    )
}
