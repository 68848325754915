import { useState, useEffect } from "react"
import { Col, Container, Row } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { api } from "../../../api"
import { useForm } from "../../../hooks/useForm"
import { Board } from "../../Board"
import { BreadCrumbs } from "../../BreadCrumbs"
import { Button } from "../../Button"
import { CustomOptions } from "../../CustomOptions"
import { CustomSelect } from "../../CustomSelect"
import CustomText from "../../CustomText"
import { EmptyStates } from "../../EmptyStates"
import { Form } from "../../Form"
import emptySubject from '../../../Imgs/EmptyStates/Social 7 cursos.png'
import { Loading } from "../../Loading"
import { createLog } from "../../../actions/logs"

const options = [
    { label: "1er Cuatrimestre", type: "firstQuarter" },
    { label: "2do Cuatrimestre", type: "secondQuarter" },
    { label: "Otras instancias", type: "otherInstances" }
]

export const ReportCard = () => {
    const [values, handleInputChange, setValues] = useForm({
        area: options[0],
        year: (new Date()).getFullYear()
    })
    const auth = useSelector(state => state.auth)
    const { id } = useParams()
    const [assessmentStudent, setAssessmentStudent] = useState([])
    const [loading, setLoading] = useState(true)
    const [years, setYears] = useState([])
    const dispatch = useDispatch();

    const getFinalReviews = async () => {
        setLoading(true)
        try {
            const { data } = await api.get(`assessments/getAssessmentsByStudentByYear/${id}/${values.year}`)
            setAssessmentStudent(data?.assessments.filter(aux => aux.subject !== null))
    
            const instances = []
            data?.assessments?.forEach(async (instance) => {
                if (instance?.subject !== null) {
                    const otherInstance = await api.get(`otherInstance/getInstanceFromSubject/${instance?.subject?._id}`)
                    const value = otherInstance.data.allInstance
                    if (otherInstance.data.allInstance.length) instances.push(...value)
                }
            })
            setOtherInstance(instances)
        } catch (error) {
                setValues({
                    area: options[0],
                    year: (new Date()).getFullYear()
                })
                dispatch(
                    createLog({
                        type: "error",
                        title: "No se encontró la información",
                        text: error.data.msg + "."
                    })
                )
        } finally {
            setLoading(false)
        }
    }

    const getYears = async () => {
        const { data } = await api.get('years')
        if(data.years.length) data.years.forEach(y => setYears(years => [...years, y.year]))
    }

    const [otherInstance, setOtherInstance] = useState([]);

    const otherInstanceOption = otherInstance.map((instance, i) => {
        return { label: instance.name, value: instance._id, index: i }
    })

    const [selectedInstance, setSelectedInstance] = useState({})

    const handleSelectedInstanceChange = ({ target }) => {
        setSelectedInstance(target.value);
    }

    useEffect(() => {
        getFinalReviews()
        getYears()
    }, [])

    return (
        <main>
            <Container>
                {
                    loading ? <Loading /> :
                        <>
                            <Row className='mb-2 pb-2'>
                                <Col xs={12}>
                                    <BreadCrumbs
                                        lastSteps={[
                                            { to: "/home", step: "Inicio" }
                                        ]}
                                        title="Boletines" />
                                </Col>
                            </Row>

                            <Row>
                                <Col xs={12}>
                                    <CustomOptions
                                        options={options}
                                        value={values.area}
                                        setValue={option => setValues({
                                            ...values,
                                            area: option
                                        })}
                                    />
                                </Col>
                            </Row>

                            {
                                auth.user.userRole !== 'students' &&
                                <div className="d-flex align-items-center mt-4 ">
                                    {
                                        values.area.type === "otherInstances" &&
                                        <Col xs={3}>
                                            <CustomSelect
                                                options={otherInstanceOption}
                                                placeholder={!assessmentStudent?.finalReviews?.otherInstance?.length ? "Elija una instancia" : "No hay instancias creadas"}
                                                labelContent={<span className='color-disabled input-label'>Otras instancias</span>}
                                                alwaysShow
                                                handleInputChange={handleSelectedInstanceChange}
                                                values={selectedInstance}
                                                name="otherInstance" />
                                        </Col>
                                    }
                                    <Form
                                        className='w-100'
                                        onSubmit={() => { getFinalReviews() }}
                                    >
                                        <Row className="d-flex align-items-center">
                                            <Col xs={2}>
                                                <CustomSelect
                                                    options={years}
                                                    placeholder="Ciclo lectivo"
                                                    labelContent={<span className='color-disabled input-label'>Ciclo lectivo</span>}
                                                    alwaysShow
                                                    name="year"
                                                    handleInputChange={handleInputChange}
                                                    values={values.year}
                                                />
                                            </Col>
                                            <Col xs={2}>
                                                <Button
                                                    content="Aplicar"
                                                    className="button-secondary button-m-sb py-2 px-4"
                                                />
                                            </Col>
                                        </Row>
                                    </Form>
                                </div>
                            }
                            {
                                (values.area.type === "otherInstances" && auth.user.userRole === 'students') &&
                                <Col xs={3} className="mt-3">
                                    <CustomSelect
                                        options={otherInstanceOption}
                                        placeholder={!assessmentStudent?.finalReviews?.otherInstance?.length ? "Elija una instancia" : "No hay instancias creadas"}
                                        labelContent={<span className='color-disabled input-label'>Otras instancias</span>}
                                        alwaysShow
                                        handleInputChange={handleSelectedInstanceChange}
                                        values={selectedInstance}
                                        name="otherInstance" />
                                </Col>
                            }

                            {
                                assessmentStudent.length === 0 ?
                                    <EmptyStates src={emptySubject} title='No tienes ninguna nota' text='Actualmente no tienes notas. Para más información puedes comunicarte con secretaría.' />
                                    :
                                    <Board
                                        className="board-row-color-3 pt-3"
                                        firstRow={
                                            <>
                                                <Col xs={2}>
                                                    <div className='my-2'>
                                                        <span className='body-m-b'>Materia</span>
                                                    </div>
                                                </Col>
                                                {
                                                    values.area.type === "firstQuarter" &&
                                                    <>
                                                        <Col xs={2}>
                                                            <div className='my-2'>
                                                                <span className='body-m-b'>Nota 1er Bimestre</span>
                                                            </div>
                                                        </Col>
                                                        <Col xs={2}>
                                                            <div className='my-2'>
                                                                <span className='body-m-b'>Nota 2do Bimestre</span>
                                                            </div>
                                                        </Col>
                                                    </>
                                                }
                                                {
                                                    values.area.type === "secondQuarter" &&
                                                    <>
                                                        <Col xs={2}>
                                                            <div className='my-2'>
                                                                <span className='body-m-b'>Nota 3er Bimestre</span>
                                                            </div>
                                                        </Col>
                                                        <Col xs={2}>
                                                            <div className='my-2'>
                                                                <span className='body-m-b'>Nota 4to Bimestre</span>
                                                            </div>
                                                        </Col>
                                                    </>
                                                }


                                                {
                                                    values.area.type === "otherInstances"
                                                        ?
                                                        <>
                                                            <Col xs={2}>
                                                                <div className='my-2'>
                                                                    <span className='body-m-b'>1er Cuatrimestre</span>
                                                                </div>
                                                            </Col>
                                                            <Col xs={3}>
                                                                <div className='my-2'>
                                                                    <span className='body-m-b'>2do Cuatrimestre</span>
                                                                </div>
                                                            </Col>
                                                            <Col xs={2}>
                                                                <div className='my-2'>
                                                                    <span className='body-m-b'>Otras instancias</span>
                                                                </div>
                                                            </Col>
                                                        </>
                                                        :
                                                        <>
                                                            <Col xs={2}>
                                                                <div className='my-2'>
                                                                    <span className='body-m-b'>Nota conceptual</span>
                                                                </div>
                                                            </Col>
                                                            <Col xs={3}>
                                                                <div className='my-2'>
                                                                    <span className='body-m-b'>Nota del cuatrimestre</span>
                                                                </div>
                                                            </Col>
                                                        </>
                                                }
                                            </>
                                        }
                                        rows={
                                            assessmentStudent?.map((assess, i) =>
                                                <Row className='board__row py-1' key={i}>
                                                    <Col xs={2}>
                                                        <div className='my-2'>
                                                            <span className='body-m'>{assess?.subject?.name}</span>
                                                        </div>
                                                    </Col>
                                                    {
                                                        values.area.type === "firstQuarter" &&
                                                        <>
                                                            <Col xs={2}>
                                                                <div className='my-2'>
                                                                    <CustomText value={assess?.finalReviews?.firstBimester} />
                                                                </div>
                                                            </Col>
                                                            <Col xs={2}>
                                                                <div className='my-2'>
                                                                    <CustomText value={assess?.finalReviews?.secondBimester} />
                                                                </div>
                                                            </Col>
                                                            <Col xs={2}>
                                                                <div className='my-2'>
                                                                    <CustomText value={Math.round((assess?.finalReviews?.firstBimester + assess?.finalReviews?.secondBimester) / 2)} />
                                                                </div>
                                                            </Col>
                                                            <Col xs={3}>
                                                                <div className='my-2'>
                                                                    <CustomText value={assess?.finalReviews?.fisrtTerm} />
                                                                </div>
                                                            </Col>
                                                        </>
                                                    }
                                                    {
                                                        values.area.type === "secondQuarter" &&
                                                        <>
                                                            <Col xs={2}>
                                                                <div className='my-2'>
                                                                    <CustomText value={assess?.finalReviews?.thirthBimester} />
                                                                </div>
                                                            </Col>
                                                            <Col xs={2}>
                                                                <div className='my-2'>
                                                                    <CustomText value={assess?.finalReviews?.fourthBimester} />
                                                                </div>
                                                            </Col>
                                                            <Col xs={2}>
                                                                <div className='my-2'>
                                                                    <CustomText value={Math.round((assess?.finalReviews?.thirthBimester + assess?.finalReviews?.fourthBimester) / 2)} />
                                                                </div>
                                                            </Col>
                                                            <Col xs={3}>
                                                                <div className='my-2'>
                                                                    <CustomText value={assess?.finalReviews?.secondTerm} />
                                                                </div>
                                                            </Col>
                                                        </>
                                                    }
                                                    {
                                                        values.area.type === "otherInstances" &&
                                                        <>
                                                            <Col xs={2}>
                                                                <div className='my-2'>
                                                                    <CustomText value={assess?.finalReviews?.fisrtTerm} />
                                                                </div>
                                                            </Col>
                                                            <Col xs={3}>
                                                                <div className='my-2'>
                                                                    <CustomText value={assess?.finalReviews?.secondTerm} />
                                                                </div>
                                                            </Col>
                                                            <Col xs={2}>
                                                                <div className='my-2'>
                                                                    <p>
                                                                        {
                                                                            assess?.finalReviews?.otherReviews?.some(or => or?.otherInstance === selectedInstance) ?
                                                                                `${otherInstance?.find(aux => aux?._id === selectedInstance)?.name} - ${assess?.finalReviews?.otherReviews?.find(or => or?.otherInstance === selectedInstance)?.instanceReview}`
                                                                                :
                                                                                `-`
                                                                        }
                                                                    </p>
                                                                </div>
                                                            </Col>
                                                        </>
                                                    }
                                                </Row>
                                            )
                                        }
                                    />
                            }
                        </>
                }
            </Container>
        </main>
    )
}
