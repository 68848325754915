import { Col, Row } from 'react-bootstrap'
import { UserPresentation } from './UserPresentation'
import { reduceString, translate } from '../../../helpers/reduceString'
import { useSelector } from 'react-redux'
import { getUnflattenedObject } from '../../../helpers/flatten'
import { SubjectInfoText } from '../subjects/SubjectInfoText'

export const UserAssignments = ({ user }) => {
    const { userRole, uid } = useSelector(state => state.auth.user)
    const userFlatten = getUnflattenedObject(user)

    return (
        <>
            <UserPresentation
                registerDate={userFlatten?.incorporationDate}
                id={userFlatten._id} name={userFlatten.name || " "}
                lastName={userFlatten.lastName || " "}
                profileImgUrl={userFlatten.urlImgProfile}
                edit={userRole === "administration" || userFlatten._id === uid}
                shifts={reduceString(userFlatten?.courses, "shift", "/")}
                level={reduceString(userFlatten?.courses, "level", "/")}
                course={userFlatten?.courses && userFlatten?.courses.find(crs => crs.year === (new Date()).getFullYear()).age}
            />
            <Row>
                <Col xs={{ span: 8, offset: 1 }}>
                    <h4>Cursos asignados</h4>
                </Col>
            </Row>
            {userFlatten?.courses?.map((assigment, i) =>
                <div key={i}>
                    <Row>
                        <Col xs={{ span: 3, offset: 1 }}>
                            <h5>{assigment?.age}</h5>
                        </Col>
                    </Row>
                    {
                        assigment?.subjects?.map(subj =>
                            <Row key={subj._id} className='mt-3'>
                                <Col xs={{ span: 3, offset: 1 }}>
                                    <SubjectInfoText name='Curso' values={[subj?.name]} />
                                </Col>
                                <Col xs={3}>
                                    <SubjectInfoText name='Turno' values={[assigment?.shift]} />
                                </Col>
                                <Col xs={3}>
                                    <SubjectInfoText name='Nivel' values={[translate(assigment?.level)]} />
                                </Col>
                                {
                                    subj.schedule &&
                                    <Col xs={{ span: 5, offset: 1 }} className="mt-4">
                                        <SubjectInfoText
                                            name='Días y horarios'
                                            values={subj?.schedule?.map((value) => `${value.day} de ${value.fromHour}:${value.fromMin} hs a ${value.toHour}:${value.toMin} hs`)}
                                        />
                                    </Col>
                                }
                                <Col xs={{ span: 10, offset: 1 }} className="mt-3">
                                    <hr />
                                </Col>
                            </Row>
                        )
                    }
                </div>
            )
            }
        </>
    )
}
