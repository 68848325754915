import React from 'react';
import { Button } from '../Button';

export const DropdownButton = ({ label, icon, onClick, disabled = false}) => {
    return (
        <Button
            icon={icon}
            content={<span className="ml-2">{label}</span>}
            onClick={onClick}
            disabled={disabled}
            className="d-flex bg-hover-4 button__only-icon-withoutcolor show-box-item py-2 px-3 mt-3 " 
        />
    )
}
