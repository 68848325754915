import { Col, Row } from "react-bootstrap"
import { CustomInput } from "../../CustomInput"

export const UserAlternariveContactInformation = ({ form, handleInputChange, disable }) => {

    const getKey = (key) => `alternativeContact.${key}`

    return (
        <>
            <Row className='userper mt-2'>
                <Col xs={{ span: 8, offset: 1 }}>
                    <div className='d-flex justify-content-between mb-4'>
                        <h4>Datos de contacto alternativos</h4>
                    </div>
                </Col>
            </Row>
            <Row className="my-4">
                <Col xs={{ span: 3, offset: 1 }} className='userpersonaldata__form-input body-l'>
                    <CustomInput
                        name={getKey('fullName')}
                        value={form[getKey('fullName')]}
                        handleInputChange={handleInputChange}
                        labelContent={<span className='color-disabled input-label'>Apellido y nombre</span>}
                        placeholder="Ej: Alva, Roberto"
                        classNameInput="w-100"
                        alwaysShow
                        disabled={!disable}
                    />
                </Col>
                <Col xs={2}>
                    <CustomInput
                        type="number"
                        name={getKey('documentId')}
                        value={form[getKey('documentId')]}
                        handleInputChange={handleInputChange}
                        labelContent={<span className='color-disabled input-label'>DNI</span>}
                        placeholder="Ej: 15.523.359"
                        classNameInput="w-100"
                        alwaysShow
                        disabled={!disable}
                    />
                </Col>
                <Col xs={2}>
                    <CustomInput
                        name={getKey('relationship')}
                        value={form[getKey('relationship')]}
                        handleInputChange={handleInputChange}
                        labelContent={<span className='color-disabled input-label'>Vínculo</span>}
                        placeholder="Ej: Abuelo"
                        classNameInput="w-100"
                        alwaysShow
                        disabled={!disable}
                    />
                </Col>
                <Col xs={2}>
                    <CustomInput
                        type="number"
                        name={getKey('celPhone')}
                        value={form[getKey('celPhone')]}
                        handleInputChange={handleInputChange}
                        labelContent={<span className='color-disabled input-label'>Teléfono</span>}
                        placeholder="Ej: 011-256 2564"
                        classNameInput="w-100"
                        alwaysShow
                        disabled={!disable}
                    />
                </Col>
            </Row>
        </>
    )
}
