import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { setToken } from '../helpers/fetch';

export const useAuth = () => {

    const { user, adminUser } = useSelector(state => state.auth);
    const [activeUser, setActiveUser] = useState({})

    useEffect(() => {
        if (user.uid) {
            setActiveUser(user);
        } else if (adminUser?.uid) {
            if (adminUser.jwt) setToken(adminUser.jwt);
            setActiveUser(adminUser);
        } else {
            setActiveUser({});
        }
    }, [user, adminUser])
    

    return {user: activeUser};
}