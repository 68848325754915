import { useEffect, useState } from "react"

const reviewsOption = [
    { label: "1 (uno)", value: 1 },
    { label: "2 (dos)", value: 2 },
    { label: "3 (tres)", value: 3 },
    { label: "4 (cuatro)", value: 4 },
    { label: "5 (cinco)", value: 5 },
    { label: "6 (seis)", value: 6 },
    { label: "7 (siete)", value: 7 },
    { label: "8 (ocho)", value: 8 },
    { label: "9 (nueve)", value: 9 },
    { label: "10 (diez)", value: 10 },
]

const CustomText = ({ value, className = '' }) => {

    const [number, setNumber] = useState('Nota')

    const numberInfo = () => {
        reviewsOption.map(option => {
            if (value === option.value)
                setNumber(option.label)
        })
    }
    useEffect(() => {
        numberInfo()
    })
    

    return (
        <span className={className === ''  ? "body-m" : className}> {number} </span>
    )
}
export default CustomText