import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { BreadCrumbs } from '../../BreadCrumbs'
import { Calendar as BigCalendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import 'moment/locale/es'
import "react-big-calendar/lib/css/react-big-calendar.css"
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { CustomFabicon } from "../../CustomFabicon"
import { api } from '../../../helpers/fetch'
import { createLog } from '../../../actions/logs'
import { useDispatch } from 'react-redux'
import { startLogout } from '../../../actions/auth'
import { SelectAccount } from '../../SelectAccount'


moment.locale('es');
export const Calendar = () => {
    const localizer = momentLocalizer(moment)
    const [ events, setEvents ] = useState([]);
    const [ token, setToken ] = useState(localStorage.getItem('token'));
    
    const history = useHistory()
    const goToEvent = (id) => {
        history.push(`/calendar/eventdetail/${id}`);
    }
    const dispatch = useDispatch()
    const eventStyleGetter = () => {

        const style = {
            backgroundColor: '#0056A8',
            fontFamily: " Roboto",
            fontSize: "0.875rem",
        }
        return { style }
    }

    const getEvents = async(jwt) => {
        api.get("events", {headers: {"x-token": jwt} })
            .then(
                resp => setEvents(resp.data.map( 
                    event => ({
                        ...event,
                        start: event.fromHour,
                        end: event.toHour
                    })
                ))
            )
            .catch(
                err =>{
                    dispatch(
                        createLog({
                            type: "info",
                            title: "La sesión expiró.",
                            text: err?.msg || err
                        })
                    );
                    dispatch(startLogout());
                }
            )
    }

    useEffect(() => {
        getEvents(token);
    }, [token]);

    return (
        <main>
            <Container>
                <Row className='mb-2 pb-2'>
                    <Col xs={12}>
                        <BreadCrumbs lastSteps={[{ to: "/home", step: "Inicio" }, { to: "/messages", step: "Mensajes" }]} title="Calendario" />
                    </Col>
                </Row>
                <Row className='mb-2 pb-2'>
                    <Col xs={12}>
                        <SelectAccount setToken={setToken} />
                    </Col>
                </Row>
                <Row className='mb-6'>
                    <Col xs={12}>
                        <div>
                            <BigCalendar
                                localizer={localizer}
                                events={events}
                                startAccessor="start"
                                endAccessor="end"
                                header={{
                                    left: "prev,next today",
                                    center: "title",
                                    right: "dayGridMonth,timeGridWeek,timeGridDay,listWeek"
                                }}
                                messages={
                                    {
                                        allDay: 'Todo el día',
                                        previous: '<',
                                        next: '>',
                                        today: 'Hoy',
                                        month: 'Mes',
                                        week: 'Semana',
                                        day: 'Día',
                                        agenda: 'Agenda',
                                        date: 'Fecha',
                                        time: "Hora",
                                        event: 'Evento',
                                        noEventsInRange: 'No hay eventos en este rango',
                                        showMore: total => `+ Ver más (${total})`
                                    }
                                }
                                onDoubleClickEvent={e => goToEvent(e._id)}
                                style={{ minHeight: "80vh", width: "100%" }}
                                eventPropGetter={eventStyleGetter}
                                components={{
                                    month: {
                                        dateHeader: ({ date, label }) => {
                                            return (
                                                <p className="p-1" style={{ textAlign: "start" }}>{label}</p>
                                            );
                                        }
                                    }
                                }}
                            />
                        </div>
                    </Col>
                </Row>
                <CustomFabicon text="Nuevo evento" to="/calendar/new" />
            </Container>
        </main>
    )
}
