import React, { useEffect, useRef, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import driveApi from '../../../api/drive'
import { File } from '../../File'
import { FilePicker } from 'react-file-picker'
import { Button } from '../../Button'
import { getUploadableFile } from '../../../helpers/get-uploadable-file'
import { Toast } from 'primereact/toast'
import { getUnflattenedObject } from '../../../helpers/flatten'

const getAttachmentsFromForm = (form) => {
    return Object.entries(form).reduce((acc, [key, value]) => {
        if (key.startsWith('personalAttachments')) {
            acc[key] = value
        }
        return acc
    }, {})
}

const getLastAttachmentIndex = (form) => {
    const attachments = getAttachmentsFromForm(form)
    const keys = Object.keys(attachments)
    if (!keys.length) return -1
    return keys.map(key => parseInt(key.split('.')[1].split('.')[0])).sort((a, b) => a - b).pop()
}

export const UserPersonalAttachments = ({ form, setForm, disable}) => {
    const [attachments, setAttachments] = useState([])
    const toast = useRef(null)

    const showSuccess = (summary = 'Actualizado', detail) => {
        toast.current.show({ severity: 'success', summary, detail, life: 3000 })
    }

    const showError = (summary, detail) => {
        toast.current.show({ severity: 'error', summary, detail, life: 3000 })
    }

    const onFilePickerChange = async (file) => {
        try {
            const driveFile = await driveApi.uploadFile(await getUploadableFile(file))
            addFile(driveFile)
            showSuccess('Archivo subido', 'El archivo se ha subido correctamente')
        } catch (error) {
            showError('Error al subir el archivo', error.message)
            throw error
        }
    }

    const setAttachmentsFromForm = () => {
        const { personalAttachments } = getUnflattenedObject(getAttachmentsFromForm(form))
        setAttachments(personalAttachments || [])
    }

    const addFile = (file) => {
        const index = getLastAttachmentIndex(form) + 1
        const flattenedObject = Object.entries(file).reduce((acc, [key, value]) => {
            acc[`personalAttachments.${index}.${key}`] = value
            return acc
        }, {})

        setForm({ ...form, ...flattenedObject })
    }

    useEffect(() => {
        setAttachmentsFromForm()
    }, [form])

    const options = [
        {
            name: 'Copiar link',
            icon: 'pi pi-link',
            action: (file) => {
                navigator.clipboard.writeText(`https://drive.google.com/file/d/${file.id}/view?usp=sharing`)
                showSuccess('Link copiado', 'El link se ha copiado al portapapeles')
            }
        },
        {
            name: 'Cambiar nombre',
            icon: 'pi pi-pencil',
            action: async (file) => {
                const [oldName, extension] = file.name.split('.')
                const newName = prompt('Nuevo nombre:', oldName)

                if (newName && newName !== oldName) {
                    await driveApi.updateFile(file.id, { name: extension ? `${newName}.${extension}` : newName })
                    showSuccess('Nombre cambiado', 'El nombre del archivo se ha cambiado correctamente')
                }
            }
        },
    ]

    return (<>
        <Toast ref={toast} />
        <Row className='mt-4 pt-3'>
            < Col xs={{ span: 8, offset: 1 }}>
                <div className='d-flex justify-content-between'>
                    <h5>Adjuntos personales</h5>
                </div>
            </Col >
        </Row >
        <Row className='mt-2 '>
            {
                attachments?.map((regulation, index) =>
                    <Col md={{ span: 3, offset: index % 3 === 0 ? 1 : 0 }} s={4} xs={6} key={regulation.id} className={index > 2 ? "mt-4" : ""}>
                        <File key={index} file={regulation} options={options} onError={showError} dropDown={disable}/>
                    </Col>
                )
            }
        </Row >
        <Row className='mt-2 '>
            <Col xs={{ span: 8, offset: 1 }}>
                <FilePicker onChange={onFilePickerChange}>
                    <Button className={'button-primary'} content="Subir Archivo" disabled={!disable}/>
                </FilePicker>
            </Col>
        </Row>
        <Row className=''>
            <Col xs={{ span: 10, offset: 1 }}>
                <hr />
            </Col>
        </Row >
    </>
    )
}
