import { types } from "../types/types";

export const createLog = (item) => {
    return (dispatch) => {
            dispatch(setLog(item));
            setTimeout(() => {
            dispatch(clearLog());
        }, 5000);
    };
};

const setLog = (item) => ({
    type: types.setLog,
    payload: item,
});

export const clearLog = () => ({
    type: types.clearLog,
});
