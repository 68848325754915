import { Container } from "react-bootstrap"
import { useSelector } from "react-redux"
import { SubjectStudentReport } from "./SubjectStudentReport"
import { EmptyStates } from "../../EmptyStates"
import emptySubject from '../../../Imgs/EmptyStates/Social 7 cursos.png'

const SubjectStudentCalification = ({ assessments }) => {
    const auth = useSelector(state => state.auth)

    return (
        <Container className="subjectDetail__container">
            {
                assessments.length === 0 ?
                    <EmptyStates src={emptySubject} title='No tienes ninguna nota' text='Actualmente no tienes notas. Para más información puedes comunicarte con secretaría.' />
                    :
                    <>
                        <SubjectStudentReport title='Informe valorativo' type='conceptual' id={auth.user._id} role={auth.user.userRole} />
                        <SubjectStudentReport title='Informe evaluativo' type='evaluative' id={auth.user._id} role={auth.user.userRole} />
                    </>
            }
        </Container>
    )
}
export default SubjectStudentCalification