import { ScheduleDay } from "./ScheduleDay"
import { CustomInput } from "../../CustomInput"
import { Button } from "../../Button"
import { Link } from "react-router-dom"
import { Col, Row } from "react-bootstrap"
import { translate } from "../../../helpers/reduceString"

const CourseSubjetEdit = ({ values, subjectid, courseid, course, onEditEvent, scheduleDay, handleInputChange }) => {
    return (
        <>
            <Row>
                <Col xs={12}>
                    <hr className='w-100' />
                </Col>
            </Row>
            <Row className="mb-3 justify-content-end">
                <Col xs="auto">
                    <Link to={`/courses/detail/subjects/closingperiod/${courseid}/${subjectid}`}>
                        <Button
                            className='btn button-primary button-m-sb'
                            content="Cierre de períodos"
                        />
                    </Link>
                </Col>
            </Row>
            <div className="coursenew_form px-5 py-3 mb-6">
                <Row>
                    <Col xs={12} className='my-3'>
                        <h3>Información de la materia</h3>
                    </Col>
                </Row>
                <Row>
                    <Col xs={3} className='my-3'>
                        <p>Curso: <b>{`${course?.age} ${course?.division}`}</b></p>
                    </Col>
                    <Col xs={3} className='my-3'>
                        <p>Turno: <b>{`${course.shift}`}</b></p>
                    </Col>
                    <Col xs={3} className='my-3'>
                        <p>Nivel: <b>{`${translate(course.level)}`}</b></p>
                    </Col>
                </Row>

                <Row>
                    <Col xs={12}>
                        <hr className='w-100' />
                    </Col>
                </Row>
                <Row>
                    <Col xs={4} className='my-3'>
                        <h3>Días y horarios</h3>
                    </Col>
                    <Col className='my-3'>
                        <Button
                            className='btn button__only-icon-withoutcolor color-primary button-s-sb'
                            icon="pencilMUI"
                            content="Editar"
                            iconRight={false}
                            onClick={onEditEvent} />
                    </Col>
                </Row>
                <Row>
                    <Col className='my-1'>
                        {scheduleDay.map(
                            (schedule, i) => {
                                return <ScheduleDay
                                    key={i}
                                    index={subjectid}
                                    schedule={schedule}
                                    disable={true} />
                            }
                        )}
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <hr className='w-100' />
                    </Col>
                </Row>
                <Row>
                    <Col xs={4} className='my-3'>
                        <h3>Docente a cargo</h3>
                    </Col>
                    <Col className='my-3'>
                        <Button
                            className='btn button__only-icon-withoutcolor color-primary button-s-sb'
                            icon="pencilMUI"
                            content="Editar"
                            iconRight={false}
                            onClick={onEditEvent} />
                    </Col>
                </Row>
                <Row>
                    <Col xs={3} className='my-3'>
                        <CustomInput name="teacher" value={`${values.teacher.lastName}, ${values.teacher.name}`} handleInputChange={handleInputChange} disabled={true} />
                    </Col>
                </Row>
            </div>
        </>

    )
}
export default CourseSubjetEdit