import { useState } from "react"
import { useEffect } from "react"
import { Col, Container, Row } from "react-bootstrap"
import { useLocation, useParams } from "react-router-dom/cjs/react-router-dom.min"
import { api } from "../../../helpers/fetch"
import { BreadCrumbs } from "../../BreadCrumbs"
import { Line } from "../../Line"
import { Loading } from "../../Loading"
import { SubjectInfoText } from "./SubjectInfoText"
import { SubjectStudentReport } from "./SubjectStudentReport"

const getLevelName = (level) => {
    switch (level) {
        case 'Elementary': return 'Inicial'
        case 'Primary': return 'Primario'
        case 'High': return 'Secundario'
        default: return ''
    }
}

export const SubjectStudentDetail = () => {
    const { state: course } = useLocation()
    const { id: subjectId, uid: studentId } = useParams()
    const [subject, setSubject] = useState({})
    const [student, setStudent] = useState({})
    const [attendances, setAttendances] = useState({})
    const [isLoading, setIsLoading] = useState(true)
    const [studentCourse, setStudentCourse] = useState([])

    const getStudent = async () => {
        
        const res = await api.get(`assessments/getAssessmentsBySubject/${subjectId}`)
        setStudentCourse(res.data.course)

        const { student: currentStudent, subject: currentSubject } = res.data.assessments?.find(({ student }) => student._id === studentId)
        setStudent(currentStudent)
        setSubject(currentSubject)

        const studentAttendancesArray = currentStudent?.attendances?.find(att => att.course === res.data.course?._id)?.attendances
        
        setAttendances({
            total: studentAttendancesArray?.length ?? 0,
            student: studentAttendancesArray?.reduce((acc, cur) => acc + cur?.value, 0) ?? 0
        })
        setIsLoading(false)
    }

    useEffect(() => {
        getStudent()
    }, [subjectId])


    return (
        <Container className="subjectDetail__container">
            {isLoading ? <Loading /> : <>
                <Row className='mb-3'>
                    <Col xs={12}>
                        <BreadCrumbs
                            lastSteps={[
                                { to: "/home", step: "Inicio" },
                                { to: "/subjects", step: "Materias" },
                                { to: {pathname: `/subjects/detail/${subjectId}`, state: {course}}, step: subject.name }
                            ]}
                            title='Información del alumno'
                        />
                    </Col>
                </Row>

                <Line mb={3} />

                <Row className="mb-4">
                    <Col>
                        <h3 className="m-0">{student?.lastName}, {student?.name}</h3>
                    </Col>
                </Row>

                <Row className="mb-4">
                    <Col xs={3}>
                        <SubjectInfoText name="Curso" values={[`${studentCourse?.age} ${studentCourse?.division} - ${studentCourse?.shift}`]} />
                    </Col>
                    <Col xs={3}>
                        <SubjectInfoText name="Nivel" values={[getLevelName(studentCourse?.level)]} />
                    </Col>
                    <Col xs={3}>
                        <SubjectInfoText name="Asistencias" values={[`${attendances?.student} de ${attendances?.total}`]} />
                    </Col>
                </Row>

                <Line mb={2} />

                <SubjectStudentReport title='Informe valorativo' type='conceptual' />
                <SubjectStudentReport title='Informe evaluativo' type='evaluative' course={course} />

            </>}
        </Container>
    )
}
