import moment from "moment";

export const sortByDate = (array, order, param = "date") => {
    switch (order) {
        case "Descendente":
            return array.sort((a, b) => {

                const firstObjDate = new Date(moment(a[param]).utcOffset('+0100').format("MM/DD/YYYY"));
                const secondObjDate = new Date(moment(b[param]).utcOffset('+0100').format("MM/DD/YYYY"));

                return secondObjDate - firstObjDate;
            });
        case "Ascendente":
            return array.sort((a, b) => {
                const firstObjDate = new Date(moment(a[param]).utcOffset('+0100').format("MM/DD/YYYY"));
                const secondObjDate = new Date(moment(b[param]).utcOffset('+0100').format("MM/DD/YYYY"));

                return firstObjDate - secondObjDate;
            });
        default:
          return array;
    }
};
