import { api } from '.'

const sheetsApi = {
  async exportBudgetToSheet (budgetId) {
    return (await api.post(`budget/${budgetId}/export`))?.data
  },
  async exportAllBudgetsToSheet () {
    return (await api.post(`budget/export`))?.data
  }
}

export default sheetsApi