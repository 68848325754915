import { useEffect, useState } from "react"
import { Col, Container, Row } from "react-bootstrap"
import { useHistory } from 'react-router'
import { useCounter } from '../../../hooks/useCounter'
import { useForm } from "../../../hooks/useForm"
import { Board } from "../../Board"
import { BreadCrumbs } from "../../BreadCrumbs"
import { Button } from "../../Button"
import { CustomOptions } from "../../CustomOptions"
import { CustomSelect } from "../../CustomSelect"
import { Form } from "../../Form"
import { Loading } from "../../Loading"

import { Link, useLocation } from "react-router-dom"
import { PaginationControll } from '../../PaginationControll'

import { api } from "../../../helpers/fetch"
import { removeDuplicates } from "../../../helpers/removeDuplicate"

const options = [
    {
        label: "Inicial",
        type: "elementary",
    },
    {
        label: "Primaria",
        type: "primary",
    },
    {
        label: "Secundaria",
        type: "high",
    }
]

const shiftOptions = [
    {
        label: 'Todos',
        type: 'all'
    },
    {
        label: 'Mañana',
        type: 'Mañana'
    },
    {
        label: 'Tarde',
        type: 'Tarde'
    }
]

const roleDictionary = {
    teachers: 'Docente',
    tutorship: 'Docente',
    directorate: 'Docente / Directivo'
}

export const List = () => {
    const { pathname } = useLocation()
    const { push } = useHistory();
    const [isLoading, setIsLoading] = useState(true)
    const [isTeacher] = useState(pathname === "/teacherlist")
    const [userCourse, setUserCourse] = useState([])
    const [schoolYears, setSchoolYears] = useState([])
    const [coursesOptions, setCoursesOptions] = useState([]);
    const [values, handleInputChange, setValues] = useForm({
        area: options[0],
        year: (new Date()).getFullYear(),
        age: { label: "Todos", type: "" },
        shift: shiftOptions[0],
    })
    const { counter, increment, decrement, reset } = useCounter(1)
    const [total, setTotal] = useState(1)

    const getUserById = async (id) => {
        const resp = await api.get(`auth/getUser/${id}`)
        return resp.data.user
    }

    const getSchoolYears = async () => {
        const resp = await api.get('/years')
        resp.data.years.forEach(y => setSchoolYears(schoolYears => [...schoolYears, y.year]))
    }

    const getUsers = async (level) => {

        setIsLoading(true)
        const year = values.year
        const age = values.age.type
        const shift = values.shift.type
        const userArr = [];
        const resp = await api.get(`/courses/allCourses/${year}`)
        const { courses } = resp.data

        setCoursesOptions([
            { label: "Todos", type: "" },
            ...courses?.filter(c => c.level.toLowerCase() === level.toLowerCase())
                .reduce((arr, item) => {
                if (!arr.includes(item.age)) {
                    arr.push(`${item.age} - ${item.division}`);
                }
                return arr;
                }, [])
                .map((age) => {
                    return { label: age, type: age };
                })
        ])
        
        const filteredCourses = courses
            .filter(c => c.level.toLowerCase() === level.toLowerCase())
            .filter(c => {
                if (!age) return true
                const [ageCourse, div] = age.split(" - ")
                return c.age === ageCourse && c.division === div
            })
            .filter(c => (shift === 'all') ? true : c.shift === shift)

        reset()
        filteredCourses.forEach(async (course, i) => {
            if (isTeacher) {
                if ( !userArr.some( user => user._id === course.tutorship )) {
                    const tutorshipResp = await getUserById(course.tutorship)
                    userArr.push({
                        ...tutorshipResp,
                        shift: course.shift,
                        age: course.age,
                        division: course.division,
                        subjects: course.subjects
                    })
                }
                await Promise.all(course.subjects.map(async (subject) => {
                    if ( !userArr.some( user => user._id === subject.teacher ) && subject.teacher) {
                        const teacherResp = await getUserById(subject.teacher)
                        userArr.push({
                            ...teacherResp,
                            shift: course.shift,
                            age: course.age,
                            division: course.division,
                        })
                    }
                    return
                }))          
            } else {
                await Promise.all(course.students.map(async (studentId) => {
                    const value = await getUserById(studentId)
                    userArr.push({
                        ...value,
                        shift: course.shift,
                        age: course.age,
                        division: course.division,
                        total: value?.attendances[0]?.attendances?.length ?? 0,
                        studentAcc: value?.attendances[0]?.attendances?.reduce((acc, cur) => acc + cur?.value, 0) ?? 0
                    })
                    return
                }))
            }
            if (filteredCourses.length - 1 === i ) {
                setUserCourse( 
                    isTeacher ?
                        userArr
                            .sort((a, b) => {
                                if(a.lastName < b.lastName) { return -1; }
                                if(a.lastName > b.lastName) { return 1; }
                                return 0;
                            })
                    : userArr
                )
                setIsLoading(false)
            }
        })     
    }

    const onSubmitHandle = () => {
        getUsers(values.area.type)
    }
    
    useEffect(() => {
        getSchoolYears()
    }, [])

    useEffect(() => {
        getUsers(values.area.type)
    }, [values.area.type])

    useEffect(() => {
        setTotal(Math.ceil(userCourse.length/10))
    }, [userCourse])
    

    return (
        <main>
            <Container>
                <Row className='mb-2 pb-2'>
                    <Col xs={12}>
                        <BreadCrumbs
                            lastSteps={[
                                { to: "/home", step: "Inicio" }
                            ]}
                            title={isTeacher ? 'Listado de docentes' : 'Listado de alumnos'} />
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <CustomOptions
                            options={options}
                            value={values.area}
                            setValue={option => setValues({
                                ...values,
                                area: option,
                                age: { label: "Todos", value: "" },
                                shift: shiftOptions[0],
                            })}
                        />
                    </Col>
                </Row>

                <Form
                    className='w-100'
                    onSubmit={onSubmitHandle}
                >
                    <Row className="mt-2 mb-4 d-flex align-items-end">
                        <Col xs={2}>
                            <CustomSelect
                                options={schoolYears.sort((a,b) => b - a)}
                                labelContent={<span className='color-disabled input-label'>Ciclo lectivo</span>}
                                alwaysShow
                                name="year"
                                handleInputChange={handleInputChange}
                                values={values.year}
                            />
                        </Col>
                        <Col xs={2}>
                            <p className="mb-2">
                            {values.area.label === "Inicial"
                                ? "Sala"
                                : values.area.label === "Primaria"
                                ? "Grado"
                                : "Año"}
                            </p>
                            <CustomSelect
                                options={coursesOptions}
                                name="age"
                                values={values.age}
                                handleInputChange={handleInputChange}
                                placeholder="Todos"
                            />
                        </Col>
                        <Col xs={2}>
                            <CustomSelect
                                options={shiftOptions}
                                labelContent={<span className='color-disabled input-label'>Turno</span>}
                                alwaysShow
                                name="shift"
                                handleInputChange={handleInputChange}
                                values={values.shift}
                            />
                        </Col>
                        <Col xs={2}>
                            <Button
                                content="Aplicar"
                                className="button-secondary button-m-sb py-2 px-4"
                                type='submit'
                            />
                        </Col>
                    </Row>
                </Form>

                {isLoading ? <Loading /> :
                    <>
                        <Board
                            className="board-row-color-3"
                            firstRow={
                                <>
                                    <Col xs={3}>
                                        <div className='my-2'>
                                            <span className='body-m-b'>Apellido y nombre</span>
                                        </div>
                                    </Col>
                                    <Col xs={2}>
                                        <div className='my-2'>
                                            <span className='body-m-b'>{isTeacher ? 'Materias' : 'Curso'}</span>
                                        </div>
                                    </Col>
                                    <Col xs={2}>
                                        <div className='my-2'>
                                            <span className='body-m-b'>Turno</span>
                                        </div>
                                    </Col>
                                    <Col xs={2}>
                                        <div className='my-2'>
                                            <span className='body-m-b'>{isTeacher ? 'Roles' : 'Asistencias'}</span>
                                        </div>
                                    </Col>
                                </>
                            }
                            rows={
                                removeDuplicates(userCourse, "_id").slice((counter - 1) * 10, counter * 10).map((usr) =>
                                    <Row
                                        key={usr._id}
                                        className='board__row cursor-pointer py-1'
                                        onClick={() =>
                                            push({
                                                pathname: `/userdetail/${usr._id}`,
                                                state: { type: `${usr.userRole}` },
                                            })
                                        }
                                    >
                                        <Col xs={3}>
                                            <div className='my-2'>
                                                <span className='body-m'> {`${usr.lastName}, ${usr.name}`}</span>
                                            </div>
                                        </Col>
                                        <Col xs={2}>
                                            <div className='my-2'>
                                                <span className='body-m'>
                                                    {
                                                        isTeacher
                                                            ? `${usr.subjects?.length}`
                                                            : `${usr.age} - ${usr.division}`
                                                    }
                                                </span>
                                            </div>
                                        </Col>
                                        <Col xs={2}>
                                            <div className='my-2'>
                                                <span className='body-m'>{usr.shift}</span>
                                            </div>
                                        </Col>
                                        <Col xs={2}>
                                            <div className='my-2'>
                                                <span className='body-m'>
                                                    {
                                                        isTeacher
                                                            ? roleDictionary[usr.userRole]
                                                            : `${usr?.studentAcc} de ${usr?.total}`
                                                    }
                                                </span>
                                            </div>
                                        </Col>
                                        <Col xs={{ span: 2 }} className="mr-2 ml-auto">
                                            <div className='my-2 d-flex justify-content-end'>
                                                {!isTeacher &&
                                                    <Link to={{
                                                        pathname: `/reportcard/${usr._id}`,
                                                        state: { type: `${usr.userRole}` },
                                                    }}
                                                        className=""
                                                    >
                                                        <Button
                                                            icon="bookOpenFA"
                                                            className="button__only-icon-withoutcolor ml-3"
                                                            onClick={e => {
                                                                e.preventDefault();
                                                                push({
                                                                    pathname: `/reportcard/${usr._id}`,
                                                                    state: { type: `${usr.userRole}` },
                                                                })
                                                                e.stopPropagation()
                                                            }} />
                                                    </Link>
                                                }
                                            </div>
                                        </Col>
                                    </Row>
                                )
                            }
                        />
                        <Row className='my-5 pt-2'>
                            <Col xs={11} className="d-flex justify-content-center">
                                <PaginationControll
                                    counter={counter}
                                    increment={increment}
                                    decrement={decrement}
                                    totalPages={total}
                                />
                            </Col>
                        </Row>
                    </>
                }
            </Container>
        </main >
    )
}
