import moment from "moment"
import { RadioButton } from "primereact/radiobutton"
import { Col, Row } from "react-bootstrap"
import { CustomSelect } from "../../CustomSelect"
import { ScrollableBoard } from "./ScrollableBoard"

const monthOptions = [
    { label: 'Enero', type: 0, },
    { label: 'Febrero', type: 1 },
    { label: 'Marzo', type: 2 },
    { label: 'Abril', type: 3 },
    { label: 'Mayo', type: 4 },
    { label: 'Junio', type: 5 },
    { label: 'Julio', type: 6 },
    { label: 'Agosto', type: 7 },
    { label: 'Septiembre', type: 8 },
    { label: 'Octubre', type: 9 },
    { label: 'Noviembre', type: 10 },
    { label: 'Diciembre', type: 11 }
]

export const HistoricAttendance = ({ course, month, setMonth, handleCheckedAttendanceHistoric }) => {

    return (
        <>
            <Row>
                <Col xs={2} className="py-4">
                    <CustomSelect
                        labelContent={<span className='color-disabled input-label'>Mes</span>}
                        alwaysShow
                        placeholder="Febrero"
                        options={monthOptions}
                        name='month'
                        handleInputChange={e => setMonth(e.target.value)}
                        values={month}
                    />
                </Col>
            </Row>
            <ScrollableBoard
                className="board-row-color-3 my-3"

                fixedColumnXs={3}
                scrollableColumnXs={9}

                fixedColumnHeader={
                    <Col>
                        <div className='my-2'>
                            <span className='body-m-b'>Apellido y nombre</span>
                        </div>
                    </Col>
                }
                scrollableColumnHeader={
                    <Col className="d-flex">
                        {new Array(moment().month(month.type).daysInMonth()).fill(1)?.map((cur, i) => cur + i)?.map(date =>
                            <div key={date} className="d-flex justify-content-center align-items-center scrollable-item py-3">
                                <span className='body-m-b'>{date}/{month.type + 1}</span>
                            </div>
                        )}
                    </Col>

                }

                fixedColumnBody={
                    course.students?.map((usr) =>
                        <Row
                            key={usr._id}
                            className='board__row board__left-row'
                        >
                            <Col>
                                <div className='my-2 py-1'>
                                    <span className='body-m-regular'> {`${usr.lastName}, ${usr.name}`}</span>
                                </div>
                            </Col>
                        </Row>
                    )
                }
                scrollableColumnBody={
                    course.students?.map((usr) =>
                        <Row
                            key={usr._id}
                            className='board__row width-max-content board__right-row'
                        >
                            <Col className="d-flex">
                                {new Array(moment().month(month.type).daysInMonth())?.fill(1)?.map((cur, i) => cur + i)?.map(day =>
                                    <div key={day} className="d-flex justify-content-center align-items-center scrollable-item py-1">
                                        <RadioButton
                                            disabled
                                            className='radio-button'
                                            value='Presente'
                                            name="RadioButtonCheckAttendance"
                                            checked={handleCheckedAttendanceHistoric(usr._id, day)}
                                        />
                                    </div>
                                )}
                            </Col>
                        </Row>
                    )}

            />

        </>

    )
}
