import { useEffect, useState } from "react"
import { Col, Container, Row } from "react-bootstrap"
import { useParams } from "react-router-dom"

import { CourseInformationForm } from "./CourseInformationForm"
import { StudentSubjectList } from "./StudentsSubjectList"

import { api } from "../../../helpers/fetch"
import { useForm } from "../../../hooks/useForm"
import { BreadCrumbs } from "../../BreadCrumbs"
import { CustomOptions } from "../../CustomOptions"
import { Loading } from "../../Loading"
import { Subjects } from "../subjects/Subjects"
import { CourseInformationDisplay } from "./CourseInformationDisplay"
import { useAuth } from "../../../hooks/useAuth"


export const CourseDetail = () => {
    const { user } = useAuth()
    const { id } = useParams();

    const [loading, setLoading] = useState(true);

    const courseDetailOption = [{
        label: "Información",
        type: "Information",
    },
    {
        label: "Materias",
        type: "Subjects",
    },
    {
        label: "Alumnos",
        type: "Students",
    }]

    const courseDetailOptionTeacher = [{
        label: "Información",
        type: "Information",
    },
    {
        label: "Alumnos",
        type: "Students",
    }]

    const [courseDetail, setCourseDetail] = useState((user.userRole === 'teachers' || user?.userRole === 'tutorship' ) ? courseDetailOptionTeacher[0] : courseDetailOption[0]);
    const [courseAttendances, setCourseAttendances] = useState({})
    const [totalAttendances, setTotalAttendances] = useState(0)

    //--------- Course Info --------------
    const [values, handleInputChange, setValues] = useForm({
        _id: "",
        level: "",
        age: "",
        division: "",
        shift: "",
        tutorship: {},
        signatures: [],
        students: [],
    })

    const getCourseDetail = async (courseId) => {
        await api.get(`courses/getById/${courseId}`).then(res => {
            setValues(res.data.course)
        })
    }

    const getCourseAttendances = async (courseId) => {
        const res = await api.get(`attendance/${courseId}`)
        setCourseAttendances(res.data?.course)
        setLoading(false)
    }

    const getStudentAttendancesById = (studentId) => {
        return courseAttendances.students
            ?.find(student => student._id === studentId)
            ?.attendances[0]
            ?.attendances
            ?.reduce((acc, cur) => acc + cur?.value, 0) ?? 0
    }

    const getTotalAttendances = () => {
        if (courseAttendances.students?.length)
            setTotalAttendances(courseAttendances?.students[0]?.attendances[0]?.attendances?.length ?? 0)
    }

    useEffect(() => {
        getCourseDetail(id)
        getCourseAttendances(id)
    }, [id])

    useEffect(() => {
        getTotalAttendances()
    }, [courseAttendances])

    let selectedTabContent
    if (user.userRole === 'teachers' || user?.userRole === 'tutorship') {
        switch (courseDetail.type) {
            case 'Information':
                selectedTabContent = <CourseInformationDisplay courseInfo={values} teacherInfo={user} />
                break
            case 'Students':
                selectedTabContent = <StudentSubjectList id={id} courseInfo={values} role={user.userRole} totalAttendances={totalAttendances} getStudentAttendancesById={getStudentAttendancesById} />
                break
            default:
                break
        }
    } else if (user.userRole === 'directorate') {
        switch (courseDetail.type) {
            case 'Information':
                selectedTabContent = <CourseInformationDisplay courseInfo={values} teacherInfo={user} />
                break;
            case 'Subjects':
                selectedTabContent = <Subjects breadCrumbsVisible={false} showFabicon={true} faviconTo={`/courses/newsubject/${id}`} courseId={id} />
                break
            case 'Students':
                selectedTabContent = <StudentSubjectList id={id} courseInfo={values} totalAttendances={totalAttendances} getStudentAttendancesById={getStudentAttendancesById} role={user.userRole} />
            default: 
                break;
        }
    } else {
        switch (courseDetail.type) {
            case 'Information':
                selectedTabContent = <CourseInformationForm id={id} courseInfo={values} />
                break
            case 'Subjects':
                selectedTabContent = <Subjects breadCrumbsVisible={false} showFabicon={true} faviconTo={`/courses/newsubject/${id}`} courseId={id} />
                break
            case 'Students':
                selectedTabContent = <StudentSubjectList id={id} courseInfo={values} totalAttendances={totalAttendances} getStudentAttendancesById={getStudentAttendancesById} />
                break
            default:
                break
        }
    }

    return (
        <>
            {loading ? <Loading /> :
                <Container className='mb-2 pb-2'>
                    <Row>
                        <Col xs={12}>
                            <BreadCrumbs
                                lastSteps={[
                                    { to: "/home", step: "Inicio" },
                                    { to: "/courses", step: "Cursos" }
                                ]}
                                title={`${values.age} ${values.division} - ${values.shift}`}
                            />
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col xs={12}>
                            <CustomOptions
                                options={(user.userRole === 'teachers' || user?.userRole === 'tutorship') ? courseDetailOptionTeacher : courseDetailOption}
                                value={courseDetail}
                                setValue={setCourseDetail}
                            />
                        </Col>
                    </Row>

                    {selectedTabContent}
                </Container >
            }
        </>
    )
}