import { types } from "../types/types";

const initialState = null;

export const usersReducer = ( state = initialState, action) => {
    
    switch ( action.type ) {
        case types.getUsers:
            return action.payload
        default:
            return state;
    }

};
