import React from 'react'
import { Row } from 'react-bootstrap'

export const Board = ({ rows, firstRow, className = "", navButtons, navFunctionRight, navFunctionLeft, bottomButtons }) => {
    return (
        <div className={'board__container px-3 ' + className}>
            <Row className='board__first-row py-2'>
                {firstRow}
            </Row>
            {rows}
        </div>
    )
}
