import classNames from "classnames"
import { Col, Row } from "react-bootstrap"

export const SubjectEvaluativeCriteriaItem = ({ role, name, handleInputChange, entrie, editable = false, value, property, onChangeHandle, label = 'Descripción del criterio' }) => {
    return (
        <>
            {entrie &&
                <Row>
                    <Col>
                        <p className="body-l-sb mb-4 color-header-cartelera">{entrie}</p>
                    </Col>
                </Row>
            }
            <Row>
                <Col md={6} xs={12}>
                    {
                        role === 'students' ?
                            <div className="mb-3 color-inputs" >{value}</div>
                            :
                            <div className="d-flex flex-column mb-4 subjectCriteria__input">

                                <label className="mt-3 ml-3 color-inputs input-label" htmlFor="textarea-1">{label}</label>
                                <textarea
                                    id="textarea-1"
                                    className={classNames("mx-3 mb-2 body-l bg-transparent", { "color-disabled": !editable })}
                                    cols="50" rows="3"
                                    name={name}
                                    disabled={!editable}
                                    defaultValue={value}

                                    onChange={handleInputChange}
                                />
                            </div>

                    }
                </Col>
            </Row>
        </>
    )
}
