import React from "react";
import classNames from "classnames";

export const UserSelector = ({ 
    id,
    name = "", 
    values, 
    active, 
    last, 
    onMouseEnter, 
    selectUser,
    close
}) => {
    const customName = name.toLowerCase().includes(
                          values.toLowerCase()) ? 
                              [...name].map((e, i) => 
                                  <span 
                                      key={e + i} 
                                      className={
                                          classNames({ 
                                              "body-m-sb": values.toLowerCase().includes(e.toLowerCase()), 
                                              "body-m": !values.includes(e) 
                                          })
                                      }
                                  >
                                      {e}
                                  </span>) : name

    return (
        <div
          role="button"
          onClick={() => {
              selectUser(id);
              close()
            }}
          onMouseEnter={onMouseEnter}
          className={classNames(
              "searcher__item my-2 w-100 px-3 py-2",
              { active: active },
              { last: last }
          )}
        >
            <p>{customName}</p>
        </div>
  );
};
