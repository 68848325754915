import { faFolder } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Offcanvas } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { createLog } from "../../../actions/logs";
import { api } from "../../../helpers/fetch";
import { useForm } from "../../../hooks/useForm";
import { Button } from "../../Button";
import { CustomInput } from "../../CustomInput";
import { File } from "../../File";
import { Line } from "../../Line";

const SubjectStudentAside = ({ auth, activity, bimester, id }) => {
    const [show, setShow] = useState(false);

    const [values, handleInputChange, setValues] = useForm({
        student: auth.user._id,
        activity: activity._id,
        comment: "",
        urlDoc: [{
            url: "",
            docName: ""
        }],
        bimester: bimester,
        isQualify: false,
        subject: id
    })

    const handleClose = () => setShow(false)
    const handleShow = () => setShow(true)

    const [resources, setResources] = useState([])
    const [resourcesInput, setResourcesProgramInput] = useState({ url: "", docName: "" })
    const [addResourse, setAddResource] = useState(false)
    const dispatch = useDispatch();

    const handleAddResourceDocument = () => {
        setAddResource(true)
    }

    const handleResourceChange = ({ target }) => {
        setResourcesProgramInput({
            ...resourcesInput,
            [target.name]: target.value
        })
    }

    const handleCancelSubmitResource = () => { setAddResource(false) }

    const handleSubmitResource = async () => {
        const data = { resources: resources.concat([resourcesInput]) }
        setAddResource(false)
        setResourcesProgramInput({ url: "", docName: "" })
        setResources(data.resources)
    }

    const resourcesFileOptions = [{
        name: 'Borrar',
        icon: 'pi pi-trash',
        action: (file, fileId) => {
            const resoursesFiltered = resources.filter((resource, index) => fileId !== index)
            setResources(resoursesFiltered)
        }
    }]

    const getActivity = async () => {
        try {
            const data = await api.get(`activity-deliveriesbystudent/${auth.user._id}`)
            const act = data.data.filter(aux => aux.activity === activity._id)

            if (act.length) {
                setValues(act[0])
                setResources(act[0].urlDoc)
            }
        } catch (error) {
            console.log(error)
        }
    }

    const onSubmitEvent = async () => {
        try {
            const res = await api.post('activity-deliveries', values)
            if (res.status === 201) {
                dispatch(createLog({
                    type: "success",
                    title: "Envio exitoso",
                    text: `Los datos se enviaron correctamente`
                }))
            }

        } catch (error) {
            dispatch(createLog({
                type: "error",
                title: "No se pudo enviar",
                text: `Intente mas tarde`
            }))
        }
    }

    const [edit, setEdit] = useState(false)

    const dayActivityEdit = () => {
        const actualDay = new Date().toISOString()
        const day = activity.expDateTime
        if (actualDay < day )
            setEdit(true)
    }

    useEffect(() => {
        getActivity()
        dayActivityEdit()
    }, [])

    useEffect(() => {
        setValues({
            ...values,
            urlDoc: resources
        })
    }, [resources])

    return (
        <>
            <div className={show ? 'back-opacity' : 'd-none'} />
            <Button
                type='button'
                content={<span className='ml-1 mt-1'>Entregar actividad</span>}
                className='mr-5 btn button-primary button-m-sb'
                onClick={handleShow}
                disabled={!edit}
            />
            <Offcanvas show={show} onHide={handleClose} className='sidenav mt-5 p-5'>
                <Offcanvas.Header className="mt-5 mb-4 d-flex">
                    <Offcanvas.Title className="aside-canvas" >Entregar actividad</Offcanvas.Title>
                    <Button
                        icon='closeMUI'
                        onClick={handleClose}
                        className='border-disabled' />
                </Offcanvas.Header>
                <Offcanvas.Body>

                    <div className="d-flex align-items-center">
                        <FontAwesomeIcon icon={faFolder} />
                        <h4 className="m-0 ml-2">Documentos</h4>
                    </div>

                    {resources?.map((resource, i) =>

                        <div className="my-4" key={resource._id}>
                            <File
                                fileId={i}
                                file={{ name: resource.url }}
                                url={resource.docName}
                                options={resourcesFileOptions}
                            />
                        </div>

                    )}
                    {(!addResourse) &&

                        <Button
                            icon="plusMUI"
                            content={<span className="ml-1 mt-1">Agregar documento</span>}
                            className=" button-terciary button-s-sb d-flex align-items-center mt-5 p-0 "
                            iconRight={false}
                            type="button"
                            onClick={handleAddResourceDocument} />
                    }
                    {addResourse &&
                        <>
                            <div className="mb-4 mt-3">
                                <CustomInput
                                    classNameInput={'w-100'}
                                    classNameLabel={''}
                                    handleInputChange={handleResourceChange}
                                    name="url"
                                    value={resourcesInput.url}
                                    placeholder="Nombre del documento"
                                />
                            </div>
                            <CustomInput
                                classNameInput={'w-100'}
                                classNameLabel={''}
                                handleInputChange={handleResourceChange}
                                name="docName"
                                value={resourcesInput.docName}
                                placeholder="Url del documento"
                            />

                            <div className="d-flex mt-3 mb-2">
                                <Button
                                    type="button"
                                    className=" button-terciary button-s-sb d-flex align-items-center ml-2 mr-5 p-0 "
                                    content={<span className="ml-1 mt-1">Cancelar</span>}
                                    iconRight={false}
                                    onClick={handleCancelSubmitResource} />
                                <Button
                                    type="button"
                                    className='btn button-primary color-primary button-m-sb mr-5'
                                    content={<span className="ml-1 mt-1">Guardar</span>}
                                    onClick={handleSubmitResource}
                                    disabled={resourcesInput.url && resourcesInput.docName ? false : true} />
                            </div>
                        </>
                    }

                    <Line mt='4' mb='4' />
                    <textarea
                        className="newmessages__textarea mt-2 body-l w-100 p-3"
                        cols="40" rows="3"
                        placeholder="Comentarios de la actividad"
                        value={values.comment}
                        name='comment'
                        onChange={handleInputChange}
                    />
                    <Line mt='4' />
                    <div className="d-flex justify-content-around mt-4">
                        <Button
                            content='Cancelar'
                            onClick={handleClose}
                            className='button-terciary btn button-m-sb'
                        />
                        <Button
                            content='Entregar actividad'
                            onClick={() => (onSubmitEvent(), handleClose())}
                            className='btn button-primary button-m-sb'
                        />
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    )
}
export default SubjectStudentAside