import { types } from "../types/types";

const initialState = '';

export const scopesReducer = ( state = initialState, action) => {
    
    switch ( action.type ) {
        case types.setScope:
            return action.payload
        default:
            return state;
    }

};
