import React, { useState } from 'react'

export const CustomInput = ({
    labelContent = "",
    classNameInput = "",
    classNameLabel = "",
    name = "",
    value = "",
    handleInputChange = function() {},
    placeholder = "",
    hidden = false,
    generalClassName = "",
    labelContentDown = "",
    disabled = false,
    alwaysShow = false,
    type = "",
    checked = false
}) => {

    const [showLabel, setShowLabel] = useState(false);

    return (
        <div className={'position-relative custom-input ' + generalClassName}>
            {(showLabel || value !== "" || alwaysShow) &&
                <label
                    htmlFor={name}
                    className={classNameLabel + " label-up"}
                    hidden={hidden}
                >{labelContent}</label>
            }
            <input
                type={type}
                value={value}
                onChange={handleInputChange}
                placeholder={placeholder || labelContent}
                name={name}
                className={classNameInput}
                onFocus={(e) => { setShowLabel(true) }}
                onBlur={(e) => { setShowLabel(false) }}
                disabled={disabled}
                checked={checked}
            />
            {(showLabel || value !== "") && labelContentDown &&
                <label
                    htmlFor={name}
                    className={classNameLabel + " input-label  label-down color-primary"}
                    hidden={hidden}
                >{labelContentDown}</label>
            }
        </div>
    )
}
