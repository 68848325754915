import { Col, Row } from "react-bootstrap"
import { Button } from "../../Button"
import { CustomSelect } from "../../CustomSelect"
import { Line } from "../../Line"
import { SubjectEvaluativeCriteriaItem } from "./SubjectEvaluativeCriteriaItem"

const conceptualReportsTitles = [
    { name: 'firstCriteria', title: 'Participación en clases y/o reuniones virtuales.' },
    { name: 'secondCriteria', title: 'Entrega de trabajos solicitados.' },
    { name: 'thirdCriteria', title: 'Interacción con los compañeros y docentes en linea.' },
    { name: 'fourthCriteria', title: 'Comprensión de los contenidos específicos de la asignatura.' }
]
const assessmentCriteriaReviewOptions = [
    { type: 'inProcess', label: 'En proceso' },
    { type: 'satisfactory', label: 'Satisfactorio' },
    { type: 'advanced', label: 'Avanzado' }
]

export const SubjectStudentConceptualReport = ({ period, criteria, handleInputChangeObject, handleSaveClick, handleCancelClick, handleEditClick, role, edit }) => {

    return (
        <Row>
            <Col className='mx-6'>
                {
                    role === 'students' &&
                    <>
                        <p className="m-0 mb-4 mx-2 color-primary body-l">Nota conceptual del {period.name}: <strong>{criteria.find(criterion => criterion?.bimester === period.bimester)?.assessmentCriteriaReview}</strong></p>
                        <Line mb={4} />
                    </>
                }
                <div className="mx-2 mt-1 subjectCriteria__container">
                    {conceptualReportsTitles.map((report, i) => <div key={report.title}>
                        <SubjectEvaluativeCriteriaItem
                            editable={edit}
                            entrie={report.title}
                            value={criteria.find(criterion => criterion?.bimester === period.bimester)?.[report.name] ?? ''}
                            handleInputChange={(e) => handleInputChangeObject(period.bimester, report.name, e.target.value)}
                            role={role}
                        />
                        <Line mb={4} />
                    </div>)}
                </div>
                {
                    role !== 'students' &&
                    <div className="mx-2 mb-2">
                        <h5 className="m-0 mb-3">Nota conceptual del {period.name}</h5>
                        <Row>
                            <Col xs={3} >
                                <CustomSelect
                                    labelContent={<>
                                        <span className='color-error input-label'>* </span>
                                        <span className='color-disabled input-label'>Nota de concepto</span>
                                    </>}
                                    className="my-4 pb-4"
                                    alwaysShow
                                    disabled={!edit}
                                    options={assessmentCriteriaReviewOptions}
                                    handleInputChange={(e) => handleInputChangeObject(period.bimester, 'assessmentCriteriaReview', e.target.value?.type)}
                                    values={assessmentCriteriaReviewOptions.find(crt =>
                                        criteria.find(criterion => criterion?.bimester === period.bimester)?.assessmentCriteriaReview === crt.type
                                    )}
                                />
                            </Col>
                        </Row>
                        <Line mb={2} />
                        <div className="d-flex justify-content-end my-4">
                            {
                                edit ?
                                    <>
                                        <Button
                                            className="button-quaternary body-m-sb"
                                            content="Cancelar"
                                            onClick={() => { handleCancelClick() }}
                                        />
                                        <Button
                                            className="button-primary body-m-sb ml-4"
                                            content="Guardar"
                                            onClick={() => { handleSaveClick(); handleCancelClick() }}
                                        />

                                    </>
                                    :
                                    <Button
                                            className="button-primary body-m-sb ml-4"
                                            content="Editar"
                                            onClick={() => { handleEditClick() }}
                                        />
                            }
                        </div>
                    </div>
                }
            </Col>
        </Row>
    )
}
