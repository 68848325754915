import React from 'react'
import { Col, Row } from 'react-bootstrap'
import img from "../Imgs/gifs/animation_500_l1as1n8e.gif"

export const Loading = () => {
    return (
        <Row>
            <Col xs={12} className="justify-content-center d-flex" >
                <img src={img} alt="Por favor espere..." height="50%" />
            </Col>
        </Row>
    )
}
