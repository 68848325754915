import { api, clearToken, setToken } from "../helpers/fetch";
import { types } from "../types/types";
import { createLog } from "./logs";

export const startLoginWithGoogle = (token) => {
    return async (dispatch, getState) => {

        const { data } = await api.post("auth", { token })
        const body = data;
        
        if (body.ok) {
            if( body.user.email !== process.env.REACT_APP_ADMIN_EMAIL ) {
                setToken(body.jwt);
                const user = {
                    user: {
                        uid: body.user._id,
                        ...body.user
                    },
                }
                const msg = {
                    title: "Bienvenido!",
                    text: "Has ingresado correctamente..."
                }
                dispatch(login(user));
                dispatch(
                    createLog({
                        type: "success",
                        ...msg,
                    })
                );
            } else {
                const user = {
                    adminUser: {
                        uid: body.user._id,
                        jwt: body.jwt,
                        ...body.user
                    },
                }
                const msg = {
                    title: "Excelente!",
                    text: !getState().auth.user?.uid ? "Ya tienes acceso con tu otra cuenta..." : "Has ingresado correctamente..."
                }
                dispatch(login(user));
                dispatch(
                    createLog({
                        type: "success",
                        ...msg,
                    })
                );
            }
        } else {
            dispatch(
                createLog({
                    type: "error",
                    title: "Lo sentimos!",
                    text: body.msg,
                })
            );
        }
    };
};

export const revalidateSession = () => {
    return async (dispatch, getState) => {
        const {data} = await api.post("auth/revalidate-session");
        const body = data;

        if (body.ok) {
            if( body.user.email !== process.env.REACT_APP_ADMIN_EMAIL ) {
                const user = {
                    user: {
                        uid: body.user._id,
                        ...body.user
                    },
                }
                const msg = {
                    title: "Bienvenido!",
                    text: "Has ingresado correctamente..."
                }
                dispatch(login(user));
                dispatch(
                    createLog({
                        type: "success",
                        ...msg,
                    })
                );
            } else {
                const user = {
                    adminUser: {
                        uid: body.user._id,
                        jwt: body.jwt,
                        ...body.user
                    },
                }
                const msg = {
                    title: "Excelente!",
                    text: !getState().auth.user?.uid ? "Ya tienes acceso con tu otra cuenta..." : "Has ingresado correctamente..."
                }
                dispatch(login(user));
                dispatch(
                    createLog({
                        type: "success",
                        ...msg,
                    })
                );
            }
        } else {
            dispatch(logout());
            dispatch(
                createLog({
                    type: "info",
                    title: "Iniciar sesión",
                    text: body.msg,
                })
            );
        }
    };
};

const login = (user) => ({
    type: types.authLogin,
    payload: user,
});

export const startLogout = () => {
    return async (dispatch) => {
        if (localStorage.getItem('token')) await api.post("auth/logout");
        clearToken()
        dispatch(logout());
    };
};

const logout = () => ({
    type: types.authLogout,
});
