import classNames from 'classnames';
import useComponentVisible from '../hooks/useComponentVisible'
import { ListBox } from 'primereact/listbox';
import { Button } from './Button';

export const DropDown = ({ options, onClick, onChange }) => {
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false)

  const trigger = (option) => {
    option.action && option.action()
  }

  const _onClick = (e) => {
    onClick && onClick(e)
    setIsComponentVisible(!isComponentVisible)
  }

  const _onChange = (e) => {
    (onChange || trigger)(e.value)
    setIsComponentVisible(false)
  }

  return <div className='position-relative' ref={ref} >
    <Button className='ilp-dropdown-button' label='Options' icon='moreVert' onClick={_onClick} />
    <div className={classNames('ilp-dropdown position-absolute', { 'show': isComponentVisible })}>
      <ListBox options={options} onChange={_onChange} optionLabel="name" style={{ width: '15rem' }} itemTemplate={(option) => {
        return (
          <div className="list-item">
            <div className="list-item-content d-flex">
              <div className="list-item-icon mr-2">
                <i className={`pi ${option.icon}`}></i>
              </div>
              <div className="list-item-text">
                {option.name}
              </div>
            </div>
          </div>
        )
      }} />
    </div>
  </div>
}