import { BreadCrumbs } from "../../BreadCrumbs"
import { Col, Container, Row } from "react-bootstrap"
import { useForm } from "../../../hooks/useForm"
import { CustomOptions } from "../../CustomOptions"
import moment from "moment"
import { useEffect } from "react"
import { SubjectInfoText } from "../subjects/SubjectInfoText"
import { Line } from "../../Line"
import { Loading } from "../../Loading"
import { useParams } from "react-router-dom"
import { api } from "../../../helpers/fetch"
import { useState } from "react"
import { HistoricAttendance } from "./HistoricAttendance"
import { TodaysAttendance } from "./TodaysAttendance"
import { useDispatch } from "react-redux"
import { createLog } from "../../../actions/logs"

const options = [
    { label: 'Asistencia del día', type: 'todaysAttendance' },
    { label: 'Asistencia histórica', type: 'historicAttendance' }
]

const levelDictionary = (level) => {
    switch (level?.toLowerCase()) {
        case 'elementary': return 'Inicial'
        case 'primary': return 'Primaria'
        case 'high': return 'Secundaria'
        default: return
    }
}

const capitalize = (phrase) => `${phrase[0].toUpperCase()}${phrase.slice(1)}`

const initialMonth = {
    label: capitalize(moment().format('MMMM')),
    type: parseInt(moment().format('MM')) - 1
}

export const Attendance = () => {
    const [values, handleInputChange, setValues] = useForm({
        date: moment().format('YYYY-MM-DD')
    })

    const { id: courseId } = useParams()
    const [course, setCourse] = useState({})
    const [isLoading, setIsLoading] = useState(true)
    const [selectedTab, setSelectedTab] = useState(options[0])
    const [month, setMonth] = useState(initialMonth)
    const [attendances, setAttendances] = useState([])
    const dispatch = useDispatch();


    const getCourseAttendances = async () => {
        const res = await api.get(`attendance/${courseId}`)
        setCourse(res.data?.course)
        setIsLoading(false)
    }

    const getDayAttendance = () => {
        const attendancesArray = course.students?.map(student => ({
            studentId: student._id,
            attendance: student.attendances[0]?.attendances?.find(att => moment(att.date, 'YYYY-MM-DD').format('YYYY-MM-DD') === values.date)
        }))
        setAttendances(attendancesArray)
    }

    const handleCheckChange = async ({ studentId, value, type, extraValue }) => {
        const oldAttendance = attendances?.find(att => att.studentId === studentId)
        const filteredAttendances = attendances?.filter(att => att.studentId !== studentId)

        if(type === undefined && value === 1)
            type = 'faceToFace'
        else if (value === 0)
            type = 'unexcusedAbsence'

        const updatedAttendance = {
            studentId,
            attendance: {
                date: values.date + 'T00:00:00.000Z',
                type: type ?? oldAttendance?.attendance?.type,
                value: value ?? oldAttendance?.attendance?.value,
                extraValue: extraValue ?? oldAttendance?.attendance?.extraValue
            }
        }
        setAttendances([...filteredAttendances, updatedAttendance])

        if (updatedAttendance.attendance.type && typeof updatedAttendance.attendance.value === 'number'){
            try {
                await api.post(`attendance/${courseId}/${studentId}`, updatedAttendance.attendance)
            } catch(err) {
                dispatch(
                    createLog({
                        type: "error",
                        title: "No se guardo la asistencia",
                    })
                )
            }
        }

    }

    const handleCheckedAttendanceHistoric = (studentId, day) => {
        const currentDay = moment().set({ 'month': month?.type, 'date': day }).format('YYYY-MM-DD')
        const currentStudent = course?.students?.find(student => student._id === studentId)

        return currentStudent?.attendances[0]?.attendances
            ?.find(att => moment(att.date, 'YYYY-MM-DD').format('YYYY-MM-DD') === currentDay)?.value === 1
    }

    useEffect(() => {
        getCourseAttendances()
    }, [courseId, selectedTab])

    useEffect(() => {
        getDayAttendance()
    }, [course, values.date])

    return (
        <>
            {isLoading ? <Loading /> :
                <main>
                    <Container>
                        <Row>
                            <Col xs={12}>
                                <BreadCrumbs
                                    lastSteps={[
                                        { to: "/home", step: "Inicio" },
                                        { to: "/courses", step: "Cursos" },
                                        { to: `/courses/detail/${courseId}`, step: `${course.age ?? ''} ${course.division ?? ''} - ${course.shift ?? ''}` },
                                    ]}
                                    title="Asistencia"
                                />
                            </Col>
                        </Row>

                        <Row>
                            <Col xs={12}>
                                <CustomOptions
                                    options={options}
                                    value={selectedTab}
                                    setValue={setSelectedTab}
                                />
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <h3 className="mt-2 mb-4">
                                    {capitalize(moment(values.date).format('dddd'))} {moment(values.date).format('D')} de {moment(values.date).format('MMMM')}
                                </h3>
                            </Col>
                        </Row>

                        <Row className="mb-4">
                            <Col xs={3}><SubjectInfoText name="Curso" values={[course.age ?? '']} /></Col>
                            <Col xs={3}><SubjectInfoText name="Turno" values={[course.shift ?? '']} /></Col>
                            <Col xs={3}><SubjectInfoText name="Nivel" values={[levelDictionary(course.level)]} /></Col>
                        </Row>

                        <Line mb={3} />

                        {
                            selectedTab.type === 'todaysAttendance'
                                ? <TodaysAttendance {...{ course, values, handleInputChange, attendances, handleCheckChange }} />
                                : <HistoricAttendance {...{ course, month, setMonth, handleCheckedAttendanceHistoric }} />
                        }
                    </Container>
                </main >
            }
        </>
    )
}