import React, { useEffect, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom';
import { Col, Container, Row } from 'react-bootstrap'
import { BreadCrumbs } from '../../BreadCrumbs'
import { Button } from "../../Button"
import { useForm } from "../../../hooks/useForm"
import classNames from 'classnames'
import { CustomInput } from '../../CustomInput'
import { CustomSwitch } from '../../CustomSwitch'
import { api } from '../../../helpers/fetch'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import { createLog } from '../../../actions/logs'
import { UserSearcher } from '../../UserSearcher/UserSearcher';


export const EventForm = () => {

    const now = moment()
    const [mailFor, setMailFor] = useState([]);
    const { user } = useSelector(state => state.auth);
    const { uid } = user;
    const [values, handleInputChange, setValues] = useForm({
        title: "",
        type: "EVENT",
        date: now,
        fromHour: now,
        toHour: now,
        allDay: false,
        guestList: [],
        owner: uid,
        invitedAreas: [],
        description: "",
        isGlobalEvent: false,
    });
    const [showOptions, setShowOptions] = useState(false);
    const { id } = useParams();
    const { goBack } = useHistory();

    const hoursOptions = ["0 am", "1 am", "2 am", "3 am", "4 am", "5 am", "6 am", "7 am", "8 am", "9 am", "10 am", "11 am", "12 pm", "13 pm", "14 pm", "15 pm", "16 pm", "17 pm", "18 pm", "19 pm", "20 pm", "21 pm", "22 pm", "23 pm"];
    const dispatch = useDispatch();

    const onsubmitCancel = (e) => {
        e.preventDefault();
        goBack();
    };

    const onSubmitEvent = async (e) => {
        e.preventDefault();
        if (id) {
            api.put(`events/${id}`, values)
                .then(resp => {
                    if (resp.status === 200) {
                        dispatch(createLog({
                            type: "success",
                            title: "Evento editado",
                        }))
                        goBack();
                    } else {
                        dispatch(createLog({
                            type: "error",
                            title: "Lo sentimos!",
                        }))
                    }
                })

        } else {
            api.post("events", values)
                .then(resp => {
                    if (resp.status === 200) {
                        dispatch(createLog({
                            type: "success",
                            title: "Evento creado",
                        }))
                        goBack();
                    } else {
                        dispatch(createLog({
                            type: "error",
                            title: "Lo sentimos!",
                        }))
                    }
                })
        }
    }

    useEffect(() => {
        if (id) {
            api.get(`events/${id}`)
                .then(resp => {
                    setValues(resp.data)
                    setUserArr(resp.data.guestList)
                })
                .catch(() => {
                    dispatch(
                        createLog({
                            type: "info",
                            title: "Lo sentimos",
                            text: "Algo fue mal...",
                        })
                    )
                })
        }
    }, [dispatch, id, setValues])



    const deleteMail = (e, type) => {
        setMailFor(mailFor.filter(mail =>
            mail !== e
        ))
        setUserArr(userArr.filter(id =>
            id !== e.id
        ))
    }

    const [users, setUsers] = useState([{}])
    const [userArr, setUserArr] = useState([])

    const getUsers = () => {
        api.get("auth/getAllUsers").then(res => {
            setUsers(res.data.users)
            
            const userArray = []
            userArr.forEach(us => {
                const data = res.data.users.find(aux => aux.id === us)
                userArray.push(data)
            })
            setMailFor(userArray)
        })
    }
    
    const addUsers = (_id) => {
        const addUser = users.find(user => user.id === _id)
        setMailFor([...mailFor, addUser])
        setUserArr([...userArr, addUser.id])
    }

    useEffect(() => {
        getUsers()
        setValues({...values, guestList: userArr})
    }, [userArr])

    return (
        <main>
            <Container>
                <Row className='mb-4 pb-2'>
                    <Col xs={12}>
                        <BreadCrumbs
                            lastSteps={[{ to: "/home", step: "Inicio" }, { to: "/calendar", step: "Calendario" }]}
                            title="Nuevo evento" />
                    </Col>
                </Row>
                <div className='newevent__form mb-6'>
                    <form>
                        <Row className="my-5">
                            <Col xs={{ span: 4, offset: 1 }}>
                                <CustomInput
                                    labelContent="Título del evento"
                                    name="title"
                                    hidden={false}
                                    value={values.title}
                                    handleInputChange={handleInputChange}
                                    placerholder="Título del evento"
                                    classNamesInput='w-100 body-l'
                                    classNamesLabel='body-s' />
                            </Col>
                        </Row>
                        <Row className="mb-5">
                            <Col xs={{ span: 12, offset: 1 }}>
                                <Button
                                    type="button"
                                    content="Evento"
                                    className={classNames(" px-3 py-2 body-m-b mr-4", { "button-default": values.type === "EVENT", "button__only-icon-hover color-text  ": values.type !== "EVENT" })}
                                    onClick={() => setValues({ ...values, type: "EVENT" })} />
                                <Button
                                    type="button"
                                    content="Recordatorio"
                                    className={classNames(" px-3 py-2 body-m-b ", { "button-default": values.type === "REMINDER", " button__only-icon-hover color-text ": values.type !== "REMINDER" })}
                                    onClick={() => setValues({ ...values, type: "REMINDER" })} />
                            </Col>
                        </Row>
                        <Row className="mb-2 align-items-end">
                            <Col xs={{ span: 3, offset: 1 }}>
                                <label className="input-label mb-0 mr-2">Desde</label>
                                <input
                                    type="date"
                                    onChange={e => {
                                        setValues({
                                            ...values,
                                            date: moment(e.target.value),
                                            fromHour: moment(e.target.value)
                                        })
                                    }}
                                    value={moment(values.fromHour).format("yyyy-MM-DD")}
                                    className='select w-100 p-1'
                                />
                            </Col>
                            <Col xs={{ span: 3, offset: 1 }} className="d-flex flex-direction-row flex-nowrap">
                                <div className='d-flex flex-direction-row flex-nowrap align-items-center mr-3'>
                                    <label className="input-label mb-0 mr-2">Hora</label>
                                    <select
                                        disabled={values.allDay === true}
                                        className='select p-1 mr-2'
                                        value={moment(values.fromHour).format("H")}
                                        onChange={e => {

                                            setValues({
                                                ...values,
                                                fromHour: moment(values.fromHour).set('hour', e.target.value)
                                            })
                                        }}
                                    >
                                        {hoursOptions.map((e, i) => <option key={e + "from" + i} value={i}>{e}</option>)}
                                    </select>
                                </div>
                                <div className='d-flex flex-direction-row flex-nowrap align-items-center'>
                                    <label className="input-label mb-0 mr-2">mins</label>
                                    <select
                                        disabled={values.allDay === true}
                                        className='select p-1'
                                        value={(new Date(values.fromHour)).getUTCMinutes()}
                                        onChange={e => setValues({
                                            ...values,
                                            fromHour: moment(values.fromHour).set('minute', e.target.value)
                                        })}
                                    >
                                        <option value={0}>0</option>
                                        <option value={15}>15</option>
                                        <option value={30}>30</option>
                                        <option value={45}>45</option>
                                    </select>
                                </div>
                            </Col>
                        </Row>
                        <Row className="mb-4 align-items-end">
                            <Col xs={{ span: 3, offset: 1 }}>
                                <label className="input-label mb-0 mr-2">Hasta</label>
                                <input
                                    type="date"
                                    onChange={e => {
                                        setValues({
                                            ...values,
                                            toHour: moment(e.target.value)
                                        })
                                    }}
                                    value={moment(values.toHour).format("yyyy-MM-DD")}
                                    className='w-100 select p-1'
                                />
                            </Col>
                            <Col xs={{ span: 3, offset: 1 }} className="d-flex flex-direction-row flex-nowrap">
                                <div className='d-flex flex-direction-row flex-nowrap align-items-center mr-3'>
                                    <label className="input-label mb-0 mr-2">Hora</label>
                                    <select
                                        disabled={values.allDay === true}
                                        className='select p-1 mr-2'
                                        value={moment(values.toHour).format("H")}
                                        onChange={e => {

                                            setValues({
                                                ...values,
                                                toHour: moment(values.toHour).set('hour', e.target.value)
                                            })
                                        }}
                                    >
                                        {hoursOptions.map((e, i) => <option key={e + "from" + i} value={i}>{e}</option>)}
                                    </select>
                                </div>
                                <div className='d-flex flex-direction-row flex-nowrap align-items-center'>
                                    <label className="input-label mb-0 mr-2">mins</label>
                                    <select
                                        disabled={values.allDay === true}
                                        className='select p-1'
                                        value={(new Date(values.toHour)).getUTCMinutes()}
                                        onChange={e => setValues({
                                            ...values,
                                            toHour: moment(values.toHour).set('minute', e.target.value)
                                        })}
                                    >
                                        <option value={0}>0</option>
                                        <option value={15}>15</option>
                                        <option value={30}>30</option>
                                        <option value={45}>45</option>
                                    </select>
                                </div>
                            </Col>
                        </Row>
                        <Row className="mb-4">
                            <Col xs={{ span: 3, offset: 1 }} >
                                <div className="d-flex flex-direction-row align-items-center">
                                    <p className='body-m mr-auto mb-2'>Todo el día</p>
                                    <CustomSwitch state={values.allDay} handleChange={() => {
                                        setValues({ ...values, allDay: !values.allDay })
                                    }} name="allDay" />
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={{ span: 10, offset: 1 }}>
                                <hr className='' />
                            </Col>
                        </Row>
                        <Row className="mt-4">
                            <Col xs={{ span: 11, offset: 1 }}>
                                <p className='body-l-sb'>Invitados</p>
                            </Col>
                        </Row>
                        <Row className="mt-3 mb-5">
                            <Col xs={{ span: 8, offset: 1 }}>
                                <div className='d-flex flex-direction-row w-100 flex-wrap mb-5'>
                                    <div className='d-flex flex-direction-row messageform__mails-container flex-wrap'>
                                        {mailFor?.map((e) => {
                                            return <div className='messageform__mails-format m-1' key={e.id}>
                                                <span className='body-s-b px-2 '>{`${e?.lastName}, ${e?.name} (${e?.email})`}</span>
                                                <Button icon="cross" type="button" className="button__only-icon-withoutcolor" onClick={() => deleteMail(e, "mailFor")} />
                                            </div>
                                        })
                                        }
                                    </div>
                                </div>
                            </Col>
                            <Col xs={{ span: 4, offset: 1 }}>
                                <UserSearcher onSearcherSubmit={addUsers} searchByAtr='email' label="Para"/>
                            </Col>
                        </Row>
                        <Row className=" align-items-center">
                            <Col xs={{ span: 3, offset: 1 }}>
                                <div className="d-flex flex-direction-row align-items-center">
                                    <p className='body-m mr-auto mb-2'>Toda la institución</p>
                                    <CustomSwitch state={values.isGlobalEvent} handleChange={() => {
                                        setValues({ ...values, isGlobalEvent: !values.isGlobalEvent, invitedAreas: !values.isGlobalEvent ? [] : values.invitedAreas })
                                    }} name="isGlobalEvent" />
                                </div>
                            </Col>
                            <Col xs={{ span: 1, offset: 1 }}>
                                <Button
                                    onClick={() => setShowOptions(!showOptions)}
                                    icon="arrowUpMUI"
                                    className="button__only-icon-withoutcolor mb-2"
                                    type="button" />
                            </Col>
                        </Row>
                        {showOptions && <>
                            <Row>
                                <Col xs={{ span: 6, offset: 1 }}>
                                    <hr className='mr-4' />
                                </Col>
                            </Row>


                            <Row>
                                <Col xs={{ span: 3, offset: 1 }}>
                                    <div className="d-flex flex-direction-row align-items-center">
                                        <p className='body-m mr-auto mb-2'>Dirección</p>
                                        <CustomSwitch
                                            disabled={values.isGlobalEvent}
                                            state={values.invitedAreas.includes("directorate")}
                                            handleChange={() =>
                                                setValues({ ...values, invitedAreas: values.invitedAreas?.includes("directorate") ? values.invitedAreas?.filter(e => e !== "directorate") : [...values.invitedAreas, "directorate"] })}
                                            word="directorate" />
                                    </div>
                                    <div className="d-flex flex-direction-row align-items-center">
                                        <p className='body-m mr-auto mb-2'>Preceptoría</p>
                                        <CustomSwitch
                                            disabled={values.isGlobalEvent}
                                            state={values.invitedAreas.includes("tutorship")}
                                            handleChange={() =>
                                                setValues({ ...values, invitedAreas: values.invitedAreas?.includes("tutorship") ? values.invitedAreas?.filter(e => e !== "tutorship") : [...values.invitedAreas, "tutorship"] })}
                                            word="tutorship" />
                                    </div>
                                    <div className="d-flex flex-direction-row align-items-center">
                                        <p className='body-m mr-auto mb-2'>Alumnos</p>
                                        <CustomSwitch
                                            disabled={values.isGlobalEvent}
                                            state={values.invitedAreas.includes("students")}
                                            handleChange={() =>
                                                setValues({ ...values, invitedAreas: values.invitedAreas?.includes("students") ? values.invitedAreas?.filter(e => e !== "students") : [...values.invitedAreas, "students"] })}
                                            word="students" />
                                    </div>
                                </Col>
                                <Col xs={{ span: 3 }}>
                                    <div className="d-flex flex-direction-row align-items-center">
                                        <p className='body-m mr-auto mb-2'>Docentes</p>
                                        <CustomSwitch
                                            disabled={values.isGlobalEvent}
                                            state={values.invitedAreas.includes("teachers")}
                                            handleChange={() =>
                                                setValues({ ...values, invitedAreas: values.invitedAreas?.includes("teachers") ? values.invitedAreas?.filter(e => e !== "teachers") : [...values.invitedAreas, "teachers"] })}
                                            word="teachers" />
                                    </div>
                                    <div className="d-flex flex-direction-row align-items-center">
                                        <p className='body-m mr-auto mb-2'>Administración</p>
                                        <CustomSwitch
                                            disabled={values.isGlobalEvent}
                                            state={values.invitedAreas.includes("administration")}
                                            handleChange={() =>
                                                setValues({ ...values, invitedAreas: values.invitedAreas?.includes("administration") ? values.invitedAreas?.filter(e => e !== "administration") : [...values.invitedAreas, "administration"] })}
                                            word="administration" />
                                    </div>
                                    <div className="d-flex flex-direction-row align-items-center">
                                        <p className='body-m mr-auto mb-2'>Secretaría</p>
                                        <CustomSwitch
                                            disabled={values.isGlobalEvent}
                                            state={values.invitedAreas.includes("secretariat")}
                                            handleChange={() =>
                                                setValues({ ...values, invitedAreas: values.invitedAreas?.includes("secretariat") ? values.invitedAreas?.filter(e => e !== "secretariat") : [...values.invitedAreas, "secretariat"] })}
                                            word="secretariat" />
                                    </div>
                                </Col>
                            </Row>
                        </>
                        }
                        <Row className="mt-4">
                            <Col xs={{ span: 10, offset: 1 }}>
                                <hr />
                            </Col>
                        </Row>
                        <Row className="mb-5">
                            <Col xs={{ span: 6, offset: 1 }} className="d-flex flex-direction-column pr-5">
                                <label htmlFor="description" className='input-label color-labels'>
                                    Descripción
                                </label>
                                <textarea placeholder='Descripción' name="description" onChange={handleInputChange} rows={10} className='p-2 newevent__textarea' value={values.description}>
                                </textarea>
                            </Col>
                        </Row>
                        <Row className="mb-5">
                            <Col xs={{ span: 10, offset: 1 }} className="d-flex">
                                <div className='ml-auto d-flex'>
                                    <Button
                                        type="reset"
                                        className='btn button__only-icon-withoutcolor color-primary  button-m-sb mr-5'
                                        content="Cancelar"
                                        onClick={onsubmitCancel} />
                                    <Button
                                        className='btn button-primary button-m-sb'
                                        content={id ? "Editar evento" : "Añadir evento"}
                                        onClick={onSubmitEvent}
                                        disabled={(values.title && values.description) ? false : true} />
                                </div>
                            </Col>
                        </Row>
                    </form>
                </div>
            </Container >
        </main>
    )
}
