import { Col, Row } from "react-bootstrap"
import { Button } from "./Button"
import { CustomInput } from "./CustomInput"


export const UrlDocument = ({ key, index, doc, urls, setUrls, classNameInput, classNameLabel }) => {

    const handleUrlChange = ({target}) => {
        setUrls(
            urls?.map((url, urlIndex) =>
                (urlIndex === index ? { ...url, [target.name]: target.value } : url)
            )
        )
    }

    const handleDelUrlDocument = ({target}) => {
        const newUrls = urls.filter((doc, i)=> i !== index )
        setUrls(newUrls)
    }

    return(
        <>
        <Row>
            <Col xs={4}>
                <CustomInput
                    classNameInput={classNameInput}
                    classNameLabel={classNameLabel}
                    handleInputChange={handleUrlChange}
                    name="docName"
                    value={doc.docName}
                    placeholder="Nombre del documento"
                />
            </Col>
            <Col xs={4}>
                <CustomInput
                    classNameInput={classNameInput}
                    classNameLabel={classNameLabel}
                    handleInputChange={handleUrlChange}
                    name="url"
                    value={doc.url}
                    placeholder="Url del documento"
                />
            </Col>
            <Col xs={4}>
                <Button
                    icon="removeMUI"
                    content={<span className="ml-1 mt-1">Agregar documento</span>}
                    className=" button-terciary button-s-sb d-flex align-items-center m-1 py-4 "
                    iconRight={false}
                    type="button"
                    onClick={handleDelUrlDocument} />
            </Col>
        </Row>
        </>
    )
}