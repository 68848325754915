import moment from "moment"
import { RadioButton } from "primereact/radiobutton"
import { Col, Row } from "react-bootstrap"
import { Board } from "../../Board"
import { CustomSelect } from "../../CustomSelect"

const participationOptions = [
    { label: 'Presencial', type: 'faceToFace' },
    { label: 'Virtual (Cámara prendida)', type: 'virtualCamOn' },
    { label: 'Virtual (Cámara apagada)', type: 'virtualCamOff' },
    { label: 'Ausente justificada', type: 'excusedAbsence' },
    { label: 'Ausente no justificada', type: 'unexcusedAbsence' },
]

export const TodaysAttendance = ({ course, values, handleInputChange, attendances, handleCheckChange }) => {

    const getParticipationTypeLabelValues = (studentId) => {
        const type = attendances?.find(att => att.studentId === studentId)?.attendance?.type
        const label = participationOptions.find(opt => opt.type === type)?.label
        return { type, label}
    }

    return (
        <>
            <Row>
                <Col xs={2} className="py-4">
                    <input
                        required
                        type="date"
                        name="date"
                        className='newbudget-form-select body-l'
                        value={moment(values.date).format("YYYY-MM-DD")}
                        onChange={handleInputChange}
                        placeholder="dd/mm/yyyy"
                    />
                </Col>
            </Row>
            <Board
                className="board-row-color-3 my-3"
                firstRow={
                    <>
                        <Col xs={3}>
                            <div className='my-2'>
                                <span className='body-m-b'>Apellido y nombre</span>
                            </div>
                        </Col>
                        <Col xs={2}>
                            <div className='my-2'>
                                <span className='body-m-b'>Asistencia del día</span>
                            </div>
                        </Col>
                    </>
                }
                rows={
                    course.students?.map((usr, i) =>
                        <Row
                            key={usr._id}
                            className='board__row cursor-pointer py-1'
                        >
                            <Col xs={3}>
                                <div className='my-2'>
                                    <span className='body-m-regular'> {`${usr.lastName}, ${usr.name}`}</span>
                                </div>
                            </Col>
                            <Col xs={2}>
                                <div className='my-2 d-flex align-items-center'>
                                    <RadioButton
                                        value='Presente'
                                        name={`attendance_${usr._id}`}
                                        checked={attendances?.find(att => att.studentId === usr._id)?.attendance?.value === 1}
                                        onChange={() => handleCheckChange({
                                            studentId: usr._id,
                                            value: 1
                                        })}
                                    />
                                    <label className="m-0 px-2 body-m-regular">Presente</label>
                                </div>
                            </Col>
                            <Col xs={2}>
                                <div className='my-2 d-flex align-items-center'>
                                    <RadioButton
                                        value='Ausente'
                                        name={`attendance_${usr._id}`}
                                        checked={attendances?.find(att => att.studentId === usr._id)?.attendance?.value === 0}
                                        onChange={() => handleCheckChange({
                                            studentId: usr._id,
                                            value: 0
                                        })}
                                    />
                                    <label className="m-0 px-2 body-m-regular">Ausente</label>
                                </div>
                            </Col>
                            <Col xs={3}>
                                <div className='my-2'>
                                    <CustomSelect
                                        placeholder="Tipo de participación"
                                        options={participationOptions}
                                        handleInputChange={(e) => handleCheckChange({
                                            studentId: usr._id,
                                            type: e.target.value.type
                                        })}
                                        values={getParticipationTypeLabelValues(usr._id)}
                                    />
                                </div>
                            </Col>
                        </Row>
                    )
                }
            />
        </>
    )
}
