import '../scss/components/_switch.scss'
import classNames from 'classnames'

export const Switch = ({
    id = '',
    name = '',
    switchValue = false,
    handleInputChange = function() {},
    disabled = false
}) => {
    return (
        <div className={classNames(
            'switch__container d-flex align-items-center gap-8',
            { active: switchValue },
            { disabled }
        )}>
            <input
                id={id}
                name={name}
                type='checkbox'
                className='switch'
                onChange={handleInputChange}
                disabled={disabled}
                checked={switchValue}
            />
            <div className='switch__ball-container'>
                <div className='switch__ball' />
            </div>
        </div>
    )
}