import { useEffect, useState } from "react"
import { Col, Container, Row } from "react-bootstrap"
import { useDispatch } from "react-redux"

import { createLog } from "../../../actions/logs"
import { api } from "../../../helpers/fetch"
import { useForm } from "../../../hooks/useForm"
import { Button } from "../../Button"
import { CustomInput } from "../../CustomInput"
import { CustomSelect } from "../../CustomSelect"
import { Loading } from "../../Loading"


export const CourseInformationForm = ({id, courseInfo}) => {
    const [loading, setLoading] = useState(true);
    const dispatch = useDispatch();

    //--------- Course Info --------------
    const [values, handleInputChange, setValues] = useForm({
        _id: "",
        level: "",
        age: "",
        division: "",
        shift: "",
        tutorship: "",
        signatures: [],
        students: [],
    })

    //--------- Levels --------------
    const levelOptions = [
        {label: 'Jardín', value: 'Elementary'},
        {label: 'Primaria', value: 'Primary'},
        {label: 'Secundaria', value: 'High'},
    ];

    //--------- Division --------------
    const divisionOptions = [
        {label: 'A', value: 'A'},
        {label: 'B', value: 'B'},
        {label: 'C', value: 'C'},
    ];

    //--------- Shift --------------
    const shiftOptions = [
        {label: 'Mañana', value: 'Mañana'},
        {label: 'Tarde', value: 'Tarde'},
    ];

    //--------- Tutorships --------------
    const [tutors, setTutors] = useState();

    const tutorsOptions = tutors?.map((tutor) => {
        return {label:`${tutor.name} ${tutor.lastName}` , value: tutor._id};
    });

    const getTutors = () => {
        api.get("auth/getUsers?userRole=teachers&userRole=tutorship&userRole=directorate").then(res => {
            const users = res.data.users.filter(aux => aux?.userAllRoles?.length > 1 || aux.userRole === 'teachers' || aux.userRole === 'tutorship')
            setTutors(users);
        })
    }
    

    const onsubmitEdit = ()=>{
        api.put(`courses/edit/${id}`, values).then( () => {
            dispatch(createLog({
                type: "success",
                title: "¡Edición Exitosa!",
                text: `El curso ${values.age} - ${values.division} se ha editado con éxito.`,
            }))
        })
    }

    useEffect(() => {
        setValues(courseInfo);
        getTutors();
        setLoading(false);
    }, [courseInfo])
    

    return (
        <>
            {loading ? <Loading /> :
                <Container className='mb-2 pb-2'>
                    <div className="coursenew_form px-5 py-3">
                        <Row className="px-5">
                            <h3 className=" mt-4 pt-1 color-text">Información del Curso</h3>
                        </Row>
                        <Row className="px-5">
                            <Col xs={3} className='my-5'>    
                                <CustomSelect options={levelOptions} name="level" labelContent={<><span style={{color: "#ff0000"}}>*</span> Nivel</>} alwaysShow={true} values={values.level} handleInputChange={handleInputChange} placeholder={'nivel'} />
                            </Col>
                            <Col xs={3} className='my-5'>    
                            <CustomInput classNameInput="w-100" placeholder='Año' name="age" labelContent={<><span style={{color: "#ff0000"}}>*</span> Año</>} alwaysShow={true} handleInputChange={handleInputChange} value={values.age} />
                            </Col>
                            <Col xs={3} className='my-5'>    
                                <CustomSelect options={divisionOptions} name="division" labelContent={<><span style={{color: "#ff0000"}}>*</span> División</>} alwaysShow={true} values={values.division} handleInputChange={handleInputChange} placeholder={'división'} />
                            </Col>
                            <Col xs={3} className='my-5'>    
                                <CustomSelect options={shiftOptions} name="shift" labelContent={<><span style={{color: "#ff0000"}}>*</span> Turno</>} alwaysShow={true} values={values.shift} handleInputChange={handleInputChange} placeholder={'turno'} />
                            </Col>
                        </Row>
                        <Row className="px-5">
                            <Col xs={12}>
                                <hr className='w-100' />
                            </Col>
                        </Row>
                        <Row className="px-5">
                            <Col xs={4} className='my-5'>    
                                <CustomSelect options={tutorsOptions} name="tutorship" values={values?.tutorship} labelContent="Docente/Tutor a cargo del Curso" alwaysShow={true} handleInputChange={handleInputChange} placeholder={`${values?.tutorship?.name} ${values.tutorship?.lastName}`} />
                            </Col>
                        </Row>
                        <Row className="mb-5 px-5">
                            <Col xs={{ span: 10, offset: 1 }} className="d-flex">
                                <div className='ml-auto d-flex'>
                                    <Button
                                        type="reset"
                                        className='btn button-secondary color-primary button-s-sb mt-3'
                                        icon="pencilMUI"
                                        content="Editar Curso"
                                        onClick={onsubmitEdit} />
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container >
            }
        </>
    )
}