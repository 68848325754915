import { Col, Row } from "react-bootstrap";
import { CustomSelect } from "../../CustomSelect";
import { nationalities } from "../../../helpers/nationalities";
import { provinces } from "../../../helpers/provinces";
import { documentTypes } from "../../../helpers/document-types";
import { CustomInput } from "../../CustomInput";
import { formatDateForInput } from "../../../helpers/format-date-for-input";

export const UserPersonalData = ({ form, handleInputChange, disable }) => {

  const documentTypeList = documentTypes();
  const nacionalityList = nationalities();
  const provinceList = provinces();

  return (
    <>
      <Row className="userper mt-2 ">
        <Col xs={{ span: 8, offset: 1 }}>
          <div className="d-flex justify-content-between">
            <h4>
              {form.role === "students" ? "Datos del alumno" : "Datos personales"}
            </h4>
          </div>
        </Col>
      </Row>
      <Row className="d-flex align-items-center mt-5 mb-3">
        <Col
          xs={{ span: 2, offset: 1 }}
          className="userpersonaldata__form-input"
        >
          <CustomInput
            labelContent={
                <span className="color-disabled input-label">
                Fecha de nacimiento
              </span>
            }
            type="date"
            name="dateBirth"
            value={formatDateForInput(form.dateBirth)}
            placeholder="Ej: 31/01/2023"
            handleInputChange={handleInputChange}
            classNameInput="body-l w-100"
            classNameLabel="input-label"
            disabled={!disable}
            alwaysShow
          />
        </Col>
        <Col xs={2}>
          <CustomSelect
            labelContent={
                <span className="color-disabled input-label ">
                Tipo de documento
              </span>
            }
            handleInputChange={handleInputChange}
            options={documentTypeList}
            values={form.documentType}
            name="documentType"
            placeholder="DNI"
            className="mr-3 body-l w-100"
            disabled={!disable}
            alwaysShow
          />
        </Col>
        <Col xs={2}>
          <CustomInput
            labelContent={
              <span className="color-disabled input-label">DNI</span>
            }
            handleInputChange={handleInputChange}
            name="documentId"
            value={form.documentId}
            placeholder="Ej: 60.000.332"
            classNameInput="body-l w-100"
            classNameLabel="input-label"
            disabled={!disable}
            alwaysShow
          />
        </Col>
      </Row>
      <Row className="d-flex align-items-center mt-5">
        <Col
          xs={{ span: 2, offset: 1 }}
          className="userpersonaldata__form-input body-l"
        >
          <CustomSelect
            options={nacionalityList}
            labelContent={
                <span className="color-disabled input-label">Nacionalidad</span>
            }
            name="country"
            values={form.country}
            handleInputChange={handleInputChange}
            placeholder="Ej: Argentina"
            className="mr-3 body-l w-100"
            disabled={!disable}
            alwaysShow
          />
        </Col>
        <Col xs={2}>
          <CustomSelect
            labelContent={
                <span className="color-disabled input-label">Provincia</span>
            }
            options={provinceList}
            values={form.province}
            handleInputChange={handleInputChange}
            name="province"
            placeholder="Ej: Buenos Aires"
            className="mr-3 body-l w-100"
            disabled={!disable}
            alwaysShow
          />
        </Col>
        <Col xs={2}>
          <CustomInput
            labelContent={
                <span className="color-disabled input-label">Localidad</span>
            }
            name="locality"
            value={form.locality}
            handleInputChange={handleInputChange}
            placeholder="Ej: Ciudadela"
            className="mr-3 body-l w-100"
            disabled={!disable}
            alwaysShow
          />
        </Col>
        <Col xs={2}>
          <CustomInput
            labelContent={
                <span className="color-disabled input-label">Código postal</span>
            }
            name="zipCode"
            value={form.zipCode}
            handleInputChange={handleInputChange}
            placeholder="Ej: 4444"
            type="number"
            className="mr-3 body-l w-100"
            disabled={!disable}
            alwaysShow
          />
        </Col>
      </Row>
      <Row className="d-flex align-items-center mt-5">
        <Col
          xs={{ span: 3, offset: 1 }}
          className="userpersonaldata__form-input body-l"
        >
          <CustomInput
            labelContent={
              <span className="color-disabled input-label">Dirección</span>
            }
            name="address.street"
            value={form["address.street"]}
            handleInputChange={handleInputChange}
            classNameInput="body-l w-100"
            classNameLabel="input-label "
            placeholder="Ej: San Martin 1520"
            disabled={!disable}
            alwaysShow
          />
        </Col>
        <Col xs={2}>
          <CustomInput
            placeholder="Ej.: 2"
            labelContent={
                <span className="color-disabled input-label">Piso</span>
            }
            name="address.floor"
            value={form["address.floor"]}
            handleInputChange={handleInputChange}
            classNameInput="body-l w-100"
            classNameLabel="input-label"
            disabled={!disable}
            alwaysShow
          />
        </Col>
        <Col xs={2}>
          <CustomInput
            labelContent={
                <span className="color-disabled input-label">Departamento</span>
            }
            name="address.apartment"
            value={form["address.apartment"]}
            handleInputChange={handleInputChange}
            placeholder="Ej: B"
            classNameInput="body-l w-100"
            classNameLabel="input-label"
            disabled={!disable}
            alwaysShow
          />
        </Col>
      </Row>
    </>
  );
};
