import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";

import { api } from "../../../helpers/fetch";

import { BreadCrumbs } from "../../BreadCrumbs";
import { Loading } from "../../Loading";
import { CustomOptions } from "../../CustomOptions";
import { CustomSelect } from "../../CustomSelect";
import { useForm } from "../../../hooks/useForm";
import moment from "moment";
import { CardCartelera } from "../../CardCartelera";
import { CustomFabicon } from "../../CustomFabicon";
import { Button } from "../../Button";
import { useSelector } from "react-redux";
import { Line } from "../../Line";
import { EmptyStates } from "../../EmptyStates";
import emptySubject from "../../../Imgs/EmptyStates/Social 7 cursos.png";
import { useHistory } from "react-router";

export const Courses = () => {
  const auth = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(true);
  const { push } = useHistory();

  //--------- School Years & general values--------------
  const [schoolYears, setSchoolYears] = useState([]);

  const [values, handleInputChange] = useForm({
    year: parseInt(moment(new Date()).format("YYYY")),
    age: "",
    shift: "",
  });

  const getSchoolYears = () => {
    api.get("/years/").then((res) => {
      const yearsList = res.data.years
        .sort((a, b) => b.year - a.year)
        .map((y) => ({ label: y.year, value: y.year }));
      setSchoolYears(yearsList);
      setLoading(false);
    });
  };

  //--------- Education Stage -----------

  const educationalStageOptions = [
    {
      label: "Inicial",
      type: "Elementary",
    },
    {
      label: "Primaria",
      type: "Primary",
    },
    {
      label: "Secundaria",
      type: "High",
    },
  ];

  const [educationalStage, setEducationalStage] = useState(
    auth.user.level ?
        educationalStageOptions.find( lvl => lvl.type === auth.user.level )
    :
        educationalStageOptions[0]
  );

  //--------- Courses -----------
  const [courses, setCourses] = useState([]);
  const [coursesOptions, setCoursesOptions] = useState([]);

  const uniqueAgeOptions = coursesOptions?.reduce((arr, item) => {
    if (!arr.includes(item.age)) {
      arr.push(item.age);
    }
    return arr;
  }, []);

  const ageOptions = uniqueAgeOptions?.map((age) => {
    return { label: age, value: age };
  });
  ageOptions?.unshift({ label: "Todos", value: "" });

  //--------- Turno -----------
  const uniqueShiftOptions = coursesOptions?.reduce((arr, item) => {
    if (!arr.includes(item.shift)) {
      arr.push(item.shift);
    }
    return arr;
  }, []);

  const shiftOptions = uniqueShiftOptions?.map((shift) => {
    return { label: shift, value: shift };
  });
  shiftOptions?.unshift({ label: "Todos", value: "" });

  const getCourses = async (year, level) => {
    if (
      auth.user.userRole === "teachers" ||
      auth.user.userRole === "tutorship"
    ) {
      const coursesArr = auth.user.courses;
      const coursesFilter = coursesArr.filter(
        (course) => course.year === values.year
      );
      setCourses(coursesFilter);
      setCoursesOptions(coursesFilter);
    } else if (
      auth.user.directorType === "GeneralDirector" ||
      auth.user.directorType === "LegalRepresentative" ||
      (!auth?.user?.level && auth?.user?.userRole === "directorate") ||
      auth.user.userRole === "secretariat" ||
      auth.user.userRole === "administration"
    ) {
      await api.get(`/courses/${year}`).then((res) => {
        const coursesArr = res.data.courses;
        const coursesFilter = coursesArr.filter(
          (course) => course.level === level.type
        );
        setCourses(coursesFilter);
        setCoursesOptions(coursesFilter);
      });
    } else {
      await api.get(`/courses/${year}`).then((res) => {
        const coursesArr = res.data.courses;
        const coursesFilter = coursesArr.filter(
          (course) =>
            (course.level === auth.user.level) && (!auth.user.shift || course.shift === auth.user.shift)
        );
        setCourses(coursesFilter);
        setCoursesOptions(coursesFilter);
      });
    }
  };

  const handleNewSchoolYear = () => {
    push("/courses/createyear");
  };

  const HandleFilter = () => {
    const filterByAge = coursesOptions.filter((courses) => {
      if (!values.age && !values.shift) {
        return true;
      }
      if (!values.age) {
        return courses.shift === values.shift;
      }
      if (!values.shift) {
        return courses.age === values.age;
      } else {
        return courses.age === values.age && courses.shift === values.shift;
      }
    });
    setCourses(filterByAge);
  };

  useEffect(() => {
    getCourses(values.year, educationalStage);
  }, [educationalStage, values.year, auth]);

  useEffect(() => {
    getSchoolYears();
  }, []);

  return (
    <>
      <Container className="mb-2 pb-2">
        {loading ? (
          <Loading />
        ) : (
          <>
            <Row>
              <Col xs={10}>
                <BreadCrumbs
                  lastSteps={[{ to: "/home", step: "Inicio" }]}
                  title="Cursos"
                />
              </Col>
              {auth?.user?.userRole === "secretariat" && (
                <Col xs={2}>
                  <Button
                    className="btn button-secondary color-primary button-s-sb mt-3"
                    content="Nuevo Ciclo Lectivo"
                    iconRight={false}
                    onClick={handleNewSchoolYear}
                  />
                </Col>
              )}
            </Row>
            {auth?.user?.userRole !== "teachers" &&
            (auth?.user?.directorType === "GeneralDirector" ||
              auth?.user?.directorType === "LegalRepresentative" ||
              (!auth?.user?.level && auth?.user?.userRole === "directorate") ||
              auth?.user?.userRole === "secretariat") ? (
              <Row className="mt-5">
                <Col xs={12}>
                  <CustomOptions
                    options={educationalStageOptions}
                    value={educationalStage}
                    setValue={setEducationalStage}
                  />
                </Col>
              </Row>
            ) : (
              <Line mt={4} mb={3} />
            )}
            <Row>
              <Col xs={2}>
                <p className="mb-2">Ciclo Lectivo</p>
                <CustomSelect
                  options={schoolYears}
                  name="year"
                  values={values.year}
                  handleInputChange={handleInputChange}
                  placeholder="Ciclo lectivo"
                />
              </Col>
              <Col xs={2}>
                <p className="mb-2">
                  {educationalStage.label === "Inicial"
                    ? "Sala"
                    : educationalStage.label === "Primaria"
                    ? "Grado"
                    : "Año"}
                </p>
                <CustomSelect
                  options={ageOptions}
                  name="age"
                  values={values.age}
                  handleInputChange={handleInputChange}
                  placeholder="Todos"
                />
              </Col>
              {educationalStage.label !== "Secundaria" && (
                <>
                  <Col xs={2}>
                    <p className="mb-2">Turno</p>
                    <CustomSelect
                      options={shiftOptions}
                      name="shift"
                      values={values.shift}
                      handleInputChange={handleInputChange}
                      placeholder="Todos"
                    />
                  </Col>
                </>
              )}
              <Col xs={2}>
                <Button
                  className="btn button-secondary color-primary button-s-sb mt-3"
                  content="Aplicar"
                  iconRight={false}
                  onClick={HandleFilter}
                />
              </Col>
            </Row>
            {courses.length === 0 ? (
              <EmptyStates
                src={emptySubject}
                title="Asignaciones en proceso"
                text="Actualmente la asignación de cursos está en proceso. Para más información puedes comunicarte con secretaría."
              />
            ) : (
              <Row className="my-5">
                <Col xs={9}>
                  <Row>
                    {courses?.map((e, i) => (
                      <Col
                        sm={12}
                        md={6}
                        lg={5}
                        xl={4}
                        key={e + i}
                        className="mb-3"
                      >
                        <CardCartelera
                          title={`${e.age} ${e.division}`}
                          cardBody={"Turno " + e.shift}
                          to={`/courses/detail/${e._id}`}
                          arrow={false}
                        />
                      </Col>
                    ))}
                  </Row>
                </Col>
              </Row>
            )}
          </>
        )}

        {auth?.user?.userRole === "secretariat" && (
          <CustomFabicon text="Nuevo curso" to="/courses/new" />
        )}
      </Container>
    </>
  );
};
