import { Col, Container, Row } from "react-bootstrap"
import { useEffect, useState } from "react";

import { BreadCrumbs } from "../../BreadCrumbs"
import { CardCartelera } from "../../CardCartelera"
import { Loading } from "../../Loading";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarDay } from '@fortawesome/free-solid-svg-icons';
import { CustomFabicon } from "../../CustomFabicon";
import { api } from "../../../helpers/fetch";
import { useSelector } from "react-redux";
import { EmptyStates } from "../../EmptyStates";
import emptySubject from '../../../Imgs/EmptyStates/Social 7 cursos.png'

export const Subjects = ({ breadCrumbsVisible = true, showFabicon = false, faviconTo, courseId, cardPathTo }) => {

    const [breadCrumbs, setBreadCrumbs] = useState(true)
    const [loading, setLoading] = useState(true)
    const [subjects, setSubjects] = useState([])
    const auth = useSelector(state => state.auth)

    const getSubjects = async () => {
        if (courseId) {
            await api.get(`subjects/${courseId}`).then(res => {
                setSubjects(res.data.subjects)
            })
        } else {
            const subjectsWithCourse = []

            auth.user.subjects.forEach(subject => {
                const course = getCourseFromSubjectId(subject._id)

                const newSubject = {
                    ...subject,
                    course
                }
                subjectsWithCourse.push(newSubject)
            })

            setSubjects(subjectsWithCourse)
        }
        setLoading(false)
    }

    const getCourseFromSubjectId = (subId) => {
        return auth.user.courses.find(course =>
            course.subjects.some(subFromCourse =>
                subFromCourse._id === subId
            )
        )
    }

    useEffect(() => {
        setBreadCrumbs(breadCrumbsVisible);
        getSubjects()
    }, [breadCrumbsVisible])

    return (
        <main>
            {loading ? <Loading /> :
                <Container>
                    <div className={breadCrumbs ? '' : 'hidden'}>
                        <Row className='mb-2 pb-2'>
                            <Col xs={12}>
                                <BreadCrumbs
                                    lastSteps={[
                                        { to: "/home", step: "Inicio" }
                                    ]}
                                    title="Materias" />
                            </Col>
                        </Row>
                        <Row>
                            <Col className='mb-3'>
                                <hr className='customoptions__hr w-100 m-0 mb-3' />
                            </Col>
                        </Row>
                    </div>
                    {
                        subjects.length === 0 ?
                            <EmptyStates src={emptySubject} title='Asignaciones en proceso' text='Actualmente la asignación de materias está en proceso. Para más información puedes comunicarte con secretaría.' />
                            :
                            <Row className='my-2 py-2'>
                                {subjects?.map((subject, i) =>
                                    <Col sm={12} md={6} lg={5} xl={3} key={i} className="mb-3">
                                        <CardCartelera
                                            title={subject.name}
                                            to={courseId
                                                ? `/courses/detail/subjects/${courseId}/${subject._id}`
                                                : {
                                                    pathname: `/subjects/detail/${subject._id}`,
                                                    state: { course: '/subjects' }
                                                }
                                            }
                                            cardBody={
                                                <>
                                                    <div className="d-flex">
                                                        {
                                                            (auth.user.userRole === 'secretariat' || auth.user.userRole === 'directorate') ?
                                                                <p className="body-m-sb mb-2">
                                                                    Docente:
                                                                    {
                                                                        subject.teacher ?
                                                                            `${subject.teacher.lastName}, ${subject.teacher.name}`
                                                                            :
                                                                            'Sin asignar'
                                                                    }
                                                                </p>
                                                                :
                                                                <p className="body-m-sb mb-2">
                                                                    {subject.course
                                                                        ?
                                                                        `${subject?.course?.age} ${subject?.course?.division} - ${subject?.course?.shift}`
                                                                        :
                                                                        'Sin curso'
                                                                    }
                                                                </p>
                                                        }
                                                    </div>
                                                    <p className="body-m-sb"><FontAwesomeIcon icon={faCalendarDay} className="mr-2" />
                                                        {`${subject.schedule[0].day}
                                                ${subject.schedule[1] ? `- ${subject.schedule[1].day}` : ''}
                                                ${subject.schedule[2] ? `- ${subject.schedule[2].day}` : ''}`}
                                                    </p>
                                                </>
                                            }
                                            arrow={false}
                                        />
                                    </Col>
                                )}
                            </Row>
                    }
                    {
                        (auth.user.userRole === 'secretariat' && showFabicon) && <CustomFabicon text="Nueva materia" to={faviconTo} />
                    }
                </Container>
            }
        </main>
    )
}
