
export const types = {

    authLogin: '[auth] Login',
    autTokenRenew: '[auth] Token Renew',
    authLogout: '[auth] Logout',

    getUsers: '[users] Get Users',
    
    setLog: '[logs] setLog',
    clearLog: '[logs] clearLog',

    setScope: '[scopes] Set Scope'
}