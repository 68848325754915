import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useForm } from "../../hooks/useForm"
import { UserSelector } from "./UserSelector";

export const UserSearcher = ({ onSearcherSubmit, searchByAtr, roleToFind, label }) => {

    const [usersFiltered, setUsersFiltered] = useState([]);

    const [showLabel, setShowLabel] = useState(false);
    const [values, handleInputChange] = useForm({ search: "" });
    const [active, setActive] = useState(0);
    const { users } = useSelector(state => state);

    const matchName = (arg, value) => {
        return arg.toLowerCase().includes(value.toLowerCase())
    };

    useEffect(() => {
        
        if (values.search) {
            setUsersFiltered(
                users
                    .filter((item) => matchName(item[searchByAtr], values.search) && (!roleToFind || item.userRole === roleToFind) )
                    .sort((a, b) => {
                        if (a[matchName(a[searchByAtr], values.search)] > b[matchName(b[searchByAtr], values.search)]) {
                            return 1;
                        }
                        if (a[matchName(a.lastName, values.search)] < b[matchName(b.lastName, values.search)]) {
                            return -1;
                        }
                        return 0;
                    })
                    .slice(0, 8)
            );
        } else {
            setUsersFiltered([]);
            setActive(0);
        }

    }, [values, users]);

    const handleKey = ({ code }) => {
        switch (code) {
            case "ArrowDown":
                if (active < usersFiltered.length) {
                    setActive(active + 1);
                } else {
                    setActive(0);
                }
                break;
            case "ArrowUp":
                if (active < 1) {
                    setActive(usersFiltered.length);
                } else {
                    setActive(active - 1);
                }
                break;
            case "Enter":
                onSearcherSubmit(usersFiltered[active -1 ].id);
                break;
            default:
                break;
        }
    };

    return (
        <div className="position-relative">
            <div className='position-relative custom-input' >
                {(showLabel) && <label htmlFor="Nombre y apellid" className="position-absolute label-up" >{label}</label>}
                <input
                    placeholder={label}
                    className="sheet__input w-100 "
                    value={values.search}
                    autoComplete="off"
                    name="search"
                    onChange={handleInputChange}
                    onKeyDown={handleKey}
                    onFocus={(e) => {
                        setShowLabel(true)
                    }}
                    
                />
            </div>
            {
                usersFiltered.length > 0 && showLabel &&
                < div className="namesearcher__selector py-2 w-100" onMouseLeave={() => setActive(0)}>
                    {
                        usersFiltered.map((item, index) => (
                            <UserSelector
                                key={item.id}
                                id={item.id}
                                active={active === index + 1}
                                onMouseEnter={() => setActive(index + 1)}
                                last={active === usersFiltered.length}
                                selectUser={() => onSearcherSubmit(usersFiltered[active -1 ].id)}
                                searcherValue={values.search}
                                name={`${item.lastName}, ${item.name} (${item.email})`}
                                values={values.search}
                                close={()=> setShowLabel(false)}
                            />
                        ))}
                </div>
            }
        </div >


    )
}

UserSearcher.propTypes = {
    onSearcherSubmit: PropTypes.func.isRequired,
}
