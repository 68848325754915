import { useEffect, useState } from "react"
import { Col, Container, Row } from "react-bootstrap"
import { useParams } from "react-router-dom"
import { api } from "../../../helpers/fetch"
import { BreadCrumbs } from "../../BreadCrumbs"
import { UserSearcher } from '../../UserSearcher/UserSearcher'
import { Loading } from "../../Loading"
import { CardCartelera } from "../../CardCartelera"
import { Button } from "../../Button"
import { useDispatch } from "react-redux"
import { useHistory } from "react-router-dom"
import { createLog } from "../../../actions/logs"
import { translate } from "../../../helpers/reduceString"



export const NewStudentAssign = () => {

    const {courseid} = useParams();
    const [loading, setLoading] = useState(true);
    const { goBack } = useHistory();
    const dispatch = useDispatch();
    const [course, setCourse] = useState({})
    const [students, setStudents] = useState([{}])
    const [studentsArr, setStudentsArr] = useState([])

    const addStudent = (_id)=> {
        const student = students.find(user=> user._id === _id)
        setStudentsArr([
            ...studentsArr,
            student
        ])
    }

    const getCourseDetail = async (courseId) => {
        await api.get(`courses/getById/${courseId}`).then(res => {
            setCourse(res.data.course);
            setStudentsArr(res.data.course.students);
        })
    }

    const getStudents = () => {
        api.get("auth/getUsers?userRole=students").then(res => {
            setStudents(res.data.users)
            setLoading(false);
        })
    }

    const onSubmitCancel = () => {
        goBack();
    }

    const onSubmitEvent = () => {
        const data = {students: studentsArr.map(student => student._id)}
        api.put(`courses/assign-students/${courseid}`, data).then( res => {
            if(res.data.ok === true) {
                dispatch(createLog({
                    type: res.data.status === 'complete' ? "success" : "info",
                    title: res.data.status === 'complete' ? "¡Asignación exitosa!" : "Asignaciones finalizada",
                    text: res.data.msg,
                }))
                goBack();
            } else {
                dispatch(createLog({
                    type: "error",
                    title: "Hubo un problema",
                    text: res.data.msg + `Vuelve a intentarlo.`,
                }))
            }
        })
    }

    const handleDelete = (_id) => {
        const studentDelete = studentsArr.filter((student) => student._id !== _id);
        setStudentsArr(studentDelete);
    }

    useEffect(() => {
        getCourseDetail(courseid)
        getStudents();
    }, [])
    

    return(
        <>
        {loading ? <Loading /> :
                <Container className='mb-2 pb-2'>
                    <Row>
                        <Col xs={12}>
                            <BreadCrumbs lastSteps={[{ to: "/home", step: "Inicio" }, { to: "/courses", step: "Cursos" }, { to: `/courses/detail/${courseid}`, step: `${course.age} - ${course.division}` }]} title='Nueva Asignación' />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <hr className='w-100' />
                        </Col>
                    </Row>
                    <div className="coursenew_form px-5 py-3">
                        <Row>
                            <Col xs={12} className='my-3'>    
                                <h3>Información del Curso</h3>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={3} className='my-3'>    
                                <p className="body-l-b">{`Curso: ${course.age} ${course.division}`}</p>
                            </Col>
                            <Col xs={2} className='my-3'>    
                                <p className="body-l-b">{`Turno: ${course.shift}`}</p>
                            </Col>
                            <Col xs={2} className='my-3'>    
                                <p className="body-l-b">{`Nivel: ${translate(course.level)}`}</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <hr className='w-100' />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} className='my-3'>    
                                <h3>Alumnos a asignar</h3>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={5} className='my-3'>
                                <UserSearcher onSearcherSubmit={addStudent} searchByAtr='email' roleToFind='students' label="Email" />
                            </Col>
                        </Row>
                        <Row className='my-2 py-2'>
                        {studentsArr.map((student) =>
                            <Col xs={6} className="mb-3" key={student._id}>
                                <div>
                                    <CardCartelera
                                        linkActive={false}
                                        title=""
                                        cardBody={
                                            <>
                                                <div className="new-assign-card-div d-flex">
                                                    <img src={student?.urlImgProfile} alt="foto de perfil" />
                                                    <div>
                                                        <p className="mx-3">{`${student?.lastName}, ${student?.name} (${student?.email})`}</p>
                                                        {/* <p className="mx-3 p-normal">XX años</p> */}
                                                    </div>
                                                </div>
                                                <Button
                                                    className='btn button__only-icon-withoutcolor color-primary button-s-sb'
                                                    icon="deleteMUI"
                                                    content="Eliminar asignación"
                                                    onClick={e => handleDelete(student._id)} />
                                            </>
                                        }
                                        arrow={false}
                                    />
                                </div>
                            </Col>
                        )}
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <hr className='w-100' />
                            </Col>
                        </Row>
                        <Row className="mt-3 mb-2">
                            <Col xs={{ span: 10, offset: 1 }} className="d-flex">
                                <div className='ml-auto d-flex'>
                                    <Button
                                    type="reset"
                                    className='button-quaternary body-m-sb mr-5'
                                    content="Cancelar"
                                    onClick={onSubmitCancel} />
                                    <Button
                                    className='btn button-primary button-m-sb'
                                    content="Guardar asignación"
                                    onClick={onSubmitEvent}
                                    disabled={studentsArr.length ? false : true} />
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container >
            }
        </>
    )
}