import { Col, Row } from "react-bootstrap"
import { Line } from "../../Line"
import { SubjectInfoText } from "../subjects/SubjectInfoText"
import { useAuth } from "../../../hooks/useAuth"

const dictionaryLevel = {
    Elementary: 'Inicial',
    Primary: 'Primaria',
    Secondary: 'Secundaria',
}

export const CourseInformationDisplay = ({ courseInfo, teacherInfo }) => {
    const { age, division, level, shift, tutorship } = courseInfo
    const { name, lastName } = teacherInfo
    const { user } = useAuth()

    return (
        <>
            <Row className="mt-3 mb-4">
                <Col>
                    <h3 className="m-0">Información del curso</h3>
                </Col>
            </Row>
            <Row className="mb-4">
                <Col xs={3}>
                    <SubjectInfoText name="Curso" values={[`${age} ${division}`]} />
                </Col>
                <Col xs={3}>
                    <SubjectInfoText name="Turno" values={[shift]} />
                </Col>
                <Col xs={3}>
                    <SubjectInfoText name="Nivel" values={[dictionaryLevel[level]]} />
                </Col>
            </Row>
            <Row className="mb-4">
                <Col xs={6}>
                    <SubjectInfoText name="Días y horarios" values={["..."]} />
                </Col>
                <Col xs={6}>
                    <SubjectInfoText 
                        name="Docente a cargo" 
                        values={
                            user.userRole === 'teachers' ? [`${name} ${lastName}`] : [`${tutorship.name} ${tutorship.lastName}`]
                        } 
                        />
                </Col>
            </Row>
            <Line />
        </>
    )
}
