import React from 'react'
import { Link } from 'react-router-dom'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRightLong } from '@fortawesome/free-solid-svg-icons';

export const CardCartelera = ({ title = "Cursos", linkActive = true, to = "", cardBody, arrow = true }) => {


    return (
        <>
            {linkActive ?

                <Link className="w-100 h-100  my-3" to={to}>
                    <div className="cardcartelera w-100 h-100 p-3">
                        <h4>{title}</h4>
                        {cardBody}
                        {
                            arrow &&
                            <div className='cardcartelera-arrow'>
                                <FontAwesomeIcon icon={faArrowRightLong} />
                            </div>
                        }
                    </div >
                </Link >
                :
                <div className="cardcartelera w-100 h-100 p-3">
                    <h4>{title}</h4>
                    {cardBody}
                    {
                        arrow &&
                        <div className='cardcartelera-arrow'>
                            <FontAwesomeIcon icon={faArrowRightLong} />
                        </div>
                    }
                </div >
            }
        </>

    )
}
