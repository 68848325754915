import { useEffect } from "react"
import { useState } from "react"
import { Col, Container, Row } from "react-bootstrap"
import { useLocation, useParams } from "react-router-dom/cjs/react-router-dom.min"
import { api } from "../../../helpers/fetch"
import { BreadCrumbs } from "../../BreadCrumbs"
import { Loading } from "../../Loading"
import { EvaluativeReportBody } from "./EvaluativeReportBody"
import { useSelector } from "react-redux"

export const EvaluativeReport = () => {
    const { state: { course, standard } } = useLocation()
    const { id: subjectId, uid: studentId, activityDeliveryId: actDelivery } = useParams()
    const [subject, setSubject] = useState({})
    const [isLoading, setIsLoading] = useState(true)
    const [actStudent, setActStudent] = useState([])
    const [infoActStudent, setInfoActStudent] = useState([])
    const { userRole } = useSelector(state => state.auth.user)

    const getSubject = async () => {
        const res = await api.get(`subjects/getById/${subjectId}`)
        const activitiStudent = await api.get(`activity-deliveries/${actDelivery}`)
        const infoAct = await api.get(`activities/${activitiStudent.data.activity}`)

        setSubject(res.data.subject)
        setActStudent(activitiStudent.data)
        setInfoActStudent(infoAct.data)
        setIsLoading(false)
    }

   
    useEffect(() => {
        getSubject()
    }, [subjectId])

    return (
        <Container className="mb-3">
            {isLoading ? <Loading /> : <>
                <Row className='mb-3'>
                    <Col xs={12}>
                        {
                            userRole === 'students' ?
                                <BreadCrumbs
                                    lastSteps={[
                                        { to: "/home", step: "Inicio" },
                                        { to: "/subjects", step: "Materias" },
                                        { to: { pathname: `/subjects/detail/${subjectId}`, state: { course } }, step: subject.name },
                                    ]}
                                    title='Informe evaluativo'
                                />
                                :
                                <BreadCrumbs
                                    lastSteps={[
                                        { to: "/home", step: "Inicio" },
                                        { to: "/subjects", step: "Materias" },
                                        { to: { pathname: `/subjects/detail/${subjectId}`, state: { course } }, step: subject.name },
                                        { to: { pathname: `/subjects/student/detail/${subjectId}/${studentId}`, state: { course } }, step: "Información del alumno" }
                                    ]}
                                    title='Informe evaluativo'
                                />
                        }
                    </Col>
                </Row>
                <EvaluativeReportBody actStudent={actStudent} infoActStudent={infoActStudent} userRole={userRole} />
            </>}

        </Container>
    )
}
