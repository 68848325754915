import React from 'react'
import AddIcon from '@mui/icons-material/Add';
import { Link } from 'react-router-dom'
import { Fab } from '@mui/material'

export const CustomFabicon = ({ text, to, onClick }) => {

    const FabContent = () => (
        <Fab size="large" variant="extended" aria-label="add" style={{
            position: "fixed",
            backgroundColor: "#0056A8",
            right: "14%",
            bottom: "3rem",
            zIndex: "4"
        }} onClick={onClick}>
            <AddIcon className=" my-auto color-white mr-1 ml-0 " />
            <span className='button-m color-white text-transform-none mr34 ml-2'>
                {text}
            </span>
        </Fab>
    )

    const FabLink = () => (
        <Link to={to || '/'}>
            <FabContent />
        </Link>
    )
    return (
        <> {onClick ? <FabContent /> : <FabLink />} </>
    )
}
