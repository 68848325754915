import { useEffect, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import driveApi from "../../../api/drive";
import { getUnflattenedObject } from "../../../helpers/flatten";
import { getUploadableFile } from "../../../helpers/get-uploadable-file";
import { reduceString } from "../../../helpers/reduceString";
import { Line } from "../../Line";
import { UserPresentation } from "../ControlSheets/UserPresentation";
import { FilePicker } from "react-file-picker";
import { Button } from "../../Button";
import { Toast } from "primereact/toast";
import { File } from "../../File";
import { CustomInput } from "../../CustomInput";

const defaultAttachments = {
  physicalAbilityCertifications: [],
  vaccinationCertificate: null,
  bloodTypeCertificate: null,
};

const getAttachmentsFromForm = (form) => {
  return Object.entries(form).reduce((acc, [key, value]) => {
    if (Object.keys(defaultAttachments).some((k) => key.startsWith(k))) {
      acc[key] = value;
    }
    return acc;
  }, {});
};

const getLastAttachmentIndex = (form, key) => {
  const attachments = getAttachmentsFromForm(form) || {};
  const keys = Object.keys(attachments).filter((k) => k.startsWith(`${key}.`));
  if (!keys.length) return -1;
  return keys
    .map((k) => parseInt(k.split(".")[1].split(".")[0]))
    .sort((a, b) => a - b)
    .pop();
};

export const UserMedicalHistory = ({
  form,
  onInputChange,
  setForm,
  disable,
  handleDisable,
}) => {
  const [attachments, setAttachments] = useState({ ...defaultAttachments });
  const toast = useRef(null);
  const userFlatten = getUnflattenedObject(form);

  const { userRole, uid } = useSelector((state) => state.auth.user);

  const onFilePickerChange = async (file, key, shouldPush = false) => {
    try {
      const driveFile = await driveApi.uploadFile(
        await getUploadableFile(file)
      );
      addFile(driveFile, key, shouldPush);
      showSuccess("Archivo subido", "El archivo se ha subido correctamente");
    } catch (error) {
      showError("Error al subir el archivo", error.message);
      throw error;
    }
  };

  const setAttachmentsFromForm = () => {
    const attachments = getUnflattenedObject(getAttachmentsFromForm(form));
    setAttachments(attachments || { ...defaultAttachments });
  };

  const addFile = (file, attachmentKey, shouldPush) => {
    const index = shouldPush
      ? getLastAttachmentIndex(form, attachmentKey) + 1
      : null;
    const flattenedObject = Object.entries(file).reduce((acc, [key, value]) => {
      const keys = [attachmentKey, index, key].filter((key) => key !== null);

      acc[keys.join(".")] = value;
      return acc;
    }, {});

    setForm({ ...form, ...flattenedObject });
  };

  useEffect(() => {
    setAttachmentsFromForm();
  }, [form]);

  const showSuccess = (summary = "Actualizado", detail) => {
    toast.current.show({ severity: "success", summary, detail, life: 3000 });
  };

  const showError = (summary, detail) => {
    toast.current.show({ severity: "error", summary, detail, life: 3000 });
  };

  const deleteFile = (file, attachmentKey) => {
    const _form = { ...form };
    const shouldFilter = Array.isArray(defaultAttachments[attachmentKey]);
    const keyMatchPattern = (() => {
      if (!shouldFilter) return attachmentKey;

      const foundKey = Object.entries(_form).find(
        ([key, value]) =>
          key.startsWith(`${attachmentKey}.`) && value === file.id
      )[0];
      const index = +foundKey.split(".")[1].split(".")[0];
      return `${attachmentKey}.${index}`;
    })();

    const keysToDelete = Object.keys(_form).filter((key) =>
      key.startsWith(keyMatchPattern)
    );

    keysToDelete.forEach((key) => {
      delete _form[key];
    });
    if (!shouldFilter) _form[attachmentKey] = null;

    setForm(_form);
  };

  const options = [
    {
      name: "Copiar link",
      icon: "pi pi-link",
      action: (file) => {
        navigator.clipboard.writeText(
          `https://drive.google.com/file/d/${file.id}/view?usp=sharing`
        );
        showSuccess("Link copiado", "El link se ha copiado al portapapeles");
      },
    },
    {
      name: "Cambiar nombre",
      icon: "pi pi-pencil",
      action: async (file) => {
        const [oldName, extension] = file.name.split(".");
        const newName = prompt("Nuevo nombre:", oldName);

        if (newName && newName !== oldName) {
          await driveApi.updateFile(file.id, {
            name: extension ? `${newName}.${extension}` : newName,
          });
          // updateRegulations()
          showSuccess(
            "Nombre cambiado",
            "El nombre del archivo se ha cambiado correctamente"
          );
        }
      },
    },
    {
      name: "Borrar",
      icon: "pi pi-trash",
      action: async (file, attachmentKey) => {
        deleteFile(file, attachmentKey);
      },
    },
  ];

  return (
    <>
      <Toast ref={toast} />
      <UserPresentation
        id={userFlatten._id}
        name={userFlatten.name || " "}
        lastName={userFlatten.lastName || " "}
        profileImgUrl={userFlatten.urlImgProfile}
        role={userFlatten.userRole}
        edit={userRole === "administration" || userFlatten._id === uid}
        shifts={reduceString(userFlatten?.courses, "shift", "/")}
        level={reduceString(userFlatten?.courses, "level", "/")}
        course={
          userFlatten?.courses &&
          userFlatten?.courses.find(
            (crs) => crs.year === new Date().getFullYear()
          ).age
        }
        disable={disable}
        handleDisable={handleDisable}
      />

      <Row>
        <Col xs={{ offset: 1 }}>
          <h4 className="mb-4">Obra social</h4>
        </Col>
      </Row>

      <Row className="my-4">
        <Col xs={{ span: 2, offset: 1 }}>
          <CustomInput
            disabled={!disable}
            name="healthInsurance.name"
            labelContent={
                <span className="color-disabled input-label">Obra social</span>
            }
            value={form["healthInsurance.name"]}
            handleInputChange={onInputChange}
            placeholder="Ej:OSI"
            classNameInput="body-l w-100"
            classNameLabel="input-label"
            alwaysShow
          />
        </Col>
        <Col xs={2}>
          <CustomInput
            disabled={!disable}
            name="healthInsurance.id"
            placeholder="Ej: 11 2255 3330"
            labelContent={
              <span className="color-disabled input-label">
                Número de afiliado
              </span>
            }
            value={form["healthInsurance.id"]}
            handleInputChange={onInputChange}
            classNameInput="body-l w-100"
            classNameLabel="input-label"
            alwaysShow
          />
        </Col>
      </Row>

      <Row>
        <Col xs={{ span: 10, offset: 1 }}>
          <Line mt={4} mb={4} />
        </Col>
      </Row>

      <Row>
        <Col xs={{ offset: 1 }}>
          <h4 className="mb-4">Documentación médica</h4>
        </Col>
      </Row>

      <Row>
        <Col xs={{ span: 11, offset: 1 }}>
          <h5 className="mb-3">Aptos físicos</h5>
        </Col>
        {attachments.physicalAbilityCertifications
          ?.filter(Boolean)
          .map((file, index) => (
            <Col
              md={{ span: 3, offset: index % 3 === 0 ? 1 : 0 }}
              s={4}
              xs={6}
              key={file.id}
              className={"mt-2"}
            >
              <File
                key={index}
                file={file}
                options={options}
                onError={showError}
                fileId="physicalAbilityCertifications"
              />
            </Col>
          ))}
        <Col xs={{ span: 11, offset: 1 }} className="mb-4">
          <FilePicker
            onChange={(e) =>
              onFilePickerChange(e, "physicalAbilityCertifications", true)
            }
          >
            <Button
              disabled={!disable}
              className={"button-primary"}
              content="Subir Archivo"
            />
          </FilePicker>
        </Col>
      </Row>

      <Row>
        <Col xs={{ span: 10, offset: 1 }}>
          <Line mb={4} />
        </Col>
      </Row>

      <Row>
        <Col xs={{ span: 11, offset: 1 }}>
          <h5 className="mb-3">Vacunas</h5>
        </Col>
        {attachments.vaccinationCertificate && (
          <Col md={{ span: 3, offset: 1 }} s={4} xs={6} className={"mt-2"}>
            <File
              file={attachments.vaccinationCertificate}
              options={options}
              onError={showError}
              fileId="vaccinationCertificate"
            />
          </Col>
        )}
        <Col xs={{ span: 11, offset: 1 }} className="mb-4">
          <FilePicker
            onChange={(e) =>
              onFilePickerChange(e, "vaccinationCertificate", false)
            }
          >
            <Button
              disabled={!disable}
              className={"button-primary"}
              content="Reemplazar Archivo"
            />
          </FilePicker>
        </Col>
      </Row>

      <Row>
        <Col xs={{ span: 10, offset: 1 }}>
          <Line mb={4} />
        </Col>
      </Row>

      <Row>
        <Col xs={{ span: 11, offset: 1 }}>
          <h5 className="mb-3">Grupo sanguíneo</h5>
        </Col>
        {attachments.bloodTypeCertificate && (
          <Col md={{ span: 3, offset: 1 }} s={4} xs={6} className={"mt-2"}>
            <File
              file={attachments.bloodTypeCertificate}
              options={options}
              onError={showError}
              fileId="bloodTypeCertificate"
            />
          </Col>
        )}
        <Col xs={{ span: 11, offset: 1 }} className="mb-4">
          <FilePicker
            onChange={(e) =>
              onFilePickerChange(e, "bloodTypeCertificate", false)
            }
          >
            <Button
              disabled={!disable}
              className={"button-primary"}
              content="Reemplazar Archivo"
            />
          </FilePicker>
        </Col>
      </Row>
    </>
  );
};
