import axios from 'axios'
import { startLogout } from '../actions/auth'
import { store } from '../store/store'
const baseUrl = process.env.REACT_APP_API_URL

export const setToken = (token) => {
    localStorage.setItem('token', token)
    axios.defaults.headers.common['x-token'] = token
}

export const clearToken = window.clearToken = () => {
    localStorage.clear('token')
    axios.defaults.headers.common['x-token'] = ''
}

axios.interceptors.response.use(
    (response) => response,
    (error, any) => {
        if (error.response.status === 401)
            store.dispatch(startLogout())

        return Promise.reject(error.response)
    }
)

// ----------------------------
axios.defaults.baseURL = baseUrl
axios.defaults.headers["Content-type"] = "application/json"

const token = localStorage.getItem('token')
token && setToken(token)
// ----------------------------

export const api = axios
