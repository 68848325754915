import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { useForm } from '../../../hooks/useForm'
import { CustomInput } from '../../CustomInput'
import { RadioButton } from 'primereact/radiobutton';
import { api } from '../../../helpers/fetch';
import { useDispatch } from 'react-redux';
import { createLog } from '../../../actions/logs';
import { Button } from '../../Button'
import { Loading } from '../../Loading';
import { useHistory } from 'react-router';
import { CustomSelect } from '../../CustomSelect';
import moment from 'moment';

export const UploadUserForm = ({ id }) => {
  const dispatch = useDispatch();
  const { goBack, push } = useHistory();
  const [loading, setLoading] = useState(true);
  const [values, handleInputChange, setValues] = useForm({
    name: "",
    lastName: "",
    userRole: "",
    email: "",
    documentType: "DU",
    documentId: "",
    celPhone: "",
    directorType: '',
    level: '',
    shift: '',
    userAllRoles: [],
    incorporationDate: ''
  });

  const handleDateInputChange = ({ target }) => {
    setValues({
      ...values,
      incorporationDate: moment(target.value).format('yyyy-MM-DD')
    })
  }

  const getUser = async () => {
    api.get(`auth/getUser/${id}`)
      .then(
        resp => {
          setLoading(false);
          resp.data.ok ?
            setValues(resp.data.user)
            :
            dispatch(
              createLog({
                type: "error",
                title: "Planillas de Control",
                text: resp.data.msg
              })
            )
        }
      ).catch(err => console.log(err))
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    if (id !== undefined) {
      getUser();
    } else
      setLoading(false);
  }, []);

  const roleOptions = [
    { label: "Alumnos", value: "students" },
    { label: "Docentes", value: "teachers" },
    { label: "Administrativo/a", value: "administration" },
    { label: "Preceptor", value: "tutorship" },
    { label: "Secretario/a", value: "secretariat" },
    { label: "Directivos", value: "directorate" }
  ];

  const directorOptions = [
    { label: "Director general", value: 'GeneralDirector' },
    { label: 'Representante legal', value: 'LegalRepresentative' },
    { label: 'Director', value: 'Director' },
    { label: 'Vicedirector', value: 'Vicedirector' }
  ]

  const directorLevel = [
    { label: 'Inicial', value: 'Elementary' },
    { label: 'Primaria', value: 'Primary' },
    { label: 'Secundaria', value: 'High' }
  ]

  const directorShift = [
    { label: 'Mañana', value: 'Mañana' },
    { label: 'Tarde', value: 'Tarde' },
    { label: 'Noche', value: 'Noche' }
  ]

  const otherRoles = [
    { label: 'Profesor', value: ['directorate', 'teachers'] },
    { label: 'No tiene', value: [''] }
  ]

  const handleSubmit = async e => {
    e.preventDefault()
    const index = values.email.indexOf("@")
    if (values.email.slice(index) !== "@ilp.edu.ar") {
      return dispatch(
        createLog({
          type: "error",
          title: "Crear Usuario",
          text: "El email debe ser institucional",
        })
      )
    }
    try {
      if (id) {
        const { data } = await api.put(`auth/modify-profile/${id}`, values)
        dispatch(
          createLog({
            type: "success",
            title: "Editar Usuario",
            text: data.msg,
          })
        )
        push("/controlsheets")
      } else {
        const { data } = await api.post("auth/new", values)
        dispatch(
          createLog({
            type: "success",
            title: "Crear Usuario",
            text: `${data.user.name} ${data.user.lastName}: Se ha agregado satisfactoriamente`,
          })
        )
        push("/controlsheets")
      }
    } catch (error) {
      dispatch(
        createLog({
          type: "error",
          title: "Error",
          text: "Algo fue mal"
        })
      )
    }
  }
  if (loading) {
    return <Loading />
  }
  return (
    <form onSubmit={handleSubmit} className='my-5 newUserForm__container'>
      <Row className='my-1'>
        <Col xs={{ offset: 1, span: 10 }}>
          <p className='body-l color-labels body-l'> * Seleccione el rol del usuario</p>
          <div className='d-flex mt-3 pt-1 not-teacher-options'>
            {
              roleOptions.map(role => {
                return <div key={role.label} className='d-flex align-items-center mr-4 '>
                  <RadioButton value={role.value} name="userRole" onChange={handleInputChange} checked={values.userRole === role.value} />
                  <label className='my-0 px-2'>{role.label}</label>
                </div>
              }
              )
            }
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs={{ offset: 1, span: 3 }} className="my-4">
          <CustomInput name="lastName" value={values.lastName} labelContentDown="Como se encuentra en su documento" labelContent='* Apellido' classNameInput='w-100' placeholder='* Apellido' handleInputChange={handleInputChange} />
        </Col>
        <Col xs={3} className="my-4">
          <CustomInput name="name" value={values.name} labelContentDown="Como se encuentra en su documento" labelContent='* Nombre' classNameInput='w-100' placeholder='* Nombre' handleInputChange={handleInputChange} />
        </Col>
      </Row>
      {
        values.userRole === 'directorate' &&
        <>
          <Row>
            <Col xs={{ offset: 1, span: 3 }} className='my-4'>
              <CustomSelect
                options={directorOptions}
                placeholder='* Cargo'
                alwaysShow
                handleInputChange={handleInputChange}
                values={values.directorType}
                name="directorType"
              />
            </Col>
            {
              (values.directorType === 'Director' || values.directorType === 'Vicedirector') &&
              <>
                <Col xs={3} className="my-4">
                  <CustomSelect
                    options={directorLevel}
                    placeholder='* Nivel'
                    alwaysShow
                    handleInputChange={handleInputChange}
                    values={values.level}
                    name="level"
                  />
                </Col>
                <Col xs={3} className="my-4">
                  <CustomSelect
                    options={directorShift}
                    placeholder='* Turno'
                    alwaysShow
                    handleInputChange={handleInputChange}
                    values={values.shift}
                    name="shift"
                  />
                </Col>
              </>
            }
          </Row>
          <Row>
            <Col xs={{ offset: 1, span: 3 }} className='my-4'>
              <CustomSelect
                options={otherRoles}
                placeholder='* Otro rol'
                alwaysShow
                handleInputChange={handleInputChange}
                values={values.userAllRoles}
                name="userAllRoles"
              />
            </Col>
          </Row>
        </>
      }
      <Row>
        <Col xs={{ offset: 1, span: 10 }} className="my-0">
          <hr />
        </Col>
      </Row>
      <Row>
        <Col xs={{ offset: 1, span: 4 }} className="my-4">
          <CustomInput name="email" value={values.email} labelContentDown="Correo para ingresar a la plataforma" labelContent='* Dirección de correo del instituto' classNameInput='w-100' placeholder='* Dirección de correo del instituto' handleInputChange={handleInputChange} classNameLabel="color-label input-label" />
        </Col>
      </Row>
      <Row className='my-4'>
        <Col xs={{ offset: 1, span: 1 }} >
          <CustomInput name="documentType" value={values.documentType} labelContent='DNI' classNameInput='w-100' placeholder='DNI' handleInputChange={handleInputChange} />
        </Col>
        <Col xs={2} >
          <CustomInput name="documentId" value={values.documentId} labelContent='Nro. de documento' classNameInput='w-100' placeholder='Nro. de Documento' handleInputChange={handleInputChange} />
        </Col>
        <Col xs={2} >
          <CustomInput name="celPhone" value={values.celPhone} labelContent='Celular' classNameInput='w-100' placeholder='Celular' handleInputChange={handleInputChange} />
        </Col>
      </Row>
      <Row className='mt-5 mb-4'>
        <Col xs={{ offset: 1, span: 3 }}>
          <CustomInput
            type="date"
            classNameInput="w-100 my-4 cursor-pointer"
            classNameLabel="w-100 mb-4 pb-2 "
            handleInputChange={handleDateInputChange}
            name="incorporationDate"
            labelContent={<><span style={{ color: "#ff0000" }}>*</span> Fecha de incorporación</>}
            value={moment(values.incorporationDate).format("yyyy-MM-DD")}
            placeholder="Fecha de incorporación"
            alwaysShow />
        </Col>
      </Row>
      <Row className='my-5  align-items-center'>
        <Col xs={{ span: 10, offset: 1 }}>
          <div className=' d-flex justify-content-end w-100 '>
            <Button
              type="reset"
              onClick={goBack}
              className='button__only-icon-withoutcolor color-primary button-m-sb mr-5'
              content="Cancelar"
            />
            <Button
              type="submit"
              className='button-primary button-m-sb'
              content={`${id ? "Actualizar" : "Crear"} usuario`}
            />
          </div>
        </Col>
      </Row>
    </form >
  )
}
