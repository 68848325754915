import { useEffect, useState } from "react"
import { Col, Row } from "react-bootstrap"
import { AccordionToggle } from "../../AccordionToggle"
import { Button } from "../../Button"
import { ActivityForm } from "./ActivityForm"
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import FiberManualRecordOutlinedIcon from '@mui/icons-material/FiberManualRecordOutlined';
import { SubjectActivityAccBody } from "./SubjectActivityAccBody"


export const SubjectTopicAccBody = ({ topic, role, bimester }) => {

    const [addActívity, setAddActivity] = useState(true)
    const [activities, setActivities] = useState([])

    const handleAddActivity = () => { setAddActivity(false) }

    useEffect(() => {
        setActivities(topic.activities)
    }, [topic])

    return (
        <>
            {activities?.map((activity, i) =>
                <Row className="mx-2" key={i}>
                    <Col className="mb-2">
                        <AccordionToggle items={
                            [{
                                noBorder: true,
                                header:
                                    <>
                                        <div className="d-flex">
                                            {activity.type === 'activity' ?
                                                <FiberManualRecordOutlinedIcon className="icons__8 color-primary pt-1" />
                                                :
                                                <FiberManualRecordIcon className="icons__8 color-primary pt-1" />
                                            }
                                            <h5 className="ml-3 m-0">{activity.type === 'activity' ? "Actividad: " : "Evaluación:"}</h5>
                                            <h5 className="ml-2 m-0 body-l">{activity.title}</h5>
                                        </div>
                                    </>,
                                body: <SubjectActivityAccBody
                                    bimester={bimester}
                                    activities={activities}
                                    setActivities={setActivities}
                                    activity={activity} 
                                    role={role}/>
                            }]
                        } />
                    </Col>
                </Row>
            )}
            {
                role !== 'students' &&
                (addActívity ?
                    <Button
                        icon="plusMUI"
                        content={<span className="ml-1">Agregar contenido</span>}
                        className="mt-3 mb-4 ml-3 button-terciary button-s-sb d-flex align-items-center"
                        iconRight={false}
                        type="button"
                        onClick={handleAddActivity}
                    />
                    :
                    <ActivityForm
                        topic={topic}
                        activities={activities}
                        setActivities={setActivities}
                        setAddActivity={setAddActivity} />)
            }
        </>
    )
}