import React from 'react'
import InsertInvitationOutlinedIcon from '@mui/icons-material/InsertInvitationOutlined';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import { Link } from 'react-router-dom';
import moment from 'moment'
import 'moment/locale/es'

export const UpcomingEventCard = ({ evt }) => {
    moment.locale('es');

    return (
        <div className='upcomingEventCard p-3'>
            <h4>Evento próximo</h4>
            <h5 className='mt-3'>{evt.title}</h5>
            <hr className='upcomingEventCard__hr w-100 my-2' />
            <div className='d-flex flex-direction-column mb-2  '>
                <div className='mb-2 d-flex align-items-center icons__18 '>
                    <InsertInvitationOutlinedIcon />
                    <span className='body-s-b text-nowrap ml-2 '>
                        {moment(evt.date).format("dddd DD [de] MMMM ")}
                    </span>
                </div>
                <div className='mb-2 d-flex align-items-center icons__18 '>
                    <AccessTimeOutlinedIcon />
                    <span className='body-s-b text-nowrap ml-2'>
                        {moment(evt.fromHour).format("hh:mm A ")}
                    </span>
                </div>

            </div>
            <div className='w-100 d-flex justify-content-center mt-auto'>
                <Link to="/calendar" className='upcomingEventCard__link'>
                    <InsertInvitationOutlinedIcon /> <span className='button-s-sb'>Ver todos los eventos</span>
                </Link>
            </div>
        </div>
    )
}
