import { faChevronRight, faCircle } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useEffect, useState } from "react"
import { Col, Row } from "react-bootstrap"
import { Link } from "react-router-dom"
import { useParams } from "react-router-dom/cjs/react-router-dom.min"
import CustomText from "../../CustomText"
import { Line } from "../../Line"

export const SubjectStudentEvaluativeReport = ({ course, standard, infoActivity, period, infoStudent, role }) => {

    const { id: subjectId, uid: studentId } = useParams()
    const [infoBimester, setinfoBimester] = useState([])

    const filerBimester = () => {
        const unitsByBimester = infoStudent.filter(aux => aux.bimester.toString() === period.toString())
        setinfoBimester(unitsByBimester)
    }

    useEffect(() => {
        filerBimester()
    }, [])

    return (
        <>
            {
                infoBimester.length
                    ?
                    <>
                        {infoBimester?.length > 0 && <Line />}
                        {infoBimester.map((std, i) =>
                            <div className="mx-4 mb-0" key={i}>
                                <Row className='justify-content-between align-items-center'>
                                    <Col className='col-auto d-flex align-items-center py-2'>
                                        <FontAwesomeIcon icon={faCircle} className='color-primary fa-xs p-2 mr-2 my-1' />
                                        <p className='body-l-sb'>
                                            {infoActivity?.find(aux => aux._id === std.activity)?.type === "activity" ? "Actividad: " : "Evaluación:"}
                                            <span className='body-l'>
                                                {infoActivity.find(aux => aux._id === std.activity)?.title}
                                            </span>
                                        </p>
                                    </Col>
                                    <Col className='col-auto d-flex align-items-center py-2'>
                                        {
                                            std.isQualify ?
                                                <>
                                                    <p className='body-m-sb'>
                                                        <CustomText className='body-m-sb' value={std?.calification} />
                                                        <span className='body-m'>- Calificado el {std?.dateCalification}</span>
                                                    </p>
                                                    <Link to={{
                                                        pathname: `/subjects/detail/evaluative-report/${subjectId}/${studentId}/${std._id}`,
                                                        state: { course, standard: std }
                                                    }} className='color-primary'><FontAwesomeIcon icon={faChevronRight} className='ml-2 fa-sm' /></Link>
                                                </>
                                                :
                                                <>
                                                    <Link to={{
                                                        pathname: `/subjects/detail/evaluative-report/${subjectId}/${studentId}/${std._id}`,
                                                        state: { course, standard: std }
                                                    }} className='color-primary'>{role === 'students' ? "Sin calificar" : "Calificar"}</Link>
                                                    <FontAwesomeIcon icon={faChevronRight} className='ml-2 p-2 fa-sm' />
                                                </>
                                        }
                                    </Col>
                                </Row>
                                {i < infoBimester.length - 1 && <Line />}
                            </div>
                        )}
                    </>
                    :
                    <>
                        <p className='body-l mx-3 py-2' >No se entregó ninguna actividad o evaluación</p>
                    </>
            }
        </>
    )
}