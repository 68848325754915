import { Button } from "../../Button"
import { useEffect, useState } from "react"
import { CustomInput } from "../../CustomInput"
import { api } from "../../../helpers/fetch"
import { Col, Row } from "react-bootstrap"
import { AccordionToggle } from "../../AccordionToggle"
import { SubjectUnitAccBody } from "./SubjectUnitAccBody"
import { useDispatch } from "react-redux"
import { createLog } from "../../../actions/logs"

export const SubjectModuleAccBody = ({ role, data, subjectId, index }) => {

    const dispatch = useDispatch();
    const [addUnit, setAddUnit] = useState(true)
    const [input, setInput] = useState("")
    const [units, setUnits] = useState()



    const handleAddUnit = () => { setAddUnit(false) }

    const handleInputUnitNameChange = ({ target }) => setInput(target.value)

    const handleConfirmNewUnit = async () => {
        const data = {
            title: input,
            subject: subjectId,
            bimester: index,
        }
        await api.post('/subject-units', data).then(res => {
            if (res) {
                setUnits([
                    ...units,
                    {
                        ...res.data,
                        topics: []
                    }
                ])
                setInput("")
                setAddUnit(true)
            }
        })
    }

    const handleCancelNewUnit = () => {
        setAddUnit(true)
    }

    const handleDelUnit = (e, unit, i) => {
        if (!unit.topics.length) {
            api.delete(`subject-units/${unit._id}`).then(res => {
                if (res) {
                    const newUnits = units.filter((unit, index) => index !== i)
                    setUnits(newUnits)
                }
            })
        } else {
            dispatch(createLog({
                type: "error",
                title: "No se pudo borrar",
                text: `La Unidad que intenta borrar contiene Temas. Elimine estos e intentelo nuevamente`,
            }))
        }
    }

    useEffect(() => {
        const bimesterData = data?.filter(data => data.bimester === index)
        setUnits(bimesterData)
    }, [data, index])


    return (
        <>
            {units?.map((unit, i) =>
                <Row className="mx-2" key={unit._id}>
                    <Col className="mb-2">
                        <AccordionToggle items={
                            [{
                                noBorder: true,
                                header: <>
                                    <div className="d-flex space-between w-100">
                                        <h5 className="m-0">{unit.title}</h5>
                                        {
                                            role !== 'students' &&
                                            <Button
                                                type="button"
                                                icon="deleteMUI"
                                                className="button__only-icon-withoutcolor button-s-sb mr-4 p-0 "
                                                iconRight={false}
                                                onClick={(e) => handleDelUnit(e, unit, i)} />
                                        }
                                    </div>
                                </>,
                                body: <SubjectUnitAccBody
                                    setUnits={setUnits}
                                    unit={unit}
                                    units={units}
                                    role={role}
                                    bimester={index}
                                />
                            }]
                        } />
                    </Col>
                </Row>
            )}
            {role !== 'students' &&
                (addUnit ?
                <Button
                    className='mb-3 mt-2 ml-4 btn button-primary button-m-sb'
                    content="Agregar unidad"
                    onClick={handleAddUnit} />
                :
                <div className="d-flex mb-4 ml-3 mt-3">
                    <CustomInput
                        classNameInput="mx-3"
                        placeholder="Nombre de la unidad"
                        name="title"
                        value={input}
                        handleInputChange={handleInputUnitNameChange} />
                    <Button
                        icon="check"
                        className="circular-border-success mx-4"
                        onClick={handleConfirmNewUnit} />
                    <Button
                        icon="closeMUI"
                        className="circular-border-error"
                        onClick={handleCancelNewUnit} />
                </div>)
            }
        </>
    )
}