import { useEffect, useState } from "react"
import { Col, Container, Row } from "react-bootstrap"
import { useParams } from "react-router-dom"
import { useHistory } from "react-router-dom"

import { api } from "../../../helpers/fetch"
import { useForm } from "../../../hooks/useForm"
import { BreadCrumbs } from "../../BreadCrumbs"
import { Loading } from "../../Loading"
import { useAuth } from "../../../hooks/useAuth"
import CourseSubjetEdit from "./CourseSubjetEdit"
import CourseSubjetInfo from "./CourseSubjetInfo"


export const CourseSubjectDetail = () => {

    const [loading, setLoading] = useState(true)
    const { push } = useHistory();
    const { courseid } = useParams();
    const { subjectid } = useParams();
    const { user } = useAuth()

    //--------- Course Info --------------
    const [course, setCourse] = useState();

    //--------- Subject Info --------------
    const [values, handleInputChange, setValues] = useForm({
        name: "",
        schedule: [{}],
        teacher: ""
    })

    //--------- schedule Info --------------

    const [scheduleDay, setScheduleDay] = useState(values.schedule)

    const onEditEvent = () => {
        push(`/courses/newsubject/${courseid}/${subjectid}`)
    }


    const getCourseDetail = async (id) => {
        await api.get(`courses/getById/${id}`).then(res => {
            setCourse(res.data.course);
            setLoading(false);
        })
    }

    const getSubjectDetail = async (id) => {
        await api.get(`subjects/getById/${id}`).then(async res => {
            await setScheduleDay(res.data.subject.schedule);
            await setValues(res.data.subject);
        })
    }

    useEffect(() => {
        getSubjectDetail(subjectid)
        getCourseDetail(courseid);
    }, [])

    return (
        <>
            {loading ? <Loading /> :
                <Container>
                    <Row>
                        <Col xs={12}>
                            <BreadCrumbs lastSteps={[{ to: "/home", step: "Inicio" }, { to: "/Courses", step: "Cursos" }, { to: `/courses/detail/${courseid}`, step: `${course?.age} ${course?.division}` }]} title={values.name} />
                        </Col>
                    </Row>
                    {
                        (user.userRole === 'secretariat') ? (

                            <CourseSubjetEdit
                                values={values}
                                subjectid={subjectid}
                                courseid={courseid}
                                course={course}
                                onEditEvent={onEditEvent}
                                scheduleDay={scheduleDay}
                                handleInputChange={handleInputChange}
                            />

                        ) : (
                            <CourseSubjetInfo 
                                values={values} 
                                course={course} 
                                courseid={courseid} 
                                subjectid={subjectid} 
                            />
                        )
                    }
                </Container>
            }
        </>
    )
}