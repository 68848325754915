import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { useParams } from 'react-router';
import { ThreadMessage } from './Detail/ThreadMessage'
import { BreadCrumbs } from '../../BreadCrumbs'
import { getThread } from '../../../api/messages';
import { Loading } from '../../Loading';

export const MessageDetail = () => {
  const [recieved] = useState(true);//esto es para cuando si es mensaje enviado o recibido
  const [thread, setThread] = useState({});
  const [loading, setLoading] = useState(true);

  const VIEW_TYPES = {
    VIEW: 'VIEW',
    REPLY: 'REPLY',
    FORWARD: 'FORWARD'
  }

  const [currentViewType, setCurrentViewType] = useState(VIEW_TYPES.VIEW);
  const { id } = useParams();

  useEffect(() => {
    onUpdateThread()
  }, [id])

  const onUpdateThread = async (thread) => {
    setLoading(true)
    try {
      setThread(await getThread(id))
    } catch (error) {
      console.error(error) // TODO handle error
    }
    setLoading(false)
  }

  if (loading)
    return <Container> <Loading /> </Container>

  return (
    <main>
      <Container>
        <Row className='mb-4 pb-2'>
          <Col xs={12}>
            <BreadCrumbs lastSteps={[{ to: "/home", step: "Inicio" }, { to: "/messages", step: "Mensajes" }]} title={recieved ? "Mensaje recibido" : "Mensaje enviado"} />
          </Col>
        </Row>
        <div className='messagedetail__container'>
          <Row className="mt-5 mb-3">
            <Col xs={{ span: 11, offset: 1 }}>
              <h3>{(thread?.messages || [])[0]?.payload?.headers.find(head => head.name === "Subject")?.value}</h3>
            </Col>
          </Row>
          {thread?.messages?.map((msg, i) => {
            const isLast = i + 1 === thread.messages.length
            return <React.Fragment key={i}>
              <ThreadMessage msg={msg} onUpdateThread={onUpdateThread} isLast={isLast} />
            </React.Fragment>
          })}
        </div>

      </Container>
    </main>
  )
}
