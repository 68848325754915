import { useState } from "react"
import { Col, Container, Row } from "react-bootstrap"
import { useParams } from "react-router-dom"
import { Board } from "../../Board"
import { BreadCrumbs } from "../../BreadCrumbs"
import { CustomOptions } from "../../CustomOptions"
import { Loading } from "../../Loading"
import classNames from "classnames"
import CustomText from "../../CustomText"
import { Button } from "../../Button"
import { usePutAndGetData } from "../../../hooks/usePutAndGetData"
import { Switch } from "../../Switch"
import { CustomSelect } from "../../CustomSelect"
import { CustomInput } from "../../CustomInput"

const options = [
    { label: "1er Cuatrimestre", type: "firstTerm" },
    { label: "2do Cuatrimestre", type: "secondTerm" },
    { label: "Otras instancias", type: "otherInstances" }
]

const CourseDirectiveClosingPeriod = () => {

    const { courseid, subjectid } = useParams()
    const [customOption, setcustomOption] = useState(options[0])
    const {
        course,
        subject,
        editedAssessments,
        loading,
        handleInputChange,
        handleAproveAll,
        onSubmitEvent,
        otherInstance,
        otherInstanceOption,
        handleSelectedInstanceChange,
        selectedInstance
    } = usePutAndGetData(courseid, subjectid, customOption)

    const [disable, setDisable] = useState(true)
    const [infoSearch, setInfoSearch] = useState([])

    const handleDisableTrue = () => { setDisable(true) }
    const handleDisableFalse = () => { setDisable(false) }
    const handleSearch = () => { setInfoSearch(selectedInstance) }

    return (
        <main>
            {loading ? <Loading /> :
                <Container>
                    <Row className='mb-2 pb-2'>
                        <Col xs={12}>
                            <BreadCrumbs
                                lastSteps={[
                                    { to: "/home", step: "Inicio" },
                                    { to: "/courses", step: "Cursos" },
                                    { to: `/courses/detail/${course._id}`, step: `${course.age ?? ''} ${course.division ?? ''} - ${course.shift ?? ''}` },
                                    { to: `/courses/detail/subjects/${course._id}/${subject._id}`, step: `${subject.name ?? ''}` },
                                ]}
                                title="Cierre de periodos"
                            />
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={12}>
                            <CustomOptions
                                options={options}
                                value={customOption}
                                setValue={setcustomOption}
                            />
                        </Col>
                    </Row>
                    {
                        customOption.type !== 'otherInstances' ?
                            <Row className="mb-3 justify-content-end">
                                <Col xs="auto">
                                    <Button
                                        className='btn button-primary button-m-sb'
                                        content={disable === true ? "Editar cierre de cuatrimestre" : "Aprobación masiva"}
                                        onClick={disable === true ? handleDisableFalse : handleAproveAll}
                                        disabled={editedAssessments?.some(aux=> aux.finalReviews.fisrtTerm) ? false : true}
                                    />
                                </Col>
                            </Row>
                            :
                            <Row className="my-3">
                                <Col xs={{ span: 10, offset: 0 }} className="d-flex align-items-center">
                                    <CustomSelect
                                        options={otherInstanceOption}
                                        placeholder={otherInstance.length ? "Elija una instancia" : "No hay instancias creadas"}
                                        labelContent={<span className='color-disabled input-label'>Otras instancias</span>}
                                        alwaysShow
                                        handleInputChange={handleSelectedInstanceChange}
                                        values={selectedInstance}
                                        name="otherInstance" />

                                    <Button
                                        className='btn button-secondary button-m-sb mx-4'
                                        content="Aplicar"
                                        onClick={handleSearch} />
                                </Col>
                            </Row>
                    }
                    <Board
                        className="board-row-color-3 pt-3"
                        firstRow={
                            <>
                                <Col xs={2}>
                                    <div className='my-2'>
                                        <span className='body-m-b'>Apellido y nombre</span>
                                    </div>
                                </Col>
                                {customOption.type !== 'otherInstances' ?
                                    <>
                                        <Col xs={2}>
                                            <div className='my-2 d-flex center-box'>
                                                <span className='body-m-b'>1er Bimestre</span>
                                            </div>
                                        </Col>
                                        <Col xs={2}>
                                            <div className='my-2 d-flex center-box'>
                                                <span className='body-m-b'>2do Bimestre</span>
                                            </div>
                                        </Col>
                                        <Col xs={2}>
                                            <div className='my-2 d-flex center-box'>
                                                <span className='body-m-b'>Nota conceptual</span>
                                            </div>
                                        </Col>
                                        <Col xs={2}>
                                            <div className='my-2 d-flex center-box'>
                                                {
                                                    customOption.type === 'firstTerm'
                                                        ?
                                                        <span className='body-m-b'>1er Cuatrimestre</span>
                                                        :
                                                        <span className='body-m-b'>2do Cuatrimestre</span>

                                                }
                                            </div>
                                        </Col>
                                        <Col xs={2}>
                                            <div className='my-2 d-flex center-box'>
                                                <span className='body-m-b'>Estado</span>
                                            </div>
                                        </Col>
                                    </>
                                    :
                                    <>
                                        <Col xs={2}>
                                            <div className='my-2 d-flex center-box'>
                                                <span className='body-m-b'>1er Cuatrimestre</span>
                                            </div>
                                        </Col>
                                        <Col xs={2}>
                                            <div className='my-2 d-flex center-box'>
                                                <span className='body-m-b'>2do Cuatrimestre</span>
                                            </div>
                                        </Col>
                                        <Col xs={3}>
                                            <div className='my-2 d-flex center-box'>
                                                <span className='body-m-b'>Otras instancias</span>
                                            </div>
                                        </Col>
                                    </>
                                }
                            </>
                        }
                        rows={editedAssessments.map((assessment, i) =>
                            <Row className={classNames('py-1', { "board__row ": i !== assessment.length, "board__last-row ": i === assessment.length - 1 })} key={assessment._id} >
                                <Col xs={2}>
                                    <div className='my-2'>
                                        <span className='body-m'>{`${assessment.student?.lastName}, ${assessment.student?.name}`}</span>
                                    </div>
                                </Col>

                                {customOption.type === 'firstTerm' &&
                                    <>
                                        <Col xs={2} className="d-flex center-box">
                                            <CustomText value={assessment.finalReviews?.firstBimester} />
                                        </Col>
                                        <Col xs={2} className="d-flex center-box">
                                            <CustomText value={assessment.finalReviews?.secondBimester} />
                                        </Col>
                                        <Col xs={2} className="d-flex center-box">
                                            <CustomText value={Math.trunc((assessment.finalReviews?.firstBimester + assessment.finalReviews?.secondBimester) / 2)} />
                                        </Col>
                                        <Col xs={2} className="d-flex center-box">
                                            <CustomText value={assessment.finalReviews?.fisrtTerm} />
                                        </Col>
                                        <Col xs={2} className="d-flex center-box">
                                            <p className="mx-2" >{(assessment?.finalReviews?.isAprovedfirst) ? 'Aprobado' : 'Pendiente'}</p>
                                            <Switch
                                                name={i}
                                                handleInputChange={(e) => handleInputChange(i, e.target.checked)}
                                                switchValue={assessment?.finalReviews?.isAprovedfirst}
                                                type="checkbox"
                                                disabled={disable}
                                            />
                                        </Col>
                                    </>
                                }
                                {customOption.type === 'secondTerm' &&
                                    <>
                                        <Col xs={2} className="d-flex center-box">
                                            <CustomText value={assessment.finalReviews?.thirthBimester} />
                                        </Col>
                                        <Col xs={2} className="d-flex center-box">
                                            <CustomText value={assessment.finalReviews?.fourthBimester} />
                                        </Col>
                                        <Col xs={2} className="d-flex center-box">
                                            <CustomText value={Math.trunc((assessment.finalReviews?.thirthBimester + assessment?.finalReviews?.fourthBimester) / 2)} />
                                        </Col>
                                        <Col xs={2} className="d-flex center-box">
                                            <CustomText value={assessment.finalReviews?.secondTerm} />
                                        </Col>
                                        <Col xs={2} className="d-flex center-box">
                                            <p className="mx-2" >{(assessment?.finalReviews?.isAprovedsecond) ? 'Aprobado' : 'Pendiente'}</p>
                                            <Switch
                                                name={i}
                                                handleInputChange={(e) => handleInputChange(i, e.target.checked)}
                                                switchValue={assessment?.finalReviews?.isAprovedsecond}
                                                type="checkbox"
                                                disabled={disable}
                                            />
                                        </Col>
                                    </>
                                }
                                {customOption.type === 'otherInstances' &&
                                    <>
                                        <Col xs={2} className="d-flex center-box">
                                            <CustomText value={assessment.finalReviews?.fisrtTerm} />
                                        </Col>
                                        <Col xs={2} className="d-flex center-box">
                                            <CustomText value={assessment.finalReviews?.secondTerm} />
                                        </Col>
                                        <Col xs={3} className="d-flex center-box">
                                            <p>{otherInstance.find(aux => aux._id === infoSearch)?.name}<b className="mx-1">-</b></p>
                                            <CustomInput
                                                classNameInput="border-bottom-none text-align-center tam__custom"
                                                name='otherInstances'
                                                value={assessment.finalReviews.otherReviews.find(or => or.otherInstance === infoSearch)?.instanceReview}
                                                disabled={true}
                                            />
                                        </Col>
                                    </>
                                }

                            </Row>
                        )}
                    />
                    {
                        (customOption.type !== 'otherInstances' && disable === false) &&
                        <Row className="my-5">
                            <Col xs={{ span: 10, offset: 2 }} className="d-flex">
                                <div className='ml-auto d-flex'>
                                    <Button
                                        type="reset"
                                        className='btn button-secondary color-primary  button-m-sb mr-5'
                                        content="Cancelar"
                                        onClick={handleDisableTrue} />
                                    <Button
                                        className='btn button-primary button-m-sb'
                                        content="Aprobar seleccionados"
                                        onClick={() => { onSubmitEvent(); handleDisableTrue() }}
                                    />
                                </div>
                            </Col>
                        </Row>
                    }
                </Container>
            }
        </main >
    )
}
export default CourseDirectiveClosingPeriod