import { Col, Row } from "react-bootstrap"
import { CustomSelect } from "../../CustomSelect"
import { CustomSwitch } from "../../CustomSwitch"
import { Line } from "../../Line"
import { formatDateForInput } from '../../../helpers/format-date-for-input'
import { documentTypes } from '../../../helpers/document-types'
import { nationalities } from '../../../helpers/nationalities'
import { provinces } from '../../../helpers/provinces'
import { CustomInput } from "../../CustomInput"

export const UserParentInformation = ({ title, form, handleInputChange, parentToModify, disable }) => {

    const WrappedSwitch = (props) => {
        return <CustomSwitch
            state={form[props.name]}
            {...props}
            handleChange={(e, value) => handleInputChange({ target: { value, name: props.name } })} />
    }

    const getKey = (key) => parentToModify && `${parentToModify}.${key}`
    // ------------------------------------------
    const homeTypes = [
        { label: 'Casa', value: 'home' },
        { label: 'Departamento', value: 'apt' },
        { label: 'PH', value: 'ph' },
    ]

    return (
        <>
            <Row className='userper mt-2'>
                <Col xs={{ span: 8, offset: 1 }}>
                    <div className='d-flex justify-content-between'>
                        <h4>{title}</h4>
                    </div>
                </Col>
            </Row>

            <Row className="align-items-center pb-2">
                <Col xs={{ span: 3, offset: 1 }}>
                    <div className="d-flex justify-content-between align-items-center">
                        <p className="body-m-regular">Habilitado para retirar</p>
                        <WrappedSwitch name={getKey('authorizedToRetire')} disabled={!disable}/>
                    </div>
                </Col>
            </Row>
            <Row className="my-4">
                <Col xs={{ span: 3, offset: 1 }} className='userpersonaldata__form-input body-l'>
                    <CustomInput
                        labelContent={<span className='color-disabled input-label'>Apellido y nombre</span>}
                        name={getKey('fullName')}
                        value={form[getKey('fullName')]}
                        handleInputChange={handleInputChange}
                        placeholder="Ej: Alves, Roberto"
                        classNameInput="w-100"
                        alwaysShow
                        disabled={!disable}
                    />
                </Col>
                <Col xs={2}>
                    <CustomInput
                        type="date"
                        name={getKey('birthDate')}
                        value={form[getKey('birthDate')]}
                        value={formatDateForInput(form[getKey('birthDate')])}
                        handleInputChange={handleInputChange}
                        labelContent={<span className='color-disabled input-label'>Fecha de nacimiento</span>}
                        placeholder="Ej: 15/06/1978"
                        alwaysShow
                        disabled={!disable}
                    />
                </Col>
                <Col xs={2}>
                    <CustomSelect
                        name={getKey('documentType')}
                        values={form[getKey('documentType')]}
                        options={documentTypes()}
                        handleInputChange={handleInputChange}
                        labelContent={<span className='color-disabled input-label'>Tipo de documento</span>}
                        placeholder="Ej: DNI"
                        alwaysShow
                        disabled={!disable}
                    />
                </Col>
                <Col xs={2}>
                    <CustomSelect
                        name={getKey('documentId')}
                        values={form[getKey('documentId')]}
                        handleInputChange={handleInputChange}
                        labelContent={<span className='color-disabled input-label'>DNI</span>}
                        placeholder="Ej: 23.000.332"
                        classNameInput="w-100"
                        alwaysShow
                        disabled={!disable}
                    />
                </Col>
            </Row>
            <Row className="my-5">
                <Col xs={{ span: 2, offset: 1 }}>
                    <CustomSelect
                        name={getKey('country')}
                        values={form[getKey('country')]}
                        options={nationalities()}
                        handleInputChange={handleInputChange}
                        labelContent={<span className='color-disabled input-label'>Nacionalidad</span>}
                        placeholder="Ej: Argentina"
                        alwaysShow
                        disabled={!disable}
                    />
                </Col>
                <Col xs={2}>
                    <CustomSelect
                        name={getKey('province')}
                        values={form[getKey('province')]}
                        options={provinces()}
                        handleInputChange={handleInputChange}
                        labelContent={<span className='color-disabled input-label'>Provincia</span>}
                        placeholder="Ej: Buenos Aires"
                        alwaysShow
                        disabled={!disable}
                    />
                </Col>
                <Col xs={2}>
                    <CustomInput
                        name={getKey('locality')}
                        value={form[getKey('locality')]}
                        handleInputChange={handleInputChange}
                        labelContent={<span className='color-disabled input-label'>Localidad</span>}
                        placeholder="Ej: Ciudadela"
                        alwaysShow
                        disabled={!disable}
                    />
                </Col>
                <Col xs={2}>
                    <CustomInput
                        name={getKey('zipCode')}
                        value={form[getKey('zipCode')]}
                        type="number"
                        handleInputChange={handleInputChange}
                        labelContent={<span className='color-disabled input-label'>Código postal</span>}
                        placeholder="Ej: 4444"
                        alwaysShow
                        disabled={!disable}
                    />
                </Col>
            </Row>

            <Row className="mt-5 mb-4">
                <Col xs={{ span: 2, offset: 1 }}>
                    <CustomInput
                        name={getKey('homeType')}
                        value={form[getKey('homeType')]}
                        options={homeTypes}
                        handleInputChange={handleInputChange}
                        labelContent={<span className='color-disabled input-label'>Tipo de domicilio</span>}
                        placeholder="Ej: Casa"
                        alwaysShow
                        disabled={!disable}
                    />
                </Col>
                <Col xs={3}>
                    <CustomInput
                        labelContent={<span className='color-disabled input-label'>Dirección</span>}
                        name={getKey('address')}
                        value={form[getKey('address')]}
                        handleInputChange={handleInputChange}
                        placeholder="Ej: San Martín 1520"
                        classNameInput="w-100"
                        alwaysShow
                        disabled={!disable}
                    />
                </Col>
                <Col xs={2}>
                    <CustomInput
                        name={getKey('celPhoneParent')}
                        value={form[getKey('celPhoneParent')]}
                        handleInputChange={handleInputChange}
                        labelContent={<span className='color-disabled input-label'>Telefono {parentToModify === 'father' ? 'del padre' : 'de la madre'}</span>}
                        placeholder="Ej: Celular"
                        alwaysShow
                        disabled={!disable}
                    />
                </Col>
            </Row>

            <Row>
                <Col xs={{ span: 10, offset: 1 }}>
                    <Line mt={4} mb={3} />
                </Col>
            </Row>

        </>
    )
}
