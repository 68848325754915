import { useEffect, useState } from 'react'
import { Col, Row, Button } from "react-bootstrap"
import { listCourses } from '../../../api/courses'
import { CustomInput } from '../../CustomInput'
import { CustomSelect } from "../../CustomSelect"
import { Line } from "../../Line"

export const UserBrothersInformation = ({ form, handleInputChange, disable }) => {
    const [brothers, setBrothers] = useState(null)

    useEffect(() => {
        const brothersMap = (() => {
            const keys = Object.keys(form).filter(key => key.startsWith('brothers.'))
            const indexes = keys.map(key => parseInt(key.split('.')[1].split('.')[0]))
            return indexes.filter((value, index, self) => self.indexOf(value) === index)
        })()
        !brothers && brothersMap.length && setBrothers([...brothersMap])
    }, [form, brothers])

    const getKey = (index, key) => `brothers.${index}.${key}`
    const addBrother = () => {
        setBrothers(brothers && [...brothers, brothers.length] || [0])
    }

    // ------------------------------------------
    const levels = [
        { label: 'Jardín', value: 'kindergarten' },
        { label: 'Primaria', value: 'primary' },
        { label: 'Secundaria', value: 'secondary' }
    ]
    const shifts = [
        { label: 'Mañana', value: 'morning' },
        { label: 'Tarde', value: 'afternoon' }
    ]
    const [courses, setCourses] = useState([])

    const getCourses = async () => {
        const { data: { courses } } = await listCourses()
        const coursesMap = courses.map(course => ({ label: course.age, value: course._id }))
        setCourses(coursesMap)
    }

    useEffect(() => {
        getCourses()
    }, [])

    return (
        <>
            <Row className='userper mt-2'>
                <Col xs={{ span: 8, offset: 1 }}>
                    <div className='d-flex justify-content-between mb-4'>
                        <h4>Hermanos en la institución</h4>
                    </div>
                </Col>
            </Row>
            {brothers?.map((brother, i) => <Row key={i} className="my-4">
                <Col xs={{ span: 3, offset: 1 }} className='userpersonaldata__form-input body-l'>
                    <CustomInput
                        name={getKey(i, 'fullName')}
                        value={form[getKey(i, 'fullName')]}
                        labelContent={<span className='color-disabled input-label'>Apellido y nombre</span>}
                        handleInputChange={handleInputChange}
                        placeholder="Ej: Alves, Julieta"
                        classNameInput="w-100"
                        alwaysShow
                        disabled={!disable}
                    />
                </Col>
                <Col xs={2}>
                    <CustomSelect
                        name={getKey(i, 'level')}
                        options={levels}
                        values={form[getKey(i, 'level')]}
                        labelContent={<span className='color-disabled input-label'>Nivel</span>}
                        handleInputChange={handleInputChange}
                        placeholder='Ej: Jardín'
                        alwaysShow
                        disabled={!disable}
                    />
                </Col>
                <Col xs={2}>
                    <CustomSelect
                        name={getKey(i, 'shift')}
                        options={shifts}
                        values={form[getKey(i, 'shift')]}
                        labelContent={<span className='color-disabled input-label'>Turno</span>}
                        handleInputChange={handleInputChange}
                        placeholder='Ej: Mañana'
                        alwaysShow
                        disabled={!disable}
                    />
                </Col>
                <Col xs={2}>
                    <CustomSelect
                        name={getKey(i, 'course')}
                        options={courses}
                        values={form[getKey(i, 'course')]}
                        labelContent={<span className='color-disabled input-label'>Curso</span>}
                        handleInputChange={handleInputChange}
                        placeholder='Ej: Sala 3 roja'
                        alwaysShow
                        disabled={!disable}
                    />
                </Col>
            </Row>
            )}

            <Row>
                <Col xs={{ span: 8, offset: 1 }}>
                    <Row>
                        <Button
                            className='align-items-center button-primary button-m-sb'
                            content={"Agregar"}
                            onClick={addBrother}
                            disabled={!disable}>
                            Agregar
                        </Button>
                    </Row>
                </Col>
            </Row>

            <Row>
                <Col xs={{ span: 10, offset: 1 }}>
                    <Line mt={3} mb={3} />
                </Col>
            </Row>
        </>
    )
}
