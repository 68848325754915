import { Col, Row } from "react-bootstrap"

export const ScrollableBoard = ({ fixedColumnXs = 6, scrollableColumnXs = 6, fixedColumnHeader, fixedColumnBody, scrollableColumnHeader, scrollableColumnBody, className }) => {
    return (
        <div className={'scrollable-board__container px-3 ' + className}>
            <Row>
                <Col xs={fixedColumnXs} className='board__column'>
                    <Row className="board__first-left-row py-2">
                        {fixedColumnHeader}
                    </Row>
                    {fixedColumnBody}
                </Col>
                <Col xs={scrollableColumnXs} className='board__column scroll-x-auto p-0'>
                    <Row className="board__first-right-row width-max-content">
                        {scrollableColumnHeader}
                    </Row>
                    {scrollableColumnBody}
                </Col>
            </Row>
        </div>
    )
}
