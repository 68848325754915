import React from 'react'

export const Form = ({children, onSubmit, className}) => {
    return (
        <form
            className={className}
            onSubmit={
                e => {
                    e.preventDefault();
                    onSubmit();
                }
            }
        >
            {children}
        </form>
    )
}
