import { Col, Row } from "react-bootstrap"
import { Button } from "../../Button"
import { Link } from "react-router-dom"
import { SubjectInfoText } from "../subjects/SubjectInfoText"
import { Line } from "../../Line"

const CourseSubjetInfo = ({ values, course, courseid, subjectid }) => {
    return (
        <>
            <Line mt={4} mb={4}/>
            <Row>
                <Col xs={12} className='my-3'>
                    <h3>Información de la materia</h3>
                </Col>
            </Row>
            <Row>
                <Col xs={3} className='my-3'>
                    <SubjectInfoText name='Curso' values={[`${course?.age} ${course?.division}`]} />
                </Col>
                <Col xs={3} className='my-3'>
                    <SubjectInfoText name='Turno' values={[`${course.shift}`]} />
                </Col>
                <Col xs={3} className='my-3'>
                    <SubjectInfoText name='Nivel' values={[`${course.level}`]} />
                </Col>
            </Row>

            <Row>
                <Col xs={6} className='my-3'>
                    <SubjectInfoText
                        name='Días y horarios'
                        values={values.schedule.map((value) =>  `${value.day} de ${value.fromHour}:${value.fromMin} hs a ${value.toHour}:${value.toMin} hs`  )}
                    />
                </Col>
                <Col xs={6} className='my-3'>
                    <SubjectInfoText
                        name='Docente a cargo'
                        values={[`${values.teacher.lastName} ${values.teacher.name}`]}
                    />
                </Col>
            </Row>

            <Line mt={5} mb={5}/>

            <Row className="mb-3 justify-content-end">
                <Col xs="auto">
                    <Link to={`/courses/directive/closingperiod/${courseid}/${subjectid}`}>
                        <Button
                            className='btn button-primary button-m-sb'
                            content="Cierre de períodos"
                        />
                    </Link>
                </Col>
            </Row>
        </>
    )
}
export default CourseSubjetInfo