export const getFileContents = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    const readFile = function (event) {
      const buffer = reader.result
      resolve(buffer)
    }
    reader.addEventListener('load', readFile)
    reader.readAsBinaryString(file)
  })
}
